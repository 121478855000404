import React from "react";
import image1 from "../../../styles/images/concilia-solucoes/conciliador/spc/img1.jpg";
import image2 from "../../../styles/images/concilia-solucoes/conciliador/spc/img2.jpg";
import image3 from "../../../styles/images/concilia-solucoes/conciliador/spc/img3.jpg";
import background from "../../../styles/images/concilia-solucoes/conciliador/spc/background.jpg";
import "./loginspc.css";

type Props = {
  valueRecaptcha: string | null;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  openEsqueciMinhaSenha: () => void;
  change: (event: React.FormEvent<HTMLInputElement>) => void;
  setLoginRef: (ref: any) => void;
};

const LoginSPC: React.FC<Props> = ({ valueRecaptcha, handleSubmit, openEsqueciMinhaSenha, change, setLoginRef }) => {
  return (
    <body style={{ backgroundImage: `url(${background})`, margin: 0 }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <table
              id="Table_01"
              style={{ width: 1000, height: 682, border: 0, margin: 0, padding: 0 }}
              cellPadding={0}
              cellSpacing={0}
            >
              <tr>
                <td
                  align="left"
                  valign="top"
                  style={{
                    background: `url(${image1})`,
                    width: 1000,
                    height: 158,
                    backgroundRepeat: "no-repeat",
                    margin: 0,
                    padding: 0
                  }}
                ></td>
              </tr>
              <tr>
                <td
                  align="left"
                  valign="top"
                  style={{
                    background: `url(${image2})`,
                    width: 1000,
                    height: 365,
                    backgroundRepeat: "no-repeat",
                    margin: 0,
                    padding: 0
                  }}
                >
                  <div style={{ alignContent: "center", alignItems: "center" }}>
                    <table style={{ border: 0, width: 1000, margin: 0, padding: 0 }} cellPadding={0} cellSpacing={0}>
                      <tr>
                        <td
                          colSpan={3}
                          align="center"
                          className="cabecalhoLogin"
                          style={{ height: 25, width: 150, margin: 0, padding: 0 }}
                        ></td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          className="cabecalhoLogin"
                          style={{ width: 150, height: 50, margin: 0, padding: 0 }}
                        ></td>
                        <td align="center" style={{ height: 55, margin: 0, padding: 0 }} className="cabecalhoLogin">
                          <b>
                            Bem vindo ao <span style={{ color: "#ffe20a" }}>SPC CONCILIADOR </span>
                          </b>
                          <br />
                          Faça seu login e confira tudo sobre suas vendas
                          <br />
                          realizadas por cartões de crédito, débito e Ticket.
                        </td>
                        <td
                          align="center"
                          className="cabecalhoLogin"
                          style={{ width: 500, height: 50, margin: 0, padding: 0 }}
                        ></td>
                      </tr>

                      <tr>
                        <td align="center" className="cabecalhoLogin" style={{ width: 150, height: 15, margin: 0, padding: 0 }}>
                          {" "}
                        </td>
                        <td align="center" style={{ height: 15 }}>
                          <table style={{ width: "100%", padding: 0, border: 0, margin: 0 }} cellPadding={0} cellSpacing={0}>
                            <tr>
                              <td align="right" style={{ height: 15, margin: 0, padding: 5 }} className="cabecalhoLogin">
                                <span className="cabecalhoLogin">Login: </span>
                              </td>
                              <td align="left" style={{ height: 15, margin: 0, padding: 0 }}>
                                <input
                                  type="text"
                                  id="username"
                                  name="username"
                                  ref={ref => setLoginRef(ref)}
                                  required
                                  className="login"
                                  disabled={!valueRecaptcha}
                                  onChange={(e: any) => change(e)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style={{ height: 15, margin: 0, padding: 0 }} className="cabecalhoLogin">
                                <span className="cabecalhoLogin">Senha: </span>
                              </td>
                              <td align="left" style={{ height: 15, margin: 0, padding: 0 }}>
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  required
                                  className="login"
                                  disabled={!valueRecaptcha}
                                  onChange={(e: any) => change(e)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style={{ height: 15, margin: 0, padding: 0 }} className="cabecalhoLogin"></td>
                              <td align="left" style={{ height: 15, margin: 0, padding: 0 }}>
                                <div
                                  className="g-recaptcha"
                                  id="grecaptcha-target"
                                  data-sitekey="6LdFsiwpAAAAAHw5WPrCIDvCD09DrhtQPSIlsjEO"
                                  data-size="invisible"
                                ></div>
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style={{ height: 15, margin: 0, padding: 0 }} className="cabecalhoLogin"></td>
                              <td align="left" style={{ height: 15, margin: 0, padding: 0 }}>
                                <button className="botaoSpc" type="submit" />
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style={{ height: 15, margin: 0, padding: 0 }} className="cabecalhoLogin"></td>
                              <td align="left" style={{ height: 15, margin: 0, padding: 0 }}>
                                <span className="esquecisenha" onClick={openEsqueciMinhaSenha}>
                                  Esqueci minha senha
                                </span>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td
                          align="center"
                          style={{ height: 15, width: 500, margin: 0, padding: 0 }}
                          className="cabecalhoLogin"
                        ></td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  valign="bottom"
                  style={{
                    background: `url(${image3})`,
                    width: 1000,
                    height: 156,
                    backgroundRepeat: "no-repeat",
                    margin: 0,
                    padding: 0
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </body>
  );
};

export default LoginSPC;
