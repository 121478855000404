// Importação de componentes e bibliotecas necessárias
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from "react-router-dom";
import "../../styles/custom/forms/LoginForm.css";
import logo from "../../styles/images/concilia-solucoes/concilia_logo_azul_claro.png";
import ListErrors from "../ListErrors/ListErrors";
import LogoConcilia from "../LogoConcilia";

// Definição do tipo para as propriedades do componente
type Props = {
  errors: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  classes: any;
  change: (event: React.FormEvent<HTMLInputElement>) => void;
  showPassword: boolean;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: any) => void;
  loading: boolean;
  openEsqueciMinhaSenha: () => void;
  setLoginRef: (ref: any) => void;
  goToSignUp: () => void;
  valueRecaptcha: string | null;
};

// Componente funcional LoginConcilia
const LoginConcilia: React.FC<Props> = ({
  errors,
  handleSubmit,
  classes,
  change,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  loading,
  openEsqueciMinhaSenha,
  setLoginRef,
  goToSignUp,
  valueRecaptcha,
}) => {
  // Ref para o componente ReCAPTCHA
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  // Função chamada quando o ReCAPTCHA é resolvido
  const handleRecaptchaChange = (value: string | null) => {
    console.log("reCAPTCHA resolved with value:", value);
  };

  // Função chamada no envio do formulário
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Obtém o valor atual do ReCAPTCHA
    const recaptchaValue = recaptchaRef.current?.getValue();

    // Verifica se o ReCAPTCHA foi resolvido
    if (recaptchaValue) {
      // Lógica adicional antes de enviar o formulário, se necessário
      // ...

      // Chama a função original de handleSubmit
      handleSubmit(event);
    } else {
      // Exibe mensagem de erro ou lida de outra forma com o ReCAPTCHA não resolvido
      console.error("reCAPTCHA not resolved. Please complete the reCAPTCHA.");
    }
  };

  return (
    <div className={classNames(classes.fullWrap, true && classes.petal)}>
      <div className={classes.topBar}>
        <NavLink to="/">
          <img
            className="LoginForm_logo"
            src={logo}
            title={`${process.env.REACT_APP_VERSION}`}
            alt="Logo"
          />
        </NavLink>
      </div>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Seja bem-vindo ao
      </Typography>
      <section className={classes.pageFormWrap}>
        <div className={classes.btnArea}>
          <LogoConcilia classe="logo-conciliador" />
        </div>
      </section>
      <Typography
        variant="caption"
        className={classes.subtitle}
        gutterBottom
        align="center"
      >
        Informe seus dados para entrar no sistema
      </Typography>

      <ListErrors errors={errors} />

      <section className={classes.pageFormWrap}>
        <form onSubmit={handleFormSubmit}>
          <div>
            <FormControl className={classes.formControl}>
              <TextField
                inputRef={(ref) => setLoginRef(ref)}
                onChange={(e: any) => change(e)}
                autoFocus
                variant="outlined"
                name="username"
                placeholder="Seu usuário"
                label="Seu usuário"
                required
                inputProps={{ style: { textTransform: "uppercase" } }}
                className={classes.field}
              />
            </FormControl>
          </div>

          <div>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={(e: any) => change(e)}
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Sua senha"
                placeholder="Sua senha"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                className={classes.field}
              />
            </FormControl>
          </div>
          <div className={classes.optArea}>
            <Button
              size="small"
              onClick={openEsqueciMinhaSenha}
              className={classes.buttonLink}
            >
              <u>Esqueci minha senha</u>
            </Button>
            <Button
              size="small"
              onClick={goToSignUp}
              className={classes.buttonLink}
            >
              <u>Cadastre-se</u>
            </Button>
          </div>
          <div className={classes.btnArea}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              type="submit"
              disabled={loading}
            >
              Continuar
            </Button>
          </div>
          <div className={classes.btnArea}>
            {/* Componente ReCAPTCHA */}
            <ReCAPTCHA
              sitekey="6LdFsiwpAAAAAHw5WPrCIDvCD09DrhtQPSIlsjEO"
              onChange={handleRecaptchaChange}
              ref={recaptchaRef}
            />
          </div>
        </form>
      </section>
    </div>
  );
};

export default LoginConcilia;
