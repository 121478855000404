import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
export type Maybe<T> = T;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A custom scalar that handles Date */
  Date: any,
  /** A custom scalar that handles File */
  File: any,
  /** Long type */
  Long: any,
  /** Built-in java.math.BigDecimal */
  BigDecimal: any,
};


export type AgendamentoIntegracaoDao = {
   __typename?: 'AgendamentoIntegracaoDAO',
  codagendamentointegracao?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Scalars['String']>,
  unidadeconciliadora?: Maybe<Scalars['String']>,
  contacorrente?: Maybe<Scalars['Int']>,
  ajusteconsolidado?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  protocolo?: Maybe<Scalars['String']>,
  host?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  diretorioftp?: Maybe<Scalars['String']>,
  exibirenccliente?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  datainiciogeracao?: Maybe<Scalars['Date']>,
  dataalteracao?: Maybe<Scalars['Date']>,
  somenteajuste?: Maybe<Scalars['String']>,
};

export type AgendamentoIntegracaoDaoInput = {
  codagendamentointegracao?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Scalars['String']>,
  unidadeconciliadora?: Maybe<Scalars['String']>,
  contacorrente?: Maybe<Scalars['Int']>,
  ajusteconsolidado?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  datainiciogeracao?: Maybe<Scalars['Date']>,
  protocolo?: Maybe<Scalars['String']>,
  host?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  diretorioftp?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataalteracao?: Maybe<Scalars['String']>,
  exibirenccliente?: Maybe<Scalars['String']>,
  somenteajuste?: Maybe<Scalars['String']>,
};

export type AgendamentoIntegracaoEditInput = {
  codagendamentointegracao?: Maybe<Scalars['Int']>,
};

export type AgendamentoRelatorio = {
   __typename?: 'AgendamentoRelatorio',
  tipoTxt?: Maybe<Scalars['String']>,
  frequencia?: Maybe<Scalars['String']>,
  emails?: Maybe<Scalars['String']>,
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
};

export type AgendamentoRelatorioDao = {
   __typename?: 'AgendamentoRelatorioDAO',
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  relatorio?: Maybe<Scalars['String']>,
  frequencia?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['Date']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataultimageracao?: Maybe<Scalars['Date']>,
  parametros?: Maybe<Scalars['String']>,
};

export type AgendamentoRelatorioEditInput = {
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
};

export type AgendamentoRelatorioEmailDao = {
   __typename?: 'AgendamentoRelatorioEmailDAO',
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
  codagendamentorelatorioemail?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
};

export type AgendamentoRelatorioGenerico = {
   __typename?: 'AgendamentoRelatorioGenerico',
  agendamentoRelatorio?: Maybe<AgendamentoRelatorioDao>,
  agendamentoRelatorioEmail?: Maybe<Array<Maybe<AgendamentoRelatorioEmailDao>>>,
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
};

export type AgendamentoRelatorioInput = {
  relatorio?: Maybe<Scalars['String']>,
  parametros?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  frequencia?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['Date']>,
  emails?: Maybe<Array<Maybe<Scalars['String']>>>,
  codagendamentorelatorio?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
};

export type AlterarSenha = {
   __typename?: 'AlterarSenha',
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>,
  senhaAtual?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
};

export type AlterarSenha1 = {
   __typename?: 'AlterarSenha1',
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>,
  senhaAtual?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
};

export type AlunoDao = {
   __typename?: 'AlunoDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codpessoaconciliador?: Maybe<Scalars['Int']>,
  dataultimaalteracao?: Maybe<Scalars['Date']>,
  datacadastro?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  codigoaluno?: Maybe<Scalars['Int']>,
  nomealuno?: Maybe<Scalars['String']>,
  codpessoaresp?: Maybe<Scalars['Int']>,
  codAlunoLoja?: Maybe<Scalars['Int']>,
};

export type AlunoLojaDao = {
   __typename?: 'AlunoLojaDAO',
  codAlunoLoja?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codPessoaConciliador?: Maybe<Scalars['Int']>,
};

export type AlunoPlanoDao = {
   __typename?: 'AlunoPlanoDAO',
  codAlunoPlano?: Maybe<Scalars['Int']>,
  codAluno?: Maybe<Scalars['Int']>,
  datacadastro?: Maybe<Scalars['Date']>,
  valortotal?: Maybe<Scalars['BigDecimal']>,
  numeroparcelas?: Maybe<Scalars['Int']>,
  diaCobranca?: Maybe<Scalars['Int']>,
  mesPrimeiraCobranca?: Maybe<Scalars['Int']>,
  anoPrimeiraCobranca?: Maybe<Scalars['Int']>,
  valorparcela?: Maybe<Scalars['BigDecimal']>,
  bandeira?: Maybe<Scalars['String']>,
  nomecartao?: Maybe<Scalars['String']>,
  numerocartao?: Maybe<Scalars['String']>,
  tokenCard?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataInicial?: Maybe<Scalars['Date']>,
  dataFinal?: Maybe<Scalars['Date']>,
  observacao?: Maybe<Scalars['String']>,
  tipoPagamento?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  pan?: Maybe<Scalars['String']>,
  codPessoaConciliador?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
  dataValidadeCartao?: Maybe<Scalars['String']>,
  codPlanoOrigem?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
};

export type AlunoPlanoFinanciamentoDao = {
   __typename?: 'AlunoPlanoFinanciamentoDAO',
  codAlunoPlano?: Maybe<Scalars['Int']>,
  codAluno?: Maybe<Scalars['Int']>,
  codAlunoResp?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
};

export type AlunoPlanoParcelaDao = {
   __typename?: 'AlunoPlanoParcelaDAO',
  codAlunoPlanoParcela?: Maybe<Scalars['Int']>,
  codAlunoPlano?: Maybe<Scalars['Int']>,
  datacadastro?: Maybe<Scalars['Date']>,
  valorparcela?: Maybe<Scalars['BigDecimal']>,
  numeroparcela?: Maybe<Scalars['Int']>,
  dataCobranca?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  dataMudancaStatus?: Maybe<Scalars['Date']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  valorPagoOperadora?: Maybe<Scalars['BigDecimal']>,
  geraArquivoCliente?: Maybe<Scalars['String']>,
  statusfaturamento?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  dataPrevistaPagamento?: Maybe<Scalars['Date']>,
  autorizacao?: Maybe<Scalars['String']>,
  codParcelaOrigem?: Maybe<Scalars['Int']>,
  numeroTentativas?: Maybe<Scalars['Int']>,
  dataUltimaTentativa?: Maybe<Scalars['Date']>,
  codlinhavenda?: Maybe<Scalars['Int']>,
  codlinhapagamento?: Maybe<Scalars['Int']>,
  codlinhapagamentoajuste?: Maybe<Scalars['Int']>,
};

export type AlunoResponsavelDao = {
   __typename?: 'AlunoResponsavelDAO',
  codpessoa?: Maybe<Scalars['Int']>,
};

export type AlunoResponsavelLogDao = {
   __typename?: 'AlunoResponsavelLogDAO',
  codAlunoResponsavelLog?: Maybe<Scalars['Int']>,
  codResponsavelAnterior?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataAlteracao?: Maybe<Scalars['Date']>,
};

export type AnexoBean = {
   __typename?: 'AnexoBean',
  codDocumento?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type AprovacaoContratoConciliadorCrud = {
   __typename?: 'AprovacaoContratoConciliadorCRUD',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
};

export type AprovacaoContratoConciliadorPilotoCrud = {
   __typename?: 'AprovacaoContratoConciliadorPilotoCRUD',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
};

export type AprovarUrlInput = {
  identificacao: Scalars['String'],
  usuario: Scalars['String'],
  password: Scalars['String'],
  codoperadora?: Maybe<Scalars['Int']>,
};

export type ArquivoDownloadInput = {
  coddocumentos?: Maybe<Scalars['Int']>,
};

export type ArquivoGerado = {
   __typename?: 'ArquivoGerado',
  nomeArquivo?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
};

export type ArquivoRecorrenteDao = {
   __typename?: 'ArquivoRecorrenteDAO',
  codArquivo?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  dataRecebimento?: Maybe<Scalars['Date']>,
  dataProcessamento?: Maybe<Scalars['Date']>,
  mensagem?: Maybe<Scalars['String']>,
  codPessoa?: Maybe<Scalars['Int']>,
};

export type ArquivosrecebidosDao = {
   __typename?: 'ArquivosrecebidosDAO',
  codarquivo?: Maybe<Scalars['Int']>,
  titulo?: Maybe<Scalars['String']>,
  remetente?: Maybe<Scalars['String']>,
  datarecebimento?: Maybe<Scalars['Date']>,
  dataenvio?: Maybe<Scalars['Date']>,
  origem?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  nomearquivo?: Maybe<Scalars['String']>,
  codarquivoorigem?: Maybe<Scalars['Int']>,
  qtdtentativas?: Maybe<Scalars['Int']>,
  reprocessar?: Maybe<Scalars['String']>,
  geraArquivoCliente?: Maybe<Scalars['String']>,
  importadopos?: Maybe<Scalars['String']>,
  apagar?: Maybe<Scalars['String']>,
  prioridade?: Maybe<Scalars['Int']>,
  zip?: Maybe<Scalars['String']>,
  nomeOperadora?: Maybe<Scalars['String']>,
};

export type ArquivosrecebidosDaoInput = {
  codarquivo?: Maybe<Scalars['Int']>,
  titulo?: Maybe<Scalars['String']>,
  remetente?: Maybe<Scalars['String']>,
  datarecebimento?: Maybe<Scalars['Date']>,
  dataenvio?: Maybe<Scalars['Date']>,
  origem?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  nomearquivo?: Maybe<Scalars['String']>,
  codarquivoorigem?: Maybe<Scalars['Int']>,
  qtdtentativas?: Maybe<Scalars['Int']>,
  reprocessar?: Maybe<Scalars['String']>,
  geraArquivoCliente?: Maybe<Scalars['String']>,
  importadopos?: Maybe<Scalars['String']>,
  apagar?: Maybe<Scalars['String']>,
  prioridade?: Maybe<Scalars['Int']>,
  zip?: Maybe<Scalars['String']>,
};

export type ArquivoWebServiceDao = {
   __typename?: 'ArquivoWebServiceDAO',
  codArquivoWebService?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataBuscaArquivo?: Maybe<Scalars['Date']>,
  codParceiroRetaguarda?: Maybe<Scalars['String']>,
  numeroTentativas?: Maybe<Scalars['Int']>,
  gerouArquivo?: Maybe<Scalars['String']>,
  token?: Maybe<Scalars['String']>,
};

export type AssinaturasBean = {
   __typename?: 'AssinaturasBean',
  coddocumentosassinaturas?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type AssinaturasCrud = {
   __typename?: 'AssinaturasCRUD',
  listaBean?: Maybe<Array<Maybe<AssinaturasBean>>>,
};

export type Atendimento = {
   __typename?: 'Atendimento',
  listaPendenteAprovacao?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
};

export type BancoDao = {
   __typename?: 'BancoDAO',
  codbanco?: Maybe<Scalars['Int']>,
  banco?: Maybe<Scalars['String']>,
  agencia?: Maybe<Scalars['String']>,
  contacorrente?: Maybe<Scalars['String']>,
};

export type BandeirasDao = {
   __typename?: 'BandeirasDAO',
  codbandeiras?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  redeAdquerente?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  codigositef?: Maybe<Scalars['String']>,
};

export type BeanConcOperadoras = {
   __typename?: 'BeanConcOperadoras',
  nomeOperadora?: Maybe<Scalars['String']>,
  roteada?: Maybe<Scalars['String']>,
  identificador?: Maybe<Scalars['String']>,
  codOperadora?: Maybe<Scalars['Int']>,
  conciOperadora?: Maybe<ConciOperadoraDao>,
  operadorasSelecionadas?: Maybe<Array<Maybe<ConciOperadoraDao>>>,
  codigoExternoInformado?: Maybe<Scalars['String']>,
};

export type BeanFiltroRelatorioTelaAntecipadosInput = {
  codarquivo?: Maybe<Scalars['Int']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  datapagamentoate?: Maybe<Scalars['Date']>,
  datarecebimentode?: Maybe<Scalars['Date']>,
  datarecebimentoate?: Maybe<Scalars['Date']>,
  datapagamentooriginalde?: Maybe<Scalars['Date']>,
  datapagamentooriginalate?: Maybe<Scalars['Date']>,
  datavendade?: Maybe<Scalars['Date']>,
  datavendaate?: Maybe<Scalars['Date']>,
  analiticoSintetico?: Maybe<Scalars['String']>,
  exibircv?: Maybe<Scalars['String']>,
  exportar?: Maybe<Scalars['String']>,
  stsConciliacao?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  agrupamento?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  operadoras?: Maybe<Array<Maybe<Scalars['String']>>>,
  contaCorrente?: Maybe<Scalars['Int']>,
};

export type BeanFiltroRelatorioTelaInput = {
  codarquivo?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  datapagamentoate?: Maybe<Scalars['Date']>,
  datavendade?: Maybe<Scalars['Date']>,
  datavendaate?: Maybe<Scalars['Date']>,
  datarecebimentode?: Maybe<Scalars['Date']>,
  datarecebimentoate?: Maybe<Scalars['Date']>,
  datavencimentode?: Maybe<Scalars['Date']>,
  datavencimentoate?: Maybe<Scalars['Date']>,
  terminal?: Maybe<Scalars['String']>,
  terminais?: Maybe<Scalars['String']>,
  agrupamento?: Maybe<Scalars['String']>,
  agrupa?: Maybe<Scalars['String']>,
  analiticoSintetico?: Maybe<Scalars['String']>,
  informacoes?: Maybe<Scalars['String']>,
  exportar?: Maybe<Scalars['String']>,
  stsConciliacao?: Maybe<Array<Maybe<Scalars['String']>>>,
  produtos?: Maybe<Array<Maybe<Scalars['String']>>>,
  bandeiras?: Maybe<Array<Maybe<Scalars['String']>>>,
  bandeira?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  contaCorrente?: Maybe<Scalars['Int']>,
  limitedias?: Maybe<Scalars['Int']>,
  nossonumero?: Maybe<Scalars['String']>,
  filtranossonumero?: Maybe<Scalars['Boolean']>,
  meioCaptura?: Maybe<Scalars['String']>,
  redeAdquirente?: Maybe<Scalars['String']>,
  filtrarRedeAdquirente?: Maybe<Scalars['Boolean']>,
  qtdeMeses?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  recorrente?: Maybe<Scalars['String']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  operadoras?: Maybe<Array<Maybe<Scalars['String']>>>,
  tipoproduto?: Maybe<Scalars['String']>,
  datacapturade?: Maybe<Scalars['Date']>,
  datacapturaate?: Maybe<Scalars['Date']>,
};

export type BeanImportacaoUnidadeConciliadoraPlanilha = {
   __typename?: 'BeanImportacaoUnidadeConciliadoraPlanilha',
  cnpj?: Maybe<Scalars['String']>,
  nomeUnidade?: Maybe<Scalars['String']>,
  cielo?: Maybe<Scalars['String']>,
  redecard?: Maybe<Scalars['String']>,
  banrisul?: Maybe<Scalars['String']>,
  dacasa?: Maybe<Scalars['String']>,
  getnet?: Maybe<Scalars['String']>,
  global?: Maybe<Scalars['String']>,
  simulador?: Maybe<Scalars['String']>,
  stone?: Maybe<Scalars['String']>,
  banescard?: Maybe<Scalars['String']>,
  banescardRoteada?: Maybe<Scalars['String']>,
  goodcard?: Maybe<Scalars['String']>,
  goodCardRoteada?: Maybe<Scalars['String']>,
  policard?: Maybe<Scalars['String']>,
  policardRoteada?: Maybe<Scalars['String']>,
  amex?: Maybe<Scalars['String']>,
  avista?: Maybe<Scalars['String']>,
  sodexho?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
  comprocard?: Maybe<Scalars['String']>,
  cetelem?: Maybe<Scalars['String']>,
  valeshop?: Maybe<Scalars['String']>,
  maxxcard?: Maybe<Scalars['String']>,
  valecard?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['String']>,
};

export type BeanTelaContrato = {
   __typename?: 'BeanTelaContrato',
  selecionado?: Maybe<Scalars['Boolean']>,
  codpessoa?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  dataValidadeReserva?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  nomeOperadora?: Maybe<Scalars['String']>,
  codUltimaRevenda?: Maybe<Scalars['Int']>,
  codrevenda?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};


export type BucketS3AmazonCrud = {
   __typename?: 'BucketS3AmazonCRUD',
  editValues?: Maybe<BucketS3AmazonDao>,
  searchValues?: Maybe<BucketS3AmazonDao>,
};

export type BucketS3AmazonCrudInput = {
  editValues?: Maybe<BucketS3AmazonDaoInput>,
  searchValues?: Maybe<BucketS3AmazonDaoInput>,
};

export type BucketS3AmazonDao = {
   __typename?: 'BucketS3AmazonDAO',
  codbuckets3amazon?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  keyid?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  pasta?: Maybe<Scalars['String']>,
  bucketname?: Maybe<Scalars['String']>,
};

export type BucketS3AmazonDaoInput = {
  codbuckets3amazon?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  keyid?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  pasta?: Maybe<Scalars['String']>,
  bucketname?: Maybe<Scalars['String']>,
};

export type CanUploadResult = {
   __typename?: 'CanUploadResult',
  canUpload?: Maybe<Scalars['Boolean']>,
  exibirUrl?: Maybe<Scalars['Boolean']>,
  msg?: Maybe<Scalars['String']>,
  descMsg?: Maybe<Scalars['String']>,
  documentos?: Maybe<Array<Maybe<DocumentosBean>>>,
};

export type ChamadosAnexoDao = {
   __typename?: 'ChamadosAnexoDAO',
  codChamadosAnexo?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codChamadosInteracoes?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
};

export type ChamadosDao = {
   __typename?: 'ChamadosDAO',
  codChamado?: Maybe<Scalars['Int']>,
  codNiveisSla?: Maybe<Scalars['Int']>,
  codMotivos?: Maybe<Scalars['Int']>,
  codPessoaAbriuChamado?: Maybe<Scalars['Int']>,
  codPessoaPaiCliente?: Maybe<Scalars['Int']>,
  codRevenda?: Maybe<Scalars['Int']>,
  codRevendaDeRevenda?: Maybe<Scalars['Int']>,
  codSetor?: Maybe<Scalars['Int']>,
  codResponsavel?: Maybe<Scalars['Int']>,
  codResponsavelPrimeiroAtendimento?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataUltimaInteracao?: Maybe<Scalars['Date']>,
  dataInicioSla?: Maybe<Scalars['Date']>,
  statusInterno?: Maybe<Scalars['String']>,
  statusExterno?: Maybe<Scalars['String']>,
  tempoSla?: Maybe<Scalars['Int']>,
  titulo?: Maybe<Scalars['String']>,
};

export type ChamadosInteracoesDao = {
   __typename?: 'ChamadosInteracoesDAO',
  codChamadosInteracoes?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  texto?: Maybe<Scalars['String']>,
  Interno?: Maybe<Scalars['String']>,
  solicitaInteracao?: Maybe<Scalars['String']>,
  visualizada?: Maybe<Scalars['String']>,
};

export type ChamadosSetorDao = {
   __typename?: 'ChamadosSetorDAO',
  codChamadoSetor?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codSetor?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
};

export type ChamadosSlaDao = {
   __typename?: 'ChamadosSlaDAO',
  codChamadosSla?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codNiveisSla?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
};

export type ChamadosStatusDao = {
   __typename?: 'ChamadosStatusDAO',
  codChamadoStatus?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  statusInterno?: Maybe<Scalars['String']>,
  statusExterno?: Maybe<Scalars['String']>,
};

export type ChamadosTempoDao = {
   __typename?: 'ChamadosTempoDAO',
  codChamadosTempo?: Maybe<Scalars['Int']>,
  codChamado?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  inicio?: Maybe<Scalars['Date']>,
  fim?: Maybe<Scalars['Date']>,
  tempoTotal?: Maybe<Scalars['Int']>,
  valorGasto?: Maybe<Scalars['Float']>,
  fechamentoAutomatico?: Maybe<Scalars['String']>,
};

export type CidadesDao = {
   __typename?: 'CidadesDAO',
  codcidade?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  codibge?: Maybe<Scalars['String']>,
};

export type CieloBean = {
   __typename?: 'CieloBean',
  cnpj?: Maybe<Scalars['String']>,
  ec?: Maybe<Scalars['String']>,
};

export type ClienteCrud = {
   __typename?: 'ClienteCRUD',
  token?: Maybe<Scalars['String']>,
  pessoa?: Maybe<PessoaGenerica>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  listPessoa?: Maybe<Array<Maybe<PessoaDao>>>,
  exibirUsuario?: Maybe<Scalars['Boolean']>,
  codEstabelecimentoBancario?: Maybe<Scalars['String']>,
  agencia?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  dias?: Maybe<Array<Maybe<ClienteSemanaDao>>>,
  diasCliente?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<ClienteDao>,
  searchValues?: Maybe<ClienteDao>,
  enderecosAdicionais?: Maybe<Array<Maybe<EnderecoAdicionalDao>>>,
};

export type ClienteCrudInput = {
  token?: Maybe<Scalars['String']>,
  pessoa?: Maybe<PessoaGenericaInput>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  exibirUsuario?: Maybe<Scalars['Boolean']>,
  codEstabelecimentoBancario?: Maybe<Scalars['String']>,
  agencia?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  diasCliente?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<ClienteDaoInput>,
  searchValues?: Maybe<ClienteDaoInput>,
};

export type ClienteDao = {
   __typename?: 'ClienteDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codcidade?: Maybe<Scalars['Int']>,
  iss?: Maybe<Scalars['Float']>,
  inss?: Maybe<Scalars['Float']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  pagina?: Maybe<Scalars['String']>,
  aceite?: Maybe<Scalars['String']>,
  codbanco?: Maybe<Scalars['Int']>,
};

export type ClienteDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codcidade?: Maybe<Scalars['Int']>,
  iss?: Maybe<Scalars['Float']>,
  inss?: Maybe<Scalars['Float']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  pagina?: Maybe<Scalars['String']>,
  aceite?: Maybe<Scalars['String']>,
  codbanco?: Maybe<Scalars['Int']>,
};

export type ClienteSemanaDao = {
   __typename?: 'ClienteSemanaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  diasemana?: Maybe<Scalars['Int']>,
};

export type ClientesLinhaBean = {
   __typename?: 'ClientesLinhaBean',
  nomearquivo?: Maybe<Scalars['String']>,
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  statusArquivo?: Maybe<Scalars['String']>,
  datarecebimento?: Maybe<Scalars['Date']>,
  dataprevistapagamento?: Maybe<Scalars['Date']>,
  datatransacao?: Maybe<Scalars['Date']>,
  valorbruto?: Maybe<Scalars['Float']>,
  numparcela?: Maybe<Scalars['Int']>,
  qtdparcelas?: Maybe<Scalars['Int']>,
  nossonumero?: Maybe<Scalars['String']>,
  bandeira?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  numeroautorizacao?: Maybe<Scalars['String']>,
};

export type CombosPlanosConciliadoresTaxa = {
   __typename?: 'CombosPlanosConciliadoresTaxa',
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codproduto?: Maybe<Scalars['Int']>,
  operadora?: Maybe<Scalars['Boolean']>,
  produto?: Maybe<Scalars['Boolean']>,
  plano?: Maybe<Scalars['Boolean']>,
  avancar?: Maybe<Scalars['Boolean']>,
};

export type CombosPlanosTaxa = {
   __typename?: 'CombosPlanosTaxa',
  codplano?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codproduto?: Maybe<Scalars['Int']>,
  operadora?: Maybe<Scalars['Boolean']>,
  produto?: Maybe<Scalars['Boolean']>,
  plano?: Maybe<Scalars['Boolean']>,
  avancar?: Maybe<Scalars['Boolean']>,
};

export type CombosTaxa = {
   __typename?: 'CombosTaxa',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codproduto?: Maybe<Scalars['Int']>,
  externo?: Maybe<Scalars['Boolean']>,
  operadora?: Maybe<Scalars['Boolean']>,
  produto?: Maybe<Scalars['Boolean']>,
  pessoa?: Maybe<Scalars['Boolean']>,
  avancar?: Maybe<Scalars['Boolean']>,
};

export type CombosVerificaArquivos = {
   __typename?: 'CombosVerificaArquivos',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipos?: Maybe<Array<Maybe<Scalars['String']>>>,
  externoEnabled?: Maybe<Scalars['Boolean']>,
  operadoraEnabled?: Maybe<Scalars['Boolean']>,
  tipoEnabled?: Maybe<Scalars['Boolean']>,
  pessoaEnabled?: Maybe<Scalars['Boolean']>,
  avancar?: Maybe<Scalars['Boolean']>,
};

export type ConciliadoresAguardandoContatoVanCrud = {
   __typename?: 'ConciliadoresAguardandoContatoVanCRUD',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
};

export type ConciliadoresAguardandoExtratoVanCrud = {
   __typename?: 'ConciliadoresAguardandoExtratoVanCRUD',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  urlPdf?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  listaTela?: Maybe<Array<Maybe<BeanTelaContrato>>>,
  somenteDocumentosAprovados?: Maybe<Scalars['Boolean']>,
};

export type ConciliadoresCrud = {
   __typename?: 'ConciliadoresCRUD',
  controleTela?: Maybe<Scalars['String']>,
  cnpjBusca?: Maybe<Scalars['String']>,
  mensagemTela?: Maybe<Scalars['String']>,
  pessoaConciliadoraRecuperada?: Maybe<PessoaGenerica>,
  operadorasRoteadasEscolhidas?: Maybe<Array<Maybe<ConciOperadoraDao>>>,
  protegeStatus?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  exibeLinkOperadorasRoteadas?: Maybe<Scalars['String']>,
  codPessoaConciliadora?: Maybe<Scalars['String']>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codEstabelecimento?: Maybe<Scalars['Int']>,
  codConciParceiro?: Maybe<Scalars['Int']>,
  codexternoamex?: Maybe<Scalars['String']>,
  codexternosodexo?: Maybe<Scalars['String']>,
  codexternopolicard?: Maybe<Scalars['String']>,
  codexternobanescard?: Maybe<Scalars['String']>,
  codexternoticket?: Maybe<Scalars['String']>,
  codexternogoodcard?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  apelido?: Maybe<Scalars['String']>,
  arquivoaglutinado?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  identificador?: Maybe<Scalars['String']>,
  parceiroRetaguarda?: Maybe<Scalars['String']>,
  urlParceiroRetaguarda?: Maybe<Scalars['String']>,
  tokenSeguranca?: Maybe<Scalars['String']>,
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  dataInicioCaptura?: Maybe<Scalars['Date']>,
  unidadesSelecionadas?: Maybe<Array<Maybe<ConciParceiroUnidadeDao>>>,
  unidadesExistentes?: Maybe<Array<Maybe<ConciParceiroUnidadeDao>>>,
  codTefsSelecionadas?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ConciliadoresDao = {
   __typename?: 'ConciliadoresDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codramoatividade?: Maybe<Scalars['Int']>,
  codrevenda?: Maybe<Scalars['Int']>,
  dataValidadeReserva?: Maybe<Scalars['Date']>,
  codUltimaRevenda?: Maybe<Scalars['Int']>,
  codpessoarealizacadastro?: Maybe<Scalars['Int']>,
  conciliaregistrocancelado?: Maybe<Scalars['String']>,
  codrevendaderevenda?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  apelido?: Maybe<Scalars['String']>,
  codexternounidade?: Maybe<Scalars['String']>,
  tef?: Maybe<Scalars['String']>,
  gerarnossonumero?: Maybe<Scalars['String']>,
};

export type ConciliadoresEstabelecimentoCrud = {
   __typename?: 'ConciliadoresEstabelecimentoCRUD',
  tipoContrato?: Maybe<Scalars['String']>,
  codPessoaAutoIncrement?: Maybe<Scalars['Int']>,
  listaOperadoras?: Maybe<Array<Maybe<OperadorasDao>>>,
};

export type ConciliadoresImportacaoBean = {
   __typename?: 'ConciliadoresImportacaoBean',
  tipo?: Maybe<Scalars['String']>,
  nome_codigo?: Maybe<Scalars['String']>,
  ramo_codamex?: Maybe<Scalars['String']>,
  estado_codsod?: Maybe<Scalars['String']>,
  cidade_operadora?: Maybe<Scalars['String']>,
  bairro_nome?: Maybe<Scalars['String']>,
  rua?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tipopessoa?: Maybe<Scalars['String']>,
  cpfcnpj?: Maybe<Scalars['String']>,
};

export type ConciliadoresInclusaoRapidaCrud = {
   __typename?: 'ConciliadoresInclusaoRapidaCRUD',
  mestre?: Maybe<PessoaGenerica>,
  usuario?: Maybe<UsuariosDao>,
  listDocumentos?: Maybe<Array<Maybe<ProcedimentosOperadoraDao>>>,
  codigo?: Maybe<Scalars['String']>,
  detalhe?: Maybe<Scalars['String']>,
  codigoPlano?: Maybe<Scalars['Int']>,
};

export type ConciliadoresIntegSoftExpressDao = {
   __typename?: 'ConciliadoresIntegSoftExpressDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type ConciliadoresIntegSoftExpressDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type ConciliadoresNaoTrabalhamOperadora = {
   __typename?: 'ConciliadoresNaoTrabalhamOperadora',
  selecionados?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ConciliadoresUsuarioMestre = {
   __typename?: 'ConciliadoresUsuarioMestre',
  pessoa?: Maybe<PessoaDao>,
  piloto?: Maybe<Scalars['Boolean']>,
  excecaoconciliadores?: Maybe<Scalars['Boolean']>,
};

export type ConciliadorGenerica = {
   __typename?: 'ConciliadorGenerica',
  conciliadores?: Maybe<ConciliadoresDao>,
  pessoa?: Maybe<PessoaGenerica>,
  conciOperadora?: Maybe<Array<Maybe<BeanConcOperadoras>>>,
  codExterno?: Maybe<Scalars['String']>,
  nomeUnidade?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  usuario?: Maybe<UsuariosDao>,
  selecionado?: Maybe<Scalars['Boolean']>,
  codoperadora?: Maybe<Scalars['Int']>,
  migracaoConciliadoresTela?: Maybe<MigracaoConciliadoresTela>,
  tipoContrato?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  conciliadoresFiltro?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  reservado?: Maybe<Scalars['Boolean']>,
  dataCancelamentoDe?: Maybe<Scalars['Date']>,
  dataCancelamentoAte?: Maybe<Scalars['Date']>,
  naogerarnossonumero?: Maybe<Scalars['Boolean']>,
};

export type ConciliadorOperadora = {
   __typename?: 'ConciliadorOperadora',
  conciliadores?: Maybe<ConciliadoresDao>,
  pessoa?: Maybe<PessoaGenerica>,
  conciOperadora?: Maybe<ConciOperadoraDao>,
  display?: Maybe<Scalars['String']>,
  cnpjNaoTrabalhaOperadora?: Maybe<Scalars['Boolean']>,
};

export type ConciliaSelecionados = {
   __typename?: 'ConciliaSelecionados',
  s701?: Maybe<Scalars['String']>,
  s702?: Maybe<Scalars['String']>,
  codigo?: Maybe<Scalars['Long']>,
  a701?: Maybe<LabelValor>,
  a702?: Maybe<LabelValor>,
};

export type ConciOperadoraBean = {
   __typename?: 'ConciOperadoraBean',
  ec?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  apelido?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type ConciOperadoraDao = {
   __typename?: 'ConciOperadoraDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  vencimento?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
  arquivoaglutinado?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  codexternoamex?: Maybe<Scalars['String']>,
  codexternosodexo?: Maybe<Scalars['String']>,
  codexternopolicard?: Maybe<Scalars['String']>,
  codexternobanescard?: Maybe<Scalars['String']>,
  codexternoticket?: Maybe<Scalars['String']>,
  codexternogoodcard?: Maybe<Scalars['String']>,
  datainiciorecepcaoarquivos?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  canceladoAutomaticamente?: Maybe<Scalars['String']>,
  identificador?: Maybe<Scalars['String']>,
  contratopiloto?: Maybe<Scalars['String']>,
  codexternosubstitutivo?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
};

export type ConciOperadoraLiberadaDao = {
   __typename?: 'ConciOperadoraLiberadaDAO',
  codPessoaPai?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  cancelado?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  downloadAutorizacao?: Maybe<Scalars['String']>,
  downloadTermoAdesao?: Maybe<Scalars['String']>,
};

export type ConciOperadoraRoteadaDao = {
   __typename?: 'ConciOperadoraRoteadaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoaroteadora?: Maybe<Scalars['Int']>,
  codoperadoraroteadora?: Maybe<Scalars['Int']>,
  codexternoroteadora?: Maybe<Scalars['String']>,
};

export type ConciOperadoraSemUnidadeDao = {
   __typename?: 'ConciOperadoraSemUnidadeDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type ConciOperadoraStatusDao = {
   __typename?: 'ConciOperadoraStatusDAO',
  codConciOperadoraStatus?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  passou?: Maybe<Scalars['String']>,
  codPessoaLogada?: Maybe<Scalars['Int']>,
  datamigracao?: Maybe<Scalars['Date']>,
};

export type ConciParceiroDao = {
   __typename?: 'ConciParceiroDAO',
  codConciParceiro?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  urlParceiroRetaguarda?: Maybe<Scalars['String']>,
  parceiroRetaguarda?: Maybe<Scalars['String']>,
  tokenSeguranca?: Maybe<Scalars['String']>,
  dataInicioCaptura?: Maybe<Scalars['Date']>,
};

export type ConciParceiroUnidade = {
   __typename?: 'ConciParceiroUnidade',
  conciParceiroDAO?: Maybe<ConciParceiroDao>,
  listaUnidades?: Maybe<Array<Maybe<ConciParceiroUnidadeDao>>>,
};

export type ConciParceiroUnidadeDao = {
   __typename?: 'ConciParceiroUnidadeDAO',
  codConciParceiro?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type ContratoBean = {
   __typename?: 'ContratoBean',
  contratoDAO?: Maybe<ContratoDao>,
  nomePessoa?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type ContratoDao = {
   __typename?: 'ContratoDAO',
  codContrato?: Maybe<Scalars['Int']>,
  codPessoaConciliador?: Maybe<Scalars['Int']>,
  codPessoaInclusao?: Maybe<Scalars['Int']>,
  valorContrato?: Maybe<Scalars['BigDecimal']>,
  valorExcedente?: Maybe<Scalars['BigDecimal']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  tipoContrato?: Maybe<Scalars['String']>,
  dataalteracao?: Maybe<Scalars['Date']>,
};

export type ContratoValorContratoDao = {
   __typename?: 'ContratoValorContratoDAO',
  codContrato?: Maybe<Scalars['Int']>,
  codValorContratoConciliador?: Maybe<Scalars['Int']>,
};

export type DadosNfeCrud = {
   __typename?: 'DadosNfeCRUD',
  lista?: Maybe<Array<Maybe<DadosNfeDao>>>,
  editValues?: Maybe<DadosNfeDao>,
};

export type DadosNfeCrudInput = {
  editValues?: Maybe<DadosNfeDaoInput>,
  searchValues?: Maybe<DadosNfeDaoInput>,
};

export type DadosNfeDao = {
   __typename?: 'DadosNfeDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  senhacertificado?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  senhaemail?: Maybe<Scalars['String']>,
  smtp?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  naturezaoperacao?: Maybe<Scalars['String']>,
  regimeespecialtributacao?: Maybe<Scalars['String']>,
  opatantesimplesnacional?: Maybe<Scalars['String']>,
  incentivadorcultural?: Maybe<Scalars['String']>,
  pis?: Maybe<Scalars['Float']>,
  cofins?: Maybe<Scalars['Float']>,
  ir?: Maybe<Scalars['Float']>,
  csll?: Maybe<Scalars['Float']>,
  iss?: Maybe<Scalars['Float']>,
  codcidade?: Maybe<Scalars['Int']>,
  cnae?: Maybe<Scalars['String']>,
  valorminimocalculoir?: Maybe<Scalars['Float']>,
  valorminimocalculopiscofinscsll?: Maybe<Scalars['Float']>,
  subitem?: Maybe<Scalars['String']>,
  codigotributacaomunicipio?: Maybe<Scalars['String']>,
  descservico?: Maybe<Scalars['String']>,
  desccnae?: Maybe<Scalars['String']>,
  descsubitem?: Maybe<Scalars['String']>,
  codpessoaemissao?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type DadosNfeDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  senhacertificado?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  senhaemail?: Maybe<Scalars['String']>,
  smtp?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  naturezaoperacao?: Maybe<Scalars['String']>,
  regimeespecialtributacao?: Maybe<Scalars['String']>,
  opatantesimplesnacional?: Maybe<Scalars['String']>,
  incentivadorcultural?: Maybe<Scalars['String']>,
  pis?: Maybe<Scalars['Float']>,
  cofins?: Maybe<Scalars['Float']>,
  ir?: Maybe<Scalars['Float']>,
  csll?: Maybe<Scalars['Float']>,
  iss?: Maybe<Scalars['Float']>,
  codcidade?: Maybe<Scalars['Int']>,
  cnae?: Maybe<Scalars['String']>,
  valorminimocalculoir?: Maybe<Scalars['Float']>,
  valorminimocalculopiscofinscsll?: Maybe<Scalars['Float']>,
  subitem?: Maybe<Scalars['String']>,
  codigotributacaomunicipio?: Maybe<Scalars['String']>,
  descservico?: Maybe<Scalars['String']>,
  desccnae?: Maybe<Scalars['String']>,
  descsubitem?: Maybe<Scalars['String']>,
  codpessoaemissao?: Maybe<Scalars['Int']>,
  logo?: Maybe<Scalars['File']>,
  certificado?: Maybe<Scalars['File']>,
};

export type DashBoardGraph = {
   __typename?: 'DashBoardGraph',
  codpessoapai?: Maybe<Scalars['Int']>,
  seriesVendaOperadora?: Maybe<Array<Maybe<VendaOperadora>>>,
  seriesVendaTipo?: Maybe<Array<Maybe<VendaOperadora>>>,
  categoriaVendaHora?: Maybe<Array<Maybe<Scalars['String']>>>,
  seriesVendaHora?: Maybe<Array<Maybe<Scalars['Float']>>>,
  categoriaTaxaMedia?: Maybe<Array<Maybe<Scalars['String']>>>,
  seriesTaxaMedia?: Maybe<Array<Maybe<VendaOperadora>>>,
  seriesTicketMedio?: Maybe<Array<Maybe<VendaOperadora>>>,
  categoriaVendaDia?: Maybe<Array<Maybe<Scalars['String']>>>,
  seriesVendaDia?: Maybe<Array<Maybe<Scalars['Float']>>>,
  mensagem?: Maybe<Scalars['String']>,
};

export type DashBoardInput = {
  empresa?: Maybe<Scalars['String']>,
  registro?: Maybe<Scalars['String']>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
};

export type DataPosicaoContrato = {
   __typename?: 'DataPosicaoContrato',
  id?: Maybe<Scalars['Int']>,
  parentId?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  identifier?: Maybe<Scalars['String']>,
  leaf?: Maybe<Scalars['Boolean']>,
  type?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  ultimoStatus?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['Int']>,
  operadora?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  edita?: Maybe<Scalars['Boolean']>,
  codPessoaPai?: Maybe<Scalars['Int']>,
  selecionado?: Maybe<Scalars['Boolean']>,
  dataRecebimentoPrimeiroArquivo?: Maybe<Scalars['Date']>,
  childCount?: Maybe<Scalars['Int']>,
};


export type DeletarIntegracaoConciliaAjusteInput = {
  codorigemajuste?: Maybe<Scalars['Int']>,
};

export type DeletarTicketInput = {
  editValues?: Maybe<ReprocessarTicketInput>,
};

export type DeleteEstabelecimentoOperadoraInput = {
  codoperadora?: Maybe<Scalars['String']>,
  codPessoa?: Maybe<Scalars['String']>,
  codEstabelecimento?: Maybe<Scalars['String']>,
};

export type DetalheTicket = {
   __typename?: 'DetalheTicket',
  clientesLinha?: Maybe<Array<Maybe<ClientesLinhaBean>>>,
  clientesUpdateLinha?: Maybe<Array<Maybe<ClientesLinhaBean>>>,
  clientesOlxLinha?: Maybe<Array<Maybe<ClientesLinhaBean>>>,
  clientesLinhaDesconsiderados?: Maybe<Array<Maybe<ClientesLinhaBean>>>,
  clientesLinhaCancelados?: Maybe<Array<Maybe<ClientesLinhaBean>>>,
  operadoraLinha?: Maybe<Array<Maybe<OperadoraLinhaBean>>>,
  operadoraLiquidacaoLinha?: Maybe<Array<Maybe<OperadoraLinhaBean>>>,
  operadoraVendaLinha?: Maybe<Array<Maybe<OperadoraLinhaBean>>>,
  operadoraPrevisaoLinha?: Maybe<Array<Maybe<OperadoraLinhaBean>>>,
  antecipadoLinha?: Maybe<Array<Maybe<OperadoraLinhaBean>>>,
};

export type Documento = {
   __typename?: 'Documento',
  nome?: Maybe<Scalars['String']>,
};

export type DocumentoRedecardBean = {
   __typename?: 'DocumentoRedecardBean',
  pvMatriz?: Maybe<Scalars['String']>,
  cnpjMatriz?: Maybe<Scalars['String']>,
  sigla?: Maybe<Scalars['String']>,
};

export type DocumentosAprovacaoCrud = {
   __typename?: 'DocumentosAprovacaoCRUD',
  OPERADORA_CIELO?: Maybe<Scalars['Int']>,
  OPERADORA_REDECARD?: Maybe<Scalars['Int']>,
  ATIVO?: Maybe<Scalars['String']>,
  listaBean?: Maybe<Array<Maybe<DocumentosBean>>>,
  imagem?: Maybe<Scalars['String']>,
  x?: Maybe<Scalars['Long']>,
  y?: Maybe<Scalars['Long']>,
  nomeArquivo?: Maybe<Scalars['String']>,
  nomeArquivoNovo?: Maybe<Scalars['String']>,
  exibirImagem?: Maybe<Scalars['Boolean']>,
  loginUsuario?: Maybe<Scalars['String']>,
  nomeOperadora?: Maybe<Scalars['String']>,
  mensagemTela?: Maybe<Scalars['String']>,
  mensagemTelaAnexo?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  edita?: Maybe<Scalars['Boolean']>,
  diretorioSugerido?: Maybe<Scalars['String']>,
  arquivoCriado?: Maybe<Scalars['Boolean']>,
  documentoRedecardBean?: Maybe<DocumentoRedecardBean>,
  conciOperadoras?: Maybe<Array<Maybe<ConciOperadoraBean>>>,
  listaAnexo?: Maybe<Array<Maybe<AnexoBean>>>,
  coddocumentoassinatura?: Maybe<Scalars['Int']>,
};

export type DocumentosAssinaturasDao = {
   __typename?: 'DocumentosAssinaturasDAO',
  coddocumentosassinaturas?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type DocumentosBean = {
   __typename?: 'DocumentosBean',
  coddocumentos?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  codpessoapostagem?: Maybe<Scalars['Int']>,
  codpessoaaltstatus?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataalteracao?: Maybe<Scalars['Date']>,
  displayEditar?: Maybe<Scalars['Boolean']>,
  displayRejeitar?: Maybe<Scalars['Boolean']>,
  tipo?: Maybe<Scalars['String']>,
  nomeOperadora?: Maybe<Scalars['String']>,
  loginUsuario?: Maybe<Scalars['String']>,
};

export type DocumentosCrud = {
   __typename?: 'DocumentosCRUD',
  listaBean?: Maybe<Array<Maybe<DocumentosBean>>>,
};

export type DocumentosDao = {
   __typename?: 'DocumentosDAO',
  coddocumentos?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  codpessoapostagem?: Maybe<Scalars['Int']>,
  codpessoaaltstatus?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataalteracao?: Maybe<Scalars['Date']>,
  tipo?: Maybe<Scalars['String']>,
  datarejeicao?: Maybe<Scalars['Date']>,
};

export type DocumentosEnviadosOperadoraDao = {
   __typename?: 'DocumentosEnviadosOperadoraDAO',
  coddocumento?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  obs?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataenvio?: Maybe<Scalars['Date']>,
};

export type DocumentosManipulador = {
   __typename?: 'DocumentosManipulador',
  diretorioSugerido?: Maybe<Scalars['String']>,
  documentoRedecardBean?: Maybe<DocumentoRedecardBean>,
  caminho?: Maybe<Scalars['String']>,
};

export type DownloadAnalisePesquisaTicketInput = {
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type DownloadExtratosOriginaisInput = {
  codarquivoDownload?: Maybe<Scalars['String']>,
};

export type DownloadManutencaoArquivosInput = {
  codarquivoDownload?: Maybe<Scalars['String']>,
};

export type EditOperadoraStatus = {
   __typename?: 'EditOperadoraStatus',
  OperadoraStatus?: Maybe<OperadoraStatus>,
};

export type EmailPilotoRevendaDao = {
   __typename?: 'EmailPilotoRevendaDAO',
  codEmailPilotoRevenda?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
};

export type EmailViewDao = {
   __typename?: 'EmailViewDAO',
  dscodigo?: Maybe<Scalars['Int']>,
  emaildest?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  titulo?: Maybe<Scalars['String']>,
  dataenvio?: Maybe<Scalars['Date']>,
};

export type EnderecoAdicionalDao = {
   __typename?: 'EnderecoAdicionalDAO',
  codenderecoadicional?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  estado?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  tipoendereco?: Maybe<Scalars['String']>,
};

export type EnderecoAdicionalDaoInput = {
  codenderecoadicional?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  estado?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  tipoendereco?: Maybe<Scalars['String']>,
};

export type Errors = {
   __typename?: 'Errors',
  mensagemErro?: Maybe<Scalars['String']>,
  openSnackMessage?: Maybe<Scalars['Boolean']>,
};

export type EstabelBancarioDao = {
   __typename?: 'EstabelBancarioDAO',
  codbanco?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type EstabelecimentoAdicionaCrud = {
   __typename?: 'EstabelecimentoAdicionaCRUD',
  codUsuarioMestre?: Maybe<Scalars['Int']>,
};

export type EstabelecimentoCrud = {
   __typename?: 'EstabelecimentoCRUD',
  codOperadoraSelecionada?: Maybe<Scalars['Int']>,
  apelido?: Maybe<Scalars['String']>,
  nomeOperadora?: Maybe<Scalars['String']>,
  editarCodExterno?: Maybe<Scalars['Boolean']>,
  operadoras?: Maybe<Array<Maybe<OperadoraStatus>>>,
  estabelecimentos?: Maybe<Array<Maybe<ConciliadorOperadora>>>,
  listaIntervan?: Maybe<Array<Maybe<DocumentosBean>>>,
  listaAdesao?: Maybe<Array<Maybe<DocumentosBean>>>,
  tipoArquivo?: Maybe<Scalars['String']>,
  exibirIntervan?: Maybe<Scalars['Boolean']>,
  exibirAdesao?: Maybe<Scalars['Boolean']>,
  taxaCredito?: Maybe<Scalars['Float']>,
  taxaCredito0a6?: Maybe<Scalars['Float']>,
  taxaCredito7a12?: Maybe<Scalars['Float']>,
  taxaDebito?: Maybe<Scalars['Float']>,
  taxaTicket?: Maybe<Scalars['Float']>,
  cnpjnaotrabalhaoperadroa?: Maybe<Scalars['Boolean']>,
  processoConcluido?: Maybe<Scalars['Boolean']>,
  reloadPage?: Maybe<Scalars['Boolean']>,
  mensagemTela?: Maybe<Scalars['String']>,
  exibirUrl?: Maybe<Scalars['Boolean']>,
};

export type EstabelecimentoLiberaOperadoraCrud = {
   __typename?: 'EstabelecimentoLiberaOperadoraCRUD',
  login?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  operadoraSelecionada?: Maybe<Scalars['Int']>,
  operadoras?: Maybe<Array<Maybe<OperadorasBean>>>,
};

export type EstabelecimentoReservaCrud = {
   __typename?: 'EstabelecimentoReservaCRUD',
  nomePessoa?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
};

export type EstabOperadoraStatusDao = {
   __typename?: 'EstabOperadoraStatusDAO',
  codEstabOperadoraStatus?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  passou?: Maybe<Scalars['String']>,
  codPessoaLogada?: Maybe<Scalars['Int']>,
};

export type ExpurgaDadosCrud = {
   __typename?: 'ExpurgaDadosCRUD',
  selecionados?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ExpurgaDadosDao = {
   __typename?: 'ExpurgaDadosDAO',
  codpessoapai?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
};

export type FaturamentoMovimentoBean = {
   __typename?: 'FaturamentoMovimentoBean',
  terminal?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquidoadm?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  creditos?: Maybe<Scalars['Float']>,
  debitos?: Maybe<Scalars['Float']>,
  datainclusao?: Maybe<Scalars['Date']>,
  apurado?: Maybe<Scalars['Float']>,
  chave?: Maybe<Scalars['String']>,
  dataprocessado?: Maybe<Scalars['Date']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
  exibirLink?: Maybe<Scalars['String']>,
};

export type FaturamentoMovimentoBeanInput = {
  terminal?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquidoadm?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  creditos?: Maybe<Scalars['Float']>,
  debitos?: Maybe<Scalars['Float']>,
  datainclusao?: Maybe<Scalars['Date']>,
  apurado?: Maybe<Scalars['Float']>,
  chave?: Maybe<Scalars['String']>,
  dataprocessado?: Maybe<Scalars['Date']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
  exibirLink?: Maybe<Scalars['String']>,
};

export type FaturamentoMovimentoDao = {
   __typename?: 'FaturamentoMovimentoDAO',
  chave?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  apurado?: Maybe<Scalars['Float']>,
  status?: Maybe<Scalars['String']>,
  numeronota?: Maybe<Scalars['Int']>,
  protocolo?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  qtdtentativas?: Maybe<Scalars['Int']>,
  reenviaremail?: Maybe<Scalars['Boolean']>,
  reenviarnota?: Maybe<Scalars['Boolean']>,
};

export type FaturamentoMovimentoInput = {
  datageracaode?: Maybe<Scalars['Date']>,
  datageracaoate?: Maybe<Scalars['Date']>,
  terminais?: Maybe<Scalars['String']>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  statusnota?: Maybe<Scalars['String']>,
};

export type FechaMovimentoBean = {
   __typename?: 'FechaMovimentoBean',
  datavenda?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  cartao?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  parcela?: Maybe<Scalars['Int']>,
  totparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valor?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  status?: Maybe<Scalars['String']>,
  produto?: Maybe<Scalars['String']>,
  taxacliente?: Maybe<Scalars['Float']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  bandeira?: Maybe<Scalars['String']>,
  codterminal?: Maybe<Scalars['String']>,
  nometerminal?: Maybe<Scalars['String']>,
  numeroro?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
};

export type FechaMovimentoPrevisaoBean = {
   __typename?: 'FechaMovimentoPrevisaoBean',
  valor?: Maybe<Scalars['Float']>,
  codterminal?: Maybe<Scalars['String']>,
  nometerminal?: Maybe<Scalars['String']>,
};

export type FechaMovimentoReimpressaoBean = {
   __typename?: 'FechaMovimentoReimpressaoBean',
  datainclusao?: Maybe<Scalars['Date']>,
  chave?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquido?: Maybe<Scalars['Float']>,
  creditos?: Maybe<Scalars['Float']>,
  debitos?: Maybe<Scalars['Float']>,
  total?: Maybe<Scalars['Float']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
};

export type FechaMovimentoRelatorioBean = {
   __typename?: 'FechaMovimentoRelatorioBean',
  datavenda?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  parcela?: Maybe<Scalars['Int']>,
  totparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  nometerminal?: Maybe<Scalars['String']>,
  produto?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
};

export type FeriadosDao = {
   __typename?: 'FeriadosDAO',
  codFeriado?: Maybe<Scalars['Int']>,
  dia?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
};


export type FiliaisBean = {
   __typename?: 'FiliaisBean',
  apelido?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  codigo?: Maybe<Scalars['String']>,
  reservado?: Maybe<Scalars['Boolean']>,
  codPessoa?: Maybe<Scalars['Int']>,
  listBeanConcOperadoras?: Maybe<Array<Maybe<BeanConcOperadoras>>>,
};

export type Formulario = {
   __typename?: 'Formulario',
  nomeFormulario?: Maybe<Scalars['String']>,
};

export type FtpDao = {
   __typename?: 'FtpDAO',
  codftp?: Maybe<Scalars['Int']>,
  dirorigem?: Maybe<Scalars['String']>,
  dirdestino?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type FtpSeguroDao = {
   __typename?: 'FtpSeguroDAO',
  codftpseguro?: Maybe<Scalars['Int']>,
  host?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  protocolo?: Maybe<Scalars['String']>,
  diretorioftp?: Maybe<Scalars['String']>,
  diretoriodestino?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  buscaarquivonome?: Maybe<Scalars['String']>,
  identificadornome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  utilizacertificado?: Maybe<Scalars['String']>,
  nomecertificado?: Maybe<Scalars['String']>,
  descompactar?: Maybe<Scalars['String']>,
};

export type FuncionarioCrud = {
   __typename?: 'FuncionarioCRUD',
  valorHora?: Maybe<Scalars['String']>,
  codSetor?: Maybe<Scalars['Int']>,
  codHorarios?: Maybe<Scalars['Int']>,
  codResponsavel?: Maybe<Scalars['Int']>,
  mensagemTela?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['Boolean']>,
  codSetorSelecionado?: Maybe<Scalars['Int']>,
  codHorariosSelecionado?: Maybe<Scalars['Int']>,
  listHorarios?: Maybe<Array<Maybe<HorariosBean>>>,
};

export type FuncionarioDao = {
   __typename?: 'FuncionarioDAO',
  codPessoa?: Maybe<Scalars['Int']>,
  valorHora?: Maybe<Scalars['String']>,
};

export type FuncionarioSetorDao = {
   __typename?: 'FuncionarioSetorDAO',
  codPessoa?: Maybe<Scalars['Int']>,
  codSetor?: Maybe<Scalars['Int']>,
};

export type FuturaPessoaDao = {
   __typename?: 'FuturaPessoaDAO',
  codfuturapessoa?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  estado?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  logradouro?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  fantasia?: Maybe<Scalars['String']>,
  contato?: Maybe<Scalars['String']>,
  cadastroMatriz?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  inscestadual?: Maybe<Scalars['String']>,
  inscmunicipal?: Maybe<Scalars['String']>,
  codProtocolo?: Maybe<Scalars['Int']>,
  codRamoAtividade?: Maybe<Scalars['Int']>,
  numeroMaquineta?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type FuturaPessoaProtocoloDao = {
   __typename?: 'FuturaPessoaProtocoloDAO',
  codProtocolo?: Maybe<Scalars['Int']>,
  codUsuarioPai?: Maybe<Scalars['Int']>,
  numProtocolo?: Maybe<Scalars['String']>,
  dataInsercao?: Maybe<Scalars['Date']>,
  vencimento?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
};

export type FuturaPessoaViewDao = {
   __typename?: 'FuturaPessoaViewDAO',
  pessoa?: Maybe<FuturaPessoaDao>,
  tefs?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type FuturoUsuarioMestreDao = {
   __typename?: 'FuturoUsuarioMestreDAO',
  codfuturousuariomestre?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  logradouro?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  estado?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  contato?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type GeracaoPlanilhaOperadoraCrud = {
   __typename?: 'GeracaoPlanilhaOperadoraCRUD',
  codoperadora?: Maybe<Scalars['Int']>,
  urlPdf?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  chave?: Maybe<Scalars['String']>,
};

export type GeracaoPlanilhaOperadoraRedeBean = {
   __typename?: 'GeracaoPlanilhaOperadoraRedeBean',
  nome?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  numeroPv?: Maybe<Scalars['String']>,
  matrizGrupo?: Maybe<Scalars['String']>,
  numeroPvGrupo?: Maybe<Scalars['String']>,
  loginAcesstage?: Maybe<Scalars['String']>,
  possuiAcordoEc?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
};

export type GerarRelatorioPrevisaoPagamentoInput = {
  codoperadora?: Maybe<Scalars['Int']>,
  operadoras?: Maybe<Array<Maybe<Scalars['String']>>>,
  codpessoa?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  contaCorrente?: Maybe<Scalars['Int']>,
  nossonumero?: Maybe<Scalars['String']>,
  datavendade?: Maybe<Scalars['Date']>,
  datavendaate?: Maybe<Scalars['Date']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  datapagamentoate?: Maybe<Scalars['Date']>,
  datarecebimentode?: Maybe<Scalars['Date']>,
  datarecebimentoate?: Maybe<Scalars['Date']>,
  datavendalimite?: Maybe<Scalars['Date']>,
  consideraAjuste?: Maybe<Scalars['Boolean']>,
  agrupamento?: Maybe<Scalars['String']>,
  analiticoSintetico?: Maybe<Scalars['String']>,
  exportar?: Maybe<Scalars['String']>,
};

export type GerarRelatorioPrevisaoValoresLiquidosSemanalInput = {
  datavendalimite?: Maybe<Scalars['Date']>,
  consideraAjuste?: Maybe<Scalars['Boolean']>,
  apurarPendentePagamento?: Maybe<Scalars['Boolean']>,
  analise?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  contaCorrente?: Maybe<Scalars['Int']>,
  agrupamento?: Maybe<Scalars['String']>,
};

export type GerarRelatorioPrevisaoValoresLiquidosSemestralInput = {
  datavendalimite?: Maybe<Scalars['Date']>,
  apurarPendentePagamento?: Maybe<Scalars['Boolean']>,
  analise?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
  contaCorrente?: Maybe<Scalars['Int']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  analiticoSintetico?: Maybe<Scalars['String']>,
  qtdeMeses?: Maybe<Scalars['String']>,
  agrupa?: Maybe<Scalars['String']>,
  agrupamento?: Maybe<Scalars['String']>,
};

export type HeaderAntecipadoDao = {
   __typename?: 'HeaderAntecipadoDAO',
  codheaderantecipado?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codbanco?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  numoperfinanceira?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  sinalvalorbrutoavista?: Maybe<Scalars['String']>,
  valorbrutoavista?: Maybe<Scalars['Float']>,
  sinalvalorbrutoparcelado?: Maybe<Scalars['String']>,
  valorbrutoparcelado?: Maybe<Scalars['Float']>,
  sinalvalorbrutoeletronpre?: Maybe<Scalars['String']>,
  valorbrutoeletronpre?: Maybe<Scalars['Float']>,
  sinalvalorbrutototal?: Maybe<Scalars['String']>,
  valorbrutototal?: Maybe<Scalars['Float']>,
  sinalvalorliquidoavista?: Maybe<Scalars['String']>,
  valorliquidoavista?: Maybe<Scalars['Float']>,
  sinalvalorliquidoparcelado?: Maybe<Scalars['String']>,
  valorliquidoparcelado?: Maybe<Scalars['Float']>,
  sinalvalorliquidopre?: Maybe<Scalars['String']>,
  valorliquidopre?: Maybe<Scalars['Float']>,
  sinalvalorliquidototal?: Maybe<Scalars['String']>,
  valorliquidototal?: Maybe<Scalars['Float']>,
  taxadescontoantecipacao?: Maybe<Scalars['Float']>,
  sinalvalorliquido?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type HeaderOperadoraBoletoLiquidacaoDao = {
   __typename?: 'HeaderOperadoraBoletoLiquidacaoDAO',
  codheaderoperadoraboletoliquidacao?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codmeiocaptura?: Maybe<Scalars['Int']>,
  codstatuspagamento?: Maybe<Scalars['Int']>,
  codtipotransacao?: Maybe<Scalars['Int']>,
  numeroro?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  dateenviobanco?: Maybe<Scalars['Date']>,
  sinalvalorbruto?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  sinalvalorrejeicao?: Maybe<Scalars['String']>,
  valorrejeitado?: Maybe<Scalars['Float']>,
  sinalvalorliquido?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
  qtdcvaceitos?: Maybe<Scalars['Int']>,
  qtdcvrejeitados?: Maybe<Scalars['Int']>,
  tipoidentificador?: Maybe<Scalars['String']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  tarifa?: Maybe<Scalars['Float']>,
  taxagarantia?: Maybe<Scalars['Float']>,
  codbanco?: Maybe<Scalars['Int']>,
  sinalvalorcomissao?: Maybe<Scalars['String']>,
  valorcomissao?: Maybe<Scalars['Float']>,
  cdpessoa?: Maybe<Scalars['Int']>,
  cdoperadora?: Maybe<Scalars['Int']>,
  cdexterno?: Maybe<Scalars['String']>,
  datacapturatransacao?: Maybe<Scalars['Date']>,
  dataapresentacao?: Maybe<Scalars['Date']>,
  modificado?: Maybe<Scalars['String']>,
};

export type HeaderOperadoraBoletoPrevisaoDao = {
   __typename?: 'HeaderOperadoraBoletoPrevisaoDAO',
  codheaderoperadoraboletoprevisao?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codmeiocaptura?: Maybe<Scalars['Int']>,
  codstatuspagamento?: Maybe<Scalars['Int']>,
  codtipotransacao?: Maybe<Scalars['Int']>,
  numeroro?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  dateenviobanco?: Maybe<Scalars['Date']>,
  sinalvalorbruto?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  sinalvalorrejeicao?: Maybe<Scalars['String']>,
  valorrejeitado?: Maybe<Scalars['Float']>,
  sinalvalorliquido?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
  qtdcvaceitos?: Maybe<Scalars['Int']>,
  qtdcvrejeitados?: Maybe<Scalars['Int']>,
  tipoidentificador?: Maybe<Scalars['String']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  tarifa?: Maybe<Scalars['Float']>,
  taxagarantia?: Maybe<Scalars['Float']>,
  codbanco?: Maybe<Scalars['Int']>,
  sinalvalorcomissao?: Maybe<Scalars['String']>,
  valorcomissao?: Maybe<Scalars['Float']>,
  cdpessoa?: Maybe<Scalars['Int']>,
  cdoperadora?: Maybe<Scalars['Int']>,
  cdexterno?: Maybe<Scalars['String']>,
  datacapturatransacao?: Maybe<Scalars['Date']>,
  dataapresentacao?: Maybe<Scalars['Date']>,
  modificado?: Maybe<Scalars['String']>,
};

export type HeaderOperadoraDao = {
   __typename?: 'HeaderOperadoraDAO',
  codheaderoperadora?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codmeiocaptura?: Maybe<Scalars['Int']>,
  codstatuspagamento?: Maybe<Scalars['Int']>,
  codtipotransacao?: Maybe<Scalars['Int']>,
  numeroro?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  dateenviobanco?: Maybe<Scalars['Date']>,
  sinalvalorbruto?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  sinalvalorrejeicao?: Maybe<Scalars['String']>,
  valorrejeitado?: Maybe<Scalars['Float']>,
  sinalvalorliquido?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
  qtdcvaceitos?: Maybe<Scalars['Int']>,
  qtdcvrejeitados?: Maybe<Scalars['Int']>,
  tipoidentificador?: Maybe<Scalars['String']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  tarifa?: Maybe<Scalars['Float']>,
  taxagarantia?: Maybe<Scalars['Float']>,
  codbanco?: Maybe<Scalars['Int']>,
  sinalvalorcomissao?: Maybe<Scalars['String']>,
  valorcomissao?: Maybe<Scalars['Float']>,
  cdpessoa?: Maybe<Scalars['Int']>,
  cdoperadora?: Maybe<Scalars['Int']>,
  cdexterno?: Maybe<Scalars['String']>,
  datacapturatransacao?: Maybe<Scalars['Date']>,
  dataapresentacao?: Maybe<Scalars['Date']>,
  modificado?: Maybe<Scalars['String']>,
};

export type HeaderOperadoraVendaDao = {
   __typename?: 'HeaderOperadoraVendaDAO',
  codheaderoperadoravenda?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codmeiocaptura?: Maybe<Scalars['Int']>,
  codstatuspagamento?: Maybe<Scalars['Int']>,
  codtipotransacao?: Maybe<Scalars['Int']>,
  numeroro?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  dateenviobanco?: Maybe<Scalars['Date']>,
  sinalvalorbruto?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  sinalvalorrejeicao?: Maybe<Scalars['String']>,
  valorrejeitado?: Maybe<Scalars['Float']>,
  sinalvalorliquido?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
  qtdcvaceitos?: Maybe<Scalars['Int']>,
  qtdcvrejeitados?: Maybe<Scalars['Int']>,
  tipoidentificador?: Maybe<Scalars['String']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  tarifa?: Maybe<Scalars['Float']>,
  taxagarantia?: Maybe<Scalars['Float']>,
  codbanco?: Maybe<Scalars['Int']>,
  sinalvalorcomissao?: Maybe<Scalars['String']>,
  valorcomissao?: Maybe<Scalars['Float']>,
  cdpessoa?: Maybe<Scalars['Int']>,
  cdoperadora?: Maybe<Scalars['Int']>,
  cdexterno?: Maybe<Scalars['String']>,
  datacapturatransacao?: Maybe<Scalars['Date']>,
  dataapresentacao?: Maybe<Scalars['Date']>,
  modificado?: Maybe<Scalars['String']>,
};

export type HorariosBean = {
   __typename?: 'HorariosBean',
  codHorarios?: Maybe<Scalars['Int']>,
  diaSemana?: Maybe<Scalars['String']>,
  inicio?: Maybe<Scalars['String']>,
  inicioIntervalo?: Maybe<Scalars['String']>,
  fimIntervalo?: Maybe<Scalars['String']>,
  fim?: Maybe<Scalars['String']>,
  padrao?: Maybe<Scalars['String']>,
};

export type HorariosCrud = {
   __typename?: 'HorariosCRUD',
  inicio?: Maybe<Scalars['String']>,
  inicioIntervalo?: Maybe<Scalars['String']>,
  fimIntervalo?: Maybe<Scalars['String']>,
  fim?: Maybe<Scalars['String']>,
  diaSemana?: Maybe<Scalars['String']>,
  padrao?: Maybe<Scalars['Boolean']>,
  horarios?: Maybe<Array<Maybe<HorariosBean>>>,
};

export type HorariosDao = {
   __typename?: 'HorariosDAO',
  codHorarios?: Maybe<Scalars['Int']>,
  inicio?: Maybe<Scalars['Date']>,
  inicioIntervalo?: Maybe<Scalars['Date']>,
  fimIntervalo?: Maybe<Scalars['Date']>,
  fim?: Maybe<Scalars['Date']>,
  diaSemana?: Maybe<Scalars['Int']>,
  padrao?: Maybe<Scalars['String']>,
};

export type ImportacaoTicket = {
   __typename?: 'ImportacaoTicket',
  concioperadora?: Maybe<Scalars['String']>,
  terminal?: Maybe<Scalars['String']>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  codarquivo?: Maybe<Scalars['Int']>,
};

export type ImportacaoTicketInput = {
  concioperadora?: Maybe<Scalars['String']>,
  terminal?: Maybe<Scalars['String']>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
};

export type ImportacaoUnidadesConciliadores = {
   __typename?: 'ImportacaoUnidadesConciliadores',
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  novas?: Maybe<Array<Maybe<ConciOperadoraDao>>>,
};

export type ImportarPlanilhaPlanosTaxa = {
   __typename?: 'ImportarPlanilhaPlanosTaxa',
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  mensagemErro?: Maybe<Scalars['String']>,
  urlPdf?: Maybe<Scalars['String']>,
  dataInicioVigencia?: Maybe<Scalars['Date']>,
  dataFinalVigencia?: Maybe<Scalars['Date']>,
};

export type InclusaoTicketGeralInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type InformacoesPosicaoContratosPorClientesInput = {
  codPessoa?: Maybe<Scalars['String']>,
  codExterno?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type InfoUserGraphQl = {
   __typename?: 'InfoUserGraphQL',
  nomeAbreviado?: Maybe<Scalars['String']>,
  uscodigo?: Maybe<Scalars['String']>,
  logoMarcarRevenda?: Maybe<Scalars['String']>,
  simulado?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  codPessoa?: Maybe<Scalars['Int']>,
  codPessoaPai?: Maybe<Scalars['Int']>,
  codPerfil?: Maybe<Scalars['Int']>,
};

export type InserirRapidoInput = {
  codoperadora?: Maybe<Scalars['Int']>,
  nomePlano?: Maybe<Scalars['String']>,
  taxaCredito?: Maybe<Scalars['Float']>,
  taxaCredito0a6?: Maybe<Scalars['Float']>,
  taxaCredito7a12?: Maybe<Scalars['Float']>,
  taxaDebito?: Maybe<Scalars['Float']>,
  taxaTicket?: Maybe<Scalars['Float']>,
  mensagemErro?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
};

export type InstrucaoCadastroEcdao = {
   __typename?: 'InstrucaoCadastroECDAO',
  codOperadora?: Maybe<Scalars['Int']>,
};

export type IntegracaoConciliaAgendamentoCrud = {
   __typename?: 'IntegracaoConciliaAgendamentoCRUD',
  editValues?: Maybe<AgendamentoIntegracaoDao>,
  searchValues?: Maybe<AgendamentoIntegracaoDao>,
};

export type IntegracaoConciliaAgendamentoCrudInput = {
  editValues?: Maybe<AgendamentoIntegracaoDaoInput>,
  searchValues?: Maybe<AgendamentoIntegracaoDaoInput>,
};

export type IntegracaoConciliaAjusteCrud = {
   __typename?: 'IntegracaoConciliaAjusteCRUD',
  editValues?: Maybe<OrigemAjustePersonalizadoDao>,
  searchValues?: Maybe<OrigemAjustePersonalizadoDao>,
};

export type IntegracaoConciliaAjusteCrudInput = {
  editValues?: Maybe<OrigemAjustePersonalizadoDaoInput>,
  searchValues?: Maybe<OrigemAjustePersonalizadoDaoInput>,
};

export type IntegracaoConciliaInput = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  ultimos7Dias?: Maybe<Scalars['Boolean']>,
  refazerConciliacao702?: Maybe<Scalars['Boolean']>,
  refazerConciliacao701?: Maybe<Scalars['Boolean']>,
  corrigir?: Maybe<Scalars['String']>,
};

export type IntegracaoCorrigirRetorno = {
   __typename?: 'IntegracaoCorrigirRetorno',
  s701?: Maybe<Array<Maybe<LabelValor>>>,
  s702?: Maybe<Array<Maybe<LabelValor>>>,
  updatesSelecionados?: Maybe<Array<Maybe<ConciliaSelecionados>>>,
};

export type IntegracaoSoftExpressCadastro = {
   __typename?: 'IntegracaoSoftExpressCadastro',
  editValues?: Maybe<ConciliadoresIntegSoftExpressDao>,
  searchValues?: Maybe<ConciliadoresIntegSoftExpressDao>,
};

export type IntegracaoSoftExpressCadastroInput = {
  editValues?: Maybe<ConciliadoresIntegSoftExpressDaoInput>,
  searchValues?: Maybe<ConciliadoresIntegSoftExpressDaoInput>,
};

export type IntegracaoTotvsCadastroOperadoras = {
   __typename?: 'IntegracaoTotvsCadastroOperadoras',
  editValues?: Maybe<OperadorasTotvsDao>,
  searchValues?: Maybe<OperadorasTotvsDao>,
};

export type IntegracaoTotvsCadastroOperadorasInput = {
  editValues?: Maybe<OperadorasTotvsDaoInput>,
  searchValues?: Maybe<OperadorasTotvsDaoInput>,
};

export type IntegracaoTotvsCadastroParametrosConciliador = {
   __typename?: 'IntegracaoTotvsCadastroParametrosConciliador',
  editValues?: Maybe<ParametrosConciliadorTotvsDao>,
  searchValues?: Maybe<ParametrosConciliadorTotvsDao>,
};

export type IntegracaoTotvsCadastroParametrosConciliadorInput = {
  editValues?: Maybe<ParametrosConciliadorTotvsDaoInput>,
  searchValues?: Maybe<ParametrosConciliadorTotvsDaoInput>,
};

export type LabelValor = {
   __typename?: 'LabelValor',
  label?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['String']>,
};

export type LabelValorInput = {
  label?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type LabelValue = {
   __typename?: 'LabelValue',
  label?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type LinhaAntecipadoTotalizadorConciliadorBean = {
   __typename?: 'LinhaAntecipadoTotalizadorConciliadorBean',
  bruto?: Maybe<Scalars['Float']>,
  desconto?: Maybe<Scalars['Float']>,
  liquido?: Maybe<Scalars['Float']>,
  debitos?: Maybe<Scalars['Float']>,
  creditos?: Maybe<Scalars['Float']>,
  brutoantecipado?: Maybe<Scalars['Float']>,
  descontoantecipado?: Maybe<Scalars['Float']>,
  liquidoantecipado?: Maybe<Scalars['Float']>,
  jurosmensal?: Maybe<Scalars['Float']>,
  jurosefetivo?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoAntecipadoDao = {
   __typename?: 'LinhasArquivoAntecipadoDAO',
  codLinhaArquivoAntecipado?: Maybe<Scalars['Int']>,
  codHeaderAntecipado?: Maybe<Scalars['Int']>,
  codBandeira?: Maybe<Scalars['Int']>,
  numeroROOperacaoAntecipacao?: Maybe<Scalars['String']>,
  dataPagamentoOriginal?: Maybe<Scalars['Date']>,
  numeroUnicoRO?: Maybe<Scalars['String']>,
  parcela?: Maybe<Scalars['String']>,
  totalParcela?: Maybe<Scalars['String']>,
  sinalValorBrutoOriginal?: Maybe<Scalars['String']>,
  valorBrutoOriginal?: Maybe<Scalars['Float']>,
  sinalValorLiquidoOriginal?: Maybe<Scalars['String']>,
  valorLiquidoOriginal?: Maybe<Scalars['Float']>,
  sinalValorBrutoAntecipado?: Maybe<Scalars['String']>,
  valorBrutoAntecipado?: Maybe<Scalars['Float']>,
  sinalValorLiquidoAntecipado?: Maybe<Scalars['String']>,
  valorLiquidoAntecipado?: Maybe<Scalars['Float']>,
  status?: Maybe<Scalars['String']>,
  dataPagamentoROAntecipado?: Maybe<Scalars['Date']>,
  sinalValorROAntecipado?: Maybe<Scalars['String']>,
  valorROAntecipado?: Maybe<Scalars['Float']>,
  dataPagamentoAjuste?: Maybe<Scalars['Date']>,
  sinalValorAjusteDebito?: Maybe<Scalars['String']>,
  valorAjusteDebito?: Maybe<Scalars['Float']>,
  sinalValorCompensado?: Maybe<Scalars['String']>,
  valorCompensado?: Maybe<Scalars['Float']>,
  sinalValorSaldoROAntecipado?: Maybe<Scalars['String']>,
  valorSaldoROAntecipado?: Maybe<Scalars['Float']>,
  tipoIdentificador?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  cliente?: Maybe<Scalars['String']>,
  codorigemajuste?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codmeiocaptura?: Maybe<Scalars['Int']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  debito?: Maybe<Scalars['Float']>,
  credito?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoClienteCanceladaDao = {
   __typename?: 'LinhasArquivoClienteCanceladaDAO',
  codlinhasarqclicanc?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  datatransacao?: Maybe<Scalars['Date']>,
  bincartao?: Maybe<Scalars['String']>,
  qtdparcelas?: Maybe<Scalars['Int']>,
  numparcela?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  obs?: Maybe<Scalars['String']>,
  nossonumero?: Maybe<Scalars['String']>,
  versao?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  numeroautorizacao?: Maybe<Scalars['String']>,
  clientetef?: Maybe<Scalars['String']>,
  statusvenda?: Maybe<Scalars['String']>,
  valortotalvenda?: Maybe<Scalars['Float']>,
  nsutef?: Maybe<Scalars['String']>,
  modalidadepag?: Maybe<Scalars['String']>,
  dataprevistapagamento?: Maybe<Scalars['Date']>,
  datahoratransacao?: Maybe<Scalars['Date']>,
  numeroautorizacaotef?: Maybe<Scalars['String']>,
  statusantecipado?: Maybe<Scalars['String']>,
  boleto?: Maybe<Scalars['String']>,
};

export type LinhasArquivoClienteDao = {
   __typename?: 'LinhasArquivoClienteDAO',
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  datatransacao?: Maybe<Scalars['Date']>,
  modalidadepag?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  numparcela?: Maybe<Scalars['Int']>,
  qtdparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  dataprevistapagamento?: Maybe<Scalars['Date']>,
  obs?: Maybe<Scalars['String']>,
  nossonumero?: Maybe<Scalars['String']>,
  versao?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  numeroautorizacao?: Maybe<Scalars['String']>,
  clientetef?: Maybe<Scalars['String']>,
  statusvenda?: Maybe<Scalars['String']>,
  valortotalvenda?: Maybe<Scalars['Float']>,
  nsutef?: Maybe<Scalars['String']>,
  codprodutos?: Maybe<Scalars['Int']>,
  datahoratransacao?: Maybe<Scalars['Date']>,
  numeroautorizacaotef?: Maybe<Scalars['String']>,
  statusantecipado?: Maybe<Scalars['String']>,
  boleto?: Maybe<Scalars['String']>,
};

export type LinhasArquivoClienteDesconsideradosDao = {
   __typename?: 'LinhasArquivoClienteDesconsideradosDAO',
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  codbandeiras?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  datatransacao?: Maybe<Scalars['Date']>,
  modalidadepag?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  numparcela?: Maybe<Scalars['Int']>,
  qtdparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  dataprevistapagamento?: Maybe<Scalars['Date']>,
  obs?: Maybe<Scalars['String']>,
  nossonumero?: Maybe<Scalars['String']>,
  versao?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  numeroautorizacao?: Maybe<Scalars['String']>,
  clientetef?: Maybe<Scalars['String']>,
  statusvenda?: Maybe<Scalars['String']>,
  valortotalvenda?: Maybe<Scalars['Float']>,
  nsutef?: Maybe<Scalars['String']>,
  codprodutos?: Maybe<Scalars['Int']>,
  datahoratransacao?: Maybe<Scalars['Date']>,
  numeroautorizacaotef?: Maybe<Scalars['String']>,
  statusantecipado?: Maybe<Scalars['String']>,
  boleto?: Maybe<Scalars['String']>,
};

export type LinhasArquivoClienteOlxDao = {
   __typename?: 'LinhasArquivoClienteOlxDAO',
  codlinhasarquivoclienteolx?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  paymentid?: Maybe<Scalars['String']>,
  itemid?: Maybe<Scalars['String']>,
  adid?: Maybe<Scalars['String']>,
  orderid?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['BigDecimal']>,
  codarquivo?: Maybe<Scalars['Int']>,
  data?: Maybe<Scalars['Date']>,
  parcela?: Maybe<Scalars['Int']>,
};

export type LinhasArquivoClienteProcessaCancelaDao = {
   __typename?: 'LinhasArquivoClienteProcessaCancelaDAO',
  codlinrquclisolicitacancela?: Maybe<Scalars['Int']>,
  codlinhasarqclicanc?: Maybe<Scalars['Int']>,
};

export type LinhasArquivoClienteSolicitaCancelaDao = {
   __typename?: 'LinhasArquivoClienteSolicitaCancelaDAO',
  codlinrquclisolicitacancela?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  nossonumero?: Maybe<Scalars['String']>,
  bandeira?: Maybe<Scalars['String']>,
  datatransacao?: Maybe<Scalars['Date']>,
  datamodificacao?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorestornado?: Maybe<Scalars['Float']>,
  gateway?: Maybe<Scalars['String']>,
  statusprocessamento?: Maybe<Scalars['String']>,
};

export type LinhasArquivoClienteUpdateDao = {
   __typename?: 'LinhasArquivoClienteUpdateDAO',
  codlinhasarquivoclienteupdate?: Maybe<Scalars['Int']>,
  operadora?: Maybe<Scalars['String']>,
  bandeira?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  autorizacao?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  parcela?: Maybe<Scalars['String']>,
  totalparcelas?: Maybe<Scalars['String']>,
  valorparcela?: Maybe<Scalars['Float']>,
  valortotalvenda?: Maybe<Scalars['Float']>,
  nossonumero?: Maybe<Scalars['String']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  horavenda?: Maybe<Scalars['Date']>,
};

export type LinhasArquivoOperadoraAntecipadoDao = {
   __typename?: 'LinhasArquivoOperadoraAntecipadoDAO',
  codlinhasarqoperadoraant?: Maybe<Scalars['Int']>,
  codlinhaarquivoantecipado?: Maybe<Scalars['Int']>,
  cartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  sinal?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totparcelas?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  valorcomplementar?: Maybe<Scalars['Float']>,
  vlrvendaparcloja?: Maybe<Scalars['Float']>,
  vlrproxparcela?: Maybe<Scalars['Float']>,
  numeronf?: Maybe<Scalars['String']>,
  cartaoexterior?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  entradaembarque?: Maybe<Scalars['String']>,
  horatransacao?: Maybe<Scalars['Date']>,
  numerounicotransacao?: Maybe<Scalars['String']>,
  indicadorpp?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  importado?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoOperadoraBoletoLiquidacaoDao = {
   __typename?: 'LinhasArquivoOperadoraBoletoLiquidacaoDAO',
  codlinhasarqoperadoraboletoliquidacao?: Maybe<Scalars['Int']>,
  codheaderoperadoraboletoliquidacao?: Maybe<Scalars['Int']>,
  codmotivorejeicao?: Maybe<Scalars['Int']>,
  cartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  sinal?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totparcelas?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  valorcomplementar?: Maybe<Scalars['Float']>,
  vlrvendaparcloja?: Maybe<Scalars['Float']>,
  vlrproxparcela?: Maybe<Scalars['Float']>,
  numeronf?: Maybe<Scalars['String']>,
  cartaoexterior?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  entradaembarque?: Maybe<Scalars['String']>,
  horatransacao?: Maybe<Scalars['Date']>,
  numerounicotransacao?: Maybe<Scalars['String']>,
  indicadorpp?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  importado?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoOperadoraBoletoPrevisaoDao = {
   __typename?: 'LinhasArquivoOperadoraBoletoPrevisaoDAO',
  codlinhasarqoperadoraboletoprevisao?: Maybe<Scalars['Int']>,
  codheaderoperadoraboletoprevisao?: Maybe<Scalars['Int']>,
  codmotivorejeicao?: Maybe<Scalars['Int']>,
  cartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  sinal?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totparcelas?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  valorcomplementar?: Maybe<Scalars['Float']>,
  vlrvendaparcloja?: Maybe<Scalars['Float']>,
  vlrproxparcela?: Maybe<Scalars['Float']>,
  numeronf?: Maybe<Scalars['String']>,
  cartaoexterior?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  entradaembarque?: Maybe<Scalars['String']>,
  horatransacao?: Maybe<Scalars['Date']>,
  numerounicotransacao?: Maybe<Scalars['String']>,
  indicadorpp?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  importado?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoOperadoraDao = {
   __typename?: 'LinhasArquivoOperadoraDAO',
  codlinhasarqoperadora?: Maybe<Scalars['Int']>,
  codheaderoperadora?: Maybe<Scalars['Int']>,
  codmotivorejeicao?: Maybe<Scalars['Int']>,
  cartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  sinal?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totparcelas?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  valorcomplementar?: Maybe<Scalars['Float']>,
  vlrvendaparcloja?: Maybe<Scalars['Float']>,
  vlrproxparcela?: Maybe<Scalars['Float']>,
  numeronf?: Maybe<Scalars['String']>,
  cartaoexterior?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  entradaembarque?: Maybe<Scalars['String']>,
  horatransacao?: Maybe<Scalars['Date']>,
  numerounicotransacao?: Maybe<Scalars['String']>,
  indicadorpp?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  importado?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type LinhasArquivoOperadoraVendaDao = {
   __typename?: 'LinhasArquivoOperadoraVendaDAO',
  codlinhasarqoperadoravd?: Maybe<Scalars['Int']>,
  codheaderoperadoravenda?: Maybe<Scalars['Int']>,
  codmotivorejeicao?: Maybe<Scalars['Int']>,
  cartao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  sinal?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totparcelas?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  valorcomplementar?: Maybe<Scalars['Float']>,
  vlrvendaparcloja?: Maybe<Scalars['Float']>,
  vlrproxparcela?: Maybe<Scalars['Float']>,
  numeronf?: Maybe<Scalars['String']>,
  cartaoexterior?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  entradaembarque?: Maybe<Scalars['String']>,
  horatransacao?: Maybe<Scalars['Date']>,
  numerounicotransacao?: Maybe<Scalars['String']>,
  indicadorpp?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  importado?: Maybe<Scalars['String']>,
  valorliquido?: Maybe<Scalars['Float']>,
};

export type LinhasConciliacaoAntecipadosBean = {
   __typename?: 'LinhasConciliacaoAntecipadosBean',
  valorbrutooriginal?: Maybe<Scalars['Float']>,
  desconto?: Maybe<Scalars['Float']>,
  valorliquidooriginal?: Maybe<Scalars['Float']>,
  descontoantecipado?: Maybe<Scalars['Float']>,
  valorliquidoantecipado?: Maybe<Scalars['Float']>,
  taxaantecipacao?: Maybe<Scalars['Float']>,
  datapagamentooriginal?: Maybe<Scalars['Date']>,
  debitos?: Maybe<Scalars['Float']>,
  credito?: Maybe<Scalars['Float']>,
  numerounicoro?: Maybe<Scalars['String']>,
  codlinhaarquivoantecipado?: Maybe<Scalars['Int']>,
  valorbrutocorreto?: Maybe<Scalars['Float']>,
  regitroRo?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  parcela?: Maybe<Scalars['String']>,
};

export type LinhasConciliacaoBean = {
   __typename?: 'LinhasConciliacaoBean',
  nsu?: Maybe<Scalars['String']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquido?: Maybe<Scalars['Float']>,
  valordesconto?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['Int']>,
  ajuste?: Maybe<Scalars['Float']>,
  autorizacao?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  tipoConciliacao?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  tid?: Maybe<Scalars['String']>,
  codLinha?: Maybe<Scalars['Int']>,
  data?: Maybe<Scalars['Date']>,
  datavenda?: Maybe<Scalars['Date']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
};

export type LinhasConciliadasAntecipadoClienteDao = {
   __typename?: 'LinhasConciliadasAntecipadoClienteDAO',
  codlinhaarquivoantecipado?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
};

export type LinhasConciliadasAntecipadoCvdao = {
   __typename?: 'LinhasConciliadasAntecipadoCVDAO',
  codlinhasconciliadasantecipadocv?: Maybe<Scalars['Int']>,
  codlinhasarqantecipadocv?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  taxacliente?: Maybe<Scalars['Float']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
};

export type LinhasConciliadasAntecipadoDao = {
   __typename?: 'LinhasConciliadasAntecipadoDAO',
  codLinhaArquivoAntecipado?: Maybe<Scalars['Int']>,
  taxaantecipacao?: Maybe<Scalars['Float']>,
  diasantecipados?: Maybe<Scalars['Int']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  valorliquidooriginal?: Maybe<Scalars['Float']>,
  valorbrutocorreto?: Maybe<Scalars['Float']>,
  codlinhaarquivoantecipadovinculada?: Maybe<Scalars['Int']>,
  datavenda?: Maybe<Scalars['Date']>,
};

export type LinhasConciliadasAntecipadoVendaDao = {
   __typename?: 'LinhasConciliadasAntecipadoVendaDAO',
  codlinhaarquivoantecipado?: Maybe<Scalars['Int']>,
  codlinhaarqoperavd?: Maybe<Scalars['Int']>,
};

export type LinhasConciliadasBoletoLiquidacaoDao = {
   __typename?: 'LinhasConciliadasBoletoLiquidacaoDAO',
  codlinhasconciliadasboletoliquidacao?: Maybe<Scalars['Int']>,
  codlinhasarqoperadoraboletoliquidacao?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  taxacliente?: Maybe<Scalars['Float']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
};

export type LinhasConciliadasBoletoPrevisaoDao = {
   __typename?: 'LinhasConciliadasBoletoPrevisaoDAO',
  codlinhasconciliadasboletoprevisao?: Maybe<Scalars['Int']>,
  codlinhasarqoperadoraboletoprevisao?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  taxacliente?: Maybe<Scalars['Float']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
};

export type LinhasConciliadasDao = {
   __typename?: 'LinhasConciliadasDAO',
  codlinhasconciliadas?: Maybe<Scalars['Int']>,
  codlinhasarqoperadora?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  taxacliente?: Maybe<Scalars['Float']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
};

export type LinhasConciliadasVendaDao = {
   __typename?: 'LinhasConciliadasVendaDAO',
  codlinhasconciliadasvenda?: Maybe<Scalars['Int']>,
  codlinhasarqoperadoravd?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  taxacliente?: Maybe<Scalars['Float']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  tipoconciliacao?: Maybe<Scalars['String']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  valorliquidoterminal?: Maybe<Scalars['Float']>,
  taxaterminal?: Maybe<Scalars['Float']>,
};

export type LinhaTotalizadorConciliadorBean = {
   __typename?: 'LinhaTotalizadorConciliadorBean',
  bruto?: Maybe<Scalars['Float']>,
  desconto?: Maybe<Scalars['Float']>,
  liquido?: Maybe<Scalars['Float']>,
  outrosDebitos?: Maybe<Scalars['Float']>,
  outrosCreditos?: Maybe<Scalars['Float']>,
  qtdRegistros?: Maybe<Scalars['Int']>,
  antecipados?: Maybe<Scalars['Float']>,
};

export type ListarEstabelecimentosInput = {
  codoperadora?: Maybe<Scalars['Int']>,
};

export type ListarParametrosTotvsInput = {
  codpessoa?: Maybe<Scalars['Int']>,
};

export type LogarUsuarioMestreInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codperfil?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
};

export type LogDao = {
   __typename?: 'LogDAO',
  codlog?: Maybe<Scalars['String']>,
  dataocorrida?: Maybe<Scalars['Date']>,
  codpessoa?: Maybe<Scalars['Int']>,
  ip?: Maybe<Scalars['String']>,
  sistema?: Maybe<Scalars['String']>,
  localizacao?: Maybe<Scalars['String']>,
};

export type LoginSenha = {
   __typename?: 'LoginSenha',
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
};

export type LogItemDao = {
   __typename?: 'LogItemDAO',
  codlogitem?: Maybe<Scalars['String']>,
  codlog?: Maybe<Scalars['String']>,
  operacao?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  sucesso?: Maybe<Scalars['Int']>,
  pks?: Maybe<Scalars['String']>,
};


export type ManipuladorImplantacao = {
   __typename?: 'ManipuladorImplantacao',
  diretorioSugerido?: Maybe<Scalars['String']>,
  remetente?: Maybe<Scalars['String']>,
  urlDocumentoOperadora?: Maybe<Scalars['String']>,
  caminho?: Maybe<Scalars['String']>,
  documento?: Maybe<DocumentosEnviadosOperadoraDao>,
  ecMatriz?: Maybe<Scalars['String']>,
};

export type ManipuladorImplantacaoFtp = {
   __typename?: 'ManipuladorImplantacaoFTP',
  diretorioFtpDestino?: Maybe<Scalars['String']>,
  diretorioFtpOrigem?: Maybe<Scalars['String']>,
  host?: Maybe<Scalars['String']>,
  protocolo?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  buscaArquivoNome?: Maybe<Scalars['String']>,
  identificadorNome?: Maybe<Scalars['String']>,
  utilizaCerticado?: Maybe<Scalars['String']>,
  nomeCertificado?: Maybe<Scalars['String']>,
  diretorioFtpSeguroOrigem?: Maybe<Scalars['String']>,
  diretorioFtpSeguroDestino?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
};

export type ManutencaoArquivos = {
   __typename?: 'ManutencaoArquivos',
  editValues?: Maybe<ArquivosrecebidosDao>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
};

export type ManutencaoArquivosConiliacaoAberto = {
   __typename?: 'ManutencaoArquivosConiliacaoAberto',
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  mensagem?: Maybe<Scalars['String']>,
  editValues?: Maybe<ArquivosrecebidosDao>,
};

export type ManutencaoArquivosConiliacaoAbertoInput = {
  searchValues?: Maybe<ArquivosrecebidosDaoInput>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
};

export type ManutencaoArquivosInput = {
  values?: Maybe<ArquivosrecebidosDaoInput>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
};

export type ManutencaoConciliadoresCrud = {
   __typename?: 'ManutencaoConciliadoresCRUD',
  codexternosubstituido?: Maybe<Scalars['String']>,
};

export type ManutencaoContratoConciliadores = {
   __typename?: 'ManutencaoContratoConciliadores',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type ManutencaoContratos = {
   __typename?: 'ManutencaoContratos',
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  cnpj?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  pessoa?: Maybe<FuturaPessoaViewDao>,
  prIntingDocs?: Maybe<Scalars['Boolean']>,
  valorMensal?: Maybe<Scalars['Float']>,
  valorAdesao?: Maybe<Scalars['Float']>,
  valorAdesaoEntrada?: Maybe<Scalars['Float']>,
  numeroParcelasAdesao?: Maybe<Scalars['Int']>,
  valorAdesaoParcela?: Maybe<Scalars['Float']>,
  vencimentoAdesao?: Maybe<Scalars['Date']>,
  urlPdf?: Maybe<Scalars['String']>,
};

export type ManutencaoContratosAdm = {
   __typename?: 'ManutencaoContratosAdm',
  insertingCliente?: Maybe<Scalars['Boolean']>,
  de?: Maybe<Scalars['Date']>,
  ate?: Maybe<Scalars['Date']>,
  cnpj?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  newClienteCPF?: Maybe<Scalars['String']>,
  newClienteCNPJ?: Maybe<Scalars['String']>,
  pessoa?: Maybe<FuturaPessoaViewDao>,
  usuarioMestre?: Maybe<UsuariosDao>,
  conciliadores?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  conciOperadora?: Maybe<ConciOperadoraDao>,
};

export type MarcarChegadaArquivoManualVanCrud = {
   __typename?: 'MarcarChegadaArquivoManualVanCRUD',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type MeioCapturaDao = {
   __typename?: 'MeioCapturaDAO',
  codmeiocaptura?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipoMeioCaptura?: Maybe<Scalars['String']>,
};

export type MensalidadeAdesaoCnpjAdicionalDao = {
   __typename?: 'MensalidadeAdesaoCnpjAdicionalDAO',
  codpessoapai?: Maybe<Scalars['Int']>,
  qtdcnpjadicionais?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
  anoinclusao?: Maybe<Scalars['Int']>,
  mesinclusao?: Maybe<Scalars['Int']>,
  valor?: Maybe<Scalars['BigDecimal']>,
  valorconcilia?: Maybe<Scalars['BigDecimal']>,
};

export type MensalidadeTerminalDao = {
   __typename?: 'MensalidadeTerminalDAO',
  codmensalidadeterminal?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavencimento?: Maybe<Scalars['Date']>,
  valormensalidade?: Maybe<Scalars['Float']>,
  origem?: Maybe<Scalars['String']>,
  datainicial?: Maybe<Scalars['Date']>,
  datafinal?: Maybe<Scalars['Date']>,
  qtddiascobrados?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
};

export type Menu = {
   __typename?: 'Menu',
  id: Scalars['ID'],
  label?: Maybe<Scalars['String']>,
  page?: Maybe<Scalars['String']>,
  pai?: Maybe<Scalars['String']>,
  icon?: Maybe<Scalars['String']>,
};

export type Mestres = {
   __typename?: 'Mestres',
  pessoa?: Maybe<PessoaGenerica>,
  concioperadora?: Maybe<ConciOperadoraDao>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['Boolean']>,
  mensagem?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['Boolean']>,
  excecaoconciliadores?: Maybe<Scalars['Boolean']>,
  codvalorcontrato?: Maybe<Scalars['Int']>,
  desconto?: Maybe<ValorContratoConciliadorDescontoDao>,
  valorAdicional?: Maybe<ValorContratoConciliadorOperadoraDescontoDao>,
  codTef?: Maybe<Scalars['String']>,
  usuarioMestreTefs?: Maybe<Array<Maybe<UsuarioMestreTefsBean>>>,
  hashTefs?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type MestresRevenda = {
   __typename?: 'MestresRevenda',
  codPessoaPai?: Maybe<Scalars['Int']>,
  pessoa?: Maybe<PessoaGenerica>,
  editValues?: Maybe<UsuariosDao>,
};

export type MestresRevendaInput = {
  pessoa?: Maybe<PessoaGenericaInput>,
  editValues?: Maybe<UsuarioDaoInput>,
  searchValues?: Maybe<UsuarioDaoInput>,
};

export type MigracaoConciliadores = {
   __typename?: 'MigracaoConciliadores',
  dadosantigo?: Maybe<Scalars['String']>,
  dadosnovo?: Maybe<Scalars['String']>,
  mestre?: Maybe<Scalars['Int']>,
  operadora?: Maybe<Scalars['Int']>,
  mensagemErro?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  codExterno?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  datasolicitacaode?: Maybe<Scalars['Date']>,
  datasolicitacaoate?: Maybe<Scalars['Date']>,
};

export type MigracaoConciliadoresDao = {
   __typename?: 'MigracaoConciliadoresDAO',
  codMigracaoConciliadores?: Maybe<Scalars['Int']>,
  codPessoaAntigo?: Maybe<Scalars['Int']>,
  codOperadoraAntigo?: Maybe<Scalars['Int']>,
  codExternoAntigo?: Maybe<Scalars['String']>,
  codPessoaNovo?: Maybe<Scalars['Int']>,
  codOperadoraNovo?: Maybe<Scalars['Int']>,
  codExternoNovo?: Maybe<Scalars['String']>,
  codPessoaPai?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataProcessamento?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  codPessoaSolicitacao?: Maybe<Scalars['Int']>,
  mensagem?: Maybe<Scalars['String']>,
};

export type MigracaoConciliadoresLote = {
   __typename?: 'MigracaoConciliadoresLote',
  conciliadorantigo?: Maybe<Scalars['Int']>,
  conciliadornovo?: Maybe<Scalars['Int']>,
};

export type MigracaoConciliadoresTela = {
   __typename?: 'MigracaoConciliadoresTela',
  codMigracaoConciliadores?: Maybe<Scalars['Int']>,
  codPessoaAntigo?: Maybe<Scalars['Int']>,
  nomeUnidadeAntiga?: Maybe<Scalars['String']>,
  nomeOperadoraAntiga?: Maybe<Scalars['String']>,
  codExternoAntigo?: Maybe<Scalars['String']>,
  codPessoaNovo?: Maybe<Scalars['Int']>,
  nomeUnidadeNovo?: Maybe<Scalars['String']>,
  nomeOperadoraNovo?: Maybe<Scalars['String']>,
  codExternoNovo?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataProcessamento?: Maybe<Scalars['Date']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  mensagem?: Maybe<Scalars['String']>,
  selecionado?: Maybe<Scalars['Boolean']>,
};

export type MigracaoConciliadoresUsuario = {
   __typename?: 'MigracaoConciliadoresUsuario',
  codUsuarioOrigem?: Maybe<Scalars['Int']>,
  codUsuarioDestino?: Maybe<Scalars['Int']>,
  loginOrigem?: Maybe<Scalars['String']>,
  loginDestino?: Maybe<Scalars['String']>,
  mensagemTela?: Maybe<Scalars['String']>,
  conciliadoresOrigem?: Maybe<Array<Maybe<MigracaoConciliadoresUsuarioBean>>>,
  conciliadoresDestino?: Maybe<Array<Maybe<MigracaoConciliadoresUsuarioBean>>>,
};

export type MigracaoConciliadoresUsuarioBean = {
   __typename?: 'MigracaoConciliadoresUsuarioBean',
  codPessoa?: Maybe<Scalars['Int']>,
  identificacao?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  selecionado?: Maybe<Scalars['Boolean']>,
};

export type MigracaoPilotoEspecial = {
   __typename?: 'MigracaoPilotoEspecial',
  mensagem?: Maybe<Scalars['String']>,
};

export type MonitorNovosContratos = {
   __typename?: 'MonitorNovosContratos',
  listaPendenteAprovacao?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaContratosPiloto?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaContratos?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaAguardandoExtratosVan?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaExtratosVanAtrazado?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaContratosFinalizados?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
};

export type MotivoRejeicaoDao = {
   __typename?: 'MotivoRejeicaoDAO',
  codmotivorejeicao?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type MotivosBean = {
   __typename?: 'MotivosBean',
  codMotivos?: Maybe<Scalars['Int']>,
  nivelSla?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type MotivosCrud = {
   __typename?: 'MotivosCRUD',
  motivos?: Maybe<Array<Maybe<MotivosBean>>>,
};

export type MotivosDao = {
   __typename?: 'MotivosDAO',
  codMotivos?: Maybe<Scalars['Int']>,
  codNiveisSla?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  saveUsuario?: Maybe<UsuarioCrud>,
  updateUsuario?: Maybe<Scalars['Boolean']>,
  deleteUsuario?: Maybe<Scalars['Boolean']>,
  savePerfil?: Maybe<PerfilCrud>,
  deletePerfil?: Maybe<Scalars['Boolean']>,
  saveTerminal?: Maybe<TerminalCrud>,
  deleteTerminal?: Maybe<Scalars['Boolean']>,
  saveTaxasAntecipacao?: Maybe<TaxasAntecipacaoCrud>,
  deleteTaxasAntecipacao?: Maybe<Scalars['Boolean']>,
  salvarPlanosPOS?: Maybe<Scalars['Boolean']>,
  deletePlanosPOS?: Maybe<Scalars['Boolean']>,
  salvarPlanosTerminal?: Maybe<Scalars['Boolean']>,
  deletePlanosTerminal?: Maybe<Scalars['Boolean']>,
  salvarPlanosPosTaxas?: Maybe<Scalars['Boolean']>,
  deletePlanosPosTaxas?: Maybe<Scalars['Boolean']>,
  savePlanosConciliadores?: Maybe<PlanosConciliadoresCrud>,
  deletePlanosConciliadores?: Maybe<Scalars['Boolean']>,
  savePlanosConciliadoresContrato?: Maybe<PlanosConciliadoresContratoCrud>,
  deletePlanosConciliadoresContrato?: Maybe<Scalars['Boolean']>,
  savePlanosConciliadoresTaxas?: Maybe<PlanosConciliadoresTaxasCrud>,
  deletePlanosConciliadoresTaxas?: Maybe<Scalars['Boolean']>,
  login?: Maybe<Scalars['String']>,
  validarPendenciaCadastroOperadora?: Maybe<Scalars['String']>,
  uploadDocsEstabelecimento?: Maybe<Scalars['Boolean']>,
  editarEstabelecimento?: Maybe<EstabelecimentoCrud>,
  deleteEstabelecimentoOperadora?: Maybe<Scalars['Boolean']>,
  savePainelCadastroTaxa?: Maybe<Scalars['String']>,
  logout?: Maybe<Scalars['String']>,
  downloadManutencaoArquivos?: Maybe<Scalars['String']>,
  reprocessarManutencaoArquivos?: Maybe<ManutencaoArquivos>,
  deletarManutencaoArquivos?: Maybe<Scalars['Boolean']>,
  downloadExtratosOriginais?: Maybe<Scalars['String']>,
  importarTicket?: Maybe<Scalars['Boolean']>,
  importarTodosTickets?: Maybe<Scalars['Boolean']>,
  incluirTicket?: Maybe<Scalars['Boolean']>,
  inserirRapido?: Maybe<Scalars['String']>,
  downloadAnalisePesquisaTicket?: Maybe<Scalars['String']>,
  reprocessarTicket?: Maybe<ManutencaoArquivos>,
  deletarTicket?: Maybe<Scalars['Boolean']>,
  reconsiderarDesconsiderarTicket?: Maybe<Scalars['Boolean']>,
  gerarRelatorioExtratoConferenciaPagamento?: Maybe<Scalars['String']>,
  gerarRelatorioExtratoConferenciaLiquidacao?: Maybe<Scalars['String']>,
  gerarRelatorioExtratoConferenciaBoletoPrevisao?: Maybe<Scalars['String']>,
  gerarRelatorioExtratoConferenciaVenda?: Maybe<Scalars['String']>,
  gerarRelatorioTaxas?: Maybe<Scalars['String']>,
  gerarRelatorioPrevisaoValoresLiquidosSemestral?: Maybe<Scalars['String']>,
  gerarRelatorioPrevisaoValoresLiquidosSemamal?: Maybe<Scalars['String']>,
  gerarRelatorioPrevisaoPagamento?: Maybe<Scalars['String']>,
  gerarRelatorioEncontradosClientePagamento?: Maybe<Scalars['String']>,
  gerarRelatorioEncontradosClienteVenda?: Maybe<Scalars['String']>,
  gerarRelatorioEncontradosClienteLiquidacao?: Maybe<Scalars['String']>,
  gerarRelatorioEncontradosClientePrevisao?: Maybe<Scalars['String']>,
  gerarRelatorioMotivoAjuste?: Maybe<Scalars['String']>,
  gerarRelatorioValoresDivergentes?: Maybe<Scalars['String']>,
  gerarRelatorioOLXIntegracaoPagamento?: Maybe<Scalars['String']>,
  gerarRelatorioOLXIntegracaoVenda?: Maybe<Scalars['String']>,
  gerarRelatorioOLXIntegracaoAjuste?: Maybe<Scalars['String']>,
  gerarRelatorioVendasPorLojaValorBruto?: Maybe<Scalars['String']>,
  gerarRelatorioRejeitados?: Maybe<Scalars['String']>,
  gerarRelatorioVendas?: Maybe<Scalars['String']>,
  gerarRelatorioSinteticoTipoConciliacao?: Maybe<Scalars['String']>,
  gerarRelatorioTransacoesProblemasTEF?: Maybe<Scalars['String']>,
  gerarRelatorioPagamentoComDebitoAgrupadoVenda?: Maybe<Scalars['String']>,
  gerarRelatorioConciliadosPagamentoFiltroSimples?: Maybe<Scalars['String']>,
  gerarRelatorioConciliadosVendaFiltroSimples?: Maybe<Scalars['String']>,
  gerarRelatorioConciliadosBoletoFiltroSimples?: Maybe<Scalars['String']>,
  gerarRelatorioConciliadosPrevisaoFiltroSimples?: Maybe<Scalars['String']>,
  gerarRelatorioPagamentosEfetivos?: Maybe<Scalars['String']>,
  gerarRelatorioFluxoRecebimentoCartao?: Maybe<Scalars['String']>,
  gerarRelatorioMensalidadesRecebidas?: Maybe<Scalars['String']>,
  gerarRelatorioFechamentoMensalTerminal?: Maybe<Scalars['String']>,
  gerarRelatorioTaxasTerminal?: Maybe<Scalars['String']>,
  gerarRelatorioTerminaisAtivos?: Maybe<Scalars['String']>,
  gerarRelatorioTerminaisSemMovimento?: Maybe<Scalars['String']>,
  logarUsuarioMestre?: Maybe<Scalars['String']>,
  logarUsuarioMestreRevenda?: Maybe<Scalars['String']>,
  trocarSenha?: Maybe<Scalars['String']>,
  salvarTefs?: Maybe<Scalars['Boolean']>,
  salvarMestreRevenda?: Maybe<MestresRevenda>,
  salvarDadosNFe?: Maybe<DadosNfeCrud>,
  gerarRelatorioFechamentoValoresLiquidosSemestral?: Maybe<ArquivoGerado>,
  gerarRelatorioConciliadosAntecipados?: Maybe<Scalars['String']>,
  downloadArquivoConciliacaoEmAberto?: Maybe<Scalars['String']>,
  alterarSenha?: Maybe<Scalars['Boolean']>,
  redefinirSenha?: Maybe<Scalars['String']>,
  salvarRelatorioAgendamento?: Maybe<Scalars['Boolean']>,
  salvarPlanosConciliadoresContrato?: Maybe<Scalars['Boolean']>,
  gerarIntegracaoAlterdata?: Maybe<ArquivoGerado>,
  gerarIntegracaoAvanco?: Maybe<ArquivoGerado>,
  gerarIntegracaoCash?: Maybe<ArquivoGerado>,
  gerarIntegracaoKaplen?: Maybe<ArquivoGerado>,
  gerarIntegracaoMicroUniverso?: Maybe<Scalars['String']>,
  gerarIntegracaoConsinco?: Maybe<ArquivoGerado>,
  gerarIntegracaoConciliaVendaPagamentoAntecipado?: Maybe<ArquivoGerado>,
  gerarIntegracaoConciliaRetornoNossoNumero?: Maybe<ArquivoGerado>,
  gerarIntegracaoConciliaRelatorio?: Maybe<ArquivoGerado>,
  gerarIntegracaoMakro?: Maybe<Scalars['String']>,
  gerarIntegracaoTeknisa?: Maybe<ArquivoGerado>,
  gerarIntegracaoTotvs?: Maybe<ArquivoGerado>,
  gerarIntegracaoSoftwareExpress?: Maybe<ArquivoGerado>,
  gerarIntegracaoLinx?: Maybe<ArquivoGerado>,
  gerarIntegracaoOlx?: Maybe<ArquivoGerado>,
  gerarRelatorioMovimentosFechados?: Maybe<Scalars['String']>,
  deletarClientes?: Maybe<Scalars['Boolean']>,
  saveClientes?: Maybe<ClienteCrud>,
  salvarParametros?: Maybe<Scalars['Boolean']>,
  faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota?: Maybe<Scalars['Boolean']>,
  salvarBucketS3Amazon?: Maybe<BucketS3AmazonCrud>,
  deletarBucketS3AmazonCRUD?: Maybe<Scalars['Boolean']>,
  salvarIntegracaoAgendamento?: Maybe<IntegracaoConciliaAgendamentoCrud>,
  salvarParametrosTOTVS?: Maybe<IntegracaoTotvsCadastroParametrosConciliador>,
  deletarParametrosTOTVS?: Maybe<Scalars['Boolean']>,
  salvarIntegracaoTotvsCadastroOperadoras?: Maybe<IntegracaoTotvsCadastroOperadoras>,
  deletarIntegracaoTotvsCadastroOperadoras?: Maybe<Scalars['Boolean']>,
  salvarIntegracaoConciliaAjuste?: Maybe<IntegracaoConciliaAjusteCrud>,
  deletarIntegracaoConciliaAjuste?: Maybe<Scalars['Boolean']>,
  salvarIntegracaoSoftExpressCadastro?: Maybe<IntegracaoSoftExpressCadastro>,
  deletarIntegracaoSoftExpressCadastro?: Maybe<Scalars['Boolean']>,
  corrigir?: Maybe<IntegracaoCorrigirRetorno>,
  gravarCorrecao?: Maybe<Scalars['String']>,
  usuarioTrocarSenha?: Maybe<Scalars['String']>,
  aprovarUrl?: Maybe<CanUploadResult>,
};


export type MutationSaveUsuarioArgs = {
  values?: Maybe<UsuarioCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateUsuarioArgs = {
  values?: Maybe<UsuarioDaoInput>
};


export type MutationDeleteUsuarioArgs = {
  values?: Maybe<UsuarioDaoInput>
};


export type MutationSavePerfilArgs = {
  values?: Maybe<PerfilCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePerfilArgs = {
  values?: Maybe<PerfilCrudInput>
};


export type MutationSaveTerminalArgs = {
  values?: Maybe<TerminalCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteTerminalArgs = {
  values?: Maybe<TerminalCrudInput>
};


export type MutationSaveTaxasAntecipacaoArgs = {
  values?: Maybe<TaxasAntecipacaoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteTaxasAntecipacaoArgs = {
  values?: Maybe<TaxasAntecipacaoCrudInput>
};


export type MutationSalvarPlanosPosArgs = {
  values?: Maybe<PlanosCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosPosArgs = {
  values?: Maybe<PlanosCrudInput>
};


export type MutationSalvarPlanosTerminalArgs = {
  values?: Maybe<PlanosTerminalCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosTerminalArgs = {
  values?: Maybe<PlanosTerminalCrudInput>
};


export type MutationSalvarPlanosPosTaxasArgs = {
  values?: Maybe<PlanosTaxasCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosPosTaxasArgs = {
  values?: Maybe<PlanosTaxasCrudInput>
};


export type MutationSavePlanosConciliadoresArgs = {
  values?: Maybe<PlanosConciliadoresCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosConciliadoresArgs = {
  values?: Maybe<PlanosConciliadoresCrudInput>
};


export type MutationSavePlanosConciliadoresContratoArgs = {
  values?: Maybe<PlanosConciliadoresContratoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosConciliadoresContratoArgs = {
  values?: Maybe<PlanosConciliadoresContratoCrudInput>
};


export type MutationSavePlanosConciliadoresTaxasArgs = {
  values?: Maybe<PlanosConciliadoresTaxasCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletePlanosConciliadoresTaxasArgs = {
  values?: Maybe<PlanosConciliadoresTaxasCrudInput>
};


export type MutationLoginArgs = {
  username?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  values?: Maybe<RecaptchaInput>
};


export type MutationUploadDocsEstabelecimentoArgs = {
  nameFile?: Maybe<Scalars['String']>,
  tipoDoc?: Maybe<Scalars['String']>,
  file?: Maybe<Array<Maybe<Scalars['File']>>>,
  values?: Maybe<ListarEstabelecimentosInput>
};


export type MutationEditarEstabelecimentoArgs = {
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
  codExternoAntigo?: Maybe<Scalars['String']>,
  apelido?: Maybe<Scalars['String']>,
  codPessoa?: Maybe<Scalars['Int']>
};


export type MutationDeleteEstabelecimentoOperadoraArgs = {
  values?: Maybe<DeleteEstabelecimentoOperadoraInput>
};


export type MutationSavePainelCadastroTaxaArgs = {
  values?: Maybe<SavePainelCadastroTaxaInput>
};


export type MutationDownloadManutencaoArquivosArgs = {
  values?: Maybe<DownloadManutencaoArquivosInput>
};


export type MutationReprocessarManutencaoArquivosArgs = {
  values?: Maybe<ReprocessarManutencaoArquivosInput>
};


export type MutationDeletarManutencaoArquivosArgs = {
  values?: Maybe<ReprocessarManutencaoArquivosInput>
};


export type MutationDownloadExtratosOriginaisArgs = {
  values?: Maybe<DownloadExtratosOriginaisInput>
};


export type MutationImportarTicketArgs = {
  values?: Maybe<Array<Maybe<TicketsImportacaoBeanInput>>>
};


export type MutationImportarTodosTicketsArgs = {
  values?: Maybe<ImportacaoTicketInput>
};


export type MutationIncluirTicketArgs = {
  values?: Maybe<InclusaoTicketGeralInput>,
  tickets?: Maybe<Array<Maybe<TicketsInclusaoBeanInput>>>
};


export type MutationInserirRapidoArgs = {
  values?: Maybe<InserirRapidoInput>
};


export type MutationDownloadAnalisePesquisaTicketArgs = {
  values?: Maybe<DownloadAnalisePesquisaTicketInput>
};


export type MutationReprocessarTicketArgs = {
  values?: Maybe<ReprocessarTicketInput>
};


export type MutationDeletarTicketArgs = {
  values?: Maybe<DeletarTicketInput>
};


export type MutationReconsiderarDesconsiderarTicketArgs = {
  codlinhasarqcli?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  acao?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioExtratoConferenciaPagamentoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioExtratoConferenciaLiquidacaoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioExtratoConferenciaBoletoPrevisaoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioExtratoConferenciaVendaArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioTaxasArgs = {
  values?: Maybe<TaxasDaoInput>,
  exportar?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioPrevisaoValoresLiquidosSemestralArgs = {
  filtro?: Maybe<GerarRelatorioPrevisaoValoresLiquidosSemestralInput>
};


export type MutationGerarRelatorioPrevisaoValoresLiquidosSemamalArgs = {
  filtro?: Maybe<GerarRelatorioPrevisaoValoresLiquidosSemanalInput>,
  consideraAjuste?: Maybe<Scalars['Boolean']>,
  datavendalimite?: Maybe<Scalars['Date']>,
  apurarPendentePagamento?: Maybe<Scalars['Boolean']>,
  analise?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioPrevisaoPagamentoArgs = {
  filtro?: Maybe<GerarRelatorioPrevisaoPagamentoInput>,
  consideraAjuste?: Maybe<Scalars['Boolean']>
};


export type MutationGerarRelatorioEncontradosClientePagamentoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioEncontradosClienteVendaArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioEncontradosClienteLiquidacaoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioEncontradosClientePrevisaoArgs = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioMotivoAjusteArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioValoresDivergentesArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioOlxIntegracaoPagamentoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioOlxIntegracaoVendaArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioOlxIntegracaoAjusteArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioVendasPorLojaValorBrutoArgs = {
  ajustes?: Maybe<Scalars['String']>,
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioRejeitadosArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioVendasArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioSinteticoTipoConciliacaoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioTransacoesProblemasTefArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioPagamentoComDebitoAgrupadoVendaArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioConciliadosPagamentoFiltroSimplesArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioConciliadosVendaFiltroSimplesArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioConciliadosBoletoFiltroSimplesArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioConciliadosPrevisaoFiltroSimplesArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationGerarRelatorioPagamentosEfetivosArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioFluxoRecebimentoCartaoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioMensalidadesRecebidasArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioFechamentoMensalTerminalArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioTaxasTerminalArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioTerminaisAtivosArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarRelatorioTerminaisSemMovimentoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  valor?: Maybe<Scalars['Float']>
};


export type MutationLogarUsuarioMestreArgs = {
  searchValues?: Maybe<LogarUsuarioMestreInput>
};


export type MutationLogarUsuarioMestreRevendaArgs = {
  searchValues?: Maybe<LogarUsuarioMestreInput>
};


export type MutationTrocarSenhaArgs = {
  codpessoa?: Maybe<Scalars['Int']>
};


export type MutationSalvarTefsArgs = {
  tefs?: Maybe<Array<Maybe<UsuarioMestreTefsBeanInput>>>
};


export type MutationSalvarMestreRevendaArgs = {
  values?: Maybe<MestresRevendaInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationSalvarDadosNFeArgs = {
  values?: Maybe<DadosNfeCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationGerarRelatorioFechamentoValoresLiquidosSemestralArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  botaoExportar?: Maybe<Scalars['Boolean']>
};


export type MutationGerarRelatorioConciliadosAntecipadosArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaAntecipadosInput>,
  excelsimples?: Maybe<Scalars['String']>
};


export type MutationDownloadArquivoConciliacaoEmAbertoArgs = {
  codarquivoDownload?: Maybe<Scalars['String']>
};


export type MutationAlterarSenhaArgs = {
  usuario?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>
};


export type MutationRedefinirSenhaArgs = {
  senhaAtual?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>
};


export type MutationSalvarRelatorioAgendamentoArgs = {
  values?: Maybe<AgendamentoRelatorioInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationSalvarPlanosConciliadoresContratoArgs = {
  values?: Maybe<PlanoConciliadoresContratoInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationGerarIntegracaoAlterdataArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  codigocontacreditopg?: Maybe<Scalars['String']>,
  codigocontadebitopg?: Maybe<Scalars['String']>,
  codigocontacreditorc?: Maybe<Scalars['String']>,
  codigocontadebitorc?: Maybe<Scalars['String']>
};


export type MutationGerarIntegracaoAvancoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoCashArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoKaplenArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoMicroUniversoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoConsincoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  versao?: Maybe<Scalars['String']>,
  tipoNsu?: Maybe<Scalars['String']>,
  integradoPDV?: Maybe<Scalars['Boolean']>,
  mostrarDescricaoAjuste?: Maybe<Scalars['Boolean']>,
  pos?: Maybe<Scalars['String']>,
  tipodatavenda?: Maybe<Scalars['String']>,
  tipoTiket?: Maybe<Scalars['String']>
};


export type MutationGerarIntegracaoConciliaVendaPagamentoAntecipadoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  ajusteConsolidado?: Maybe<Scalars['String']>,
  tipoRelatorio: Scalars['String']
};


export type MutationGerarIntegracaoConciliaRetornoNossoNumeroArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  ultimos7Dias?: Maybe<Scalars['Boolean']>,
  refazerConciliacao702?: Maybe<Scalars['Boolean']>,
  refazerConciliacao701?: Maybe<Scalars['Boolean']>,
  corrigir?: Maybe<Scalars['String']>,
  botaoCorrigir?: Maybe<Scalars['Boolean']>
};


export type MutationGerarIntegracaoConciliaRelatorioArgs = {
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  datade?: Maybe<Scalars['Date']>,
  dataate?: Maybe<Scalars['Date']>,
  somenteProblemas?: Maybe<Scalars['Boolean']>,
  sintetico?: Maybe<Scalars['Boolean']>,
  exportar?: Maybe<Scalars['String']>
};


export type MutationGerarIntegracaoMakroArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoTeknisaArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoTotvsArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoSoftwareExpressArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoLinxArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type MutationGerarIntegracaoOlxArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  codbuckets3amazon?: Maybe<Scalars['Int']>,
  transacaoComProblema?: Maybe<Scalars['Boolean']>
};


export type MutationGerarRelatorioMovimentosFechadosArgs = {
  chave?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
  datainclusao?: Maybe<Scalars['Date']>
};


export type MutationDeletarClientesArgs = {
  values?: Maybe<ClienteCrudInput>
};


export type MutationSaveClientesArgs = {
  values?: Maybe<ClienteCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationSalvarParametrosArgs = {
  values?: Maybe<ParametrosDaoInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationFaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaArgs = {
  values?: Maybe<FaturamentoMovimentoBeanInput>,
  operacao: Scalars['String']
};


export type MutationSalvarBucketS3AmazonArgs = {
  values?: Maybe<BucketS3AmazonCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletarBucketS3AmazonCrudArgs = {
  values?: Maybe<BucketS3AmazonCrudInput>
};


export type MutationSalvarIntegracaoAgendamentoArgs = {
  values?: Maybe<IntegracaoConciliaAgendamentoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationSalvarParametrosTotvsArgs = {
  values?: Maybe<IntegracaoTotvsCadastroParametrosConciliadorInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletarParametrosTotvsArgs = {
  values?: Maybe<IntegracaoTotvsCadastroParametrosConciliadorInput>
};


export type MutationSalvarIntegracaoTotvsCadastroOperadorasArgs = {
  values?: Maybe<IntegracaoTotvsCadastroOperadorasInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletarIntegracaoTotvsCadastroOperadorasArgs = {
  values?: Maybe<IntegracaoTotvsCadastroOperadorasInput>
};


export type MutationSalvarIntegracaoConciliaAjusteArgs = {
  values?: Maybe<IntegracaoConciliaAjusteCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletarIntegracaoConciliaAjusteArgs = {
  values?: Maybe<DeletarIntegracaoConciliaAjusteInput>
};


export type MutationSalvarIntegracaoSoftExpressCadastroArgs = {
  values?: Maybe<IntegracaoSoftExpressCadastroInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type MutationDeletarIntegracaoSoftExpressCadastroArgs = {
  values?: Maybe<IntegracaoSoftExpressCadastroInput>
};


export type MutationCorrigirArgs = {
  values?: Maybe<IntegracaoConciliaInput>
};


export type MutationGravarCorrecaoArgs = {
  values?: Maybe<Array<Maybe<UploadSelecionadosInput>>>
};


export type MutationUsuarioTrocarSenhaArgs = {
  idTrocaSenha?: Maybe<Scalars['String']>,
  gravarResponsavel?: Maybe<Scalars['Boolean']>,
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>
};


export type MutationAprovarUrlArgs = {
  values?: Maybe<AprovarUrlInput>
};

export type NiveisSlaDao = {
   __typename?: 'NiveisSlaDAO',
  codNiveisSla?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  horasLimite?: Maybe<Scalars['Int']>,
};

export type Noticias = {
   __typename?: 'Noticias',
  dao?: Maybe<NoticiasDao>,
};

export type NoticiasDao = {
   __typename?: 'NoticiasDAO',
  codnoticia?: Maybe<Scalars['Int']>,
  titulo?: Maybe<Scalars['String']>,
  texto?: Maybe<Scalars['String']>,
  datainicio?: Maybe<Scalars['Date']>,
  datafim?: Maybe<Scalars['Date']>,
};

export type NovoClienteCrud = {
   __typename?: 'NovoClienteCRUD',
  listFutura?: Maybe<Array<Maybe<FuturaPessoaDao>>>,
  pessoa?: Maybe<FuturaPessoaViewDao>,
  usuarioMestre?: Maybe<FuturoUsuarioMestreDao>,
  editHTML?: Maybe<Scalars['String']>,
  codtefs?: Maybe<Scalars['String']>,
};

export type OauthPainel = {
   __typename?: 'OauthPainel',
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
};

export type OperadoraLinhaBean = {
   __typename?: 'OperadoraLinhaBean',
  nomearquivo?: Maybe<Scalars['String']>,
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  statusArquivo?: Maybe<Scalars['String']>,
  datarecebimento?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  datavenda?: Maybe<Scalars['Date']>,
  parcela?: Maybe<Scalars['Int']>,
  totparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  status?: Maybe<Scalars['String']>,
  meiocaptura?: Maybe<Scalars['String']>,
  produto?: Maybe<Scalars['String']>,
  bandeira?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  taxacomissao?: Maybe<Scalars['Float']>,
};

export type OperadorasBean = {
   __typename?: 'OperadorasBean',
  codOperadora?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type OperadorasCrud = {
   __typename?: 'OperadorasCRUD',
  listaArquivosProcedimentosOperadora?: Maybe<Array<Maybe<ProcedimentosOperadoraDao>>>,
  mensagemErro?: Maybe<Scalars['String']>,
  indiceArquivo?: Maybe<Scalars['String']>,
  tipoprocedimento?: Maybe<Scalars['String']>,
  instrucaoArquivoImagem?: Maybe<Scalars['String']>,
};

export type OperadorasDao = {
   __typename?: 'OperadorasDAO',
  codoperadora?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  formacalculo?: Maybe<Scalars['String']>,
  roteada?: Maybe<Scalars['String']>,
  qtdeDigitosEc?: Maybe<Scalars['Int']>,
  obs?: Maybe<Scalars['String']>,
  consinco?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  codPadraoEmail?: Maybe<Scalars['Int']>,
  codPadraoFtpSeguro?: Maybe<Scalars['Int']>,
  documentoPadrao?: Maybe<Scalars['String']>,
  completaquantidadedigitos?: Maybe<Scalars['String']>,
  qtdDiasAMais?: Maybe<Scalars['Int']>,
  qtdDiasAMenos?: Maybe<Scalars['Int']>,
  desconsideraSufixo?: Maybe<Scalars['String']>,
  estabelecimentoCadastroTaxa?: Maybe<Scalars['String']>,
};

export type OperadoraStatus = {
   __typename?: 'OperadoraStatus',
  operadora?: Maybe<OperadorasDao>,
  operadoraLiberada?: Maybe<ConciOperadoraLiberadaDao>,
  statusCarteira?: Maybe<Status>,
  statusTermo?: Maybe<Status>,
  statusEC?: Maybe<Status>,
  statusTaxa?: Maybe<Status>,
  displayTermo?: Maybe<Scalars['String']>,
  arquivoImagemPOS?: Maybe<Scalars['String']>,
  arquivoImagemTEF?: Maybe<Scalars['String']>,
  tipoInstrucaoCadastroEC?: Maybe<Scalars['String']>,
  downlaodTermoAdesaoCss?: Maybe<Scalars['String']>,
  downlaodAutorizacaoCss?: Maybe<Scalars['String']>,
};

export type OperadorasTotvsDao = {
   __typename?: 'OperadorasTotvsDAO',
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type OperadorasTotvsDaoInput = {
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type OperadoraUnidadeConciliadoraBean = {
   __typename?: 'OperadoraUnidadeConciliadoraBean',
  codigoOperadora?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  roteada?: Maybe<Scalars['String']>,
  codigoExterno?: Maybe<Scalars['String']>,
};

export type OrigemAjusteDao = {
   __typename?: 'OrigemAjusteDAO',
  codorigemajuste?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  obs?: Maybe<Scalars['String']>,
  exibeAjusteVenda?: Maybe<Scalars['String']>,
};

export type OrigemAjustePersonalizadoDao = {
   __typename?: 'OrigemAjustePersonalizadoDAO',
  codorigemajuste?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  ajuste?: Maybe<Scalars['String']>,
};

export type OrigemAjustePersonalizadoDaoInput = {
  codorigemajuste?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type PadraoEmailOperadoraCrud = {
   __typename?: 'PadraoEmailOperadoraCRUD',
  cartaIntervan?: Maybe<Scalars['Boolean']>,
  termoAdesao?: Maybe<Scalars['Boolean']>,
  anexoDinamico?: Maybe<Scalars['Boolean']>,
  mensagemErro?: Maybe<Scalars['String']>,
  mensagemTela?: Maybe<Scalars['String']>,
};

export type PadraoEmailOperadoraDao = {
   __typename?: 'PadraoEmailOperadoraDAO',
  codPadraoEmailOperadora?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  corpoEmail?: Maybe<Scalars['String']>,
  cartaIntervan?: Maybe<Scalars['String']>,
  termoAdesao?: Maybe<Scalars['String']>,
  anexoDinamico?: Maybe<Scalars['String']>,
};

export type PadraoFtpSeguroDao = {
   __typename?: 'PadraoFtpSeguroDAO',
  codpadraoftpseguro?: Maybe<Scalars['Int']>,
  host?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
  protocolo?: Maybe<Scalars['String']>,
  diretorioftp?: Maybe<Scalars['String']>,
  diretoriodestino?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  buscaarquivonome?: Maybe<Scalars['String']>,
  ecraiz?: Maybe<Scalars['String']>,
  utilizacertificado?: Maybe<Scalars['String']>,
  nomecertificado?: Maybe<Scalars['String']>,
  somenteFtpSimples?: Maybe<Scalars['String']>,
};

export type PagamentosTerminaisDao = {
   __typename?: 'PagamentosTerminaisDAO',
  nsu?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  valorbrutoparcela?: Maybe<Scalars['Float']>,
  parcela?: Maybe<Scalars['String']>,
  totalparcelas?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  valorliquidoparcela?: Maybe<Scalars['Float']>,
  valorcomissaoterminal?: Maybe<Scalars['Float']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  chave?: Maybe<Scalars['String']>,
  terminal?: Maybe<Scalars['String']>,
  datapagamento?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
};

export type PaginaPropriaDao = {
   __typename?: 'PaginaPropriaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  pagina?: Maybe<Scalars['String']>,
};

export type Painel = {
   __typename?: 'Painel',
  url?: Maybe<Scalars['String']>,
};

export type ParametrosConciliadorTotvsDao = {
   __typename?: 'ParametrosConciliadorTotvsDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codfilial?: Maybe<Scalars['Int']>,
  opcional1?: Maybe<Scalars['String']>,
  opcional2?: Maybe<Scalars['String']>,
  coddepartamento?: Maybe<Scalars['String']>,
  codcentrocusto?: Maybe<Scalars['String']>,
  tipocontabil?: Maybe<Scalars['Int']>,
  codmoeda?: Maybe<Scalars['String']>,
  codcontacaixa?: Maybe<Scalars['String']>,
  statuslancamento?: Maybe<Scalars['Int']>,
  codcoligadaclifor?: Maybe<Scalars['Int']>,
  codcoligadacontacaixa?: Maybe<Scalars['Int']>,
  tiposacado?: Maybe<Scalars['String']>,
  codevento?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type ParametrosConciliadorTotvsDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codfilial?: Maybe<Scalars['Int']>,
  opcional1?: Maybe<Scalars['String']>,
  opcional2?: Maybe<Scalars['String']>,
  coddepartamento?: Maybe<Scalars['String']>,
  codcentrocusto?: Maybe<Scalars['String']>,
  tipocontabil?: Maybe<Scalars['Int']>,
  codmoeda?: Maybe<Scalars['String']>,
  codcontacaixa?: Maybe<Scalars['String']>,
  statuslancamento?: Maybe<Scalars['Int']>,
  codcoligadaclifor?: Maybe<Scalars['Int']>,
  codcoligadacontacaixa?: Maybe<Scalars['Int']>,
  tiposacado?: Maybe<Scalars['String']>,
  codevento?: Maybe<Scalars['String']>,
};

export type ParametrosDao = {
   __typename?: 'ParametrosDAO',
  grupo?: Maybe<Scalars['String']>,
  item?: Maybe<Scalars['String']>,
  texto?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type ParametrosDaoInput = {
  grupo?: Maybe<Scalars['String']>,
  item?: Maybe<Scalars['String']>,
  texto?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type ParametrosFtpBean = {
   __typename?: 'ParametrosFTPBean',
  diretorioFtpDestino?: Maybe<Scalars['String']>,
  diretorioFtpOrigem?: Maybe<Scalars['String']>,
  host?: Maybe<Scalars['String']>,
  protocolo?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  buscaArquivoNome?: Maybe<Scalars['String']>,
  identificadorNome?: Maybe<Scalars['String']>,
  utilizaCerticado?: Maybe<Scalars['String']>,
  nomeCertificado?: Maybe<Scalars['String']>,
  diretorioFtpSeguroOrigem?: Maybe<Scalars['String']>,
  diretorioFtpSeguroDestino?: Maybe<Scalars['String']>,
  porta?: Maybe<Scalars['Int']>,
};

export type ParametrosIntegracaoCrud = {
   __typename?: 'ParametrosIntegracaoCRUD',
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  empresa?: Maybe<Scalars['String']>,
  tipoNsu?: Maybe<Scalars['String']>,
  tipoNossonum?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
};

export type ParametrosIntegracaoDao = {
   __typename?: 'ParametrosIntegracaoDAO',
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  empresa?: Maybe<Scalars['String']>,
  tipoNsu?: Maybe<Scalars['String']>,
};

export type ParametrosRecorrenteCrud = {
   __typename?: 'ParametrosRecorrenteCRUD',
  codpessoapai?: Maybe<Scalars['Int']>,
  unidadeConciliadora?: Maybe<Scalars['String']>,
};

export type ParametrosRecorrenteDao = {
   __typename?: 'ParametrosRecorrenteDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  token?: Maybe<Scalars['String']>,
  merchantid?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type ParceiroConciliadoresDao = {
   __typename?: 'ParceiroConciliadoresDAO',
  codpessoaconciliadora?: Maybe<Scalars['Int']>,
  codpessoaparceiro?: Maybe<Scalars['Int']>,
};

export type ParceiroCrud = {
   __typename?: 'ParceiroCRUD',
  pessoa?: Maybe<PessoaGenerica>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['Boolean']>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type PdvDao = {
   __typename?: 'PdvDAO',
  codpdv?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PerfilCrud = {
   __typename?: 'PerfilCRUD',
  recursos?: Maybe<Array<Maybe<RecursoDao>>>,
  editValues?: Maybe<PerfilDao>,
  searchValues?: Maybe<PerfilDao>,
};

export type PerfilCrudEditValuesInput = {
  nome?: Maybe<Scalars['String']>,
  codperfil?: Maybe<Scalars['Int']>,
};

export type PerfilCrudInput = {
  editValues?: Maybe<PerfilCrudEditValuesInput>,
  searchValues?: Maybe<PerfilCrudEditValuesInput>,
  recursoUsuario?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type PerfilDao = {
   __typename?: 'PerfilDAO',
  codperfil?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoacriador?: Maybe<Scalars['Int']>,
};

export type PerfilDaoInput = {
  codperfil?: Maybe<Scalars['Int']>,
};

export type PerfilRecursosDao = {
   __typename?: 'PerfilRecursosDAO',
  codperfil?: Maybe<Scalars['Int']>,
  sicodigo?: Maybe<Scalars['String']>,
  rccodigo?: Maybe<Scalars['String']>,
};

export type PessoaDao = {
   __typename?: 'PessoaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  estado?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataSuspensao?: Maybe<Scalars['Date']>,
  dataCancelamento?: Maybe<Scalars['Date']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataRetiradaSuspensao?: Maybe<Scalars['Date']>,
  listEnderecoAdicional?: Maybe<Array<Maybe<EnderecoAdicionalDao>>>,
};

export type PessoaDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  estado?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataSuspensao?: Maybe<Scalars['Date']>,
  dataCancelamento?: Maybe<Scalars['Date']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataRetiradaSuspensao?: Maybe<Scalars['Date']>,
  listEnderecoAdicional?: Maybe<Array<Maybe<EnderecoAdicionalDaoInput>>>,
};

export type PessoaFisicaDao = {
   __typename?: 'PessoaFisicaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  cpf?: Maybe<Scalars['String']>,
};

export type PessoaFisicaDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  cpf?: Maybe<Scalars['String']>,
};

export type PessoaGenerica = {
   __typename?: 'PessoaGenerica',
  pessoa?: Maybe<PessoaDao>,
  pessoaF?: Maybe<PessoaFisicaDao>,
  pessoaJ?: Maybe<PessoaJuridicaDao>,
  mestre?: Maybe<Scalars['Boolean']>,
};

export type PessoaGenericaInput = {
  pessoa?: Maybe<PessoaDaoInput>,
  pessoaF?: Maybe<PessoaFisicaDaoInput>,
  pessoaJ?: Maybe<PessoaJuridicaDaoInput>,
};

export type PessoaJuridicaDao = {
   __typename?: 'PessoaJuridicaDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  cnpj?: Maybe<Scalars['String']>,
  inscestadual?: Maybe<Scalars['String']>,
  inscmunicipal?: Maybe<Scalars['String']>,
};

export type PessoaJuridicaDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  cnpj?: Maybe<Scalars['String']>,
  inscestadual?: Maybe<Scalars['String']>,
  inscmunicipal?: Maybe<Scalars['String']>,
};

export type PlanoArquivoConciliadorCrud = {
   __typename?: 'PlanoArquivoConciliadorCRUD',
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadesSemPlano?: Maybe<Array<Maybe<ConciOperadoraDao>>>,
  unidadesExistentes?: Maybe<Array<Maybe<ConciOperadoraDao>>>,
  mensagemErro?: Maybe<Scalars['String']>,
};

export type PlanoArquivoConciliadorDao = {
   __typename?: 'PlanoArquivoConciliadorDAO',
  codPlanoArquivo?: Maybe<Scalars['Int']>,
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
};

export type PlanoArquivoCrud = {
   __typename?: 'PlanoArquivoCRUD',
  planoArquivoSelecionados?: Maybe<Array<Maybe<PlanoArquivoSelecionadoDao>>>,
};

export type PlanoArquivoDao = {
   __typename?: 'PlanoArquivoDAO',
  codPlanoArquivo?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type PlanoArquivoSelecionadoDao = {
   __typename?: 'PlanoArquivoSelecionadoDAO',
  codPlanoArquivo?: Maybe<Scalars['Int']>,
  arquivoSelecionado?: Maybe<Scalars['String']>,
};

export type PlanoConciliadoresContratoEditInput = {
  editValues?: Maybe<PlanoConciliadoresContratoInput>,
};

export type PlanoConciliadoresContratoInput = {
  codplanosconciliadorescontrato?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PlanosConciliadoresContrato = {
   __typename?: 'PlanosConciliadoresContrato',
  codplanosconciliadorescontrato?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  plano?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresContratoCrud = {
   __typename?: 'PlanosConciliadoresContratoCRUD',
  listConciOperadoraGeral?: Maybe<Array<Maybe<LabelValue>>>,
  listConciOperadora?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<PlanosConciliadoresContratoDao>,
  searchValues?: Maybe<PlanosConciliadoresContratoDao>,
};

export type PlanosConciliadoresContratoCrudInput = {
  listConciOperadora?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<PlanosConciliadoresContratoDaoInput>,
  searchValues?: Maybe<PlanosConciliadoresContratoDaoInput>,
};

export type PlanosConciliadoresContratoDao = {
   __typename?: 'PlanosConciliadoresContratoDAO',
  codplanosconciliadorescontrato?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PlanosConciliadoresContratoDaoInput = {
  codplanosconciliadorescontrato?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['Int']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PlanosConciliadoresCrud = {
   __typename?: 'PlanosConciliadoresCRUD',
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  tarifacao?: Maybe<Scalars['String']>,
  editValues?: Maybe<PlanosConciliadoresDao>,
  searchValues?: Maybe<PlanosConciliadoresDao>,
};

export type PlanosConciliadoresCrudInput = {
  editValues?: Maybe<PlanosConciliadoresDaoInput>,
  searchValues?: Maybe<PlanosConciliadoresDaoInput>,
};

export type PlanosConciliadoresDao = {
   __typename?: 'PlanosConciliadoresDAO',
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  tarifacao?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresDaoInput = {
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  tarifacao?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresTaxas = {
   __typename?: 'PlanosConciliadoresTaxas',
  codplanosconciliadorestaxas?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  plano?: Maybe<Scalars['String']>,
  codprodutos?: Maybe<Scalars['Int']>,
  converterProduto?: Maybe<Scalars['String']>,
  duplicado?: Maybe<Scalars['String']>,
  converterProdutoTipo?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  converterProdutoTaxas?: Maybe<Scalars['String']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  nomeOperadora?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresTaxasCrud = {
   __typename?: 'PlanosConciliadoresTaxasCRUD',
  editValues?: Maybe<PlanosConciliadoresTaxasDao>,
  searchValues?: Maybe<PlanosConciliadoresTaxasDao>,
  taxas?: Maybe<PlanosConciliadoresTaxasDao>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosConciliadoresTaxasDao>>>,
  mensagem?: Maybe<Scalars['String']>,
  mensagemBndes?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  planoantigo?: Maybe<Scalars['Int']>,
  planonovo?: Maybe<Scalars['Int']>,
  taxabndes?: Maybe<Scalars['Float']>,
  tarifacao?: Maybe<Scalars['String']>,
  listTaxas?: Maybe<Array<Maybe<TaxasBean>>>,
  nomePlano?: Maybe<Scalars['String']>,
  taxaCredito?: Maybe<Scalars['Float']>,
  taxaCredito0a6?: Maybe<Scalars['Float']>,
  taxaCredito7a12?: Maybe<Scalars['Float']>,
  taxaDebito?: Maybe<Scalars['Float']>,
  taxaTicket?: Maybe<Scalars['Float']>,
  mensagemErro?: Maybe<Scalars['String']>,
  converterProdutoTaxas?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresTaxasCrudInput = {
  editValues?: Maybe<PlanosConciliadoresTaxasDaoInput>,
  searchValues?: Maybe<PlanosConciliadoresTaxasDaoInput>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosConciliadoresTaxasDaoInput>>>,
  mensagem?: Maybe<Scalars['String']>,
  mensagemBndes?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  planoantigo?: Maybe<Scalars['Int']>,
  planonovo?: Maybe<Scalars['Int']>,
  taxabndes?: Maybe<Scalars['Float']>,
  tarifacao?: Maybe<Scalars['String']>,
  listTaxas?: Maybe<Array<Maybe<TaxasBeanInput>>>,
  nomePlano?: Maybe<Scalars['String']>,
  taxaCredito?: Maybe<Scalars['Float']>,
  taxaCredito0a6?: Maybe<Scalars['Float']>,
  taxaCredito7a12?: Maybe<Scalars['Float']>,
  taxaDebito?: Maybe<Scalars['Float']>,
  taxaTicket?: Maybe<Scalars['Float']>,
  mensagemErro?: Maybe<Scalars['String']>,
  taxas?: Maybe<PlanosConciliadoresTaxasDaoInput>,
};

export type PlanosConciliadoresTaxasDao = {
   __typename?: 'PlanosConciliadoresTaxasDAO',
  codplanosconciliadorestaxas?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  duplicado?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  tipotaxa?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresTaxasDaoEditValuesInput = {
  codplanosconciliadorestaxas?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
};

export type PlanosConciliadoresTaxasDaoInput = {
  codplanosconciliadorestaxas?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  duplicado?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
  tipotaxa?: Maybe<Scalars['String']>,
};

export type PlanosConciliadoresTaxasDaoSearchValues = {
  codplanosconciliadorestaxas?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  duplicado?: Maybe<Scalars['String']>,
  bincartao?: Maybe<Scalars['String']>,
};

export type PlanosCrud = {
   __typename?: 'PlanosCRUD',
  mensalidade?: Maybe<Scalars['String']>,
  editValues?: Maybe<PlanosDao>,
};

export type PlanosCrudInput = {
  mensalidade?: Maybe<Scalars['String']>,
  editValues?: Maybe<PlanosDaoInput>,
};

export type PlanosDao = {
   __typename?: 'PlanosDAO',
  codplano?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  cobramensalidade?: Maybe<Scalars['String']>,
  valormensalidade?: Maybe<Scalars['Float']>,
  diacorte?: Maybe<Scalars['Int']>,
};

export type PlanosDaoEditValuesInput = {
  codplano?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  cobramensalidade?: Maybe<Scalars['String']>,
  valormensalidade?: Maybe<Scalars['Float']>,
  diacorte?: Maybe<Scalars['Int']>,
};

export type PlanosDaoInput = {
  codplano?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  cobramensalidade?: Maybe<Scalars['String']>,
  valormensalidade?: Maybe<Scalars['Float']>,
  diacorte?: Maybe<Scalars['Int']>,
};

export type PlanosTaxasCrud = {
   __typename?: 'PlanosTaxasCRUD',
  editValues?: Maybe<PlanosTaxasDao>,
  taxas?: Maybe<PlanosTaxasDao>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosTaxasDao>>>,
  mensagem?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type PlanosTaxasCrudInput = {
  editValues?: Maybe<PlanosTaxasDaoInput>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosTaxasDaoInput>>>,
};

export type PlanosTaxasDao = {
   __typename?: 'PlanosTaxasDAO',
  codplanostaxas?: Maybe<Scalars['Int']>,
  codplano?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
};

export type PlanosTaxasDaoInput = {
  codplanostaxas?: Maybe<Scalars['Int']>,
  codplano?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
};

export type PlanosTaxasPadraoCrud = {
   __typename?: 'PlanosTaxasPadraoCRUD',
  codplano?: Maybe<Scalars['Int']>,
  mensagemSucesso?: Maybe<Scalars['String']>,
};

export type PlanosTerminal = {
   __typename?: 'PlanosTerminal',
  codplanosterminal?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanos?: Maybe<Scalars['Int']>,
  codterminal?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  plano?: Maybe<Scalars['String']>,
};

export type PlanosTerminalCrud = {
   __typename?: 'PlanosTerminalCRUD',
  listTerminal?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<PlanosTerminalDao>,
};

export type PlanosTerminalCrudInput = {
  listTerminal?: Maybe<Array<Maybe<Scalars['String']>>>,
  editValues?: Maybe<PlanosTerminalDaoInput>,
};

export type PlanosTerminalDao = {
   __typename?: 'PlanosTerminalDAO',
  codplanosterminal?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanos?: Maybe<Scalars['Int']>,
  codterminal?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PlanosTerminalDaoInput = {
  codplanosterminal?: Maybe<Scalars['Int']>,
  seq?: Maybe<Scalars['Int']>,
  codplanos?: Maybe<Scalars['Int']>,
  codterminal?: Maybe<Scalars['String']>,
  vigenciaDe?: Maybe<Scalars['Date']>,
  vigenciaAte?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type PlanosTerminalPosTaxas = {
   __typename?: 'PlanosTerminalPosTaxas',
  codplanostaxas?: Maybe<Scalars['Int']>,
  codplano?: Maybe<Scalars['Int']>,
  codprodutos?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  plano?: Maybe<Scalars['String']>,
  converterProduto?: Maybe<Scalars['String']>,
};

export type PosicaoContratosPorClientesInput = {
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  statusFiltro?: Maybe<Scalars['String']>,
  codrevenda?: Maybe<Scalars['Int']>,
  codrevendaderevenda?: Maybe<Scalars['Int']>,
};

export type PrepareCadastroTaxaInput = {
  codOperadora?: Maybe<Scalars['Int']>,
};

export type ProcedimentosOperadoraDao = {
   __typename?: 'ProcedimentosOperadoraDAO',
  codProcedimentoOperadora?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  nomeArquivo?: Maybe<Scalars['String']>,
};

export type ProdutosComunCrud = {
   __typename?: 'ProdutosComunCRUD',
  mensagem?: Maybe<Scalars['String']>,
};

export type ProdutosDao = {
   __typename?: 'ProdutosDAO',
  codprodutos?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  tipoCredito?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
};

export type PropostaCrud = {
   __typename?: 'PropostaCRUD',
  mensagem?: Maybe<Scalars['String']>,
  bloqueiaOperadora?: Maybe<Scalars['Boolean']>,
};

export type ProvisionadosDao = {
   __typename?: 'ProvisionadosDAO',
  codfuturapessoa?: Maybe<Scalars['Int']>,
  codpessoaresponsavel?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  vencimento?: Maybe<Scalars['Date']>,
};

export type Query = {
   __typename?: 'Query',
  listarIntegracaoAjuste?: Maybe<Array<Maybe<OrigemAjustePersonalizadoDao>>>,
  getIntegracaoAjuste?: Maybe<IntegracaoConciliaAjusteCrud>,
  listarItensOrigemAjuste?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensOrigemAjustePersonalizado?: Maybe<Array<Maybe<LabelValor>>>,
  listarUsuario?: Maybe<Array<Maybe<UsuarioDaoList>>>,
  getUsuario?: Maybe<UsuarioCrud>,
  listarPerfil?: Maybe<Array<Maybe<PerfilDao>>>,
  getPerfil?: Maybe<PerfilCrud>,
  listarTerminal?: Maybe<Array<Maybe<TerminalDao>>>,
  getTerminal?: Maybe<TerminalCrud>,
  listarTaxasAntecipacao?: Maybe<Array<Maybe<TaxaAntecipacaoDao>>>,
  getTaxasAntecipacao?: Maybe<TaxasAntecipacaoCrud>,
  listarPlanosConciliadores?: Maybe<Array<Maybe<PlanosConciliadoresDao>>>,
  getPlanosConciliadores?: Maybe<PlanosConciliadoresCrud>,
  listarPlanosConciliadoresTaxas?: Maybe<Array<Maybe<PlanosConciliadoresTaxas>>>,
  getPlanosConciliadoresTaxas?: Maybe<PlanosConciliadoresTaxasCrud>,
  listarPlanosConciliadoresContrato?: Maybe<Array<Maybe<PlanosConciliadoresContrato>>>,
  getPlanosConciliadoresContrato?: Maybe<PlanosConciliadoresContratoCrud>,
  listarPlanosPOS?: Maybe<Array<Maybe<PlanosDao>>>,
  getPlanosPOS?: Maybe<PlanosCrud>,
  listarPlanosTerminal?: Maybe<Array<Maybe<PlanosTerminal>>>,
  getPlanosTerminal?: Maybe<PlanosTerminalCrud>,
  listarPlanosPOSTaxas?: Maybe<Array<Maybe<PlanosTerminalPosTaxas>>>,
  getPlanosPOSTaxas?: Maybe<PlanosTaxasCrud>,
  listaUnidadesConciliadoras?: Maybe<Array<Maybe<LabelValor>>>,
  listarContaCorrente?: Maybe<Array<Maybe<LabelValor>>>,
  listarStatusConciliacao?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensUnidadesConciliadorasFromCodPessoaCodOperadora?: Maybe<Array<Maybe<LabelValor>>>,
  filtrarItensUnidadesConciliadoras?: Maybe<Array<Maybe<LabelValor>>>,
  listaClientes?: Maybe<Array<Maybe<LabelValor>>>,
  listaOperadorasTaxasAntecipaxao?: Maybe<Array<Maybe<LabelValor>>>,
  listaOrigensAjustes?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensStatusConciliacao?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensStatusConciliacaoAntecipados?: Maybe<Array<Maybe<LabelValor>>>,
  listarConciliacaoEmAberto?: Maybe<Array<Maybe<ArquivosrecebidosDao>>>,
  listarItensTipoArquivo?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensStatusArquivo?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensUnidadesConciliadoras?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensOrigemArquivoRecebido?: Maybe<Array<Maybe<LabelValor>>>,
  listarManutencaoArquivos?: Maybe<Array<Maybe<ArquivosrecebidosDao>>>,
  listarExtratosOriginais?: Maybe<Array<Maybe<ArquivosrecebidosDao>>>,
  listarItensTerminal?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensMeioCaptura?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensRecorrente?: Maybe<Array<Maybe<LabelValor>>>,
  listarTickets?: Maybe<Array<Maybe<TicketsImportacaoBean>>>,
  listarItensConciliadores?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensConciliadoresCodExterno?: Maybe<Array<Maybe<LabelValor>>>,
  listarBandeiras?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensProdutos?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensPlanosConciliadores?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensPlanos?: Maybe<Array<Maybe<LabelValor>>>,
  pesquisarTickets?: Maybe<Array<Maybe<TicketsBean>>>,
  detalharTicket?: Maybe<DetalheTicket>,
  getDashBoard?: Maybe<DashBoardGraph>,
  comboItensPlanosConciliadores?: Maybe<Array<Maybe<LabelValor>>>,
  comboItensProdutos?: Maybe<Array<Maybe<LabelValor>>>,
  getMenus?: Maybe<Array<Maybe<Menu>>>,
  getNoticias?: Maybe<Array<Maybe<NoticiasDao>>>,
  getPainelCadastro?: Maybe<Array<Maybe<OperadoraStatus>>>,
  getCarregamento?: Maybe<Array<Maybe<ArquivosrecebidosDao>>>,
  getBanner?: Maybe<Scalars['String']>,
  listarUsuariosExpirados?: Maybe<Array<Maybe<UsuarioDao>>>,
  listarTaxasNaoCadastradas?: Maybe<Array<Maybe<TaxasNaoCadastradasBean>>>,
  listarUnidadesNaoEncontradasExibicao?: Maybe<Array<Maybe<UnidadesNaoEncontradasDao>>>,
  downloadUnidadesNaoEncontradasExibicao?: Maybe<Scalars['String']>,
  listarEstabelecimentos?: Maybe<Array<Maybe<ConciliadorOperadora>>>,
  estabelecimentoDownload?: Maybe<Scalars['String']>,
  canUpload?: Maybe<CanUploadResult>,
  baixarArquivoEstabelecimento?: Maybe<Scalars['String']>,
  getPrepareCadastroTaxa?: Maybe<Scalars['String']>,
  listarPerfis?: Maybe<Array<Maybe<LabelValor>>>,
  listarOperadoras?: Maybe<Array<Maybe<LabelValor>>>,
  listarEstados?: Maybe<Array<Maybe<LabelValor>>>,
  getUserInfo?: Maybe<InfoUserGraphQl>,
  getListOfResourcesGraphql?: Maybe<Array<Maybe<LabelValor>>>,
  getPosicaoContratosPorClientes?: Maybe<Array<Maybe<DataPosicaoContrato>>>,
  getInformacoesPosicaoContrato?: Maybe<Array<Maybe<ResultadoStatus>>>,
  getAgendamentosRelatorios?: Maybe<Array<Maybe<AgendamentoRelatorio>>>,
  getAgendamentoRelatorio?: Maybe<AgendamentoRelatorioGenerico>,
  getAgendamentoIntegracao?: Maybe<IntegracaoConciliaAgendamentoCrud>,
  getItensUnidadesPlanosConciliadores?: Maybe<Array<Maybe<LabelValue>>>,
  getItensUnidadesPlanos?: Maybe<Array<Maybe<LabelValue>>>,
  getItensProdutosByOperadora?: Maybe<Array<Maybe<LabelValor>>>,
  getItensProdutosByOperadoraPos?: Maybe<Array<Maybe<LabelValor>>>,
  resultadoAddTaxas?: Maybe<ValidacaoTaxas>,
  resultadoAddTaxasPos?: Maybe<ValidacaoTaxasPos>,
  resultadoAddTaxasBndes?: Maybe<ValidacaoTaxas>,
  resultadoAtualizaTaxas?: Maybe<ValidacaoTaxas>,
  resultadoSubTaxas?: Maybe<ValidacaoTaxas>,
  resultadoSubTaxasPos?: Maybe<ValidacaoTaxasPos>,
  listarItensBucketS3Amazon?: Maybe<Array<Maybe<LabelValor>>>,
  getPlanosContrato?: Maybe<Array<Maybe<PlanosConciliadoresContratoCrud>>>,
  listarCidades?: Maybe<Array<Maybe<LabelValor>>>,
  listarItensEstabelecimentoBancario?: Maybe<Array<Maybe<LabelValor>>>,
  getClientes?: Maybe<Array<Maybe<PessoaDao>>>,
  getCliente?: Maybe<ClienteCrud>,
  listarParametros?: Maybe<Array<Maybe<ParametrosDao>>>,
  listarFaturamentoMovimento?: Maybe<Array<Maybe<FaturamentoMovimentoBean>>>,
  listarItensTerminalVinculo?: Maybe<Array<Maybe<LabelValor>>>,
  listarOfNamesTipoMeioCapturaSemVazio?: Maybe<Array<Maybe<LabelValor>>>,
  getInfoNota?: Maybe<FaturamentoMovimentoDao>,
  previsualizarNota?: Maybe<Scalars['String']>,
  getRelatorioFechaMovimentoPrevisao?: Maybe<Scalars['String']>,
  listarMovimentosFechados?: Maybe<Array<Maybe<FechaMovimentoReimpressaoBean>>>,
  getUrlPainel?: Maybe<Scalars['String']>,
  getAutenticacaoPainel?: Maybe<OauthPainel>,
  listarBucketS3Amazon?: Maybe<Array<Maybe<BucketS3AmazonDao>>>,
  getBucketS3Amazon?: Maybe<BucketS3AmazonCrud>,
  listarParametrosTOTVS?: Maybe<Array<Maybe<ParametrosConciliadorTotvsDao>>>,
  getParametroTOTVS?: Maybe<IntegracaoTotvsCadastroParametrosConciliador>,
  listarCodigoOperadoraTOTVS?: Maybe<Array<Maybe<OperadorasTotvsDao>>>,
  getCodigoOperadoraTOTVS?: Maybe<IntegracaoTotvsCadastroOperadoras>,
  listarIntegracaoSoftExpressCadastro?: Maybe<Array<Maybe<ConciliadoresIntegSoftExpressDao>>>,
  getIntegracaoSoftExpressCadastro?: Maybe<IntegracaoSoftExpressCadastro>,
  getLoginSenhaAtendimento?: Maybe<LoginSenha>,
  getUsuariosMestres?: Maybe<Array<Maybe<UsuarioDaoList>>>,
  getUsuariosMestresRevenda?: Maybe<Array<Maybe<UsuarioDaoList>>>,
  listarTefs?: Maybe<Array<Maybe<LabelValor>>>,
  listarAllTefsDAO?: Maybe<Array<Maybe<TefsDao>>>,
  getTefs?: Maybe<Array<Maybe<UsuarioMestreTefsBean>>>,
  getDadoNFe?: Maybe<DadosNfeCrud>,
  getAllDadosNFe?: Maybe<Array<Maybe<DadosNfeDao>>>,
  getInfoUsuarrioTrocarSenha?: Maybe<UsuarioTrocaSenha>,
  listarEnderecosAdicionais?: Maybe<Array<Maybe<LabelValor>>>,
  listarUFs?: Maybe<Array<Maybe<LabelValor>>>,
  getAgendamentosIntegracao?: Maybe<Array<Maybe<AgendamentoIntegracaoDao>>>,
  getRelatorios?: Maybe<Array<Maybe<Relatorio>>>,
  getIntegracao?: Maybe<Array<Maybe<Relatorio>>>,
  getFormularioPlanosOpcoesPlanosConciliadores?: Maybe<Formulario>,
  getErrors?: Maybe<Errors>,
};


export type QueryGetIntegracaoAjusteArgs = {
  values?: Maybe<OrigemAjustePersonalizadoDaoInput>
};


export type QueryListarUsuarioArgs = {
  values?: Maybe<UsuarioDaoInput>
};


export type QueryGetUsuarioArgs = {
  values?: Maybe<UsuarioDaoInput>
};


export type QueryGetPerfilArgs = {
  values?: Maybe<PerfilDaoInput>
};


export type QueryListarTerminalArgs = {
  values?: Maybe<TerminalDaoInput>
};


export type QueryGetTerminalArgs = {
  values?: Maybe<TerminalDaoInput>
};


export type QueryListarTaxasAntecipacaoArgs = {
  values?: Maybe<TaxaAntecipacaoDaoInput>
};


export type QueryGetTaxasAntecipacaoArgs = {
  values?: Maybe<TaxaAntecipacaoDaoInput>
};


export type QueryListarPlanosConciliadoresArgs = {
  values?: Maybe<PlanosConciliadoresDaoInput>
};


export type QueryGetPlanosConciliadoresArgs = {
  values?: Maybe<PlanosConciliadoresDaoInput>
};


export type QueryListarPlanosConciliadoresTaxasArgs = {
  values?: Maybe<PlanosConciliadoresTaxasDaoInput>
};


export type QueryGetPlanosConciliadoresTaxasArgs = {
  values?: Maybe<PlanosConciliadoresTaxasDaoInput>
};


export type QueryListarPlanosConciliadoresContratoArgs = {
  values?: Maybe<PlanosConciliadoresContratoDaoInput>
};


export type QueryGetPlanosConciliadoresContratoArgs = {
  values?: Maybe<PlanosConciliadoresContratoDaoInput>
};


export type QueryListarPlanosPosArgs = {
  values?: Maybe<PlanosDaoInput>
};


export type QueryGetPlanosPosArgs = {
  values?: Maybe<PlanosDaoInput>
};


export type QueryListarPlanosTerminalArgs = {
  values?: Maybe<PlanosTerminalDaoInput>
};


export type QueryGetPlanosTerminalArgs = {
  values?: Maybe<PlanosTerminalDaoInput>
};


export type QueryListarPlanosPosTaxasArgs = {
  values?: Maybe<PlanosTaxasDaoInput>
};


export type QueryGetPlanosPosTaxasArgs = {
  values?: Maybe<PlanosTaxasDaoInput>
};


export type QueryListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraArgs = {
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type QueryFiltrarItensUnidadesConciliadorasArgs = {
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type QueryListaOperadorasTaxasAntecipaxaoArgs = {
  codOperadora?: Maybe<Scalars['Int']>
};


export type QueryListarConciliacaoEmAbertoArgs = {
  values?: Maybe<ManutencaoArquivosConiliacaoAbertoInput>
};


export type QueryListarManutencaoArquivosArgs = {
  values?: Maybe<ManutencaoArquivosInput>
};


export type QueryListarExtratosOriginaisArgs = {
  values?: Maybe<ManutencaoArquivosInput>
};


export type QueryListarTicketsArgs = {
  values?: Maybe<ImportacaoTicketInput>
};


export type QueryListarItensConciliadoresCodExternoArgs = {
  codpessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type QueryListarBandeirasArgs = {
  codOperadora?: Maybe<Scalars['Int']>
};


export type QueryPesquisarTicketsArgs = {
  values?: Maybe<TicketsBeanInput>
};


export type QueryDetalharTicketArgs = {
  values?: Maybe<TicketsDetalheInput>
};


export type QueryGetDashBoardArgs = {
  values?: Maybe<DashBoardInput>
};


export type QueryListarEstabelecimentosArgs = {
  values?: Maybe<ListarEstabelecimentosInput>
};


export type QueryEstabelecimentoDownloadArgs = {
  values?: Maybe<ListarEstabelecimentosInput>,
  tipoDocumento?: Maybe<Scalars['String']>
};


export type QueryCanUploadArgs = {
  tipoDoc?: Maybe<Scalars['String']>,
  values?: Maybe<ListarEstabelecimentosInput>
};


export type QueryBaixarArquivoEstabelecimentoArgs = {
  values?: Maybe<ArquivoDownloadInput>
};


export type QueryGetPrepareCadastroTaxaArgs = {
  values?: Maybe<PrepareCadastroTaxaInput>
};


export type QueryGetPosicaoContratosPorClientesArgs = {
  values?: Maybe<PosicaoContratosPorClientesInput>
};


export type QueryGetInformacoesPosicaoContratoArgs = {
  codPessoa?: Maybe<Scalars['String']>,
  codExterno?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>
};


export type QueryGetAgendamentoRelatorioArgs = {
  values?: Maybe<AgendamentoRelatorioEditInput>
};


export type QueryGetAgendamentoIntegracaoArgs = {
  values?: Maybe<AgendamentoIntegracaoEditInput>
};


export type QueryGetItensUnidadesPlanosConciliadoresArgs = {
  codplanosconciliadores?: Maybe<Scalars['Int']>
};


export type QueryGetItensUnidadesPlanosArgs = {
  codplanos?: Maybe<Scalars['Int']>
};


export type QueryGetItensProdutosByOperadoraArgs = {
  codoperadora?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>
};


export type QueryGetItensProdutosByOperadoraPosArgs = {
  codoperadora?: Maybe<Scalars['Int']>,
  codplano?: Maybe<Scalars['Int']>
};


export type QueryResultadoAddTaxasArgs = {
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  tipotaxa?: Maybe<Scalars['String']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type QueryResultadoAddTaxasPosArgs = {
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type QueryResultadoAddTaxasBndesArgs = {
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type QueryResultadoAtualizaTaxasArgs = {
  codtaxa?: Maybe<Scalars['Int']>
};


export type QueryResultadoSubTaxasArgs = {
  codtaxa?: Maybe<Scalars['Int']>,
  tarifacao?: Maybe<Scalars['String']>
};


export type QueryResultadoSubTaxasPosArgs = {
  codtaxa?: Maybe<Scalars['Int']>
};


export type QueryGetClienteArgs = {
  values?: Maybe<ClienteDaoInput>
};


export type QueryListarFaturamentoMovimentoArgs = {
  values?: Maybe<FaturamentoMovimentoInput>
};


export type QueryGetInfoNotaArgs = {
  values?: Maybe<FaturamentoMovimentoBeanInput>
};


export type QueryPrevisualizarNotaArgs = {
  values?: Maybe<FaturamentoMovimentoBeanInput>
};


export type QueryGetRelatorioFechaMovimentoPrevisaoArgs = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  dias?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type QueryListarMovimentosFechadosArgs = {
  values?: Maybe<FaturamentoMovimentoInput>
};


export type QueryListarBucketS3AmazonArgs = {
  values?: Maybe<BucketS3AmazonDaoInput>
};


export type QueryGetBucketS3AmazonArgs = {
  values?: Maybe<BucketS3AmazonDaoInput>
};


export type QueryListarParametrosTotvsArgs = {
  values?: Maybe<ListarParametrosTotvsInput>
};


export type QueryGetParametroTotvsArgs = {
  values?: Maybe<ParametrosConciliadorTotvsDaoInput>
};


export type QueryGetCodigoOperadoraTotvsArgs = {
  values?: Maybe<OperadorasTotvsDaoInput>
};


export type QueryGetIntegracaoSoftExpressCadastroArgs = {
  values?: Maybe<ConciliadoresIntegSoftExpressDaoInput>
};


export type QueryGetTefsArgs = {
  codpessoa?: Maybe<Scalars['Int']>
};


export type QueryGetDadoNFeArgs = {
  values?: Maybe<DadosNfeDaoInput>
};


export type QueryGetAllDadosNFeArgs = {
  values?: Maybe<DadosNfeDaoInput>
};


export type QueryGetInfoUsuarrioTrocarSenhaArgs = {
  param?: Maybe<Scalars['String']>
};

export type RamoAtividadeDao = {
   __typename?: 'RamoAtividadeDAO',
  codramoatividade?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type RecaptchaInput = {
  recaptcha?: Maybe<Scalars['String']>,
};

export type ReconciliacaoBean = {
   __typename?: 'ReconciliacaoBean',
  datavenda?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  cartao?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  parcela?: Maybe<Scalars['Int']>,
  totparcelas?: Maybe<Scalars['Int']>,
  valorbruto?: Maybe<Scalars['Float']>,
  valorliquidoesperado?: Maybe<Scalars['Float']>,
  valor?: Maybe<Scalars['Float']>,
  valorliquidopago?: Maybe<Scalars['Float']>,
  status?: Maybe<Scalars['String']>,
  produto?: Maybe<Scalars['String']>,
  taxacliente?: Maybe<Scalars['Float']>,
  taxacomissao?: Maybe<Scalars['Float']>,
  outroscreditos?: Maybe<Scalars['Float']>,
  outrosdebitos?: Maybe<Scalars['Float']>,
  bandeira?: Maybe<Scalars['String']>,
  codterminal?: Maybe<Scalars['String']>,
  nometerminal?: Maybe<Scalars['String']>,
  numeroro?: Maybe<Scalars['String']>,
  nomeestabelecimento?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codlinha?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
};

export type ReconciliacaoDao = {
   __typename?: 'ReconciliacaoDAO',
  codreconciliacao?: Maybe<Scalars['Int']>,
  codlinha?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
  codarquivo?: Maybe<Scalars['Int']>,
};

export type RecorrenteCallBackTransactionDao = {
   __typename?: 'RecorrenteCallBackTransactionDAO',
  codCallBackTransaction?: Maybe<Scalars['Int']>,
  tid?: Maybe<Scalars['String']>,
  orderReference?: Maybe<Scalars['String']>,
  lastUpdate?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  transactionCode?: Maybe<Scalars['String']>,
  storeId?: Maybe<Scalars['String']>,
  customersId?: Maybe<Scalars['String']>,
  customersName?: Maybe<Scalars['String']>,
  recurrencesId?: Maybe<Scalars['String']>,
  ticketId?: Maybe<Scalars['String']>,
  ipAddress?: Maybe<Scalars['String']>,
  chargeTotal?: Maybe<Scalars['String']>,
  numberInstallment?: Maybe<Scalars['String']>,
  currencyCode?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  acquirerUrl?: Maybe<Scalars['String']>,
  dataRecebimento?: Maybe<Scalars['Date']>,
};

export type RecorrentePaymentsDao = {
   __typename?: 'RecorrentePaymentsDAO',
  codTransactionResponse?: Maybe<Scalars['Int']>,
  ticket?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  statusMessage?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  dataOperacao?: Maybe<Scalars['Date']>,
  parcela?: Maybe<Scalars['Int']>,
};

export type RecorrenteRespostaConsolidadaDao = {
   __typename?: 'RecorrenteRespostaConsolidadaDAO',
  codRespostaConsolidada?: Maybe<Scalars['Int']>,
  codPlanoAlunoParcela?: Maybe<Scalars['Int']>,
  transactionId?: Maybe<Scalars['String']>,
  operationId?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  log?: Maybe<Scalars['String']>,
  errorCode?: Maybe<Scalars['String']>,
  orderreference?: Maybe<Scalars['String']>,
  ordercurrency?: Maybe<Scalars['String']>,
  ordertotalAmount?: Maybe<Scalars['String']>,
  orderdateTime?: Maybe<Scalars['String']>,
  processorflag?: Maybe<Scalars['String']>,
  processornumberOfPayments?: Maybe<Scalars['String']>,
  processoracquirer?: Maybe<Scalars['String']>,
  processoracquirerId?: Maybe<Scalars['String']>,
  processorurlAuthentication?: Maybe<Scalars['String']>,
  processoramount?: Maybe<Scalars['String']>,
  processorcode?: Maybe<Scalars['String']>,
  processormessage?: Maybe<Scalars['String']>,
  processormessage2?: Maybe<Scalars['String']>,
  processorlr?: Maybe<Scalars['String']>,
  processorarp?: Maybe<Scalars['String']>,
  processornsu?: Maybe<Scalars['String']>,
  processortid?: Maybe<Scalars['String']>,
  processorpan?: Maybe<Scalars['String']>,
  processoreci?: Maybe<Scalars['String']>,
  processorstatusCode?: Maybe<Scalars['String']>,
  processoravsResponseCode?: Maybe<Scalars['String']>,
  processortokenCard?: Maybe<Scalars['String']>,
};

export type RecorrenteTransactionResponseDao = {
   __typename?: 'RecorrenteTransactionResponseDAO',
  codTransactionResponse?: Maybe<Scalars['Int']>,
  transactionId?: Maybe<Scalars['String']>,
  operationId?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  log?: Maybe<Scalars['String']>,
  errorcode?: Maybe<Scalars['String']>,
  orderreference?: Maybe<Scalars['String']>,
  ordercurrency?: Maybe<Scalars['String']>,
  ordertotalAmount?: Maybe<Scalars['String']>,
  orderdateTime?: Maybe<Scalars['String']>,
  processorflag?: Maybe<Scalars['String']>,
  processoramount?: Maybe<Scalars['String']>,
  processordateStart?: Maybe<Scalars['String']>,
  processordateEnd?: Maybe<Scalars['String']>,
  processormessage?: Maybe<Scalars['String']>,
  processorquantityPayments?: Maybe<Scalars['String']>,
  processortokenCard?: Maybe<Scalars['String']>,
  tipoVenda?: Maybe<Scalars['String']>,
  processornumberOfPayments?: Maybe<Scalars['String']>,
  processoracquirer?: Maybe<Scalars['String']>,
  processorurlAuthentication?: Maybe<Scalars['String']>,
  processorcode?: Maybe<Scalars['String']>,
  processorlr?: Maybe<Scalars['String']>,
  processornsu?: Maybe<Scalars['String']>,
  processortid?: Maybe<Scalars['String']>,
  processorarp?: Maybe<Scalars['String']>,
  processorpan?: Maybe<Scalars['String']>,
  processoreci?: Maybe<Scalars['String']>,
  processorstatusCode?: Maybe<Scalars['String']>,
  processoravsResponseCode?: Maybe<Scalars['String']>,
  codPlanoAluno?: Maybe<Scalars['Int']>,
};

export type RecursoDao = {
   __typename?: 'RecursoDAO',
  sicodigo?: Maybe<Scalars['String']>,
  rccodigo?: Maybe<Scalars['String']>,
  agrupamento?: Maybe<Scalars['String']>,
  recurso?: Maybe<Scalars['String']>,
  nometela?: Maybe<Scalars['String']>,
};

export type Relatorio = {
   __typename?: 'Relatorio',
  label?: Maybe<Scalars['String']>,
  page?: Maybe<Scalars['String']>,
  pais?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type RelatorioCadastroPlanoTaxaBean = {
   __typename?: 'RelatorioCadastroPlanoTaxaBean',
  nomePlano?: Maybe<Scalars['String']>,
  nomeProduto?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  dataVigenciaInicial?: Maybe<Scalars['String']>,
  dataVigenciaFinal?: Maybe<Scalars['String']>,
  taxaAvista?: Maybe<Scalars['BigDecimal']>,
  parcelasDePrimeiraColuna?: Maybe<Scalars['Int']>,
  parcelasAtePrimeiraColuna?: Maybe<Scalars['Int']>,
  taxaPrimeiraColuna?: Maybe<Scalars['BigDecimal']>,
  parcelasDeSegundaColuna?: Maybe<Scalars['Int']>,
  parcelasAteSegundaColuna?: Maybe<Scalars['Int']>,
  taxaSegundaColuna?: Maybe<Scalars['BigDecimal']>,
};

export type ReprocessamentoAgendadoDao = {
   __typename?: 'ReprocessamentoAgendadoDAO',
  codreprocessamento?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  datareprocessamento?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  rotina?: Maybe<Scalars['String']>,
};

export type ReprocessamentoDao = {
   __typename?: 'ReprocessamentoDAO',
  codreprocessamento?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
  datareprocessamento?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  rotina?: Maybe<Scalars['String']>,
};

export type ReprocessarManutencaoArquivosInput = {
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
};

export type ReprocessarTicketInput = {
  codarquivo?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
};

export type ResponsavelAlunoDao = {
   __typename?: 'ResponsavelAlunoDAO',
  codPessoa?: Maybe<Scalars['Int']>,
  codPessoaPai?: Maybe<Scalars['Int']>,
  dataInclusao?: Maybe<Scalars['Date']>,
};

export type ResultadoStatus = {
   __typename?: 'ResultadoStatus',
  codExterno?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['String']>,
  passou?: Maybe<Scalars['String']>,
  observacao?: Maybe<Scalars['String']>,
  revenda?: Maybe<Scalars['String']>,
  subrevenda?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
};

export type RevendaCrud = {
   __typename?: 'RevendaCRUD',
  pessoa?: Maybe<PessoaGenerica>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['Boolean']>,
  listaConciliados?: Maybe<Array<Maybe<Scalars['String']>>>,
  tipo?: Maybe<Scalars['String']>,
  observacao?: Maybe<Scalars['String']>,
  caminhoLogomarca?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  linkCriaSenha?: Maybe<Scalars['String']>,
  linkSistema?: Maybe<Scalars['String']>,
  informacaoAdicional?: Maybe<Scalars['String']>,
  urlManual?: Maybe<Scalars['String']>,
  notificaExpiracaoUsuario?: Maybe<Scalars['String']>,
  geramensalidade?: Maybe<Scalars['String']>,
  gerasomentecontrato?: Maybe<Scalars['String']>,
  qtddiasviracontrato?: Maybe<Scalars['Int']>,
};

export type RevendaDao = {
   __typename?: 'RevendaDAO',
  codPessoaRevenda?: Maybe<Scalars['Int']>,
  codRevendaPai?: Maybe<Scalars['Int']>,
  dataCadastro?: Maybe<Scalars['Date']>,
  usuCadastro?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  observacao?: Maybe<Scalars['String']>,
  codrevendaderevenda?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  linkCriaSenha?: Maybe<Scalars['String']>,
  linkSistema?: Maybe<Scalars['String']>,
  informacaoAdicional?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  urlManual?: Maybe<Scalars['String']>,
  notificaExpiracaoUsuario?: Maybe<Scalars['String']>,
  geramensalidade?: Maybe<Scalars['String']>,
  gerasomentecontrato?: Maybe<Scalars['String']>,
};

export type RevendaDeRevendaCrud = {
   __typename?: 'RevendaDeRevendaCRUD',
  pessoa?: Maybe<PessoaGenerica>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['Boolean']>,
  listaConciliados?: Maybe<Array<Maybe<Scalars['String']>>>,
  observacao?: Maybe<Scalars['String']>,
};

export type SavePainelCadastroTaxaInput = {
  codOperadora?: Maybe<Scalars['Int']>,
  taxaCredito?: Maybe<Scalars['Float']>,
  taxaCredito0a6?: Maybe<Scalars['Float']>,
  taxaCredito7a12?: Maybe<Scalars['Float']>,
  taxaDebito?: Maybe<Scalars['Float']>,
  taxaTicket?: Maybe<Scalars['Float']>,
};

export type SetorDao = {
   __typename?: 'SetorDAO',
  codSetor?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type SetorFuncionarioBean = {
   __typename?: 'SetorFuncionarioBean',
  codSetor?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  gravado?: Maybe<Scalars['String']>,
};

export type SiglaDao = {
   __typename?: 'SiglaDAO',
  codsigla?: Maybe<Scalars['Int']>,
  sigla?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
};

export type SolicitacaoRedeCardBean = {
   __typename?: 'SolicitacaoRedeCardBean',
  razaoSocial?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  ec?: Maybe<Scalars['String']>,
  sigla?: Maybe<Scalars['String']>,
};

export type SolicitaImplantacaoConciliadores = {
   __typename?: 'SolicitaImplantacaoConciliadores',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
};

export type SolicitaLiberacaoConciliadores = {
   __typename?: 'SolicitaLiberacaoConciliadores',
  mensagem?: Maybe<Scalars['String']>,
  mensagemErro?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  listaEmails?: Maybe<Array<Maybe<EmailPilotoRevendaDao>>>,
  listaConciliadoresSelecionados?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  listaValoresExistentes?: Maybe<Array<Maybe<ValorContratoConciliadorDao>>>,
  listaValoresSelecionados?: Maybe<Array<Maybe<ValorContratoConciliadorDao>>>,
  valoresContrato?: Maybe<Array<Maybe<Scalars['String']>>>,
  valorContrato?: Maybe<Scalars['BigDecimal']>,
  valorExcedido?: Maybe<Scalars['BigDecimal']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codigoPlano?: Maybe<Scalars['Int']>,
};

export type Status = {
   __typename?: 'Status',
  descricao?: Maybe<Scalars['String']>,
};

export type StatusConciliadoresBean = {
   __typename?: 'StatusConciliadoresBean',
  nome?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  ramoatividade?: Maybe<Scalars['String']>,
};

export type StatusEstabelecimentoBean = {
   __typename?: 'StatusEstabelecimentoBean',
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  tipo?: Maybe<Scalars['String']>,
};

export type StatusPagamentoDao = {
   __typename?: 'StatusPagamentoDAO',
  codstatuspagamento?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type TaxaAntecipacaoDao = {
   __typename?: 'TaxaAntecipacaoDAO',
  codtaxaantecipacao?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type TaxaAntecipacaoDaoInput = {
  codtaxaantecipacao?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
};

export type TaxaAnteConcOpeDao = {
   __typename?: 'TaxaAnteConcOpeDAO',
  codtaxaantecipacao?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type TaxasAntecipacaoCrud = {
   __typename?: 'TaxasAntecipacaoCRUD',
  listConciOperadora?: Maybe<Array<Maybe<Scalars['String']>>>,
  codoperadora?: Maybe<Scalars['Int']>,
  editValues?: Maybe<TaxaAntecipacaoDao>,
  searchValues?: Maybe<TaxaAntecipacaoDao>,
};

export type TaxasAntecipacaoCrudInput = {
  listConciOperadora?: Maybe<Array<Maybe<Scalars['String']>>>,
  codoperadora?: Maybe<Scalars['Int']>,
  editValues?: Maybe<TaxaAntecipacaoDaoInput>,
  searchValues?: Maybe<TaxaAntecipacaoDaoInput>,
};

export type TaxasBean = {
   __typename?: 'TaxasBean',
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codproduto?: Maybe<Scalars['Int']>,
  empresa?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  produtos?: Maybe<Scalars['String']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
};

export type TaxasBeanInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codproduto?: Maybe<Scalars['Int']>,
  empresa?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  produtos?: Maybe<Scalars['String']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
};

export type TaxasCrud = {
   __typename?: 'TaxasCRUD',
  taxas?: Maybe<TaxasDao>,
  mensagem?: Maybe<Scalars['String']>,
  listTaxas?: Maybe<Array<Maybe<TaxasBean>>>,
};

export type TaxasDao = {
   __typename?: 'TaxasDAO',
  codtaxas?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codproduto?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  obs?: Maybe<Scalars['String']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
};

export type TaxasDaoInput = {
  codtaxas?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codproduto?: Maybe<Scalars['Int']>,
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  obs?: Maybe<Scalars['String']>,
  vigenciade?: Maybe<Scalars['Date']>,
  vigenciaate?: Maybe<Scalars['Date']>,
};

export type TaxasNaoCadastradas = {
   __typename?: 'TaxasNaoCadastradas',
  taxas?: Maybe<Array<Maybe<TaxasNaoCadastradasBean>>>,
};

export type TaxasNaoCadastradasBean = {
   __typename?: 'TaxasNaoCadastradasBean',
  produto?: Maybe<Scalars['String']>,
  nomepessoa?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  vendade?: Maybe<Scalars['Date']>,
  vendaate?: Maybe<Scalars['Date']>,
  parcelasde?: Maybe<Scalars['Int']>,
  parcelasate?: Maybe<Scalars['Int']>,
};

export type TaxasNaoCadastradasDao = {
   __typename?: 'TaxasNaoCadastradasDAO',
  codtaxasnaocadastradas?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codproduto?: Maybe<Scalars['Int']>,
  parcelas?: Maybe<Scalars['Int']>,
  datavenda?: Maybe<Scalars['Date']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
};

export type TefsBandeirasDao = {
   __typename?: 'TefsBandeirasDAO',
  codbandeiras?: Maybe<Scalars['Int']>,
  codtefs?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
};

export type TefsCrud = {
   __typename?: 'TefsCRUD',
  tefsBandeiras?: Maybe<TefsBandeirasDao>,
  tefsProdutos?: Maybe<TefsProdutosDao>,
  mensagem?: Maybe<Scalars['String']>,
  expandirBandeira?: Maybe<Scalars['String']>,
  expandirProduto?: Maybe<Scalars['String']>,
};

export type TefsDao = {
   __typename?: 'TefsDAO',
  codtefs?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
};

export type TefsProdutosDao = {
   __typename?: 'TefsProdutosDAO',
  codtefs?: Maybe<Scalars['String']>,
  codprodutos?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  parcelade?: Maybe<Scalars['Int']>,
  parcelaate?: Maybe<Scalars['Int']>,
};

export type TerminalBean = {
   __typename?: 'TerminalBean',
  nome?: Maybe<Scalars['String']>,
};

export type TerminalCrud = {
   __typename?: 'TerminalCRUD',
  ativo?: Maybe<Scalars['Boolean']>,
  datainiciocobranca?: Maybe<Scalars['Date']>,
  concioperadora?: Maybe<Scalars['String']>,
  editValues?: Maybe<TerminalDao>,
  searchValues?: Maybe<TerminalDao>,
};

export type TerminalCrudInput = {
  ativo?: Maybe<Scalars['Boolean']>,
  datainiciocobranca?: Maybe<Scalars['Date']>,
  concioperadora?: Maybe<Scalars['String']>,
  editValues?: Maybe<TerminalDaoInput>,
  searchValues?: Maybe<TerminalDaoInput>,
};

export type TerminalDao = {
   __typename?: 'TerminalDAO',
  codexterno?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  vinculo?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexternoconciliador?: Maybe<Scalars['String']>,
  codpessoacliente?: Maybe<Scalars['Int']>,
};

export type TerminalDaoInput = {
  codexterno?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  vinculo?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexternoconciliador?: Maybe<Scalars['String']>,
  codpessoacliente?: Maybe<Scalars['Int']>,
};

export type TerminalLogStatusDao = {
   __typename?: 'TerminalLogStatusDAO',
  codterminallogstatus?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  data?: Maybe<Scalars['Date']>,
  status?: Maybe<Scalars['String']>,
  datainclusao?: Maybe<Scalars['Date']>,
};

export type TicketsBean = {
   __typename?: 'TicketsBean',
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  valor?: Maybe<Scalars['Float']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  codlinhasarqcli?: Maybe<Scalars['Int']>,
  origem?: Maybe<Scalars['String']>,
  dataprocessamento?: Maybe<Scalars['Date']>,
};

export type TicketsBeanInput = {
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  terminal?: Maybe<Scalars['String']>,
  nossonumero?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  datavendaate?: Maybe<Scalars['Date']>,
  tipo?: Maybe<Scalars['String']>,
};

export type TicketsDetalheInput = {
  tid?: Maybe<Scalars['String']>,
  nsu?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipo?: Maybe<Scalars['String']>,
  nossonumero?: Maybe<Scalars['String']>,
};

export type TicketsImportacaoBean = {
   __typename?: 'TicketsImportacaoBean',
  valor?: Maybe<Scalars['Float']>,
  datavenda?: Maybe<Scalars['Date']>,
  totparcelas?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codarquivo?: Maybe<Scalars['Int']>,
};

export type TicketsImportacaoBeanInput = {
  datavenda?: Maybe<Scalars['Date']>,
  totparcelas?: Maybe<Scalars['Int']>,
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  numterminal?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type TicketsInclusaoBean = {
   __typename?: 'TicketsInclusaoBean',
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  datapagamento?: Maybe<Scalars['Date']>,
  valor?: Maybe<Scalars['Float']>,
  codexterno?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codBandeira?: Maybe<Scalars['Int']>,
  quantidadeParcelas?: Maybe<Scalars['Int']>,
  parcela?: Maybe<Scalars['Int']>,
  codigo?: Maybe<Scalars['Int']>,
  boleto?: Maybe<Scalars['String']>,
};

export type TicketsInclusaoBeanInput = {
  nsu?: Maybe<Scalars['String']>,
  tid?: Maybe<Scalars['String']>,
  autorizacao?: Maybe<Scalars['String']>,
  datavenda?: Maybe<Scalars['Date']>,
  valor?: Maybe<Scalars['Float']>,
  codBandeira?: Maybe<Scalars['Int']>,
  quantidadeParcelas?: Maybe<Scalars['Int']>,
};

export type TipoTransacaoDao = {
   __typename?: 'TipoTransacaoDAO',
  codtipotransacao?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type TotalizadorConciliadorAntecipacaoBean = {
   __typename?: 'TotalizadorConciliadorAntecipacaoBean',
  lancamentoOperadora?: Maybe<LinhaAntecipadoTotalizadorConciliadorBean>,
  lancamentoArquivo?: Maybe<LinhaAntecipadoTotalizadorConciliadorBean>,
  lancamentoCliente?: Maybe<LinhaAntecipadoTotalizadorConciliadorBean>,
  codarquivo?: Maybe<Scalars['Int']>,
  nomeArquivo?: Maybe<Scalars['String']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  datapagamentoate?: Maybe<Scalars['Date']>,
};

export type TotalizadorConciliadorBean = {
   __typename?: 'TotalizadorConciliadorBean',
  lancamentoOperadora?: Maybe<LinhaTotalizadorConciliadorBean>,
  lancamentoArquivo?: Maybe<LinhaTotalizadorConciliadorBean>,
  lancamentoCliente?: Maybe<LinhaTotalizadorConciliadorBean>,
  codarquivo?: Maybe<Scalars['Int']>,
  nomeArquivo?: Maybe<Scalars['String']>,
  datapagamentode?: Maybe<Scalars['Date']>,
  datapagamentoate?: Maybe<Scalars['Date']>,
  datainicioprocessamento?: Maybe<Scalars['Date']>,
};

export type UnidadesAConciliarCrud = {
   __typename?: 'UnidadesAConciliarCRUD',
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadesSelecionadas?: Maybe<Array<Maybe<UnidadesAConciliarDao>>>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  unidadesExistentes?: Maybe<Array<Maybe<UnidadesAConciliarDao>>>,
  exibeUnidades?: Maybe<Scalars['Boolean']>,
};

export type UnidadesAConciliarDao = {
   __typename?: 'UnidadesAConciliarDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type UnidadesAConciliarTerminalCrud = {
   __typename?: 'UnidadesAConciliarTerminalCRUD',
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadesSelecionadas?: Maybe<Array<Maybe<UnidadesAConciliarTerminalDao>>>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  unidadesExistentes?: Maybe<Array<Maybe<UnidadesAConciliarTerminalDao>>>,
  exibeUnidades?: Maybe<Scalars['Boolean']>,
};

export type UnidadesAConciliarTerminalDao = {
   __typename?: 'UnidadesAConciliarTerminalDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type UnidadesConciliadorasBean = {
   __typename?: 'UnidadesConciliadorasBean',
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  pessoanome?: Maybe<Scalars['String']>,
  operadoranome?: Maybe<Scalars['String']>,
  conciliadornome?: Maybe<Scalars['String']>,
};

export type UnidadesNaoEncontradasBeanExcel = {
   __typename?: 'UnidadesNaoEncontradasBeanExcel',
  cnpj?: Maybe<Scalars['String']>,
  nomeLoja?: Maybe<Scalars['String']>,
  cielo?: Maybe<Scalars['String']>,
  redecard?: Maybe<Scalars['String']>,
  banrisul?: Maybe<Scalars['String']>,
  dacasa?: Maybe<Scalars['String']>,
  getnet?: Maybe<Scalars['String']>,
  global?: Maybe<Scalars['String']>,
  simulador?: Maybe<Scalars['String']>,
  stone?: Maybe<Scalars['String']>,
  comprocard?: Maybe<Scalars['String']>,
  banescard?: Maybe<Scalars['String']>,
  banescardRoteada?: Maybe<Scalars['String']>,
  goodCard?: Maybe<Scalars['String']>,
  goodCardRoteada?: Maybe<Scalars['String']>,
  policard?: Maybe<Scalars['String']>,
  policardRoteada?: Maybe<Scalars['String']>,
  amex?: Maybe<Scalars['String']>,
  avista?: Maybe<Scalars['String']>,
  sodexo?: Maybe<Scalars['String']>,
  ticket?: Maybe<Scalars['String']>,
};

export type UnidadesNaoEncontradasDao = {
   __typename?: 'UnidadesNaoEncontradasDAO',
  codUnidadesNaoEncontadas?: Maybe<Scalars['Int']>,
  estabelecimento?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  operadoraRoteada?: Maybe<Scalars['String']>,
  identificador?: Maybe<Scalars['String']>,
  tipoArquivo?: Maybe<Scalars['String']>,
  codpessoa?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  datainclusao?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
};

export type UnidadesNaoEncontradasExibicao = {
   __typename?: 'UnidadesNaoEncontradasExibicao',
  urlPdf?: Maybe<Scalars['String']>,
  listaUnidadesNaoEncontradas?: Maybe<Array<Maybe<UnidadesNaoEncontradasDao>>>,
};

export type UnidadesNaoImportarPoscrud = {
   __typename?: 'UnidadesNaoImportarPOSCRUD',
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadesSelecionadas?: Maybe<Array<Maybe<UnidadesNaoImportarPosdao>>>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  unidadesExistentes?: Maybe<Array<Maybe<UnidadesNaoImportarPosdao>>>,
  exibeUnidades?: Maybe<Scalars['Boolean']>,
};

export type UnidadesNaoImportarPosdao = {
   __typename?: 'UnidadesNaoImportarPOSDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type UnidadesSemArquivoClienteCrud = {
   __typename?: 'UnidadesSemArquivoClienteCRUD',
  unidades?: Maybe<Array<Maybe<Scalars['String']>>>,
  unidadesSelecionadas?: Maybe<Array<Maybe<UnidadesSemArquivoClienteDao>>>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>,
  unidadesExistentes?: Maybe<Array<Maybe<UnidadesSemArquivoClienteDao>>>,
  exibeUnidades?: Maybe<Scalars['Boolean']>,
};

export type UnidadesSemArquivoClienteDao = {
   __typename?: 'UnidadesSemArquivoClienteDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type UploadSelecionadosInput = {
  a701?: Maybe<LabelValorInput>,
  a702?: Maybe<LabelValorInput>,
};

export type UsuarioConciliadorDao = {
   __typename?: 'UsuarioConciliadorDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codpessoaconciliador?: Maybe<Scalars['Int']>,
};

export type UsuarioCrud = {
   __typename?: 'UsuarioCRUD',
  pessoa?: Maybe<PessoaGenerica>,
  tagcpf?: Maybe<Scalars['String']>,
  tagcnpj?: Maybe<Scalars['String']>,
  principal?: Maybe<Scalars['Boolean']>,
  mensagem?: Maybe<Scalars['String']>,
  login?: Maybe<Scalars['String']>,
  conciliadoresSelecionados?: Maybe<Array<Maybe<UsuarioConciliadorDao>>>,
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  exibeConciliadores?: Maybe<Scalars['String']>,
  editValues?: Maybe<UsuariosDao>,
  searchValues?: Maybe<UsuariosDao>,
};

export type UsuarioCrudInput = {
  pessoa?: Maybe<PessoaGenericaInput>,
  codpessoa?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  editValues?: Maybe<UsuarioDaoInput>,
  searchValues?: Maybe<UsuarioDaoInput>,
};

export type UsuarioDao = {
   __typename?: 'UsuarioDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  codperfil?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoarealizacadastro?: Maybe<Scalars['Int']>,
  flgIntegracaoatend?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
  excecaoconciliadores?: Maybe<Scalars['String']>,
  codrevenda?: Maybe<Scalars['Int']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  fluxoCadastro?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  senhaDefinida?: Maybe<Scalars['String']>,
  clienteantigo?: Maybe<Scalars['String']>,
  idtrocasenha?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  diaVencimento?: Maybe<Scalars['Int']>,
  validade?: Maybe<Scalars['Date']>,
};

export type UsuarioDaoInput = {
  codpessoa?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  codperfil?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoarealizacadastro?: Maybe<Scalars['Int']>,
  flgIntegracaoatend?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
  excecaoconciliadores?: Maybe<Scalars['String']>,
  codrevenda?: Maybe<Scalars['Int']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  fluxoCadastro?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  senhaDefinida?: Maybe<Scalars['String']>,
  clienteantigo?: Maybe<Scalars['String']>,
  idtrocasenha?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  diaVencimento?: Maybe<Scalars['Int']>,
  validade?: Maybe<Scalars['Date']>,
};

export type UsuarioDaoList = {
   __typename?: 'UsuarioDAOList',
  codpessoa?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  codperfil?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoarealizacadastro?: Maybe<Scalars['Int']>,
  flgIntegracaoatend?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
  excecaoconciliadores?: Maybe<Scalars['String']>,
  codrevenda?: Maybe<Scalars['Int']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  fluxoCadastro?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  senhaDefinida?: Maybe<Scalars['String']>,
  clienteantigo?: Maybe<Scalars['String']>,
  idtrocasenha?: Maybe<Scalars['String']>,
  obs?: Maybe<Scalars['String']>,
  diaVencimento?: Maybe<Scalars['Int']>,
  validade?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
};

export type UsuarioMestreTefsBean = {
   __typename?: 'UsuarioMestreTefsBean',
  codPessoaMestre?: Maybe<Scalars['Int']>,
  codTef?: Maybe<Scalars['String']>,
  nomeTef?: Maybe<Scalars['String']>,
};

export type UsuarioMestreTefsBeanInput = {
  codPessoaMestre?: Maybe<Scalars['Int']>,
  codTef?: Maybe<Scalars['String']>,
  nomeTef?: Maybe<Scalars['String']>,
};

export type UsuarioMestreTefsDao = {
   __typename?: 'UsuarioMestreTefsDAO',
  codPessoa?: Maybe<Scalars['Int']>,
  codTefs?: Maybe<Scalars['String']>,
};

export type UsuarioNovaSenha = {
   __typename?: 'UsuarioNovaSenha',
  login?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  idTrocaSenha?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  mensagemLink?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
};

export type UsuarioPrincipalCrud = {
   __typename?: 'UsuarioPrincipalCRUD',
  pessoa?: Maybe<PessoaGenerica>,
  piloto?: Maybe<Scalars['Boolean']>,
  excecaoconciliadores?: Maybe<Scalars['Boolean']>,
};

export type UsuariosDao = {
   __typename?: 'UsuariosDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  login?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['Date']>,
  codperfil?: Maybe<Scalars['Int']>,
  codpessoapai?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  codpessoarealizacadastro?: Maybe<Scalars['Int']>,
  flgIntegracaoatend?: Maybe<Scalars['String']>,
  piloto?: Maybe<Scalars['String']>,
  excecaoconciliadores?: Maybe<Scalars['String']>,
  codrevenda?: Maybe<Scalars['Int']>,
  vencimentoalterado?: Maybe<Scalars['Date']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  fluxoCadastro?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  senhaDefinida?: Maybe<Scalars['String']>,
  datainclusaosenha?: Maybe<Scalars['Date']>,
  clienteantigo?: Maybe<Scalars['String']>,
  idtrocasenha?: Maybe<Scalars['String']>,
  vencimentoidtrocasenha?: Maybe<Scalars['Date']>,
  obs?: Maybe<Scalars['String']>,
  diaVencimento?: Maybe<Scalars['Int']>,
};

export type UsuariosExpirados = {
   __typename?: 'UsuariosExpirados',
  listaUsuarios?: Maybe<Array<Maybe<UsuariosDao>>>,
};

export type UsuarioTrocaSenha = {
   __typename?: 'UsuarioTrocaSenha',
  expirado?: Maybe<Scalars['Boolean']>,
  senhaDefinida?: Maybe<Scalars['Boolean']>,
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>,
  idTrocaSenha?: Maybe<Scalars['String']>,
  mensagem?: Maybe<Scalars['String']>,
  mensagemSolicitaSenha?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>,
  goLogin?: Maybe<Scalars['Boolean']>,
};

export type ValidacaoTaxas = {
   __typename?: 'ValidacaoTaxas',
  mensagem?: Maybe<Scalars['String']>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosConciliadoresTaxasDao>>>,
  taxas?: Maybe<PlanosConciliadoresTaxasDao>,
};

export type ValidacaoTaxasPos = {
   __typename?: 'ValidacaoTaxasPos',
  mensagem?: Maybe<Scalars['String']>,
  listDeTaxas?: Maybe<Array<Maybe<PlanosTaxasDao>>>,
  taxas?: Maybe<PlanosTaxasDao>,
};

export type ValorContratoAdicionalOperadoraDao = {
   __typename?: 'ValorContratoAdicionalOperadoraDAO',
  codvalorcontratoadicionaloperadora?: Maybe<Scalars['Int']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  codoperadora?: Maybe<Scalars['Int']>,
  adesaounica?: Maybe<Scalars['String']>,
  valoradesao?: Maybe<Scalars['BigDecimal']>,
  valoradicional?: Maybe<Scalars['BigDecimal']>,
  valoradesaoConcilia?: Maybe<Scalars['BigDecimal']>,
  valoradicionalConcilia?: Maybe<Scalars['BigDecimal']>,
};

export type ValorContratoConciliadorCrud = {
   __typename?: 'ValorContratoConciliadorCRUD',
  listValorAdicional?: Maybe<Array<Maybe<ValorContratoAdicionalOperadoraDao>>>,
  valorAdicional?: Maybe<ValorContratoAdicionalOperadoraDao>,
};

export type ValorContratoConciliadorDao = {
   __typename?: 'ValorContratoConciliadorDAO',
  codValorContratoConciliador?: Maybe<Scalars['Int']>,
  valor?: Maybe<Scalars['BigDecimal']>,
  quantidade?: Maybe<Scalars['Int']>,
  valorExcedido?: Maybe<Scalars['BigDecimal']>,
  valorAdesao?: Maybe<Scalars['BigDecimal']>,
  adesaoSemArquivo?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataExpiracao?: Maybe<Scalars['Date']>,
  limitatransacoes?: Maybe<Scalars['String']>,
  qtdmaximatransacoes?: Maybe<Scalars['Int']>,
  valortransacaoexcedida?: Maybe<Scalars['BigDecimal']>,
  limitecnpj?: Maybe<Scalars['Int']>,
  valoradicionalcnpj?: Maybe<Scalars['BigDecimal']>,
  valoradesaoadicionalcnpj?: Maybe<Scalars['BigDecimal']>,
  valoradesaoadicionalcnpjConcilia?: Maybe<Scalars['BigDecimal']>,
  valorConcilia?: Maybe<Scalars['BigDecimal']>,
  valorExcedidoConcilia?: Maybe<Scalars['BigDecimal']>,
  valorAdesaoConcilia?: Maybe<Scalars['BigDecimal']>,
  valortransacaoexcedidaConcilia?: Maybe<Scalars['BigDecimal']>,
  valoradicionalcnpjConcilia?: Maybe<Scalars['BigDecimal']>,
  diacorte?: Maybe<Scalars['Int']>,
  qtdtransacoesadicionalcnpj?: Maybe<Scalars['Int']>,
  qtdtransacoesadicionaloperadora?: Maybe<Scalars['Int']>,
  fracionada?: Maybe<Scalars['String']>,
};

export type ValorContratoConciliadorDescontoDao = {
   __typename?: 'ValorContratoConciliadorDescontoDAO',
  codpessoapai?: Maybe<Scalars['Int']>,
  mensalidade?: Maybe<Scalars['Float']>,
  adesao?: Maybe<Scalars['Float']>,
  mensalidadeconcilia?: Maybe<Scalars['Float']>,
  adesaoconcilia?: Maybe<Scalars['Float']>,
};

export type ValorContratoConciliadorExcecaoCrud = {
   __typename?: 'ValorContratoConciliadorExcecaoCRUD',
  dataexpiracaode?: Maybe<Scalars['Date']>,
  dataexpiracaoate?: Maybe<Scalars['Date']>,
};

export type ValorContratoConciliadorExcecaoDao = {
   __typename?: 'ValorContratoConciliadorExcecaoDAO',
  codValorContratoConciliador?: Maybe<Scalars['Int']>,
  valor?: Maybe<Scalars['BigDecimal']>,
  quantidade?: Maybe<Scalars['Int']>,
  valorExcedido?: Maybe<Scalars['BigDecimal']>,
  dataInclusao?: Maybe<Scalars['Date']>,
  dataExpiracao?: Maybe<Scalars['Date']>,
  codpessoarevendapai?: Maybe<Scalars['Int']>,
};

export type ValorContratoConciliadorOperadoraDescontoDao = {
   __typename?: 'ValorContratoConciliadorOperadoraDescontoDAO',
  codpessoapai?: Maybe<Scalars['Int']>,
  mensalidade?: Maybe<Scalars['Float']>,
  adesao?: Maybe<Scalars['Float']>,
  mensalidadeconcilia?: Maybe<Scalars['Float']>,
  adesaoconcilia?: Maybe<Scalars['Float']>,
  codoperadora?: Maybe<Scalars['Int']>,
};

export type ValorContratoRevendaCrud = {
   __typename?: 'ValorContratoRevendaCRUD',
  valores?: Maybe<Array<Maybe<ValorContratoConciliadorDao>>>,
};

export type ValorContratoRevendaDao = {
   __typename?: 'ValorContratoRevendaDAO',
  codvalorcontratorevenda?: Maybe<Scalars['Int']>,
  codvalorcontratoconciliador?: Maybe<Scalars['Int']>,
  codpessoarevenda?: Maybe<Scalars['Int']>,
};

export type ValoresCorretosAntecipadosBean = {
   __typename?: 'ValoresCorretosAntecipadosBean',
  antecipado?: Maybe<LinhasConciliacaoAntecipadosBean>,
  codlinhasconciliadas?: Maybe<Scalars['Int']>,
};

export type ValoresCorretosBean = {
   __typename?: 'ValoresCorretosBean',
  cliente?: Maybe<LinhasConciliacaoBean>,
  operadora?: Maybe<LinhasConciliacaoBean>,
  codlinhasconciliadas?: Maybe<Scalars['Int']>,
};

export type VendaOperadora = {
   __typename?: 'VendaOperadora',
  name?: Maybe<Scalars['String']>,
  y?: Maybe<Scalars['Float']>,
  sliced?: Maybe<Scalars['Boolean']>,
  selected?: Maybe<Scalars['Boolean']>,
  data?: Maybe<Array<Maybe<Scalars['Float']>>>,
};

export type VerificaArquivoParamDao = {
   __typename?: 'VerificaArquivoParamDAO',
  codpessoa?: Maybe<Scalars['Int']>,
  codexterno?: Maybe<Scalars['String']>,
  codoperadora?: Maybe<Scalars['Int']>,
  tipoarquivo?: Maybe<Scalars['String']>,
};

export type VincularConciliadorRevendaCrud = {
   __typename?: 'VincularConciliadorRevendaCRUD',
  conciliadoresSemPai?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  conciliadoresSemPaiSelecionados?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  usuarioMestreSelecionado?: Maybe<UsuariosDao>,
  mensagem?: Maybe<Scalars['String']>,
  codPessoaUsuarioMestreSelecionado?: Maybe<Scalars['Int']>,
};

export type VinculaUsuarioMestreConciliadorCrud = {
   __typename?: 'VinculaUsuarioMestreConciliadorCRUD',
  conciliadoresSemPai?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  conciliadoresSemPaiSelecionados?: Maybe<Array<Maybe<ConciliadorGenerica>>>,
  usuarioMestreSelecionado?: Maybe<UsuariosDao>,
  mensagem?: Maybe<Scalars['String']>,
  codPessoaUsuarioMestreSelecionado?: Maybe<Scalars['Int']>,
};

export type WebServicePayGoDao = {
   __typename?: 'WebServicePayGoDAO',
  codwebservice?: Maybe<Scalars['Int']>,
  codpessoa?: Maybe<Scalars['Int']>,
  datainicio?: Maybe<Scalars['Date']>,
  ultimocodigo?: Maybe<Scalars['Long']>,
  usuario?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  ultimamensagem?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  ultimaexecucao?: Maybe<Scalars['Date']>,
};

export type GetErrorsQueryVariables = {};


export type GetErrorsQuery = (
  { __typename?: 'Query' }
  & { getErrors: Maybe<(
    { __typename?: 'Errors' }
    & Pick<Errors, 'mensagemErro' | 'openSnackMessage'>
  )> }
);

export type GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables = {};


export type GetFormularioPlanosOpcoesPlanosConciliadoresQuery = (
  { __typename?: 'Query' }
  & { getFormularioPlanosOpcoesPlanosConciliadores: Maybe<(
    { __typename?: 'Formulario' }
    & Pick<Formulario, 'nomeFormulario'>
  )> }
);

export type GetIntegracaoQueryVariables = {};


export type GetIntegracaoQuery = (
  { __typename?: 'Query' }
  & { getIntegracao: Maybe<Array<Maybe<(
    { __typename?: 'Relatorio' }
    & Pick<Relatorio, 'label' | 'pais' | 'page'>
  )>>> }
);

export type GetRelatoriosQueryVariables = {};


export type GetRelatoriosQuery = (
  { __typename?: 'Query' }
  & { getRelatorios: Maybe<Array<Maybe<(
    { __typename?: 'Relatorio' }
    & Pick<Relatorio, 'label' | 'pais' | 'page'>
  )>>> }
);

export type AlterarSenhaMutationVariables = {
  usuario?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>
};


export type AlterarSenhaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'alterarSenha'>
);

export type AprovarUrlMutationVariables = {
  values?: Maybe<AprovarUrlInput>
};


export type AprovarUrlMutation = (
  { __typename?: 'Mutation' }
  & { aprovarUrl: Maybe<(
    { __typename?: 'CanUploadResult' }
    & Pick<CanUploadResult, 'canUpload' | 'exibirUrl' | 'msg'>
    & { documentos: Maybe<Array<Maybe<(
      { __typename?: 'DocumentosBean' }
      & Pick<DocumentosBean, 'nome' | 'status' | 'obs' | 'coddocumentos'>
    )>>> }
  )> }
);

export type CorrigirMutationVariables = {
  values?: Maybe<IntegracaoConciliaInput>
};


export type CorrigirMutation = (
  { __typename?: 'Mutation' }
  & { corrigir: Maybe<(
    { __typename?: 'IntegracaoCorrigirRetorno' }
    & { s701: Maybe<Array<Maybe<(
      { __typename?: 'LabelValor' }
      & Pick<LabelValor, 'label' | 'valor'>
    )>>>, s702: Maybe<Array<Maybe<(
      { __typename?: 'LabelValor' }
      & Pick<LabelValor, 'label' | 'valor'>
    )>>>, updatesSelecionados: Maybe<Array<Maybe<(
      { __typename?: 'ConciliaSelecionados' }
      & Pick<ConciliaSelecionados, 's701' | 's702' | 'codigo'>
      & { a701: Maybe<(
        { __typename?: 'LabelValor' }
        & Pick<LabelValor, 'label' | 'valor'>
      )>, a702: Maybe<(
        { __typename?: 'LabelValor' }
        & Pick<LabelValor, 'label' | 'valor'>
      )> }
    )>>> }
  )> }
);

export type DeletarBucketS3AmazonCrudMutationVariables = {
  values?: Maybe<BucketS3AmazonCrudInput>
};


export type DeletarBucketS3AmazonCrudMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarBucketS3AmazonCRUD'>
);

export type DeletarClientesMutationVariables = {
  values?: Maybe<ClienteCrudInput>
};


export type DeletarClientesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarClientes'>
);

export type DeletarIntegracaoConciliaAjusteMutationVariables = {
  values?: Maybe<DeletarIntegracaoConciliaAjusteInput>
};


export type DeletarIntegracaoConciliaAjusteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarIntegracaoConciliaAjuste'>
);

export type DeletarIntegracaoSoftExpressCadastroMutationVariables = {
  values?: Maybe<IntegracaoSoftExpressCadastroInput>
};


export type DeletarIntegracaoSoftExpressCadastroMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarIntegracaoSoftExpressCadastro'>
);

export type DeletarIntegracaoTotvsCadastroOperadorasMutationVariables = {
  values?: Maybe<IntegracaoTotvsCadastroOperadorasInput>
};


export type DeletarIntegracaoTotvsCadastroOperadorasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarIntegracaoTotvsCadastroOperadoras'>
);

export type DeletarManutencaoArquivosMutationVariables = {
  values?: Maybe<ReprocessarManutencaoArquivosInput>
};


export type DeletarManutencaoArquivosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarManutencaoArquivos'>
);

export type DeletarParametrosTotvsMutationVariables = {
  values?: Maybe<IntegracaoTotvsCadastroParametrosConciliadorInput>
};


export type DeletarParametrosTotvsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarParametrosTOTVS'>
);

export type DeletarTicketMutationVariables = {
  values?: Maybe<DeletarTicketInput>
};


export type DeletarTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletarTicket'>
);

export type DeleteEstabelecimentoOperadoraMutationVariables = {
  values?: Maybe<DeleteEstabelecimentoOperadoraInput>
};


export type DeleteEstabelecimentoOperadoraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEstabelecimentoOperadora'>
);

export type DeletePerfilMutationVariables = {
  values?: Maybe<PerfilCrudInput>
};


export type DeletePerfilMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePerfil'>
);

export type DeletePlanosConciliadoresMutationVariables = {
  values?: Maybe<PlanosConciliadoresCrudInput>
};


export type DeletePlanosConciliadoresMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosConciliadores'>
);

export type DeletePlanosConciliadoresContratoMutationVariables = {
  values?: Maybe<PlanosConciliadoresContratoCrudInput>
};


export type DeletePlanosConciliadoresContratoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosConciliadoresContrato'>
);

export type DeletePlanosConciliadoresTaxasMutationVariables = {
  values?: Maybe<PlanosConciliadoresTaxasCrudInput>
};


export type DeletePlanosConciliadoresTaxasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosConciliadoresTaxas'>
);

export type DeletePlanosPosMutationVariables = {
  values?: Maybe<PlanosCrudInput>
};


export type DeletePlanosPosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosPOS'>
);

export type DeletePlanosPosTaxasMutationVariables = {
  values?: Maybe<PlanosTaxasCrudInput>
};


export type DeletePlanosPosTaxasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosPosTaxas'>
);

export type DeletePlanosTerminalMutationVariables = {
  values?: Maybe<PlanosTerminalCrudInput>
};


export type DeletePlanosTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlanosTerminal'>
);

export type DeleteTaxasAntecipacaoMutationVariables = {
  values?: Maybe<TaxasAntecipacaoCrudInput>
};


export type DeleteTaxasAntecipacaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxasAntecipacao'>
);

export type DeleteTerminalMutationVariables = {
  values?: Maybe<TerminalCrudInput>
};


export type DeleteTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTerminal'>
);

export type DeleteUsuarioMutationVariables = {
  values?: Maybe<UsuarioDaoInput>
};


export type DeleteUsuarioMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUsuario'>
);

export type DownloadAnalisePesquisaTicketMutationVariables = {
  values?: Maybe<DownloadAnalisePesquisaTicketInput>
};


export type DownloadAnalisePesquisaTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadAnalisePesquisaTicket'>
);

export type DownloadArquivoConciliacaoEmAbertoMutationVariables = {
  codarquivoDownload?: Maybe<Scalars['String']>
};


export type DownloadArquivoConciliacaoEmAbertoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadArquivoConciliacaoEmAberto'>
);

export type DownloadExtratosOriginaisMutationVariables = {
  values?: Maybe<DownloadExtratosOriginaisInput>
};


export type DownloadExtratosOriginaisMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadExtratosOriginais'>
);

export type DownloadManutencaoArquivosMutationVariables = {
  values?: Maybe<DownloadManutencaoArquivosInput>
};


export type DownloadManutencaoArquivosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadManutencaoArquivos'>
);

export type EditarEstabelecimentoMutationVariables = {
  codOperadora?: Maybe<Scalars['Int']>,
  codExterno?: Maybe<Scalars['String']>,
  codExternoAntigo?: Maybe<Scalars['String']>,
  apelido?: Maybe<Scalars['String']>,
  codPessoa?: Maybe<Scalars['Int']>
};


export type EditarEstabelecimentoMutation = (
  { __typename?: 'Mutation' }
  & { editarEstabelecimento: Maybe<(
    { __typename?: 'EstabelecimentoCRUD' }
    & Pick<EstabelecimentoCrud, 'mensagemTela'>
    & { operadoras: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraStatus' }
      & Pick<OperadoraStatus, 'arquivoImagemPOS' | 'arquivoImagemTEF' | 'downlaodTermoAdesaoCss' | 'downlaodAutorizacaoCss'>
      & { operadora: Maybe<(
        { __typename?: 'OperadorasDAO' }
        & Pick<OperadorasDao, 'codoperadora' | 'nome' | 'formacalculo' | 'consinco' | 'estabelecimentoCadastroTaxa'>
      )>, statusEC: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'descricao'>
      )>, statusTaxa: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'descricao'>
      )>, statusTermo: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'descricao'>
      )>, statusCarteira: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'descricao'>
      )> }
    )>>> }
  )> }
);

export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables = {
  values?: Maybe<FaturamentoMovimentoBeanInput>,
  operacao: Scalars['String']
};


export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota'>
);

export type GerarIntegracaoAlterdataMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  codigocontacreditopg?: Maybe<Scalars['String']>,
  codigocontadebitopg?: Maybe<Scalars['String']>,
  codigocontacreditorc?: Maybe<Scalars['String']>,
  codigocontadebitorc?: Maybe<Scalars['String']>
};


export type GerarIntegracaoAlterdataMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoAlterdata: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data'>
  )> }
);

export type GerarIntegracaoAvancoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoAvancoMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoAvanco: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data'>
  )> }
);

export type GerarIntegracaoCashMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoCashMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoCash: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data'>
  )> }
);

export type GerarIntegracaoConciliaRelatorioMutationVariables = {
  conciliadores?: Maybe<Array<Maybe<Scalars['String']>>>,
  datade?: Maybe<Scalars['Date']>,
  dataate?: Maybe<Scalars['Date']>,
  somenteProblemas?: Maybe<Scalars['Boolean']>,
  sintetico?: Maybe<Scalars['Boolean']>,
  exportar?: Maybe<Scalars['String']>
};


export type GerarIntegracaoConciliaRelatorioMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoConciliaRelatorio: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data'>
  )> }
);

export type GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  ultimos7Dias?: Maybe<Scalars['Boolean']>,
  refazerConciliacao702?: Maybe<Scalars['Boolean']>,
  refazerConciliacao701?: Maybe<Scalars['Boolean']>,
  corrigir?: Maybe<Scalars['String']>,
  botaoCorrigir?: Maybe<Scalars['Boolean']>
};


export type GerarIntegracaoConciliaRetornoNossoNumeroMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoConciliaRetornoNossoNumero: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  ajusteConsolidado?: Maybe<Scalars['String']>,
  tipoRelatorio: Scalars['String']
};


export type GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoConciliaVendaPagamentoAntecipado: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data'>
  )> }
);

export type GerarIntegracaoConsincoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  versao?: Maybe<Scalars['String']>,
  tipoNsu?: Maybe<Scalars['String']>,
  integradoPDV?: Maybe<Scalars['Boolean']>,
  mostrarDescricaoAjuste?: Maybe<Scalars['Boolean']>,
  pos?: Maybe<Scalars['String']>,
  tipoTiket?: Maybe<Scalars['String']>,
  tipodatavenda?: Maybe<Scalars['String']>
};


export type GerarIntegracaoConsincoMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoConsinco: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoKaplenMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoKaplenMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoKaplen: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoLinxMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoLinxMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoLinx: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoMakroMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoMakroMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarIntegracaoMakro'>
);

export type GerarIntegracaoMicroUniversoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoMicroUniversoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarIntegracaoMicroUniverso'>
);

export type GerarIntegracaoOlxMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  codbuckets3amazon?: Maybe<Scalars['Int']>,
  transacaoComProblema?: Maybe<Scalars['Boolean']>
};


export type GerarIntegracaoOlxMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoOlx: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoSoftwareExpressMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoSoftwareExpressMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoSoftwareExpress: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoTeknisaMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoTeknisaMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoTeknisa: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarIntegracaoTotvsMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarIntegracaoTotvsMutation = (
  { __typename?: 'Mutation' }
  & { gerarIntegracaoTotvs: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarRelatorioConciliadosAntecipadosMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaAntecipadosInput>,
  excelsimples?: Maybe<Scalars['String']>
};


export type GerarRelatorioConciliadosAntecipadosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioConciliadosAntecipados'>
);

export type GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioConciliadosBoletoFiltroSimplesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioConciliadosBoletoFiltroSimples'>
);

export type GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioConciliadosPagamentoFiltroSimplesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioConciliadosPagamentoFiltroSimples'>
);

export type GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioConciliadosPrevisaoFiltroSimples'>
);

export type GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioConciliadosVendaFiltroSimplesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioConciliadosVendaFiltroSimples'>
);

export type GerarRelatorioEncontradosClienteLiquidacaoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioEncontradosClienteLiquidacaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioEncontradosClienteLiquidacao'>
);

export type GerarRelatorioEncontradosClientePagamentoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioEncontradosClientePagamentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioEncontradosClientePagamento'>
);

export type GerarRelatorioEncontradosClientePrevisaoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioEncontradosClientePrevisaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioEncontradosClientePrevisao'>
);

export type GerarRelatorioEncontradosClienteVendaMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioEncontradosClienteVendaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioEncontradosClienteVenda'>
);

export type GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioExtratoConferenciaBoletoPrevisao'>
);

export type GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioExtratoConferenciaLiquidacaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioExtratoConferenciaLiquidacao'>
);

export type GerarRelatorioExtratoConferenciaPagamentoMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioExtratoConferenciaPagamentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioExtratoConferenciaPagamento'>
);

export type GerarRelatorioExtratoConferenciaVendaMutationVariables = {
  values?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioExtratoConferenciaVendaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioExtratoConferenciaVenda'>
);

export type GerarRelatorioFechamentoMensalTerminalMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioFechamentoMensalTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioFechamentoMensalTerminal'>
);

export type GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  botaoExportar?: Maybe<Scalars['Boolean']>
};


export type GerarRelatorioFechamentoValoresLiquidosSemestralMutation = (
  { __typename?: 'Mutation' }
  & { gerarRelatorioFechamentoValoresLiquidosSemestral: Maybe<(
    { __typename?: 'ArquivoGerado' }
    & Pick<ArquivoGerado, 'nomeArquivo' | 'data' | 'mensagem'>
  )> }
);

export type GerarRelatorioFluxoRecebimentoCartaoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioFluxoRecebimentoCartaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioFluxoRecebimentoCartao'>
);

export type GerarRelatorioMensalidadesRecebidasMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioMensalidadesRecebidasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioMensalidadesRecebidas'>
);

export type GerarRelatorioMotivoAjusteMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioMotivoAjusteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioMotivoAjuste'>
);

export type GerarRelatorioMovimentosFechadosMutationVariables = {
  chave?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  filtrode?: Maybe<Scalars['Date']>,
  filtroate?: Maybe<Scalars['Date']>,
  datainclusao?: Maybe<Scalars['Date']>
};


export type GerarRelatorioMovimentosFechadosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioMovimentosFechados'>
);

export type GerarRelatorioOlxIntegracaoAjusteMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioOlxIntegracaoAjusteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioOLXIntegracaoAjuste'>
);

export type GerarRelatorioOlxIntegracaoPagamentoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioOlxIntegracaoPagamentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioOLXIntegracaoPagamento'>
);

export type GerarRelatorioOlxIntegracaoVendaMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioOlxIntegracaoVendaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioOLXIntegracaoVenda'>
);

export type GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioPagamentoComDebitoAgrupadoVenda'>
);

export type GerarRelatorioPagamentosEfetivosMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioPagamentosEfetivosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioPagamentosEfetivos'>
);

export type GerarRelatorioPrevisaoPagamentoMutationVariables = {
  filtro?: Maybe<GerarRelatorioPrevisaoPagamentoInput>,
  consideraAjuste?: Maybe<Scalars['Boolean']>
};


export type GerarRelatorioPrevisaoPagamentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioPrevisaoPagamento'>
);

export type GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables = {
  filtro?: Maybe<GerarRelatorioPrevisaoValoresLiquidosSemanalInput>,
  consideraAjuste?: Maybe<Scalars['Boolean']>,
  datavendalimite?: Maybe<Scalars['Date']>,
  apurarPendentePagamento?: Maybe<Scalars['Boolean']>,
  analise?: Maybe<Scalars['String']>
};


export type GerarRelatorioPrevisaoValoresLiquidosSemamalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioPrevisaoValoresLiquidosSemamal'>
);

export type GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables = {
  filtro?: Maybe<GerarRelatorioPrevisaoValoresLiquidosSemestralInput>
};


export type GerarRelatorioPrevisaoValoresLiquidosSemestralMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioPrevisaoValoresLiquidosSemestral'>
);

export type GerarRelatorioRejeitadosMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  tipo?: Maybe<Scalars['String']>
};


export type GerarRelatorioRejeitadosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioRejeitados'>
);

export type GerarRelatorioSinteticoTipoConciliacaoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioSinteticoTipoConciliacaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioSinteticoTipoConciliacao'>
);

export type GerarRelatorioTaxasMutationVariables = {
  values?: Maybe<TaxasDaoInput>,
  exportar?: Maybe<Scalars['String']>
};


export type GerarRelatorioTaxasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioTaxas'>
);

export type GerarRelatorioTaxasTerminalMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioTaxasTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioTaxasTerminal'>
);

export type GerarRelatorioTerminaisAtivosMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioTerminaisAtivosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioTerminaisAtivos'>
);

export type GerarRelatorioTerminaisSemMovimentoMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  valor?: Maybe<Scalars['Float']>
};


export type GerarRelatorioTerminaisSemMovimentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioTerminaisSemMovimento'>
);

export type GerarRelatorioTransacoesProblemasTefMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioTransacoesProblemasTefMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioTransacoesProblemasTEF'>
);

export type GerarRelatorioValoresDivergentesMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioValoresDivergentesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioValoresDivergentes'>
);

export type GerarRelatorioVendasMutationVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioVendasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioVendas'>
);

export type GerarRelatorioVendasPorLojaValorBrutoMutationVariables = {
  ajustes?: Maybe<Scalars['String']>,
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>
};


export type GerarRelatorioVendasPorLojaValorBrutoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gerarRelatorioVendasPorLojaValorBruto'>
);

export type GravarCorrecaoMutationVariables = {
  values?: Maybe<Array<Maybe<UploadSelecionadosInput>>>
};


export type GravarCorrecaoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'gravarCorrecao'>
);

export type ImportarTicketMutationVariables = {
  values?: Maybe<Array<Maybe<TicketsImportacaoBeanInput>>>
};


export type ImportarTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importarTicket'>
);

export type ImportarTodosTicketsMutationVariables = {
  values?: Maybe<ImportacaoTicketInput>
};


export type ImportarTodosTicketsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importarTodosTickets'>
);

export type IncluirTicketMutationVariables = {
  values?: Maybe<InclusaoTicketGeralInput>,
  tickets?: Maybe<Array<Maybe<TicketsInclusaoBeanInput>>>
};


export type IncluirTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'incluirTicket'>
);

export type InserirRapidoMutationVariables = {
  values?: Maybe<InserirRapidoInput>
};


export type InserirRapidoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inserirRapido'>
);

export type LogarUsuarioMestreMutationVariables = {
  searchValues?: Maybe<LogarUsuarioMestreInput>
};


export type LogarUsuarioMestreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logarUsuarioMestre'>
);

export type LogarUsuarioMestreRevendaMutationVariables = {
  searchValues?: Maybe<LogarUsuarioMestreInput>
};


export type LogarUsuarioMestreRevendaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logarUsuarioMestreRevenda'>
);

export type LoginMutationVariables = {
  username?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  values?: Maybe<RecaptchaInput>
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ReconsiderarDesconsiderarTicketMutationVariables = {
  codlinhasarqcli?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  acao?: Maybe<Scalars['String']>
};


export type ReconsiderarDesconsiderarTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reconsiderarDesconsiderarTicket'>
);

export type RedefinirSenhaMutationVariables = {
  senhaAtual?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>
};


export type RedefinirSenhaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'redefinirSenha'>
);

export type ReprocessarManutencaoArquivosMutationVariables = {
  values?: Maybe<ReprocessarManutencaoArquivosInput>
};


export type ReprocessarManutencaoArquivosMutation = (
  { __typename?: 'Mutation' }
  & { reprocessarManutencaoArquivos: Maybe<(
    { __typename?: 'ManutencaoArquivos' }
    & { editValues: Maybe<(
      { __typename?: 'ArquivosrecebidosDAO' }
      & Pick<ArquivosrecebidosDao, 'codarquivo'>
    )> }
  )> }
);

export type ReprocessarTicketMutationVariables = {
  values?: Maybe<ReprocessarTicketInput>
};


export type ReprocessarTicketMutation = (
  { __typename?: 'Mutation' }
  & { reprocessarTicket: Maybe<(
    { __typename?: 'ManutencaoArquivos' }
    & { editValues: Maybe<(
      { __typename?: 'ArquivosrecebidosDAO' }
      & Pick<ArquivosrecebidosDao, 'codarquivo'>
    )> }
  )> }
);

export type SalvarBucketS3AmazonMutationVariables = {
  values?: Maybe<BucketS3AmazonCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarBucketS3AmazonMutation = (
  { __typename?: 'Mutation' }
  & { salvarBucketS3Amazon: Maybe<(
    { __typename?: 'BucketS3AmazonCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'BucketS3AmazonDAO' }
      & Pick<BucketS3AmazonDao, 'codbuckets3amazon' | 'codpessoapai' | 'keyid' | 'senha' | 'nome' | 'pasta' | 'bucketname'>
    )>, searchValues: Maybe<(
      { __typename?: 'BucketS3AmazonDAO' }
      & Pick<BucketS3AmazonDao, 'codbuckets3amazon'>
    )> }
  )> }
);

export type SalvarDadosNFeMutationVariables = {
  values?: Maybe<DadosNfeCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarDadosNFeMutation = (
  { __typename?: 'Mutation' }
  & { salvarDadosNFe: Maybe<(
    { __typename?: 'DadosNfeCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'DadosNfeDAO' }
      & Pick<DadosNfeDao, 'codpessoa'>
    )> }
  )> }
);

export type SalvarIntegracaoAgendamentoMutationVariables = {
  values?: Maybe<IntegracaoConciliaAgendamentoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarIntegracaoAgendamentoMutation = (
  { __typename?: 'Mutation' }
  & { salvarIntegracaoAgendamento: Maybe<(
    { __typename?: 'IntegracaoConciliaAgendamentoCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'AgendamentoIntegracaoDAO' }
      & Pick<AgendamentoIntegracaoDao, 'codagendamentointegracao' | 'tipo' | 'codpessoapai' | 'codoperadora' | 'codpessoa' | 'conciliadores' | 'unidadeconciliadora' | 'contacorrente' | 'ajusteconsolidado' | 'status' | 'datainiciogeracao' | 'protocolo' | 'host' | 'porta' | 'login' | 'diretorioftp' | 'datainclusao' | 'dataalteracao' | 'exibirenccliente'>
    )> }
  )> }
);

export type SalvarIntegracaoConciliaAjusteMutationVariables = {
  values?: Maybe<IntegracaoConciliaAjusteCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarIntegracaoConciliaAjusteMutation = (
  { __typename?: 'Mutation' }
  & { salvarIntegracaoConciliaAjuste: Maybe<(
    { __typename?: 'IntegracaoConciliaAjusteCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'OrigemAjustePersonalizadoDAO' }
      & Pick<OrigemAjustePersonalizadoDao, 'codorigemajuste' | 'codpessoapai' | 'codexterno'>
    )>, searchValues: Maybe<(
      { __typename?: 'OrigemAjustePersonalizadoDAO' }
      & Pick<OrigemAjustePersonalizadoDao, 'codorigemajuste' | 'codpessoapai' | 'codexterno'>
    )> }
  )> }
);

export type SalvarIntegracaoSoftExpressCadastroMutationVariables = {
  values?: Maybe<IntegracaoSoftExpressCadastroInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarIntegracaoSoftExpressCadastroMutation = (
  { __typename?: 'Mutation' }
  & { salvarIntegracaoSoftExpressCadastro: Maybe<(
    { __typename?: 'IntegracaoSoftExpressCadastro' }
    & { editValues: Maybe<(
      { __typename?: 'ConciliadoresIntegSoftExpressDAO' }
      & Pick<ConciliadoresIntegSoftExpressDao, 'codpessoa' | 'codexterno'>
    )>, searchValues: Maybe<(
      { __typename?: 'ConciliadoresIntegSoftExpressDAO' }
      & Pick<ConciliadoresIntegSoftExpressDao, 'codpessoa' | 'codexterno'>
    )> }
  )> }
);

export type SalvarIntegracaoTotvsCadastroOperadorasMutationVariables = {
  values?: Maybe<IntegracaoTotvsCadastroOperadorasInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarIntegracaoTotvsCadastroOperadorasMutation = (
  { __typename?: 'Mutation' }
  & { salvarIntegracaoTotvsCadastroOperadoras: Maybe<(
    { __typename?: 'IntegracaoTotvsCadastroOperadoras' }
    & { editValues: Maybe<(
      { __typename?: 'OperadorasTotvsDAO' }
      & Pick<OperadorasTotvsDao, 'codoperadora' | 'codpessoapai' | 'codexterno'>
    )>, searchValues: Maybe<(
      { __typename?: 'OperadorasTotvsDAO' }
      & Pick<OperadorasTotvsDao, 'codoperadora' | 'codpessoapai' | 'codexterno'>
    )> }
  )> }
);

export type SalvarMestreRevendaMutationVariables = {
  values?: Maybe<MestresRevendaInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarMestreRevendaMutation = (
  { __typename?: 'Mutation' }
  & { salvarMestreRevenda: Maybe<(
    { __typename?: 'MestresRevenda' }
    & Pick<MestresRevenda, 'codPessoaPai'>
    & { pessoa: Maybe<(
      { __typename?: 'PessoaGenerica' }
      & { pessoa: Maybe<(
        { __typename?: 'PessoaDAO' }
        & Pick<PessoaDao, 'telefone' | 'email'>
      )> }
    )>, editValues: Maybe<(
      { __typename?: 'UsuariosDAO' }
      & Pick<UsuariosDao, 'responsavel' | 'codpessoa'>
    )> }
  )> }
);

export type SalvarParametrosMutationVariables = {
  values?: Maybe<ParametrosDaoInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarParametrosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarParametros'>
);

export type SalvarParametrosTotvsMutationVariables = {
  values?: Maybe<IntegracaoTotvsCadastroParametrosConciliadorInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarParametrosTotvsMutation = (
  { __typename?: 'Mutation' }
  & { salvarParametrosTOTVS: Maybe<(
    { __typename?: 'IntegracaoTotvsCadastroParametrosConciliador' }
    & { editValues: Maybe<(
      { __typename?: 'ParametrosConciliadorTotvsDAO' }
      & Pick<ParametrosConciliadorTotvsDao, 'codpessoa' | 'codfilial' | 'opcional1' | 'opcional2' | 'coddepartamento' | 'codcentrocusto' | 'tipocontabil' | 'codmoeda' | 'codcontacaixa' | 'statuslancamento' | 'codcoligadaclifor' | 'codcoligadacontacaixa' | 'tiposacado' | 'codevento'>
    )>, searchValues: Maybe<(
      { __typename?: 'ParametrosConciliadorTotvsDAO' }
      & Pick<ParametrosConciliadorTotvsDao, 'codpessoa'>
    )> }
  )> }
);

export type SalvarPlanosConciliadoresContratoMutationVariables = {
  values?: Maybe<PlanoConciliadoresContratoInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarPlanosConciliadoresContratoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarPlanosConciliadoresContrato'>
);

export type SalvarPlanosPosMutationVariables = {
  values?: Maybe<PlanosCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarPlanosPosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarPlanosPOS'>
);

export type SalvarPlanosPosTaxasMutationVariables = {
  values?: Maybe<PlanosTaxasCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarPlanosPosTaxasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarPlanosPosTaxas'>
);

export type SalvarPlanosTerminalMutationVariables = {
  values?: Maybe<PlanosTerminalCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarPlanosTerminalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarPlanosTerminal'>
);

export type SalvarRelatorioAgendamentoMutationVariables = {
  values?: Maybe<AgendamentoRelatorioInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SalvarRelatorioAgendamentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarRelatorioAgendamento'>
);

export type SalvarTefsMutationVariables = {
  tefs?: Maybe<Array<Maybe<UsuarioMestreTefsBeanInput>>>
};


export type SalvarTefsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salvarTefs'>
);

export type SaveClientesMutationVariables = {
  values?: Maybe<ClienteCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SaveClientesMutation = (
  { __typename?: 'Mutation' }
  & { saveClientes: Maybe<(
    { __typename?: 'ClienteCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'ClienteDAO' }
      & Pick<ClienteDao, 'codpessoa'>
    )>, searchValues: Maybe<(
      { __typename?: 'ClienteDAO' }
      & Pick<ClienteDao, 'codpessoa'>
    )> }
  )> }
);

export type SavePainelCadastroTaxaMutationVariables = {
  values?: Maybe<SavePainelCadastroTaxaInput>
};


export type SavePainelCadastroTaxaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'savePainelCadastroTaxa'>
);

export type SavePerfilMutationVariables = {
  values?: Maybe<PerfilCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SavePerfilMutation = (
  { __typename?: 'Mutation' }
  & { savePerfil: Maybe<(
    { __typename?: 'PerfilCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'PerfilDAO' }
      & Pick<PerfilDao, 'codperfil' | 'codpessoacriador'>
    )>, searchValues: Maybe<(
      { __typename?: 'PerfilDAO' }
      & Pick<PerfilDao, 'codperfil' | 'codpessoacriador'>
    )> }
  )> }
);

export type SavePlanosConciliadoresMutationVariables = {
  values?: Maybe<PlanosConciliadoresCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SavePlanosConciliadoresMutation = (
  { __typename?: 'Mutation' }
  & { savePlanosConciliadores: Maybe<(
    { __typename?: 'PlanosConciliadoresCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresDAO' }
      & Pick<PlanosConciliadoresDao, 'codplanosconciliadores' | 'nome' | 'codpessoapai' | 'tarifacao'>
    )>, searchValues: Maybe<(
      { __typename?: 'PlanosConciliadoresDAO' }
      & Pick<PlanosConciliadoresDao, 'codplanosconciliadores'>
    )> }
  )> }
);

export type SavePlanosConciliadoresContratoMutationVariables = {
  values?: Maybe<PlanosConciliadoresContratoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SavePlanosConciliadoresContratoMutation = (
  { __typename?: 'Mutation' }
  & { savePlanosConciliadoresContrato: Maybe<(
    { __typename?: 'PlanosConciliadoresContratoCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresContratoDAO' }
      & Pick<PlanosConciliadoresContratoDao, 'codplanosconciliadorescontrato' | 'seq' | 'codplanosconciliadores' | 'codpessoa' | 'codoperadora' | 'codexterno' | 'vigenciaDe' | 'vigenciaAte' | 'codpessoapai'>
    )>, searchValues: Maybe<(
      { __typename?: 'PlanosConciliadoresContratoDAO' }
      & Pick<PlanosConciliadoresContratoDao, 'codplanosconciliadorescontrato' | 'seq' | 'codplanosconciliadores'>
    )> }
  )> }
);

export type SavePlanosConciliadoresTaxasMutationVariables = {
  values?: Maybe<PlanosConciliadoresTaxasCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SavePlanosConciliadoresTaxasMutation = (
  { __typename?: 'Mutation' }
  & { savePlanosConciliadoresTaxas: Maybe<(
    { __typename?: 'PlanosConciliadoresTaxasCRUD' }
    & Pick<PlanosConciliadoresTaxasCrud, 'converterProdutoTaxas'>
    & { editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'codplanosconciliadorestaxas' | 'codplanosconciliadores' | 'codprodutos' | 'de' | 'ate' | 'valorde' | 'valorate' | 'taxa' | 'duplicado' | 'bincartao'>
    )>, searchValues: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'codplanosconciliadorestaxas' | 'codplanosconciliadores' | 'codprodutos' | 'de' | 'ate' | 'valorde' | 'valorate' | 'taxa' | 'duplicado' | 'bincartao'>
    )> }
  )> }
);

export type SaveTaxasAntecipacaoMutationVariables = {
  values?: Maybe<TaxasAntecipacaoCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SaveTaxasAntecipacaoMutation = (
  { __typename?: 'Mutation' }
  & { saveTaxasAntecipacao: Maybe<(
    { __typename?: 'TaxasAntecipacaoCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'TaxaAntecipacaoDAO' }
      & Pick<TaxaAntecipacaoDao, 'codtaxaantecipacao'>
    )>, searchValues: Maybe<(
      { __typename?: 'TaxaAntecipacaoDAO' }
      & Pick<TaxaAntecipacaoDao, 'codtaxaantecipacao'>
    )> }
  )> }
);

export type SaveTerminalMutationVariables = {
  values?: Maybe<TerminalCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SaveTerminalMutation = (
  { __typename?: 'Mutation' }
  & { saveTerminal: Maybe<(
    { __typename?: 'TerminalCRUD' }
    & Pick<TerminalCrud, 'ativo' | 'datainiciocobranca' | 'concioperadora'>
    & { editValues: Maybe<(
      { __typename?: 'TerminalDAO' }
      & Pick<TerminalDao, 'codexterno' | 'codpessoapai' | 'nome' | 'vinculo' | 'dataInclusao' | 'status' | 'codpessoa' | 'codoperadora' | 'codexternoconciliador' | 'codpessoacliente'>
    )>, searchValues: Maybe<(
      { __typename?: 'TerminalDAO' }
      & Pick<TerminalDao, 'codexterno' | 'codpessoapai' | 'nome' | 'vinculo' | 'dataInclusao' | 'status' | 'codpessoa' | 'codoperadora' | 'codexternoconciliador' | 'codpessoacliente'>
    )> }
  )> }
);

export type SaveUsuarioMutationVariables = {
  values?: Maybe<UsuarioCrudInput>,
  insert?: Maybe<Scalars['Boolean']>
};


export type SaveUsuarioMutation = (
  { __typename?: 'Mutation' }
  & { saveUsuario: Maybe<(
    { __typename?: 'UsuarioCRUD' }
    & { pessoa: Maybe<(
      { __typename?: 'PessoaGenerica' }
      & { pessoa: Maybe<(
        { __typename?: 'PessoaDAO' }
        & Pick<PessoaDao, 'codpessoa'>
      )> }
    )>, editValues: Maybe<(
      { __typename?: 'UsuariosDAO' }
      & Pick<UsuariosDao, 'codperfil'>
    )>, searchValues: Maybe<(
      { __typename?: 'UsuariosDAO' }
      & Pick<UsuariosDao, 'codpessoa'>
    )> }
  )> }
);

export type TrocarSenhaMutationVariables = {
  codpessoa?: Maybe<Scalars['Int']>
};


export type TrocarSenhaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trocarSenha'>
);

export type UploadDocsEstabelecimentoMutationVariables = {
  nameFile?: Maybe<Scalars['String']>,
  tipoDoc?: Maybe<Scalars['String']>,
  file?: Maybe<Array<Maybe<Scalars['File']>>>,
  values?: Maybe<ListarEstabelecimentosInput>
};


export type UploadDocsEstabelecimentoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadDocsEstabelecimento'>
);

export type UsuarioTrocarSenhaMutationVariables = {
  idTrocaSenha?: Maybe<Scalars['String']>,
  gravarResponsavel?: Maybe<Scalars['Boolean']>,
  senha?: Maybe<Scalars['String']>,
  confirmaSenha?: Maybe<Scalars['String']>,
  responsavel?: Maybe<Scalars['String']>
};


export type UsuarioTrocarSenhaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'usuarioTrocarSenha'>
);

export type ValidarPendenciaCaadastroOperadoraMutationVariables = {};


export type ValidarPendenciaCaadastroOperadoraMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validarPendenciaCadastroOperadora'>
);

export type BaixarArquivoEstabelecimentoQueryVariables = {
  values?: Maybe<ArquivoDownloadInput>
};


export type BaixarArquivoEstabelecimentoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'baixarArquivoEstabelecimento'>
);

export type GetBannerQueryVariables = {};


export type GetBannerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBanner'>
);

export type CanUploadQueryVariables = {
  tipoDoc?: Maybe<Scalars['String']>,
  values?: Maybe<ListarEstabelecimentosInput>
};


export type CanUploadQuery = (
  { __typename?: 'Query' }
  & { canUpload: Maybe<(
    { __typename?: 'CanUploadResult' }
    & Pick<CanUploadResult, 'canUpload' | 'exibirUrl'>
    & { documentos: Maybe<Array<Maybe<(
      { __typename?: 'DocumentosBean' }
      & Pick<DocumentosBean, 'nome' | 'status' | 'obs' | 'coddocumentos'>
    )>>> }
  )> }
);

export type DetalharTicketQueryVariables = {
  values?: Maybe<TicketsDetalheInput>
};


export type DetalharTicketQuery = (
  { __typename?: 'Query' }
  & { detalharTicket: Maybe<(
    { __typename?: 'DetalheTicket' }
    & { clientesLinha: Maybe<Array<Maybe<(
      { __typename?: 'ClientesLinhaBean' }
      & ClientesFragment
    )>>>, clientesUpdateLinha: Maybe<Array<Maybe<(
      { __typename?: 'ClientesLinhaBean' }
      & ClientesFragment
    )>>>, clientesOlxLinha: Maybe<Array<Maybe<(
      { __typename?: 'ClientesLinhaBean' }
      & ClientesFragment
    )>>>, clientesLinhaDesconsiderados: Maybe<Array<Maybe<(
      { __typename?: 'ClientesLinhaBean' }
      & ClientesFragment
    )>>>, clientesLinhaCancelados: Maybe<Array<Maybe<(
      { __typename?: 'ClientesLinhaBean' }
      & ClientesFragment
    )>>>, operadoraLinha: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraLinhaBean' }
      & OperadorasFragment
    )>>>, operadoraLiquidacaoLinha: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraLinhaBean' }
      & OperadorasFragment
    )>>>, operadoraVendaLinha: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraLinhaBean' }
      & OperadorasFragment
    )>>>, operadoraPrevisaoLinha: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraLinhaBean' }
      & OperadorasFragment
    )>>>, antecipadoLinha: Maybe<Array<Maybe<(
      { __typename?: 'OperadoraLinhaBean' }
      & OperadorasFragment
    )>>> }
  )> }
);

export type ClientesFragment = (
  { __typename?: 'ClientesLinhaBean' }
  & Pick<ClientesLinhaBean, 'nomearquivo' | 'statusArquivo' | 'datarecebimento' | 'dataprevistapagamento' | 'datatransacao' | 'valorbruto' | 'numparcela' | 'qtdparcelas' | 'nossonumero' | 'bandeira' | 'nsu' | 'numeroautorizacao' | 'tid' | 'codarquivo' | 'tipo'>
);

export type OperadorasFragment = (
  { __typename?: 'OperadoraLinhaBean' }
  & Pick<OperadoraLinhaBean, 'nomearquivo' | 'statusArquivo' | 'datarecebimento' | 'datapagamento' | 'datavenda' | 'valorbruto' | 'valorliquidopago' | 'outroscreditos' | 'outrosdebitos' | 'parcela' | 'totparcelas' | 'status' | 'meiocaptura' | 'produto' | 'bandeira' | 'numterminal' | 'nsu' | 'autorizacao' | 'tid' | 'codarquivo' | 'tipo'>
);

export type GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables = {};


export type GetDownloadUnidadesNaoEncontradasExibicaoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadUnidadesNaoEncontradasExibicao'>
);

export type EstabelecimentoDownloadQueryVariables = {
  values: ListarEstabelecimentosInput,
  tipoDocumento: Scalars['String']
};


export type EstabelecimentoDownloadQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'estabelecimentoDownload'>
);

export type FiltrarItensUnidadesConciliadorasQueryVariables = {
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type FiltrarItensUnidadesConciliadorasQuery = (
  { __typename?: 'Query' }
  & { filtrarItensUnidadesConciliadoras: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type GetAgendamentoIntegracaoQueryVariables = {
  values?: Maybe<AgendamentoIntegracaoEditInput>
};


export type GetAgendamentoIntegracaoQuery = (
  { __typename?: 'Query' }
  & { getAgendamentoIntegracao: Maybe<(
    { __typename?: 'IntegracaoConciliaAgendamentoCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'AgendamentoIntegracaoDAO' }
      & Pick<AgendamentoIntegracaoDao, 'codagendamentointegracao' | 'tipo' | 'codpessoapai' | 'codoperadora' | 'codpessoa' | 'conciliadores' | 'unidadeconciliadora' | 'contacorrente' | 'ajusteconsolidado' | 'status' | 'datainiciogeracao' | 'protocolo' | 'host' | 'porta' | 'login' | 'senha' | 'diretorioftp' | 'datainclusao' | 'dataalteracao' | 'exibirenccliente' | 'somenteajuste'>
    )> }
  )> }
);

export type GetAgendamentoRelatorioQueryVariables = {
  values?: Maybe<AgendamentoRelatorioEditInput>
};


export type GetAgendamentoRelatorioQuery = (
  { __typename?: 'Query' }
  & { getAgendamentoRelatorio: Maybe<(
    { __typename?: 'AgendamentoRelatorioGenerico' }
    & { agendamentoRelatorio: Maybe<(
      { __typename?: 'AgendamentoRelatorioDAO' }
      & Pick<AgendamentoRelatorioDao, 'codagendamentorelatorio' | 'relatorio' | 'parametros' | 'tipo' | 'frequencia' | 'validade' | 'datainclusao'>
    )>, agendamentoRelatorioEmail: Maybe<Array<Maybe<(
      { __typename?: 'AgendamentoRelatorioEmailDAO' }
      & Pick<AgendamentoRelatorioEmailDao, 'email'>
    )>>> }
  )> }
);

export type GetAgendamentosIntegracaoQueryVariables = {};


export type GetAgendamentosIntegracaoQuery = (
  { __typename?: 'Query' }
  & { getAgendamentosIntegracao: Maybe<Array<Maybe<(
    { __typename?: 'AgendamentoIntegracaoDAO' }
    & Pick<AgendamentoIntegracaoDao, 'codagendamentointegracao' | 'tipo'>
  )>>> }
);

export type GetAgendamentosRelatoriosQueryVariables = {};


export type GetAgendamentosRelatoriosQuery = (
  { __typename?: 'Query' }
  & { getAgendamentosRelatorios: Maybe<Array<Maybe<(
    { __typename?: 'AgendamentoRelatorio' }
    & Pick<AgendamentoRelatorio, 'tipoTxt' | 'frequencia' | 'emails' | 'codagendamentorelatorio'>
  )>>> }
);

export type GetAllDadosNFeQueryVariables = {
  values?: Maybe<DadosNfeDaoInput>
};


export type GetAllDadosNFeQuery = (
  { __typename?: 'Query' }
  & { getAllDadosNFe: Maybe<Array<Maybe<(
    { __typename?: 'DadosNfeDAO' }
    & Pick<DadosNfeDao, 'codpessoa' | 'senhacertificado' | 'email' | 'senhaemail' | 'smtp' | 'porta' | 'naturezaoperacao' | 'regimeespecialtributacao' | 'opatantesimplesnacional' | 'incentivadorcultural' | 'pis' | 'cofins' | 'ir' | 'csll' | 'iss' | 'codcidade' | 'cnae' | 'valorminimocalculoir' | 'valorminimocalculopiscofinscsll' | 'subitem' | 'codigotributacaomunicipio' | 'descservico' | 'desccnae' | 'descsubitem' | 'codpessoaemissao' | 'nome'>
  )>>> }
);

export type GetAutenticacaoPainelQueryVariables = {};


export type GetAutenticacaoPainelQuery = (
  { __typename?: 'Query' }
  & { getAutenticacaoPainel: Maybe<(
    { __typename?: 'OauthPainel' }
    & Pick<OauthPainel, 'login' | 'senha'>
  )> }
);

export type GetBucketS3AmazonQueryVariables = {
  values?: Maybe<BucketS3AmazonDaoInput>
};


export type GetBucketS3AmazonQuery = (
  { __typename?: 'Query' }
  & { getBucketS3Amazon: Maybe<(
    { __typename?: 'BucketS3AmazonCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'BucketS3AmazonDAO' }
      & Pick<BucketS3AmazonDao, 'codbuckets3amazon' | 'codpessoapai' | 'keyid' | 'senha' | 'nome' | 'pasta' | 'bucketname'>
    )> }
  )> }
);

export type GetCarregamentoQueryVariables = {};


export type GetCarregamentoQuery = (
  { __typename?: 'Query' }
  & { getCarregamento: Maybe<Array<Maybe<(
    { __typename?: 'ArquivosrecebidosDAO' }
    & Pick<ArquivosrecebidosDao, 'codarquivo' | 'tipo' | 'datarecebimento' | 'nomeOperadora'>
  )>>> }
);

export type GetClienteQueryVariables = {
  values?: Maybe<ClienteDaoInput>
};


export type GetClienteQuery = (
  { __typename?: 'Query' }
  & { getCliente: Maybe<(
    { __typename?: 'ClienteCRUD' }
    & Pick<ClienteCrud, 'token' | 'codEstabelecimentoBancario' | 'agencia' | 'conta' | 'diasCliente' | 'exibirUsuario'>
    & { enderecosAdicionais: Maybe<Array<Maybe<(
      { __typename?: 'EnderecoAdicionalDAO' }
      & Pick<EnderecoAdicionalDao, 'codenderecoadicional' | 'codpessoa' | 'estado' | 'cidade' | 'bairro' | 'endereco' | 'complemento' | 'numero' | 'cep' | 'datainclusao' | 'tipoendereco'>
    )>>>, pessoa: Maybe<(
      { __typename?: 'PessoaGenerica' }
      & { pessoa: Maybe<(
        { __typename?: 'PessoaDAO' }
        & Pick<PessoaDao, 'codpessoa' | 'principal' | 'nome' | 'bairro' | 'endereco' | 'numero' | 'complemento' | 'cep' | 'telefone' | 'email' | 'status' | 'tipo'>
      )>, pessoaF: Maybe<(
        { __typename?: 'PessoaFisicaDAO' }
        & Pick<PessoaFisicaDao, 'cpf'>
      )>, pessoaJ: Maybe<(
        { __typename?: 'PessoaJuridicaDAO' }
        & Pick<PessoaJuridicaDao, 'cnpj' | 'inscestadual' | 'inscmunicipal'>
      )> }
    )>, editValues: Maybe<(
      { __typename?: 'ClienteDAO' }
      & Pick<ClienteDao, 'login' | 'codcidade' | 'senha' | 'inss' | 'iss' | 'codpessoapai'>
    )> }
  )> }
);

export type GetClientesQueryVariables = {};


export type GetClientesQuery = (
  { __typename?: 'Query' }
  & { getClientes: Maybe<Array<Maybe<(
    { __typename?: 'PessoaDAO' }
    & Pick<PessoaDao, 'codpessoa' | 'nome' | 'status'>
  )>>> }
);

export type GetCodigoOperadoraTotvsQueryVariables = {
  values?: Maybe<OperadorasTotvsDaoInput>
};


export type GetCodigoOperadoraTotvsQuery = (
  { __typename?: 'Query' }
  & { getCodigoOperadoraTOTVS: Maybe<(
    { __typename?: 'IntegracaoTotvsCadastroOperadoras' }
    & { editValues: Maybe<(
      { __typename?: 'OperadorasTotvsDAO' }
      & Pick<OperadorasTotvsDao, 'codoperadora' | 'codpessoapai' | 'codexterno'>
    )> }
  )> }
);

export type GetDadoNFeQueryVariables = {
  values?: Maybe<DadosNfeDaoInput>
};


export type GetDadoNFeQuery = (
  { __typename?: 'Query' }
  & { getDadoNFe: Maybe<(
    { __typename?: 'DadosNfeCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'DadosNfeDAO' }
      & Pick<DadosNfeDao, 'codpessoa' | 'senhacertificado' | 'email' | 'senhaemail' | 'smtp' | 'porta' | 'naturezaoperacao' | 'regimeespecialtributacao' | 'opatantesimplesnacional' | 'incentivadorcultural' | 'pis' | 'cofins' | 'ir' | 'csll' | 'iss' | 'codcidade' | 'cnae' | 'valorminimocalculoir' | 'valorminimocalculopiscofinscsll' | 'subitem' | 'codigotributacaomunicipio' | 'descservico' | 'desccnae' | 'descsubitem' | 'codpessoaemissao'>
    )> }
  )> }
);

export type GetDashBoardQueryVariables = {
  values?: Maybe<DashBoardInput>
};


export type GetDashBoardQuery = (
  { __typename?: 'Query' }
  & { getDashBoard: Maybe<(
    { __typename?: 'DashBoardGraph' }
    & Pick<DashBoardGraph, 'codpessoapai' | 'categoriaVendaHora' | 'seriesVendaHora' | 'categoriaTaxaMedia' | 'categoriaVendaDia' | 'seriesVendaDia' | 'mensagem'>
    & { seriesVendaOperadora: Maybe<Array<Maybe<(
      { __typename?: 'VendaOperadora' }
      & Pick<VendaOperadora, 'name' | 'y' | 'sliced' | 'selected'>
    )>>>, seriesVendaTipo: Maybe<Array<Maybe<(
      { __typename?: 'VendaOperadora' }
      & Pick<VendaOperadora, 'name' | 'y' | 'sliced' | 'selected'>
    )>>>, seriesTaxaMedia: Maybe<Array<Maybe<(
      { __typename?: 'VendaOperadora' }
      & Pick<VendaOperadora, 'name' | 'y' | 'sliced' | 'selected' | 'data'>
    )>>>, seriesTicketMedio: Maybe<Array<Maybe<(
      { __typename?: 'VendaOperadora' }
      & Pick<VendaOperadora, 'name' | 'y' | 'sliced' | 'selected'>
    )>>> }
  )> }
);

export type GetInfoNotaQueryVariables = {
  values?: Maybe<FaturamentoMovimentoBeanInput>
};


export type GetInfoNotaQuery = (
  { __typename?: 'Query' }
  & { getInfoNota: Maybe<(
    { __typename?: 'FaturamentoMovimentoDAO' }
    & Pick<FaturamentoMovimentoDao, 'status' | 'chave' | 'numeronota' | 'protocolo' | 'mensagem' | 'reenviaremail' | 'reenviarnota'>
  )> }
);

export type GetInfoUsuarrioTrocarSenhaQueryVariables = {
  param?: Maybe<Scalars['String']>
};


export type GetInfoUsuarrioTrocarSenhaQuery = (
  { __typename?: 'Query' }
  & { getInfoUsuarrioTrocarSenha: Maybe<(
    { __typename?: 'UsuarioTrocaSenha' }
    & Pick<UsuarioTrocaSenha, 'senhaDefinida' | 'expirado' | 'mensagemSolicitaSenha' | 'goLogin'>
  )> }
);

export type GetInformacoesPosicaoContratoQueryVariables = {
  codPessoa?: Maybe<Scalars['String']>,
  codExterno?: Maybe<Scalars['String']>,
  operadora?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>
};


export type GetInformacoesPosicaoContratoQuery = (
  { __typename?: 'Query' }
  & { getInformacoesPosicaoContrato: Maybe<Array<Maybe<(
    { __typename?: 'ResultadoStatus' }
    & Pick<ResultadoStatus, 'codExterno' | 'operadora' | 'status' | 'data' | 'passou' | 'observacao' | 'revenda' | 'subrevenda' | 'cnpj' | 'nome'>
  )>>> }
);

export type GetIntegracaoAjusteQueryVariables = {
  values?: Maybe<OrigemAjustePersonalizadoDaoInput>
};


export type GetIntegracaoAjusteQuery = (
  { __typename?: 'Query' }
  & { getIntegracaoAjuste: Maybe<(
    { __typename?: 'IntegracaoConciliaAjusteCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'OrigemAjustePersonalizadoDAO' }
      & Pick<OrigemAjustePersonalizadoDao, 'codorigemajuste' | 'codpessoapai' | 'codexterno'>
    )> }
  )> }
);

export type GetIntegracaoSoftExpressCadastroQueryVariables = {
  values?: Maybe<ConciliadoresIntegSoftExpressDaoInput>
};


export type GetIntegracaoSoftExpressCadastroQuery = (
  { __typename?: 'Query' }
  & { getIntegracaoSoftExpressCadastro: Maybe<(
    { __typename?: 'IntegracaoSoftExpressCadastro' }
    & { editValues: Maybe<(
      { __typename?: 'ConciliadoresIntegSoftExpressDAO' }
      & Pick<ConciliadoresIntegSoftExpressDao, 'codpessoa' | 'codexterno'>
    )> }
  )> }
);

export type GetItensProdutosByOperadoraQueryVariables = {
  codoperadora?: Maybe<Scalars['Int']>,
  codplanosconciliadores?: Maybe<Scalars['Int']>
};


export type GetItensProdutosByOperadoraQuery = (
  { __typename?: 'Query' }
  & { getItensProdutosByOperadora: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type GetItensProdutosByOperadoraPosQueryVariables = {
  codoperadora?: Maybe<Scalars['Int']>,
  codplano?: Maybe<Scalars['Int']>
};


export type GetItensProdutosByOperadoraPosQuery = (
  { __typename?: 'Query' }
  & { getItensProdutosByOperadoraPos: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type GetItensUnidadesPlanosQueryVariables = {
  codplanos?: Maybe<Scalars['Int']>
};


export type GetItensUnidadesPlanosQuery = (
  { __typename?: 'Query' }
  & { getItensUnidadesPlanos: Maybe<Array<Maybe<(
    { __typename?: 'LabelValue' }
    & Pick<LabelValue, 'label' | 'value'>
  )>>> }
);

export type GetItensUnidadesPlanosConciliadoresQueryVariables = {
  codplanosconciliadores?: Maybe<Scalars['Int']>
};


export type GetItensUnidadesPlanosConciliadoresQuery = (
  { __typename?: 'Query' }
  & { getItensUnidadesPlanosConciliadores: Maybe<Array<Maybe<(
    { __typename?: 'LabelValue' }
    & Pick<LabelValue, 'label' | 'value'>
  )>>> }
);

export type GetListOfResourcesGraphqlQueryVariables = {};


export type GetListOfResourcesGraphqlQuery = (
  { __typename?: 'Query' }
  & { getListOfResourcesGraphql: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type GetListarTaxasNaoCadastradasQueryVariables = {};


export type GetListarTaxasNaoCadastradasQuery = (
  { __typename?: 'Query' }
  & { listarTaxasNaoCadastradas: Maybe<Array<Maybe<(
    { __typename?: 'TaxasNaoCadastradasBean' }
    & Pick<TaxasNaoCadastradasBean, 'produto' | 'nomepessoa' | 'codexterno' | 'operadora' | 'vendade' | 'vendaate' | 'parcelasde' | 'parcelasate'>
  )>>> }
);

export type GetListarUsuariosExpiradosQueryVariables = {};


export type GetListarUsuariosExpiradosQuery = (
  { __typename?: 'Query' }
  & { listarUsuariosExpirados: Maybe<Array<Maybe<(
    { __typename?: 'UsuarioDAO' }
    & Pick<UsuarioDao, 'codpessoa' | 'login' | 'validade'>
  )>>> }
);

export type GetListaUnidadesNaoEncontradasQueryVariables = {};


export type GetListaUnidadesNaoEncontradasQuery = (
  { __typename?: 'Query' }
  & { listarUnidadesNaoEncontradasExibicao: Maybe<Array<Maybe<(
    { __typename?: 'UnidadesNaoEncontradasDAO' }
    & Pick<UnidadesNaoEncontradasDao, 'codpessoapai' | 'operadora' | 'estabelecimento' | 'cnpj' | 'operadoraRoteada' | 'nome'>
  )>>> }
);

export type GetLoginSenhaAtendimentoQueryVariables = {};


export type GetLoginSenhaAtendimentoQuery = (
  { __typename?: 'Query' }
  & { getLoginSenhaAtendimento: Maybe<(
    { __typename?: 'LoginSenha' }
    & Pick<LoginSenha, 'login' | 'senha'>
  )> }
);

export type GetMenusQueryVariables = {};


export type GetMenusQuery = (
  { __typename?: 'Query' }
  & { getMenus: Maybe<Array<Maybe<(
    { __typename?: 'Menu' }
    & Pick<Menu, 'id' | 'label' | 'page' | 'pai' | 'icon'>
  )>>> }
);

export type GetNoticiasQueryVariables = {};


export type GetNoticiasQuery = (
  { __typename?: 'Query' }
  & { getNoticias: Maybe<Array<Maybe<(
    { __typename?: 'NoticiasDAO' }
    & Pick<NoticiasDao, 'codnoticia' | 'titulo' | 'texto'>
  )>>> }
);

export type GetPainelCadastroQueryVariables = {};


export type GetPainelCadastroQuery = (
  { __typename?: 'Query' }
  & { getPainelCadastro: Maybe<Array<Maybe<(
    { __typename?: 'OperadoraStatus' }
    & Pick<OperadoraStatus, 'arquivoImagemPOS' | 'arquivoImagemTEF' | 'downlaodTermoAdesaoCss' | 'downlaodAutorizacaoCss'>
    & { operadora: Maybe<(
      { __typename?: 'OperadorasDAO' }
      & Pick<OperadorasDao, 'codoperadora' | 'nome' | 'formacalculo' | 'consinco' | 'estabelecimentoCadastroTaxa'>
    )>, statusEC: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'descricao'>
    )>, statusTaxa: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'descricao'>
    )>, statusTermo: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'descricao'>
    )>, statusCarteira: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'descricao'>
    )> }
  )>>> }
);

export type GetParametroTotvsQueryVariables = {
  values?: Maybe<ParametrosConciliadorTotvsDaoInput>
};


export type GetParametroTotvsQuery = (
  { __typename?: 'Query' }
  & { getParametroTOTVS: Maybe<(
    { __typename?: 'IntegracaoTotvsCadastroParametrosConciliador' }
    & { editValues: Maybe<(
      { __typename?: 'ParametrosConciliadorTotvsDAO' }
      & Pick<ParametrosConciliadorTotvsDao, 'codpessoa' | 'codfilial' | 'opcional1' | 'opcional2' | 'coddepartamento' | 'codcentrocusto' | 'tipocontabil' | 'codmoeda' | 'codcontacaixa' | 'statuslancamento' | 'codcoligadaclifor' | 'codcoligadacontacaixa' | 'tiposacado' | 'codevento'>
    )> }
  )> }
);

export type GetPerfilQueryVariables = {
  values?: Maybe<PerfilDaoInput>
};


export type GetPerfilQuery = (
  { __typename?: 'Query' }
  & { getPerfil: Maybe<(
    { __typename?: 'PerfilCRUD' }
    & { recursos: Maybe<Array<Maybe<(
      { __typename?: 'RecursoDAO' }
      & Pick<RecursoDao, 'nometela' | 'recurso' | 'rccodigo'>
    )>>>, editValues: Maybe<(
      { __typename?: 'PerfilDAO' }
      & Pick<PerfilDao, 'nome'>
    )> }
  )> }
);

export type GetPlanosConciliadoresQueryVariables = {
  values?: Maybe<PlanosConciliadoresDaoInput>
};


export type GetPlanosConciliadoresQuery = (
  { __typename?: 'Query' }
  & { getPlanosConciliadores: Maybe<(
    { __typename?: 'PlanosConciliadoresCRUD' }
    & { editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresDAO' }
      & Pick<PlanosConciliadoresDao, 'codplanosconciliadores' | 'nome' | 'codpessoapai' | 'tarifacao'>
    )> }
  )> }
);

export type GetPlanosConciliadoresContratoQueryVariables = {
  values?: Maybe<PlanosConciliadoresContratoDaoInput>
};


export type GetPlanosConciliadoresContratoQuery = (
  { __typename?: 'Query' }
  & { getPlanosConciliadoresContrato: Maybe<(
    { __typename?: 'PlanosConciliadoresContratoCRUD' }
    & Pick<PlanosConciliadoresContratoCrud, 'listConciOperadora'>
    & { listConciOperadoraGeral: Maybe<Array<Maybe<(
      { __typename?: 'LabelValue' }
      & Pick<LabelValue, 'label' | 'value'>
    )>>>, editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresContratoDAO' }
      & Pick<PlanosConciliadoresContratoDao, 'codplanosconciliadorescontrato' | 'seq' | 'codplanosconciliadores' | 'codpessoa' | 'codoperadora' | 'codexterno' | 'vigenciaDe' | 'vigenciaAte' | 'codpessoapai'>
    )> }
  )> }
);

export type GetPlanosConciliadoresTaxasQueryVariables = {
  values?: Maybe<PlanosConciliadoresTaxasDaoInput>
};


export type GetPlanosConciliadoresTaxasQuery = (
  { __typename?: 'Query' }
  & { getPlanosConciliadoresTaxas: Maybe<(
    { __typename?: 'PlanosConciliadoresTaxasCRUD' }
    & Pick<PlanosConciliadoresTaxasCrud, 'mensagem' | 'mensagemBndes' | 'codoperadora' | 'planoantigo' | 'planonovo' | 'taxabndes' | 'tarifacao' | 'nomePlano' | 'taxaCredito' | 'taxaCredito0a6' | 'taxaCredito7a12' | 'taxaDebito' | 'taxaTicket' | 'mensagemErro' | 'converterProdutoTaxas'>
    & { editValues: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'codplanosconciliadorestaxas' | 'codplanosconciliadores' | 'codprodutos' | 'bincartao'>
    )>, taxas: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa' | 'valorde' | 'valorate' | 'tipotaxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa' | 'valorde' | 'valorate' | 'codplanosconciliadorestaxas' | 'tipotaxa'>
    )>>> }
  )> }
);

export type GetPlanosPosQueryVariables = {
  values?: Maybe<PlanosDaoInput>
};


export type GetPlanosPosQuery = (
  { __typename?: 'Query' }
  & { getPlanosPOS: Maybe<(
    { __typename?: 'PlanosCRUD' }
    & Pick<PlanosCrud, 'mensalidade'>
    & { editValues: Maybe<(
      { __typename?: 'PlanosDAO' }
      & Pick<PlanosDao, 'codplano' | 'nome' | 'codpessoapai' | 'cobramensalidade' | 'valormensalidade' | 'diacorte'>
    )> }
  )> }
);

export type GetPlanosPosTaxasQueryVariables = {
  values?: Maybe<PlanosTaxasDaoInput>
};


export type GetPlanosPosTaxasQuery = (
  { __typename?: 'Query' }
  & { getPlanosPOSTaxas: Maybe<(
    { __typename?: 'PlanosTaxasCRUD' }
    & Pick<PlanosTaxasCrud, 'mensagem' | 'codoperadora'>
    & { editValues: Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'codplanostaxas' | 'codplano' | 'codprodutos' | 'de' | 'ate' | 'taxa'>
    )>, taxas: Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanostaxas'>
    )>>> }
  )> }
);

export type GetPlanosTerminalQueryVariables = {
  values?: Maybe<PlanosTerminalDaoInput>
};


export type GetPlanosTerminalQuery = (
  { __typename?: 'Query' }
  & { getPlanosTerminal: Maybe<(
    { __typename?: 'PlanosTerminalCRUD' }
    & Pick<PlanosTerminalCrud, 'listTerminal'>
    & { editValues: Maybe<(
      { __typename?: 'PlanosTerminalDAO' }
      & Pick<PlanosTerminalDao, 'codplanosterminal' | 'seq' | 'codplanos' | 'codterminal' | 'vigenciaDe' | 'vigenciaAte' | 'codpessoapai'>
    )> }
  )> }
);

export type GetPosicaoContratosPorClientesQueryVariables = {
  values: PosicaoContratosPorClientesInput
};


export type GetPosicaoContratosPorClientesQuery = (
  { __typename?: 'Query' }
  & { getPosicaoContratosPorClientes: Maybe<Array<Maybe<(
    { __typename?: 'DataPosicaoContrato' }
    & Pick<DataPosicaoContrato, 'id' | 'parentId' | 'description' | 'identifier' | 'leaf' | 'type' | 'codpessoa' | 'nome' | 'status' | 'origem' | 'ultimoStatus' | 'tipo' | 'operadora' | 'codexterno' | 'edita' | 'codPessoaPai' | 'selecionado' | 'dataRecebimentoPrimeiroArquivo' | 'childCount'>
  )>>> }
);

export type GetPrepareCadastroTaxaQueryVariables = {
  values?: Maybe<PrepareCadastroTaxaInput>
};


export type GetPrepareCadastroTaxaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPrepareCadastroTaxa'>
);

export type GetRelatorioFechaMovimentoPrevisaoQueryVariables = {
  filtro?: Maybe<BeanFiltroRelatorioTelaInput>,
  dias?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type GetRelatorioFechaMovimentoPrevisaoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRelatorioFechaMovimentoPrevisao'>
);

export type GetTaxasAntecipacaoQueryVariables = {
  values?: Maybe<TaxaAntecipacaoDaoInput>
};


export type GetTaxasAntecipacaoQuery = (
  { __typename?: 'Query' }
  & { getTaxasAntecipacao: Maybe<(
    { __typename?: 'TaxasAntecipacaoCRUD' }
    & Pick<TaxasAntecipacaoCrud, 'listConciOperadora' | 'codoperadora'>
    & { editValues: Maybe<(
      { __typename?: 'TaxaAntecipacaoDAO' }
      & Pick<TaxaAntecipacaoDao, 'codtaxaantecipacao' | 'nome' | 'taxa' | 'vigenciade' | 'vigenciaate'>
    )> }
  )> }
);

export type GetTefsQueryVariables = {
  codpessoa?: Maybe<Scalars['Int']>
};


export type GetTefsQuery = (
  { __typename?: 'Query' }
  & { getTefs: Maybe<Array<Maybe<(
    { __typename?: 'UsuarioMestreTefsBean' }
    & Pick<UsuarioMestreTefsBean, 'codTef' | 'nomeTef'>
  )>>> }
);

export type GetTerminalQueryVariables = {
  values?: Maybe<TerminalDaoInput>
};


export type GetTerminalQuery = (
  { __typename?: 'Query' }
  & { getTerminal: Maybe<(
    { __typename?: 'TerminalCRUD' }
    & Pick<TerminalCrud, 'ativo' | 'datainiciocobranca' | 'concioperadora'>
    & { editValues: Maybe<(
      { __typename?: 'TerminalDAO' }
      & Pick<TerminalDao, 'codexterno' | 'nome' | 'codpessoacliente' | 'vinculo' | 'status'>
    )> }
  )> }
);

export type GetUrlPainelQueryVariables = {};


export type GetUrlPainelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUrlPainel'>
);

export type GetUserInfoQueryVariables = {};


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { getUserInfo: Maybe<(
    { __typename?: 'InfoUserGraphQL' }
    & Pick<InfoUserGraphQl, 'nomeAbreviado' | 'uscodigo' | 'logoMarcarRevenda' | 'simulado' | 'fullName' | 'codPessoa' | 'codPessoaPai' | 'codPerfil'>
  )> }
);

export type GetUsuarioQueryVariables = {
  values?: Maybe<UsuarioDaoInput>
};


export type GetUsuarioQuery = (
  { __typename?: 'Query' }
  & { getUsuario: Maybe<(
    { __typename?: 'UsuarioCRUD' }
    & { pessoa: Maybe<(
      { __typename?: 'PessoaGenerica' }
      & { pessoa: Maybe<(
        { __typename?: 'PessoaDAO' }
        & Pick<PessoaDao, 'codpessoa' | 'nome' | 'estado' | 'cidade' | 'bairro' | 'endereco' | 'complemento' | 'numero' | 'telefone' | 'email' | 'status' | 'cep' | 'tipo'>
      )>, pessoaF: Maybe<(
        { __typename?: 'PessoaFisicaDAO' }
        & Pick<PessoaFisicaDao, 'cpf'>
      )> }
    )>, editValues: Maybe<(
      { __typename?: 'UsuariosDAO' }
      & Pick<UsuariosDao, 'login' | 'senha' | 'validade' | 'codperfil' | 'responsavel'>
    )> }
  )> }
);

export type GetUsuariosMestresQueryVariables = {};


export type GetUsuariosMestresQuery = (
  { __typename?: 'Query' }
  & { getUsuariosMestres: Maybe<Array<Maybe<(
    { __typename?: 'UsuarioDAOList' }
    & Pick<UsuarioDaoList, 'codpessoa' | 'login' | 'nome' | 'validade' | 'piloto' | 'codperfil' | 'codpessoapai'>
  )>>> }
);

export type GetUsuariosMestresRevendaQueryVariables = {};


export type GetUsuariosMestresRevendaQuery = (
  { __typename?: 'Query' }
  & { getUsuariosMestresRevenda: Maybe<Array<Maybe<(
    { __typename?: 'UsuarioDAOList' }
    & Pick<UsuarioDaoList, 'codpessoa' | 'login' | 'nome' | 'validade' | 'piloto' | 'codperfil' | 'codpessoapai'>
  )>>> }
);

export type ListaClientesQueryVariables = {};


export type ListaClientesQuery = (
  { __typename?: 'Query' }
  & { listaClientes: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListaOperadorasTaxasAntecipaxaoQueryVariables = {
  codOperadora?: Maybe<Scalars['Int']>
};


export type ListaOperadorasTaxasAntecipaxaoQuery = (
  { __typename?: 'Query' }
  & { listaOperadorasTaxasAntecipaxao: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListaOrigensAjustesQueryVariables = {};


export type ListaOrigensAjustesQuery = (
  { __typename?: 'Query' }
  & { listaOrigensAjustes: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListaUnidadesConciliadorasQueryVariables = {};


export type ListaUnidadesConciliadorasQuery = (
  { __typename?: 'Query' }
  & { listaUnidadesConciliadoras: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarAllTefsDaoQueryVariables = {};


export type ListarAllTefsDaoQuery = (
  { __typename?: 'Query' }
  & { listarAllTefsDAO: Maybe<Array<Maybe<(
    { __typename?: 'TefsDAO' }
    & Pick<TefsDao, 'codtefs' | 'nome' | 'obs'>
  )>>> }
);

export type ListarBandeirasQueryVariables = {
  codOperadora?: Maybe<Scalars['Int']>
};


export type ListarBandeirasQuery = (
  { __typename?: 'Query' }
  & { listarBandeiras: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarBucketS3AmazonQueryVariables = {
  values?: Maybe<BucketS3AmazonDaoInput>
};


export type ListarBucketS3AmazonQuery = (
  { __typename?: 'Query' }
  & { listarBucketS3Amazon: Maybe<Array<Maybe<(
    { __typename?: 'BucketS3AmazonDAO' }
    & Pick<BucketS3AmazonDao, 'codbuckets3amazon' | 'codpessoapai' | 'keyid' | 'senha' | 'nome' | 'pasta' | 'bucketname'>
  )>>> }
);

export type ListarCidadesQueryVariables = {};


export type ListarCidadesQuery = (
  { __typename?: 'Query' }
  & { listarCidades: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarCodigoOperadoraTotvsQueryVariables = {};


export type ListarCodigoOperadoraTotvsQuery = (
  { __typename?: 'Query' }
  & { listarCodigoOperadoraTOTVS: Maybe<Array<Maybe<(
    { __typename?: 'OperadorasTotvsDAO' }
    & Pick<OperadorasTotvsDao, 'codoperadora' | 'codpessoapai' | 'codexterno' | 'nome'>
  )>>> }
);

export type ListarConciliacaoEmAbertoQueryVariables = {
  values?: Maybe<ManutencaoArquivosConiliacaoAbertoInput>
};


export type ListarConciliacaoEmAbertoQuery = (
  { __typename?: 'Query' }
  & { listarConciliacaoEmAberto: Maybe<Array<Maybe<(
    { __typename?: 'ArquivosrecebidosDAO' }
    & Pick<ArquivosrecebidosDao, 'codarquivo' | 'titulo' | 'dataenvio' | 'datarecebimento' | 'dataprocessamento' | 'tipo' | 'status' | 'nomearquivo' | 'mensagem'>
  )>>> }
);

export type ListarContaCorrenteQueryVariables = {};


export type ListarContaCorrenteQuery = (
  { __typename?: 'Query' }
  & { listarContaCorrente: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarEnderecosAdicionaisQueryVariables = {};


export type ListarEnderecosAdicionaisQuery = (
  { __typename?: 'Query' }
  & { listarEnderecosAdicionais: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarEstabelecimentosQueryVariables = {
  values?: Maybe<ListarEstabelecimentosInput>
};


export type ListarEstabelecimentosQuery = (
  { __typename?: 'Query' }
  & { listarEstabelecimentos: Maybe<Array<Maybe<(
    { __typename?: 'ConciliadorOperadora' }
    & Pick<ConciliadorOperadora, 'display'>
    & { conciOperadora: Maybe<(
      { __typename?: 'ConciOperadoraDAO' }
      & Pick<ConciOperadoraDao, 'codexterno'>
    )>, conciliadores: Maybe<(
      { __typename?: 'ConciliadoresDAO' }
      & Pick<ConciliadoresDao, 'apelido' | 'codpessoa'>
    )>, pessoa: Maybe<(
      { __typename?: 'PessoaGenerica' }
      & { pessoaJ: Maybe<(
        { __typename?: 'PessoaJuridicaDAO' }
        & Pick<PessoaJuridicaDao, 'cnpj'>
      )>, pessoa: Maybe<(
        { __typename?: 'PessoaDAO' }
        & Pick<PessoaDao, 'nome' | 'codpessoa'>
      )> }
    )> }
  )>>> }
);

export type ListarEstadosQueryVariables = {};


export type ListarEstadosQuery = (
  { __typename?: 'Query' }
  & { listarEstados: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarExtratosOriginaisQueryVariables = {
  values?: Maybe<ManutencaoArquivosInput>
};


export type ListarExtratosOriginaisQuery = (
  { __typename?: 'Query' }
  & { listarExtratosOriginais: Maybe<Array<Maybe<(
    { __typename?: 'ArquivosrecebidosDAO' }
    & Pick<ArquivosrecebidosDao, 'codarquivo' | 'dataenvio' | 'datarecebimento' | 'tipo' | 'nomearquivo'>
  )>>> }
);

export type ListarFaturamentoMovimentoQueryVariables = {
  values?: Maybe<FaturamentoMovimentoInput>
};


export type ListarFaturamentoMovimentoQuery = (
  { __typename?: 'Query' }
  & { listarFaturamentoMovimento: Maybe<Array<Maybe<(
    { __typename?: 'FaturamentoMovimentoBean' }
    & Pick<FaturamentoMovimentoBean, 'datainclusao' | 'nome' | 'filtrode' | 'filtroate' | 'valorbruto' | 'valorliquidoadm' | 'valorliquidoterminal' | 'creditos' | 'debitos' | 'apurado' | 'dataprocessado' | 'chave'>
  )>>> }
);

export type ListarIntegracaoAjusteQueryVariables = {};


export type ListarIntegracaoAjusteQuery = (
  { __typename?: 'Query' }
  & { listarIntegracaoAjuste: Maybe<Array<Maybe<(
    { __typename?: 'OrigemAjustePersonalizadoDAO' }
    & Pick<OrigemAjustePersonalizadoDao, 'codorigemajuste' | 'codpessoapai' | 'codexterno' | 'ajuste'>
  )>>> }
);

export type ListarIntegracaoSoftExpressCadastroQueryVariables = {};


export type ListarIntegracaoSoftExpressCadastroQuery = (
  { __typename?: 'Query' }
  & { listarIntegracaoSoftExpressCadastro: Maybe<Array<Maybe<(
    { __typename?: 'ConciliadoresIntegSoftExpressDAO' }
    & Pick<ConciliadoresIntegSoftExpressDao, 'codpessoa' | 'codexterno' | 'nome'>
  )>>> }
);

export type ListarItensBucketS3AmazonQueryVariables = {};


export type ListarItensBucketS3AmazonQuery = (
  { __typename?: 'Query' }
  & { listarItensBucketS3Amazon: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensConciliadoresQueryVariables = {};


export type ListarItensConciliadoresQuery = (
  { __typename?: 'Query' }
  & { listarItensConciliadores: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensConciliadoresCodExternoQueryVariables = {
  codpessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type ListarItensConciliadoresCodExternoQuery = (
  { __typename?: 'Query' }
  & { listarItensConciliadoresCodExterno: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensEstabelecimentoBancarioQueryVariables = {};


export type ListarItensEstabelecimentoBancarioQuery = (
  { __typename?: 'Query' }
  & { listarItensEstabelecimentoBancario: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensMeioCapturaQueryVariables = {};


export type ListarItensMeioCapturaQuery = (
  { __typename?: 'Query' }
  & { listarItensMeioCaptura: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensOrigemAjusteQueryVariables = {};


export type ListarItensOrigemAjusteQuery = (
  { __typename?: 'Query' }
  & { listarItensOrigemAjuste: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensOrigemAjustePersonalizadoQueryVariables = {};


export type ListarItensOrigemAjustePersonalizadoQuery = (
  { __typename?: 'Query' }
  & { listarItensOrigemAjustePersonalizado: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensOrigemArquivoRecebidoQueryVariables = {};


export type ListarItensOrigemArquivoRecebidoQuery = (
  { __typename?: 'Query' }
  & { listarItensOrigemArquivoRecebido: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensPlanosQueryVariables = {};


export type ListarItensPlanosQuery = (
  { __typename?: 'Query' }
  & { listarItensPlanos: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensPlanosConciliadoresQueryVariables = {};


export type ListarItensPlanosConciliadoresQuery = (
  { __typename?: 'Query' }
  & { listarItensPlanosConciliadores: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensProdutosQueryVariables = {};


export type ListarItensProdutosQuery = (
  { __typename?: 'Query' }
  & { listarItensProdutos: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensRecorrenteQueryVariables = {};


export type ListarItensRecorrenteQuery = (
  { __typename?: 'Query' }
  & { listarItensRecorrente: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensStatusArquivoQueryVariables = {};


export type ListarItensStatusArquivoQuery = (
  { __typename?: 'Query' }
  & { listarItensStatusArquivo: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensStatusConciliacaoAntecipadosQueryVariables = {};


export type ListarItensStatusConciliacaoAntecipadosQuery = (
  { __typename?: 'Query' }
  & { listarItensStatusConciliacaoAntecipados: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensTerminalQueryVariables = {};


export type ListarItensTerminalQuery = (
  { __typename?: 'Query' }
  & { listarItensTerminal: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensTerminalVinculoQueryVariables = {};


export type ListarItensTerminalVinculoQuery = (
  { __typename?: 'Query' }
  & { listarItensTerminalVinculo: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensTipoArquivoQueryVariables = {};


export type ListarItensTipoArquivoQuery = (
  { __typename?: 'Query' }
  & { listarItensTipoArquivo: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensUnidadesConciliadorasQueryVariables = {};


export type ListarItensUnidadesConciliadorasQuery = (
  { __typename?: 'Query' }
  & { listarItensUnidadesConciliadoras: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables = {
  codPessoa?: Maybe<Scalars['Int']>,
  codOperadora?: Maybe<Scalars['Int']>
};


export type ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery = (
  { __typename?: 'Query' }
  & { listarItensUnidadesConciliadorasFromCodPessoaCodOperadora: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarManutencaoArquivosQueryVariables = {
  values?: Maybe<ManutencaoArquivosInput>
};


export type ListarManutencaoArquivosQuery = (
  { __typename?: 'Query' }
  & { listarManutencaoArquivos: Maybe<Array<Maybe<(
    { __typename?: 'ArquivosrecebidosDAO' }
    & Pick<ArquivosrecebidosDao, 'codarquivo' | 'dataenvio' | 'datarecebimento' | 'dataprocessamento' | 'status' | 'tipo' | 'nomearquivo' | 'mensagem'>
  )>>> }
);

export type ListarMovimentosFechadosQueryVariables = {
  values?: Maybe<FaturamentoMovimentoInput>
};


export type ListarMovimentosFechadosQuery = (
  { __typename?: 'Query' }
  & { listarMovimentosFechados: Maybe<Array<Maybe<(
    { __typename?: 'FechaMovimentoReimpressaoBean' }
    & Pick<FechaMovimentoReimpressaoBean, 'datainclusao' | 'filtrode' | 'filtroate' | 'valorbruto' | 'valorliquido' | 'creditos' | 'debitos' | 'total'>
  )>>> }
);

export type ListarOfNamesTipoMeioCapturaSemVazioQueryVariables = {};


export type ListarOfNamesTipoMeioCapturaSemVazioQuery = (
  { __typename?: 'Query' }
  & { listarOfNamesTipoMeioCapturaSemVazio: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarOperadorasQueryVariables = {};


export type ListarOperadorasQuery = (
  { __typename?: 'Query' }
  & { listarOperadoras: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarParametrosQueryVariables = {};


export type ListarParametrosQuery = (
  { __typename?: 'Query' }
  & { listarParametros: Maybe<Array<Maybe<(
    { __typename?: 'ParametrosDAO' }
    & Pick<ParametrosDao, 'grupo' | 'item' | 'texto'>
  )>>> }
);

export type ListarParametrosTotvsQueryVariables = {};


export type ListarParametrosTotvsQuery = (
  { __typename?: 'Query' }
  & { listarParametrosTOTVS: Maybe<Array<Maybe<(
    { __typename?: 'ParametrosConciliadorTotvsDAO' }
    & Pick<ParametrosConciliadorTotvsDao, 'codpessoa' | 'codfilial' | 'opcional1' | 'opcional2' | 'coddepartamento' | 'codcentrocusto' | 'tipocontabil' | 'codmoeda' | 'codcontacaixa' | 'statuslancamento' | 'codcoligadaclifor' | 'codcoligadacontacaixa' | 'tiposacado' | 'codevento' | 'nome'>
  )>>> }
);

export type ListarPerfilQueryVariables = {};


export type ListarPerfilQuery = (
  { __typename?: 'Query' }
  & { listarPerfil: Maybe<Array<Maybe<(
    { __typename?: 'PerfilDAO' }
    & Pick<PerfilDao, 'codperfil' | 'nome'>
  )>>> }
);

export type ListarPerfisQueryVariables = {};


export type ListarPerfisQuery = (
  { __typename?: 'Query' }
  & { listarPerfis: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarPlanosConciliadoresQueryVariables = {
  values?: Maybe<PlanosConciliadoresDaoInput>
};


export type ListarPlanosConciliadoresQuery = (
  { __typename?: 'Query' }
  & { listarPlanosConciliadores: Maybe<Array<Maybe<(
    { __typename?: 'PlanosConciliadoresDAO' }
    & Pick<PlanosConciliadoresDao, 'codplanosconciliadores' | 'nome' | 'codpessoapai' | 'tarifacao'>
  )>>> }
);

export type ListarPlanosConciliadoresContratoQueryVariables = {
  values?: Maybe<PlanosConciliadoresContratoDaoInput>
};


export type ListarPlanosConciliadoresContratoQuery = (
  { __typename?: 'Query' }
  & { listarPlanosConciliadoresContrato: Maybe<Array<Maybe<(
    { __typename?: 'PlanosConciliadoresContrato' }
    & Pick<PlanosConciliadoresContrato, 'codplanosconciliadorescontrato' | 'codplanosconciliadores' | 'seq' | 'codoperadora' | 'codpessoa' | 'codexterno' | 'vigenciaDe' | 'vigenciaAte' | 'codpessoapai' | 'plano'>
  )>>> }
);

export type ListarPlanosConciliadoresTaxasQueryVariables = {
  values?: Maybe<PlanosConciliadoresTaxasDaoInput>
};


export type ListarPlanosConciliadoresTaxasQuery = (
  { __typename?: 'Query' }
  & { listarPlanosConciliadoresTaxas: Maybe<Array<Maybe<(
    { __typename?: 'PlanosConciliadoresTaxas' }
    & Pick<PlanosConciliadoresTaxas, 'codplanosconciliadorestaxas' | 'codplanosconciliadores' | 'plano' | 'codprodutos' | 'converterProduto' | 'duplicado' | 'converterProdutoTipo' | 'bincartao' | 'converterProdutoTaxas' | 'de' | 'ate' | 'valorde' | 'valorate' | 'taxa' | 'nomeOperadora'>
  )>>> }
);

export type ListarPlanosPosQueryVariables = {
  values?: Maybe<PlanosDaoInput>
};


export type ListarPlanosPosQuery = (
  { __typename?: 'Query' }
  & { listarPlanosPOS: Maybe<Array<Maybe<(
    { __typename?: 'PlanosDAO' }
    & Pick<PlanosDao, 'codplano' | 'nome' | 'codpessoapai' | 'cobramensalidade' | 'valormensalidade' | 'diacorte'>
  )>>> }
);

export type ListarPlanosPosTaxasQueryVariables = {
  values?: Maybe<PlanosTaxasDaoInput>
};


export type ListarPlanosPosTaxasQuery = (
  { __typename?: 'Query' }
  & { listarPlanosPOSTaxas: Maybe<Array<Maybe<(
    { __typename?: 'PlanosTerminalPosTaxas' }
    & Pick<PlanosTerminalPosTaxas, 'codplanostaxas' | 'codplano' | 'codprodutos' | 'de' | 'ate' | 'taxa' | 'plano' | 'converterProduto'>
  )>>> }
);

export type ListarPlanosTerminalQueryVariables = {
  values?: Maybe<PlanosTerminalDaoInput>
};


export type ListarPlanosTerminalQuery = (
  { __typename?: 'Query' }
  & { listarPlanosTerminal: Maybe<Array<Maybe<(
    { __typename?: 'PlanosTerminal' }
    & Pick<PlanosTerminal, 'codplanosterminal' | 'seq' | 'codplanos' | 'codterminal' | 'vigenciaDe' | 'vigenciaAte' | 'codpessoapai' | 'plano'>
  )>>> }
);

export type ListarStatusConciliacaoQueryVariables = {};


export type ListarStatusConciliacaoQuery = (
  { __typename?: 'Query' }
  & { listarStatusConciliacao: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarTaxasAntecipacaoQueryVariables = {
  values?: Maybe<TaxaAntecipacaoDaoInput>
};


export type ListarTaxasAntecipacaoQuery = (
  { __typename?: 'Query' }
  & { listarTaxasAntecipacao: Maybe<Array<Maybe<(
    { __typename?: 'TaxaAntecipacaoDAO' }
    & Pick<TaxaAntecipacaoDao, 'nome' | 'vigenciade' | 'vigenciaate' | 'taxa' | 'codtaxaantecipacao'>
  )>>> }
);

export type ListarTefsQueryVariables = {};


export type ListarTefsQuery = (
  { __typename?: 'Query' }
  & { listarTefs: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarTerminalQueryVariables = {
  values?: Maybe<TerminalDaoInput>
};


export type ListarTerminalQuery = (
  { __typename?: 'Query' }
  & { listarTerminal: Maybe<Array<Maybe<(
    { __typename?: 'TerminalDAO' }
    & Pick<TerminalDao, 'codexterno' | 'nome' | 'vinculo' | 'status' | 'codpessoapai'>
  )>>> }
);

export type ListarTicketsQueryVariables = {
  values?: Maybe<ImportacaoTicketInput>
};


export type ListarTicketsQuery = (
  { __typename?: 'Query' }
  & { listarTickets: Maybe<Array<Maybe<(
    { __typename?: 'TicketsImportacaoBean' }
    & Pick<TicketsImportacaoBean, 'datavenda' | 'totparcelas' | 'valor' | 'nsu' | 'tid' | 'autorizacao' | 'numterminal' | 'codexterno' | 'codpessoa' | 'codoperadora'>
  )>>> }
);

export type ListarUFsQueryVariables = {};


export type ListarUFsQuery = (
  { __typename?: 'Query' }
  & { listarUFs: Maybe<Array<Maybe<(
    { __typename?: 'LabelValor' }
    & Pick<LabelValor, 'label' | 'valor'>
  )>>> }
);

export type ListarUsuarioQueryVariables = {
  values?: Maybe<UsuarioDaoInput>
};


export type ListarUsuarioQuery = (
  { __typename?: 'Query' }
  & { listarUsuario: Maybe<Array<Maybe<(
    { __typename?: 'UsuarioDAOList' }
    & Pick<UsuarioDaoList, 'codpessoa' | 'login' | 'validade' | 'nome'>
  )>>> }
);

export type PesquisarTicketsQueryVariables = {
  values?: Maybe<TicketsBeanInput>
};


export type PesquisarTicketsQuery = (
  { __typename?: 'Query' }
  & { pesquisarTickets: Maybe<Array<Maybe<(
    { __typename?: 'TicketsBean' }
    & Pick<TicketsBean, 'nsu' | 'tid' | 'autorizacao' | 'codexterno' | 'datavenda' | 'tipo' | 'dataprocessamento' | 'codlinhasarqcli' | 'codoperadora' | 'codpessoa' | 'origem'>
  )>>> }
);

export type PrevisualizarNotaQueryVariables = {
  values?: Maybe<FaturamentoMovimentoBeanInput>
};


export type PrevisualizarNotaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'previsualizarNota'>
);

export type ResultadoAddTaxasQueryVariables = {
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  tipotaxa?: Maybe<Scalars['String']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type ResultadoAddTaxasQuery = (
  { __typename?: 'Query' }
  & { resultadoAddTaxas: Maybe<(
    { __typename?: 'ValidacaoTaxas' }
    & Pick<ValidacaoTaxas, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanosconciliadorestaxas' | 'tipotaxa'>
    )>>> }
  )> }
);

export type ResultadoAddTaxasBndesQueryVariables = {
  valorde?: Maybe<Scalars['Float']>,
  valorate?: Maybe<Scalars['Float']>,
  taxa?: Maybe<Scalars['Float']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type ResultadoAddTaxasBndesQuery = (
  { __typename?: 'Query' }
  & { resultadoAddTaxasBndes: Maybe<(
    { __typename?: 'ValidacaoTaxas' }
    & Pick<ValidacaoTaxas, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'valorde' | 'valorate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'valorde' | 'valorate' | 'taxa' | 'codplanosconciliadorestaxas'>
    )>>> }
  )> }
);

export type ResultadoAddTaxasPosQueryVariables = {
  de?: Maybe<Scalars['Int']>,
  ate?: Maybe<Scalars['Int']>,
  taxa?: Maybe<Scalars['Float']>,
  insert?: Maybe<Scalars['Boolean']>
};


export type ResultadoAddTaxasPosQuery = (
  { __typename?: 'Query' }
  & { resultadoAddTaxasPos: Maybe<(
    { __typename?: 'ValidacaoTaxasPos' }
    & Pick<ValidacaoTaxasPos, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanostaxas'>
    )>>> }
  )> }
);

export type ResultadoAtualizaTaxasQueryVariables = {
  codtaxa?: Maybe<Scalars['Int']>
};


export type ResultadoAtualizaTaxasQuery = (
  { __typename?: 'Query' }
  & { resultadoAtualizaTaxas: Maybe<(
    { __typename?: 'ValidacaoTaxas' }
    & Pick<ValidacaoTaxas, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanosconciliadorestaxas'>
    )>>> }
  )> }
);

export type ResultadoSubTaxasQueryVariables = {
  codtaxa?: Maybe<Scalars['Int']>,
  tarifacao?: Maybe<Scalars['String']>
};


export type ResultadoSubTaxasQuery = (
  { __typename?: 'Query' }
  & { resultadoSubTaxas: Maybe<(
    { __typename?: 'ValidacaoTaxas' }
    & Pick<ValidacaoTaxas, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosConciliadoresTaxasDAO' }
      & Pick<PlanosConciliadoresTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanosconciliadorestaxas'>
    )>>> }
  )> }
);

export type ResultadoSubTaxasPosQueryVariables = {
  codtaxa?: Maybe<Scalars['Int']>
};


export type ResultadoSubTaxasPosQuery = (
  { __typename?: 'Query' }
  & { resultadoSubTaxasPos: Maybe<(
    { __typename?: 'ValidacaoTaxasPos' }
    & Pick<ValidacaoTaxasPos, 'mensagem'>
    & { taxas: Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa'>
    )>, listDeTaxas: Maybe<Array<Maybe<(
      { __typename?: 'PlanosTaxasDAO' }
      & Pick<PlanosTaxasDao, 'de' | 'ate' | 'taxa' | 'codplanostaxas'>
    )>>> }
  )> }
);

export const ClientesFragmentDoc = gql`
    fragment clientes on ClientesLinhaBean {
  nomearquivo
  statusArquivo
  datarecebimento
  dataprevistapagamento
  datatransacao
  valorbruto
  numparcela
  qtdparcelas
  nossonumero
  bandeira
  nsu
  numeroautorizacao
  tid
  codarquivo
  tipo
}
    `;
export const OperadorasFragmentDoc = gql`
    fragment operadoras on OperadoraLinhaBean {
  nomearquivo
  statusArquivo
  datarecebimento
  datapagamento
  datavenda
  valorbruto
  valorliquidopago
  outroscreditos
  outrosdebitos
  parcela
  totparcelas
  status
  meiocaptura
  produto
  bandeira
  numterminal
  nsu
  autorizacao
  tid
  codarquivo
  tipo
}
    `;
export const GetErrorsDocument = gql`
    query getErrors {
  getErrors @client {
    mensagemErro
    openSnackMessage
  }
}
    `;
export type GetErrorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetErrorsQuery, GetErrorsQueryVariables>, 'query'>;

    export const GetErrorsComponent = (props: GetErrorsComponentProps) => (
      <ApolloReactComponents.Query<GetErrorsQuery, GetErrorsQueryVariables> query={GetErrorsDocument} {...props} />
    );
    
export type GetErrorsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetErrorsQuery, GetErrorsQueryVariables> & TChildProps;
export function withGetErrors<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetErrorsQuery,
  GetErrorsQueryVariables,
  GetErrorsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetErrorsQuery, GetErrorsQueryVariables, GetErrorsProps<TChildProps>>(GetErrorsDocument, {
      alias: 'getErrors',
      ...operationOptions
    });
};
export type GetErrorsQueryResult = ApolloReactCommon.QueryResult<GetErrorsQuery, GetErrorsQueryVariables>;
export const GetFormularioPlanosOpcoesPlanosConciliadoresDocument = gql`
    query getFormularioPlanosOpcoesPlanosConciliadores {
  getFormularioPlanosOpcoesPlanosConciliadores @client {
    nomeFormulario
  }
}
    `;
export type GetFormularioPlanosOpcoesPlanosConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFormularioPlanosOpcoesPlanosConciliadoresQuery, GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables>, 'query'>;

    export const GetFormularioPlanosOpcoesPlanosConciliadoresComponent = (props: GetFormularioPlanosOpcoesPlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<GetFormularioPlanosOpcoesPlanosConciliadoresQuery, GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables> query={GetFormularioPlanosOpcoesPlanosConciliadoresDocument} {...props} />
    );
    
export type GetFormularioPlanosOpcoesPlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetFormularioPlanosOpcoesPlanosConciliadoresQuery, GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables> & TChildProps;
export function withGetFormularioPlanosOpcoesPlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetFormularioPlanosOpcoesPlanosConciliadoresQuery,
  GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables,
  GetFormularioPlanosOpcoesPlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetFormularioPlanosOpcoesPlanosConciliadoresQuery, GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables, GetFormularioPlanosOpcoesPlanosConciliadoresProps<TChildProps>>(GetFormularioPlanosOpcoesPlanosConciliadoresDocument, {
      alias: 'getFormularioPlanosOpcoesPlanosConciliadores',
      ...operationOptions
    });
};
export type GetFormularioPlanosOpcoesPlanosConciliadoresQueryResult = ApolloReactCommon.QueryResult<GetFormularioPlanosOpcoesPlanosConciliadoresQuery, GetFormularioPlanosOpcoesPlanosConciliadoresQueryVariables>;
export const GetIntegracaoDocument = gql`
    query getIntegracao {
  getIntegracao @client {
    label
    pais
    page
  }
}
    `;
export type GetIntegracaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIntegracaoQuery, GetIntegracaoQueryVariables>, 'query'>;

    export const GetIntegracaoComponent = (props: GetIntegracaoComponentProps) => (
      <ApolloReactComponents.Query<GetIntegracaoQuery, GetIntegracaoQueryVariables> query={GetIntegracaoDocument} {...props} />
    );
    
export type GetIntegracaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetIntegracaoQuery, GetIntegracaoQueryVariables> & TChildProps;
export function withGetIntegracao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetIntegracaoQuery,
  GetIntegracaoQueryVariables,
  GetIntegracaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetIntegracaoQuery, GetIntegracaoQueryVariables, GetIntegracaoProps<TChildProps>>(GetIntegracaoDocument, {
      alias: 'getIntegracao',
      ...operationOptions
    });
};
export type GetIntegracaoQueryResult = ApolloReactCommon.QueryResult<GetIntegracaoQuery, GetIntegracaoQueryVariables>;
export const GetRelatoriosDocument = gql`
    query getRelatorios {
  getRelatorios @client {
    label
    pais
    page
  }
}
    `;
export type GetRelatoriosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRelatoriosQuery, GetRelatoriosQueryVariables>, 'query'>;

    export const GetRelatoriosComponent = (props: GetRelatoriosComponentProps) => (
      <ApolloReactComponents.Query<GetRelatoriosQuery, GetRelatoriosQueryVariables> query={GetRelatoriosDocument} {...props} />
    );
    
export type GetRelatoriosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRelatoriosQuery, GetRelatoriosQueryVariables> & TChildProps;
export function withGetRelatorios<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRelatoriosQuery,
  GetRelatoriosQueryVariables,
  GetRelatoriosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRelatoriosQuery, GetRelatoriosQueryVariables, GetRelatoriosProps<TChildProps>>(GetRelatoriosDocument, {
      alias: 'getRelatorios',
      ...operationOptions
    });
};
export type GetRelatoriosQueryResult = ApolloReactCommon.QueryResult<GetRelatoriosQuery, GetRelatoriosQueryVariables>;
export const AlterarSenhaDocument = gql`
    mutation alterarSenha($usuario: String, $email: String) {
  alterarSenha(usuario: $usuario, email: $email)
}
    `;
export type AlterarSenhaMutationFn = ApolloReactCommon.MutationFunction<AlterarSenhaMutation, AlterarSenhaMutationVariables>;
export type AlterarSenhaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AlterarSenhaMutation, AlterarSenhaMutationVariables>, 'mutation'>;

    export const AlterarSenhaComponent = (props: AlterarSenhaComponentProps) => (
      <ApolloReactComponents.Mutation<AlterarSenhaMutation, AlterarSenhaMutationVariables> mutation={AlterarSenhaDocument} {...props} />
    );
    
export type AlterarSenhaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AlterarSenhaMutation, AlterarSenhaMutationVariables> & TChildProps;
export function withAlterarSenha<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AlterarSenhaMutation,
  AlterarSenhaMutationVariables,
  AlterarSenhaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AlterarSenhaMutation, AlterarSenhaMutationVariables, AlterarSenhaProps<TChildProps>>(AlterarSenhaDocument, {
      alias: 'alterarSenha',
      ...operationOptions
    });
};
export type AlterarSenhaMutationResult = ApolloReactCommon.MutationResult<AlterarSenhaMutation>;
export type AlterarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<AlterarSenhaMutation, AlterarSenhaMutationVariables>;
export const AprovarUrlDocument = gql`
    mutation aprovarUrl($values: AprovarUrlInput) {
  aprovarUrl(values: $values) {
    canUpload
    exibirUrl
    msg
    documentos {
      nome
      status
      obs
      coddocumentos
    }
  }
}
    `;
export type AprovarUrlMutationFn = ApolloReactCommon.MutationFunction<AprovarUrlMutation, AprovarUrlMutationVariables>;
export type AprovarUrlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AprovarUrlMutation, AprovarUrlMutationVariables>, 'mutation'>;

    export const AprovarUrlComponent = (props: AprovarUrlComponentProps) => (
      <ApolloReactComponents.Mutation<AprovarUrlMutation, AprovarUrlMutationVariables> mutation={AprovarUrlDocument} {...props} />
    );
    
export type AprovarUrlProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AprovarUrlMutation, AprovarUrlMutationVariables> & TChildProps;
export function withAprovarUrl<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AprovarUrlMutation,
  AprovarUrlMutationVariables,
  AprovarUrlProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AprovarUrlMutation, AprovarUrlMutationVariables, AprovarUrlProps<TChildProps>>(AprovarUrlDocument, {
      alias: 'aprovarUrl',
      ...operationOptions
    });
};
export type AprovarUrlMutationResult = ApolloReactCommon.MutationResult<AprovarUrlMutation>;
export type AprovarUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<AprovarUrlMutation, AprovarUrlMutationVariables>;
export const CorrigirDocument = gql`
    mutation corrigir($values: IntegracaoConciliaInput) {
  corrigir(values: $values) {
    s701 {
      label
      valor
    }
    s702 {
      label
      valor
    }
    updatesSelecionados {
      s701
      s702
      codigo
      a701 {
        label
        valor
      }
      a702 {
        label
        valor
      }
      codigo
    }
  }
}
    `;
export type CorrigirMutationFn = ApolloReactCommon.MutationFunction<CorrigirMutation, CorrigirMutationVariables>;
export type CorrigirComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CorrigirMutation, CorrigirMutationVariables>, 'mutation'>;

    export const CorrigirComponent = (props: CorrigirComponentProps) => (
      <ApolloReactComponents.Mutation<CorrigirMutation, CorrigirMutationVariables> mutation={CorrigirDocument} {...props} />
    );
    
export type CorrigirProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CorrigirMutation, CorrigirMutationVariables> & TChildProps;
export function withCorrigir<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CorrigirMutation,
  CorrigirMutationVariables,
  CorrigirProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CorrigirMutation, CorrigirMutationVariables, CorrigirProps<TChildProps>>(CorrigirDocument, {
      alias: 'corrigir',
      ...operationOptions
    });
};
export type CorrigirMutationResult = ApolloReactCommon.MutationResult<CorrigirMutation>;
export type CorrigirMutationOptions = ApolloReactCommon.BaseMutationOptions<CorrigirMutation, CorrigirMutationVariables>;
export const DeletarBucketS3AmazonCrudDocument = gql`
    mutation deletarBucketS3AmazonCRUD($values: BucketS3AmazonCRUDInput) {
  deletarBucketS3AmazonCRUD(values: $values)
}
    `;
export type DeletarBucketS3AmazonCrudMutationFn = ApolloReactCommon.MutationFunction<DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables>;
export type DeletarBucketS3AmazonCrudComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables>, 'mutation'>;

    export const DeletarBucketS3AmazonCrudComponent = (props: DeletarBucketS3AmazonCrudComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables> mutation={DeletarBucketS3AmazonCrudDocument} {...props} />
    );
    
export type DeletarBucketS3AmazonCrudProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables> & TChildProps;
export function withDeletarBucketS3AmazonCrud<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarBucketS3AmazonCrudMutation,
  DeletarBucketS3AmazonCrudMutationVariables,
  DeletarBucketS3AmazonCrudProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables, DeletarBucketS3AmazonCrudProps<TChildProps>>(DeletarBucketS3AmazonCrudDocument, {
      alias: 'deletarBucketS3AmazonCrud',
      ...operationOptions
    });
};
export type DeletarBucketS3AmazonCrudMutationResult = ApolloReactCommon.MutationResult<DeletarBucketS3AmazonCrudMutation>;
export type DeletarBucketS3AmazonCrudMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarBucketS3AmazonCrudMutation, DeletarBucketS3AmazonCrudMutationVariables>;
export const DeletarClientesDocument = gql`
    mutation deletarClientes($values: ClienteCRUDInput) {
  deletarClientes(values: $values)
}
    `;
export type DeletarClientesMutationFn = ApolloReactCommon.MutationFunction<DeletarClientesMutation, DeletarClientesMutationVariables>;
export type DeletarClientesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarClientesMutation, DeletarClientesMutationVariables>, 'mutation'>;

    export const DeletarClientesComponent = (props: DeletarClientesComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarClientesMutation, DeletarClientesMutationVariables> mutation={DeletarClientesDocument} {...props} />
    );
    
export type DeletarClientesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarClientesMutation, DeletarClientesMutationVariables> & TChildProps;
export function withDeletarClientes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarClientesMutation,
  DeletarClientesMutationVariables,
  DeletarClientesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarClientesMutation, DeletarClientesMutationVariables, DeletarClientesProps<TChildProps>>(DeletarClientesDocument, {
      alias: 'deletarClientes',
      ...operationOptions
    });
};
export type DeletarClientesMutationResult = ApolloReactCommon.MutationResult<DeletarClientesMutation>;
export type DeletarClientesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarClientesMutation, DeletarClientesMutationVariables>;
export const DeletarIntegracaoConciliaAjusteDocument = gql`
    mutation deletarIntegracaoConciliaAjuste($values: DeletarIntegracaoConciliaAjusteInput) {
  deletarIntegracaoConciliaAjuste(values: $values)
}
    `;
export type DeletarIntegracaoConciliaAjusteMutationFn = ApolloReactCommon.MutationFunction<DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables>;
export type DeletarIntegracaoConciliaAjusteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables>, 'mutation'>;

    export const DeletarIntegracaoConciliaAjusteComponent = (props: DeletarIntegracaoConciliaAjusteComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables> mutation={DeletarIntegracaoConciliaAjusteDocument} {...props} />
    );
    
export type DeletarIntegracaoConciliaAjusteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables> & TChildProps;
export function withDeletarIntegracaoConciliaAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarIntegracaoConciliaAjusteMutation,
  DeletarIntegracaoConciliaAjusteMutationVariables,
  DeletarIntegracaoConciliaAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables, DeletarIntegracaoConciliaAjusteProps<TChildProps>>(DeletarIntegracaoConciliaAjusteDocument, {
      alias: 'deletarIntegracaoConciliaAjuste',
      ...operationOptions
    });
};
export type DeletarIntegracaoConciliaAjusteMutationResult = ApolloReactCommon.MutationResult<DeletarIntegracaoConciliaAjusteMutation>;
export type DeletarIntegracaoConciliaAjusteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarIntegracaoConciliaAjusteMutation, DeletarIntegracaoConciliaAjusteMutationVariables>;
export const DeletarIntegracaoSoftExpressCadastroDocument = gql`
    mutation deletarIntegracaoSoftExpressCadastro($values: IntegracaoSoftExpressCadastroInput) {
  deletarIntegracaoSoftExpressCadastro(values: $values)
}
    `;
export type DeletarIntegracaoSoftExpressCadastroMutationFn = ApolloReactCommon.MutationFunction<DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables>;
export type DeletarIntegracaoSoftExpressCadastroComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables>, 'mutation'>;

    export const DeletarIntegracaoSoftExpressCadastroComponent = (props: DeletarIntegracaoSoftExpressCadastroComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables> mutation={DeletarIntegracaoSoftExpressCadastroDocument} {...props} />
    );
    
export type DeletarIntegracaoSoftExpressCadastroProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables> & TChildProps;
export function withDeletarIntegracaoSoftExpressCadastro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarIntegracaoSoftExpressCadastroMutation,
  DeletarIntegracaoSoftExpressCadastroMutationVariables,
  DeletarIntegracaoSoftExpressCadastroProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables, DeletarIntegracaoSoftExpressCadastroProps<TChildProps>>(DeletarIntegracaoSoftExpressCadastroDocument, {
      alias: 'deletarIntegracaoSoftExpressCadastro',
      ...operationOptions
    });
};
export type DeletarIntegracaoSoftExpressCadastroMutationResult = ApolloReactCommon.MutationResult<DeletarIntegracaoSoftExpressCadastroMutation>;
export type DeletarIntegracaoSoftExpressCadastroMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarIntegracaoSoftExpressCadastroMutation, DeletarIntegracaoSoftExpressCadastroMutationVariables>;
export const DeletarIntegracaoTotvsCadastroOperadorasDocument = gql`
    mutation deletarIntegracaoTotvsCadastroOperadoras($values: IntegracaoTotvsCadastroOperadorasInput) {
  deletarIntegracaoTotvsCadastroOperadoras(values: $values)
}
    `;
export type DeletarIntegracaoTotvsCadastroOperadorasMutationFn = ApolloReactCommon.MutationFunction<DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables>;
export type DeletarIntegracaoTotvsCadastroOperadorasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables>, 'mutation'>;

    export const DeletarIntegracaoTotvsCadastroOperadorasComponent = (props: DeletarIntegracaoTotvsCadastroOperadorasComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables> mutation={DeletarIntegracaoTotvsCadastroOperadorasDocument} {...props} />
    );
    
export type DeletarIntegracaoTotvsCadastroOperadorasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables> & TChildProps;
export function withDeletarIntegracaoTotvsCadastroOperadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarIntegracaoTotvsCadastroOperadorasMutation,
  DeletarIntegracaoTotvsCadastroOperadorasMutationVariables,
  DeletarIntegracaoTotvsCadastroOperadorasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables, DeletarIntegracaoTotvsCadastroOperadorasProps<TChildProps>>(DeletarIntegracaoTotvsCadastroOperadorasDocument, {
      alias: 'deletarIntegracaoTotvsCadastroOperadoras',
      ...operationOptions
    });
};
export type DeletarIntegracaoTotvsCadastroOperadorasMutationResult = ApolloReactCommon.MutationResult<DeletarIntegracaoTotvsCadastroOperadorasMutation>;
export type DeletarIntegracaoTotvsCadastroOperadorasMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarIntegracaoTotvsCadastroOperadorasMutation, DeletarIntegracaoTotvsCadastroOperadorasMutationVariables>;
export const DeletarManutencaoArquivosDocument = gql`
    mutation deletarManutencaoArquivos($values: ReprocessarManutencaoArquivosInput) {
  deletarManutencaoArquivos(values: $values)
}
    `;
export type DeletarManutencaoArquivosMutationFn = ApolloReactCommon.MutationFunction<DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables>;
export type DeletarManutencaoArquivosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables>, 'mutation'>;

    export const DeletarManutencaoArquivosComponent = (props: DeletarManutencaoArquivosComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables> mutation={DeletarManutencaoArquivosDocument} {...props} />
    );
    
export type DeletarManutencaoArquivosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables> & TChildProps;
export function withDeletarManutencaoArquivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarManutencaoArquivosMutation,
  DeletarManutencaoArquivosMutationVariables,
  DeletarManutencaoArquivosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables, DeletarManutencaoArquivosProps<TChildProps>>(DeletarManutencaoArquivosDocument, {
      alias: 'deletarManutencaoArquivos',
      ...operationOptions
    });
};
export type DeletarManutencaoArquivosMutationResult = ApolloReactCommon.MutationResult<DeletarManutencaoArquivosMutation>;
export type DeletarManutencaoArquivosMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarManutencaoArquivosMutation, DeletarManutencaoArquivosMutationVariables>;
export const DeletarParametrosTotvsDocument = gql`
    mutation deletarParametrosTOTVS($values: IntegracaoTotvsCadastroParametrosConciliadorInput) {
  deletarParametrosTOTVS(values: $values)
}
    `;
export type DeletarParametrosTotvsMutationFn = ApolloReactCommon.MutationFunction<DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables>;
export type DeletarParametrosTotvsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables>, 'mutation'>;

    export const DeletarParametrosTotvsComponent = (props: DeletarParametrosTotvsComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables> mutation={DeletarParametrosTotvsDocument} {...props} />
    );
    
export type DeletarParametrosTotvsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables> & TChildProps;
export function withDeletarParametrosTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarParametrosTotvsMutation,
  DeletarParametrosTotvsMutationVariables,
  DeletarParametrosTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables, DeletarParametrosTotvsProps<TChildProps>>(DeletarParametrosTotvsDocument, {
      alias: 'deletarParametrosTotvs',
      ...operationOptions
    });
};
export type DeletarParametrosTotvsMutationResult = ApolloReactCommon.MutationResult<DeletarParametrosTotvsMutation>;
export type DeletarParametrosTotvsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarParametrosTotvsMutation, DeletarParametrosTotvsMutationVariables>;
export const DeletarTicketDocument = gql`
    mutation deletarTicket($values: DeletarTicketInput) {
  deletarTicket(values: $values)
}
    `;
export type DeletarTicketMutationFn = ApolloReactCommon.MutationFunction<DeletarTicketMutation, DeletarTicketMutationVariables>;
export type DeletarTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletarTicketMutation, DeletarTicketMutationVariables>, 'mutation'>;

    export const DeletarTicketComponent = (props: DeletarTicketComponentProps) => (
      <ApolloReactComponents.Mutation<DeletarTicketMutation, DeletarTicketMutationVariables> mutation={DeletarTicketDocument} {...props} />
    );
    
export type DeletarTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletarTicketMutation, DeletarTicketMutationVariables> & TChildProps;
export function withDeletarTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletarTicketMutation,
  DeletarTicketMutationVariables,
  DeletarTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletarTicketMutation, DeletarTicketMutationVariables, DeletarTicketProps<TChildProps>>(DeletarTicketDocument, {
      alias: 'deletarTicket',
      ...operationOptions
    });
};
export type DeletarTicketMutationResult = ApolloReactCommon.MutationResult<DeletarTicketMutation>;
export type DeletarTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletarTicketMutation, DeletarTicketMutationVariables>;
export const DeleteEstabelecimentoOperadoraDocument = gql`
    mutation deleteEstabelecimentoOperadora($values: DeleteEstabelecimentoOperadoraInput) {
  deleteEstabelecimentoOperadora(values: $values)
}
    `;
export type DeleteEstabelecimentoOperadoraMutationFn = ApolloReactCommon.MutationFunction<DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables>;
export type DeleteEstabelecimentoOperadoraComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables>, 'mutation'>;

    export const DeleteEstabelecimentoOperadoraComponent = (props: DeleteEstabelecimentoOperadoraComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables> mutation={DeleteEstabelecimentoOperadoraDocument} {...props} />
    );
    
export type DeleteEstabelecimentoOperadoraProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables> & TChildProps;
export function withDeleteEstabelecimentoOperadora<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteEstabelecimentoOperadoraMutation,
  DeleteEstabelecimentoOperadoraMutationVariables,
  DeleteEstabelecimentoOperadoraProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables, DeleteEstabelecimentoOperadoraProps<TChildProps>>(DeleteEstabelecimentoOperadoraDocument, {
      alias: 'deleteEstabelecimentoOperadora',
      ...operationOptions
    });
};
export type DeleteEstabelecimentoOperadoraMutationResult = ApolloReactCommon.MutationResult<DeleteEstabelecimentoOperadoraMutation>;
export type DeleteEstabelecimentoOperadoraMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEstabelecimentoOperadoraMutation, DeleteEstabelecimentoOperadoraMutationVariables>;
export const DeletePerfilDocument = gql`
    mutation deletePerfil($values: PerfilCRUDInput) {
  deletePerfil(values: $values)
}
    `;
export type DeletePerfilMutationFn = ApolloReactCommon.MutationFunction<DeletePerfilMutation, DeletePerfilMutationVariables>;
export type DeletePerfilComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePerfilMutation, DeletePerfilMutationVariables>, 'mutation'>;

    export const DeletePerfilComponent = (props: DeletePerfilComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePerfilMutation, DeletePerfilMutationVariables> mutation={DeletePerfilDocument} {...props} />
    );
    
export type DeletePerfilProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePerfilMutation, DeletePerfilMutationVariables> & TChildProps;
export function withDeletePerfil<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePerfilMutation,
  DeletePerfilMutationVariables,
  DeletePerfilProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePerfilMutation, DeletePerfilMutationVariables, DeletePerfilProps<TChildProps>>(DeletePerfilDocument, {
      alias: 'deletePerfil',
      ...operationOptions
    });
};
export type DeletePerfilMutationResult = ApolloReactCommon.MutationResult<DeletePerfilMutation>;
export type DeletePerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePerfilMutation, DeletePerfilMutationVariables>;
export const DeletePlanosConciliadoresDocument = gql`
    mutation deletePlanosConciliadores($values: PlanosConciliadoresCRUDInput) {
  deletePlanosConciliadores(values: $values)
}
    `;
export type DeletePlanosConciliadoresMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables>;
export type DeletePlanosConciliadoresComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables>, 'mutation'>;

    export const DeletePlanosConciliadoresComponent = (props: DeletePlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables> mutation={DeletePlanosConciliadoresDocument} {...props} />
    );
    
export type DeletePlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables> & TChildProps;
export function withDeletePlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosConciliadoresMutation,
  DeletePlanosConciliadoresMutationVariables,
  DeletePlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables, DeletePlanosConciliadoresProps<TChildProps>>(DeletePlanosConciliadoresDocument, {
      alias: 'deletePlanosConciliadores',
      ...operationOptions
    });
};
export type DeletePlanosConciliadoresMutationResult = ApolloReactCommon.MutationResult<DeletePlanosConciliadoresMutation>;
export type DeletePlanosConciliadoresMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosConciliadoresMutation, DeletePlanosConciliadoresMutationVariables>;
export const DeletePlanosConciliadoresContratoDocument = gql`
    mutation deletePlanosConciliadoresContrato($values: PlanosConciliadoresContratoCRUDInput) {
  deletePlanosConciliadoresContrato(values: $values)
}
    `;
export type DeletePlanosConciliadoresContratoMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables>;
export type DeletePlanosConciliadoresContratoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables>, 'mutation'>;

    export const DeletePlanosConciliadoresContratoComponent = (props: DeletePlanosConciliadoresContratoComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables> mutation={DeletePlanosConciliadoresContratoDocument} {...props} />
    );
    
export type DeletePlanosConciliadoresContratoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables> & TChildProps;
export function withDeletePlanosConciliadoresContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosConciliadoresContratoMutation,
  DeletePlanosConciliadoresContratoMutationVariables,
  DeletePlanosConciliadoresContratoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables, DeletePlanosConciliadoresContratoProps<TChildProps>>(DeletePlanosConciliadoresContratoDocument, {
      alias: 'deletePlanosConciliadoresContrato',
      ...operationOptions
    });
};
export type DeletePlanosConciliadoresContratoMutationResult = ApolloReactCommon.MutationResult<DeletePlanosConciliadoresContratoMutation>;
export type DeletePlanosConciliadoresContratoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosConciliadoresContratoMutation, DeletePlanosConciliadoresContratoMutationVariables>;
export const DeletePlanosConciliadoresTaxasDocument = gql`
    mutation deletePlanosConciliadoresTaxas($values: PlanosConciliadoresTaxasCRUDInput) {
  deletePlanosConciliadoresTaxas(values: $values)
}
    `;
export type DeletePlanosConciliadoresTaxasMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables>;
export type DeletePlanosConciliadoresTaxasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables>, 'mutation'>;

    export const DeletePlanosConciliadoresTaxasComponent = (props: DeletePlanosConciliadoresTaxasComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables> mutation={DeletePlanosConciliadoresTaxasDocument} {...props} />
    );
    
export type DeletePlanosConciliadoresTaxasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables> & TChildProps;
export function withDeletePlanosConciliadoresTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosConciliadoresTaxasMutation,
  DeletePlanosConciliadoresTaxasMutationVariables,
  DeletePlanosConciliadoresTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables, DeletePlanosConciliadoresTaxasProps<TChildProps>>(DeletePlanosConciliadoresTaxasDocument, {
      alias: 'deletePlanosConciliadoresTaxas',
      ...operationOptions
    });
};
export type DeletePlanosConciliadoresTaxasMutationResult = ApolloReactCommon.MutationResult<DeletePlanosConciliadoresTaxasMutation>;
export type DeletePlanosConciliadoresTaxasMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosConciliadoresTaxasMutation, DeletePlanosConciliadoresTaxasMutationVariables>;
export const DeletePlanosPosDocument = gql`
    mutation deletePlanosPOS($values: PlanosCRUDInput) {
  deletePlanosPOS(values: $values)
}
    `;
export type DeletePlanosPosMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosPosMutation, DeletePlanosPosMutationVariables>;
export type DeletePlanosPosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosPosMutation, DeletePlanosPosMutationVariables>, 'mutation'>;

    export const DeletePlanosPosComponent = (props: DeletePlanosPosComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosPosMutation, DeletePlanosPosMutationVariables> mutation={DeletePlanosPosDocument} {...props} />
    );
    
export type DeletePlanosPosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosPosMutation, DeletePlanosPosMutationVariables> & TChildProps;
export function withDeletePlanosPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosPosMutation,
  DeletePlanosPosMutationVariables,
  DeletePlanosPosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosPosMutation, DeletePlanosPosMutationVariables, DeletePlanosPosProps<TChildProps>>(DeletePlanosPosDocument, {
      alias: 'deletePlanosPos',
      ...operationOptions
    });
};
export type DeletePlanosPosMutationResult = ApolloReactCommon.MutationResult<DeletePlanosPosMutation>;
export type DeletePlanosPosMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosPosMutation, DeletePlanosPosMutationVariables>;
export const DeletePlanosPosTaxasDocument = gql`
    mutation deletePlanosPosTaxas($values: PlanosTaxasCRUDInput) {
  deletePlanosPosTaxas(values: $values)
}
    `;
export type DeletePlanosPosTaxasMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables>;
export type DeletePlanosPosTaxasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables>, 'mutation'>;

    export const DeletePlanosPosTaxasComponent = (props: DeletePlanosPosTaxasComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables> mutation={DeletePlanosPosTaxasDocument} {...props} />
    );
    
export type DeletePlanosPosTaxasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables> & TChildProps;
export function withDeletePlanosPosTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosPosTaxasMutation,
  DeletePlanosPosTaxasMutationVariables,
  DeletePlanosPosTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables, DeletePlanosPosTaxasProps<TChildProps>>(DeletePlanosPosTaxasDocument, {
      alias: 'deletePlanosPosTaxas',
      ...operationOptions
    });
};
export type DeletePlanosPosTaxasMutationResult = ApolloReactCommon.MutationResult<DeletePlanosPosTaxasMutation>;
export type DeletePlanosPosTaxasMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosPosTaxasMutation, DeletePlanosPosTaxasMutationVariables>;
export const DeletePlanosTerminalDocument = gql`
    mutation deletePlanosTerminal($values: PlanosTerminalCRUDInput) {
  deletePlanosTerminal(values: $values)
}
    `;
export type DeletePlanosTerminalMutationFn = ApolloReactCommon.MutationFunction<DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables>;
export type DeletePlanosTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables>, 'mutation'>;

    export const DeletePlanosTerminalComponent = (props: DeletePlanosTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables> mutation={DeletePlanosTerminalDocument} {...props} />
    );
    
export type DeletePlanosTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables> & TChildProps;
export function withDeletePlanosTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeletePlanosTerminalMutation,
  DeletePlanosTerminalMutationVariables,
  DeletePlanosTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables, DeletePlanosTerminalProps<TChildProps>>(DeletePlanosTerminalDocument, {
      alias: 'deletePlanosTerminal',
      ...operationOptions
    });
};
export type DeletePlanosTerminalMutationResult = ApolloReactCommon.MutationResult<DeletePlanosTerminalMutation>;
export type DeletePlanosTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePlanosTerminalMutation, DeletePlanosTerminalMutationVariables>;
export const DeleteTaxasAntecipacaoDocument = gql`
    mutation deleteTaxasAntecipacao($values: TaxasAntecipacaoCRUDInput) {
  deleteTaxasAntecipacao(values: $values)
}
    `;
export type DeleteTaxasAntecipacaoMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables>;
export type DeleteTaxasAntecipacaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables>, 'mutation'>;

    export const DeleteTaxasAntecipacaoComponent = (props: DeleteTaxasAntecipacaoComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables> mutation={DeleteTaxasAntecipacaoDocument} {...props} />
    );
    
export type DeleteTaxasAntecipacaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables> & TChildProps;
export function withDeleteTaxasAntecipacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTaxasAntecipacaoMutation,
  DeleteTaxasAntecipacaoMutationVariables,
  DeleteTaxasAntecipacaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables, DeleteTaxasAntecipacaoProps<TChildProps>>(DeleteTaxasAntecipacaoDocument, {
      alias: 'deleteTaxasAntecipacao',
      ...operationOptions
    });
};
export type DeleteTaxasAntecipacaoMutationResult = ApolloReactCommon.MutationResult<DeleteTaxasAntecipacaoMutation>;
export type DeleteTaxasAntecipacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxasAntecipacaoMutation, DeleteTaxasAntecipacaoMutationVariables>;
export const DeleteTerminalDocument = gql`
    mutation deleteTerminal($values: TerminalCRUDInput) {
  deleteTerminal(values: $values)
}
    `;
export type DeleteTerminalMutationFn = ApolloReactCommon.MutationFunction<DeleteTerminalMutation, DeleteTerminalMutationVariables>;
export type DeleteTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTerminalMutation, DeleteTerminalMutationVariables>, 'mutation'>;

    export const DeleteTerminalComponent = (props: DeleteTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTerminalMutation, DeleteTerminalMutationVariables> mutation={DeleteTerminalDocument} {...props} />
    );
    
export type DeleteTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteTerminalMutation, DeleteTerminalMutationVariables> & TChildProps;
export function withDeleteTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTerminalMutation,
  DeleteTerminalMutationVariables,
  DeleteTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTerminalMutation, DeleteTerminalMutationVariables, DeleteTerminalProps<TChildProps>>(DeleteTerminalDocument, {
      alias: 'deleteTerminal',
      ...operationOptions
    });
};
export type DeleteTerminalMutationResult = ApolloReactCommon.MutationResult<DeleteTerminalMutation>;
export type DeleteTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTerminalMutation, DeleteTerminalMutationVariables>;
export const DeleteUsuarioDocument = gql`
    mutation deleteUsuario($values: UsuarioDAOInput) {
  deleteUsuario(values: $values)
}
    `;
export type DeleteUsuarioMutationFn = ApolloReactCommon.MutationFunction<DeleteUsuarioMutation, DeleteUsuarioMutationVariables>;
export type DeleteUsuarioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUsuarioMutation, DeleteUsuarioMutationVariables>, 'mutation'>;

    export const DeleteUsuarioComponent = (props: DeleteUsuarioComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUsuarioMutation, DeleteUsuarioMutationVariables> mutation={DeleteUsuarioDocument} {...props} />
    );
    
export type DeleteUsuarioProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteUsuarioMutation, DeleteUsuarioMutationVariables> & TChildProps;
export function withDeleteUsuario<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUsuarioMutation,
  DeleteUsuarioMutationVariables,
  DeleteUsuarioProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUsuarioMutation, DeleteUsuarioMutationVariables, DeleteUsuarioProps<TChildProps>>(DeleteUsuarioDocument, {
      alias: 'deleteUsuario',
      ...operationOptions
    });
};
export type DeleteUsuarioMutationResult = ApolloReactCommon.MutationResult<DeleteUsuarioMutation>;
export type DeleteUsuarioMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUsuarioMutation, DeleteUsuarioMutationVariables>;
export const DownloadAnalisePesquisaTicketDocument = gql`
    mutation downloadAnalisePesquisaTicket($values: DownloadAnalisePesquisaTicketInput) {
  downloadAnalisePesquisaTicket(values: $values)
}
    `;
export type DownloadAnalisePesquisaTicketMutationFn = ApolloReactCommon.MutationFunction<DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables>;
export type DownloadAnalisePesquisaTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables>, 'mutation'>;

    export const DownloadAnalisePesquisaTicketComponent = (props: DownloadAnalisePesquisaTicketComponentProps) => (
      <ApolloReactComponents.Mutation<DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables> mutation={DownloadAnalisePesquisaTicketDocument} {...props} />
    );
    
export type DownloadAnalisePesquisaTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables> & TChildProps;
export function withDownloadAnalisePesquisaTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadAnalisePesquisaTicketMutation,
  DownloadAnalisePesquisaTicketMutationVariables,
  DownloadAnalisePesquisaTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables, DownloadAnalisePesquisaTicketProps<TChildProps>>(DownloadAnalisePesquisaTicketDocument, {
      alias: 'downloadAnalisePesquisaTicket',
      ...operationOptions
    });
};
export type DownloadAnalisePesquisaTicketMutationResult = ApolloReactCommon.MutationResult<DownloadAnalisePesquisaTicketMutation>;
export type DownloadAnalisePesquisaTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadAnalisePesquisaTicketMutation, DownloadAnalisePesquisaTicketMutationVariables>;
export const DownloadArquivoConciliacaoEmAbertoDocument = gql`
    mutation downloadArquivoConciliacaoEmAberto($codarquivoDownload: String) {
  downloadArquivoConciliacaoEmAberto(codarquivoDownload: $codarquivoDownload)
}
    `;
export type DownloadArquivoConciliacaoEmAbertoMutationFn = ApolloReactCommon.MutationFunction<DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables>;
export type DownloadArquivoConciliacaoEmAbertoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables>, 'mutation'>;

    export const DownloadArquivoConciliacaoEmAbertoComponent = (props: DownloadArquivoConciliacaoEmAbertoComponentProps) => (
      <ApolloReactComponents.Mutation<DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables> mutation={DownloadArquivoConciliacaoEmAbertoDocument} {...props} />
    );
    
export type DownloadArquivoConciliacaoEmAbertoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables> & TChildProps;
export function withDownloadArquivoConciliacaoEmAberto<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadArquivoConciliacaoEmAbertoMutation,
  DownloadArquivoConciliacaoEmAbertoMutationVariables,
  DownloadArquivoConciliacaoEmAbertoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables, DownloadArquivoConciliacaoEmAbertoProps<TChildProps>>(DownloadArquivoConciliacaoEmAbertoDocument, {
      alias: 'downloadArquivoConciliacaoEmAberto',
      ...operationOptions
    });
};
export type DownloadArquivoConciliacaoEmAbertoMutationResult = ApolloReactCommon.MutationResult<DownloadArquivoConciliacaoEmAbertoMutation>;
export type DownloadArquivoConciliacaoEmAbertoMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadArquivoConciliacaoEmAbertoMutation, DownloadArquivoConciliacaoEmAbertoMutationVariables>;
export const DownloadExtratosOriginaisDocument = gql`
    mutation downloadExtratosOriginais($values: DownloadExtratosOriginaisInput) {
  downloadExtratosOriginais(values: $values)
}
    `;
export type DownloadExtratosOriginaisMutationFn = ApolloReactCommon.MutationFunction<DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables>;
export type DownloadExtratosOriginaisComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables>, 'mutation'>;

    export const DownloadExtratosOriginaisComponent = (props: DownloadExtratosOriginaisComponentProps) => (
      <ApolloReactComponents.Mutation<DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables> mutation={DownloadExtratosOriginaisDocument} {...props} />
    );
    
export type DownloadExtratosOriginaisProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables> & TChildProps;
export function withDownloadExtratosOriginais<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadExtratosOriginaisMutation,
  DownloadExtratosOriginaisMutationVariables,
  DownloadExtratosOriginaisProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables, DownloadExtratosOriginaisProps<TChildProps>>(DownloadExtratosOriginaisDocument, {
      alias: 'downloadExtratosOriginais',
      ...operationOptions
    });
};
export type DownloadExtratosOriginaisMutationResult = ApolloReactCommon.MutationResult<DownloadExtratosOriginaisMutation>;
export type DownloadExtratosOriginaisMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadExtratosOriginaisMutation, DownloadExtratosOriginaisMutationVariables>;
export const DownloadManutencaoArquivosDocument = gql`
    mutation downloadManutencaoArquivos($values: DownloadManutencaoArquivosInput) {
  downloadManutencaoArquivos(values: $values)
}
    `;
export type DownloadManutencaoArquivosMutationFn = ApolloReactCommon.MutationFunction<DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables>;
export type DownloadManutencaoArquivosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables>, 'mutation'>;

    export const DownloadManutencaoArquivosComponent = (props: DownloadManutencaoArquivosComponentProps) => (
      <ApolloReactComponents.Mutation<DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables> mutation={DownloadManutencaoArquivosDocument} {...props} />
    );
    
export type DownloadManutencaoArquivosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables> & TChildProps;
export function withDownloadManutencaoArquivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DownloadManutencaoArquivosMutation,
  DownloadManutencaoArquivosMutationVariables,
  DownloadManutencaoArquivosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables, DownloadManutencaoArquivosProps<TChildProps>>(DownloadManutencaoArquivosDocument, {
      alias: 'downloadManutencaoArquivos',
      ...operationOptions
    });
};
export type DownloadManutencaoArquivosMutationResult = ApolloReactCommon.MutationResult<DownloadManutencaoArquivosMutation>;
export type DownloadManutencaoArquivosMutationOptions = ApolloReactCommon.BaseMutationOptions<DownloadManutencaoArquivosMutation, DownloadManutencaoArquivosMutationVariables>;
export const EditarEstabelecimentoDocument = gql`
    mutation editarEstabelecimento($codOperadora: Int, $codExterno: String, $codExternoAntigo: String, $apelido: String, $codPessoa: Int) {
  editarEstabelecimento(codOperadora: $codOperadora, codExterno: $codExterno, codExternoAntigo: $codExternoAntigo, apelido: $apelido, codPessoa: $codPessoa) {
    operadoras {
      arquivoImagemPOS
      arquivoImagemTEF
      operadora {
        codoperadora
        nome
        formacalculo
        consinco
        estabelecimentoCadastroTaxa
      }
      statusEC {
        descricao
      }
      statusTaxa {
        descricao
      }
      statusTermo {
        descricao
      }
      statusCarteira {
        descricao
      }
      downlaodTermoAdesaoCss
      downlaodAutorizacaoCss
    }
    mensagemTela
  }
}
    `;
export type EditarEstabelecimentoMutationFn = ApolloReactCommon.MutationFunction<EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables>;
export type EditarEstabelecimentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables>, 'mutation'>;

    export const EditarEstabelecimentoComponent = (props: EditarEstabelecimentoComponentProps) => (
      <ApolloReactComponents.Mutation<EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables> mutation={EditarEstabelecimentoDocument} {...props} />
    );
    
export type EditarEstabelecimentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables> & TChildProps;
export function withEditarEstabelecimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditarEstabelecimentoMutation,
  EditarEstabelecimentoMutationVariables,
  EditarEstabelecimentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables, EditarEstabelecimentoProps<TChildProps>>(EditarEstabelecimentoDocument, {
      alias: 'editarEstabelecimento',
      ...operationOptions
    });
};
export type EditarEstabelecimentoMutationResult = ApolloReactCommon.MutationResult<EditarEstabelecimentoMutation>;
export type EditarEstabelecimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<EditarEstabelecimentoMutation, EditarEstabelecimentoMutationVariables>;
export const FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaDocument = gql`
    mutation faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota($values: FaturamentoMovimentoBeanInput, $operacao: String!) {
  faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota(values: $values, operacao: $operacao)
}
    `;
export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationFn = ApolloReactCommon.MutationFunction<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables>;
export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables>, 'mutation'>;

    export const FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponent = (props: FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaComponentProps) => (
      <ApolloReactComponents.Mutation<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables> mutation={FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaDocument} {...props} />
    );
    
export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables> & TChildProps;
export function withFaturamentoMovimentoReenviarEmailNotaAceitarReenviarNota<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables,
  FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaProps<TChildProps>>(FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaDocument, {
      alias: 'faturamentoMovimentoReenviarEmailNotaAceitarReenviarNota',
      ...operationOptions
    });
};
export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationResult = ApolloReactCommon.MutationResult<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation>;
export type FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationOptions = ApolloReactCommon.BaseMutationOptions<FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutation, FaturamentoMovimentoReenviarEmailNotaAceitarReenviarNotaMutationVariables>;
export const GerarIntegracaoAlterdataDocument = gql`
    mutation gerarIntegracaoAlterdata($filtro: BeanFiltroRelatorioTelaInput, $codigocontacreditopg: String, $codigocontadebitopg: String, $codigocontacreditorc: String, $codigocontadebitorc: String) {
  gerarIntegracaoAlterdata(filtro: $filtro, codigocontacreditopg: $codigocontacreditopg, codigocontadebitopg: $codigocontadebitopg, codigocontacreditorc: $codigocontacreditorc, codigocontadebitorc: $codigocontadebitorc) {
    nomeArquivo
    data
  }
}
    `;
export type GerarIntegracaoAlterdataMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables>;
export type GerarIntegracaoAlterdataComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables>, 'mutation'>;

    export const GerarIntegracaoAlterdataComponent = (props: GerarIntegracaoAlterdataComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables> mutation={GerarIntegracaoAlterdataDocument} {...props} />
    );
    
export type GerarIntegracaoAlterdataProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables> & TChildProps;
export function withGerarIntegracaoAlterdata<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoAlterdataMutation,
  GerarIntegracaoAlterdataMutationVariables,
  GerarIntegracaoAlterdataProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables, GerarIntegracaoAlterdataProps<TChildProps>>(GerarIntegracaoAlterdataDocument, {
      alias: 'gerarIntegracaoAlterdata',
      ...operationOptions
    });
};
export type GerarIntegracaoAlterdataMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoAlterdataMutation>;
export type GerarIntegracaoAlterdataMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoAlterdataMutation, GerarIntegracaoAlterdataMutationVariables>;
export const GerarIntegracaoAvancoDocument = gql`
    mutation gerarIntegracaoAvanco($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoAvanco(filtro: $filtro) {
    nomeArquivo
    data
  }
}
    `;
export type GerarIntegracaoAvancoMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables>;
export type GerarIntegracaoAvancoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables>, 'mutation'>;

    export const GerarIntegracaoAvancoComponent = (props: GerarIntegracaoAvancoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables> mutation={GerarIntegracaoAvancoDocument} {...props} />
    );
    
export type GerarIntegracaoAvancoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables> & TChildProps;
export function withGerarIntegracaoAvanco<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoAvancoMutation,
  GerarIntegracaoAvancoMutationVariables,
  GerarIntegracaoAvancoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables, GerarIntegracaoAvancoProps<TChildProps>>(GerarIntegracaoAvancoDocument, {
      alias: 'gerarIntegracaoAvanco',
      ...operationOptions
    });
};
export type GerarIntegracaoAvancoMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoAvancoMutation>;
export type GerarIntegracaoAvancoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoAvancoMutation, GerarIntegracaoAvancoMutationVariables>;
export const GerarIntegracaoCashDocument = gql`
    mutation gerarIntegracaoCash($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoCash(filtro: $filtro) {
    nomeArquivo
    data
  }
}
    `;
export type GerarIntegracaoCashMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables>;
export type GerarIntegracaoCashComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables>, 'mutation'>;

    export const GerarIntegracaoCashComponent = (props: GerarIntegracaoCashComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables> mutation={GerarIntegracaoCashDocument} {...props} />
    );
    
export type GerarIntegracaoCashProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables> & TChildProps;
export function withGerarIntegracaoCash<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoCashMutation,
  GerarIntegracaoCashMutationVariables,
  GerarIntegracaoCashProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables, GerarIntegracaoCashProps<TChildProps>>(GerarIntegracaoCashDocument, {
      alias: 'gerarIntegracaoCash',
      ...operationOptions
    });
};
export type GerarIntegracaoCashMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoCashMutation>;
export type GerarIntegracaoCashMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoCashMutation, GerarIntegracaoCashMutationVariables>;
export const GerarIntegracaoConciliaRelatorioDocument = gql`
    mutation gerarIntegracaoConciliaRelatorio($conciliadores: [String], $datade: Date, $dataate: Date, $somenteProblemas: Boolean, $sintetico: Boolean, $exportar: String) {
  gerarIntegracaoConciliaRelatorio(conciliadores: $conciliadores, datade: $datade, dataate: $dataate, somenteProblemas: $somenteProblemas, sintetico: $sintetico, exportar: $exportar) {
    nomeArquivo
    data
  }
}
    `;
export type GerarIntegracaoConciliaRelatorioMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables>;
export type GerarIntegracaoConciliaRelatorioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables>, 'mutation'>;

    export const GerarIntegracaoConciliaRelatorioComponent = (props: GerarIntegracaoConciliaRelatorioComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables> mutation={GerarIntegracaoConciliaRelatorioDocument} {...props} />
    );
    
export type GerarIntegracaoConciliaRelatorioProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables> & TChildProps;
export function withGerarIntegracaoConciliaRelatorio<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoConciliaRelatorioMutation,
  GerarIntegracaoConciliaRelatorioMutationVariables,
  GerarIntegracaoConciliaRelatorioProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables, GerarIntegracaoConciliaRelatorioProps<TChildProps>>(GerarIntegracaoConciliaRelatorioDocument, {
      alias: 'gerarIntegracaoConciliaRelatorio',
      ...operationOptions
    });
};
export type GerarIntegracaoConciliaRelatorioMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoConciliaRelatorioMutation>;
export type GerarIntegracaoConciliaRelatorioMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoConciliaRelatorioMutation, GerarIntegracaoConciliaRelatorioMutationVariables>;
export const GerarIntegracaoConciliaRetornoNossoNumeroDocument = gql`
    mutation gerarIntegracaoConciliaRetornoNossoNumero($filtro: BeanFiltroRelatorioTelaInput, $ultimos7Dias: Boolean, $refazerConciliacao702: Boolean, $refazerConciliacao701: Boolean, $corrigir: String, $botaoCorrigir: Boolean) {
  gerarIntegracaoConciliaRetornoNossoNumero(filtro: $filtro, ultimos7Dias: $ultimos7Dias, refazerConciliacao702: $refazerConciliacao702, refazerConciliacao701: $refazerConciliacao701, corrigir: $corrigir, botaoCorrigir: $botaoCorrigir) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoConciliaRetornoNossoNumeroMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables>;
export type GerarIntegracaoConciliaRetornoNossoNumeroComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables>, 'mutation'>;

    export const GerarIntegracaoConciliaRetornoNossoNumeroComponent = (props: GerarIntegracaoConciliaRetornoNossoNumeroComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables> mutation={GerarIntegracaoConciliaRetornoNossoNumeroDocument} {...props} />
    );
    
export type GerarIntegracaoConciliaRetornoNossoNumeroProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables> & TChildProps;
export function withGerarIntegracaoConciliaRetornoNossoNumero<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoConciliaRetornoNossoNumeroMutation,
  GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables,
  GerarIntegracaoConciliaRetornoNossoNumeroProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables, GerarIntegracaoConciliaRetornoNossoNumeroProps<TChildProps>>(GerarIntegracaoConciliaRetornoNossoNumeroDocument, {
      alias: 'gerarIntegracaoConciliaRetornoNossoNumero',
      ...operationOptions
    });
};
export type GerarIntegracaoConciliaRetornoNossoNumeroMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoConciliaRetornoNossoNumeroMutation>;
export type GerarIntegracaoConciliaRetornoNossoNumeroMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoConciliaRetornoNossoNumeroMutation, GerarIntegracaoConciliaRetornoNossoNumeroMutationVariables>;
export const GerarIntegracaoConciliaVendaPagamentoAntecipadoDocument = gql`
    mutation gerarIntegracaoConciliaVendaPagamentoAntecipado($filtro: BeanFiltroRelatorioTelaInput, $ajusteConsolidado: String, $tipoRelatorio: String!) {
  gerarIntegracaoConciliaVendaPagamentoAntecipado(filtro: $filtro, ajusteConsolidado: $ajusteConsolidado, tipoRelatorio: $tipoRelatorio) {
    nomeArquivo
    data
  }
}
    `;
export type GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables>;
export type GerarIntegracaoConciliaVendaPagamentoAntecipadoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables>, 'mutation'>;

    export const GerarIntegracaoConciliaVendaPagamentoAntecipadoComponent = (props: GerarIntegracaoConciliaVendaPagamentoAntecipadoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables> mutation={GerarIntegracaoConciliaVendaPagamentoAntecipadoDocument} {...props} />
    );
    
export type GerarIntegracaoConciliaVendaPagamentoAntecipadoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables> & TChildProps;
export function withGerarIntegracaoConciliaVendaPagamentoAntecipado<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation,
  GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables,
  GerarIntegracaoConciliaVendaPagamentoAntecipadoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables, GerarIntegracaoConciliaVendaPagamentoAntecipadoProps<TChildProps>>(GerarIntegracaoConciliaVendaPagamentoAntecipadoDocument, {
      alias: 'gerarIntegracaoConciliaVendaPagamentoAntecipado',
      ...operationOptions
    });
};
export type GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation>;
export type GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoConciliaVendaPagamentoAntecipadoMutation, GerarIntegracaoConciliaVendaPagamentoAntecipadoMutationVariables>;
export const GerarIntegracaoConsincoDocument = gql`
    mutation gerarIntegracaoConsinco($filtro: BeanFiltroRelatorioTelaInput, $versao: String, $tipoNsu: String, $integradoPDV: Boolean, $mostrarDescricaoAjuste: Boolean, $pos: String, $tipoTiket: String, $tipodatavenda: String) {
  gerarIntegracaoConsinco(filtro: $filtro, versao: $versao, tipoNsu: $tipoNsu, integradoPDV: $integradoPDV, mostrarDescricaoAjuste: $mostrarDescricaoAjuste, pos: $pos, tipodatavenda: $tipodatavenda, tipoTiket: $tipoTiket) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoConsincoMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables>;
export type GerarIntegracaoConsincoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables>, 'mutation'>;

    export const GerarIntegracaoConsincoComponent = (props: GerarIntegracaoConsincoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables> mutation={GerarIntegracaoConsincoDocument} {...props} />
    );
    
export type GerarIntegracaoConsincoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables> & TChildProps;
export function withGerarIntegracaoConsinco<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoConsincoMutation,
  GerarIntegracaoConsincoMutationVariables,
  GerarIntegracaoConsincoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables, GerarIntegracaoConsincoProps<TChildProps>>(GerarIntegracaoConsincoDocument, {
      alias: 'gerarIntegracaoConsinco',
      ...operationOptions
    });
};
export type GerarIntegracaoConsincoMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoConsincoMutation>;
export type GerarIntegracaoConsincoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoConsincoMutation, GerarIntegracaoConsincoMutationVariables>;
export const GerarIntegracaoKaplenDocument = gql`
    mutation gerarIntegracaoKaplen($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoKaplen(filtro: $filtro) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoKaplenMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables>;
export type GerarIntegracaoKaplenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables>, 'mutation'>;

    export const GerarIntegracaoKaplenComponent = (props: GerarIntegracaoKaplenComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables> mutation={GerarIntegracaoKaplenDocument} {...props} />
    );
    
export type GerarIntegracaoKaplenProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables> & TChildProps;
export function withGerarIntegracaoKaplen<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoKaplenMutation,
  GerarIntegracaoKaplenMutationVariables,
  GerarIntegracaoKaplenProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables, GerarIntegracaoKaplenProps<TChildProps>>(GerarIntegracaoKaplenDocument, {
      alias: 'gerarIntegracaoKaplen',
      ...operationOptions
    });
};
export type GerarIntegracaoKaplenMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoKaplenMutation>;
export type GerarIntegracaoKaplenMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoKaplenMutation, GerarIntegracaoKaplenMutationVariables>;
export const GerarIntegracaoLinxDocument = gql`
    mutation gerarIntegracaoLinx($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoLinx(filtro: $filtro) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoLinxMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables>;
export type GerarIntegracaoLinxComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables>, 'mutation'>;

    export const GerarIntegracaoLinxComponent = (props: GerarIntegracaoLinxComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables> mutation={GerarIntegracaoLinxDocument} {...props} />
    );
    
export type GerarIntegracaoLinxProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables> & TChildProps;
export function withGerarIntegracaoLinx<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoLinxMutation,
  GerarIntegracaoLinxMutationVariables,
  GerarIntegracaoLinxProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables, GerarIntegracaoLinxProps<TChildProps>>(GerarIntegracaoLinxDocument, {
      alias: 'gerarIntegracaoLinx',
      ...operationOptions
    });
};
export type GerarIntegracaoLinxMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoLinxMutation>;
export type GerarIntegracaoLinxMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoLinxMutation, GerarIntegracaoLinxMutationVariables>;
export const GerarIntegracaoMakroDocument = gql`
    mutation gerarIntegracaoMakro($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoMakro(filtro: $filtro)
}
    `;
export type GerarIntegracaoMakroMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables>;
export type GerarIntegracaoMakroComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables>, 'mutation'>;

    export const GerarIntegracaoMakroComponent = (props: GerarIntegracaoMakroComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables> mutation={GerarIntegracaoMakroDocument} {...props} />
    );
    
export type GerarIntegracaoMakroProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables> & TChildProps;
export function withGerarIntegracaoMakro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoMakroMutation,
  GerarIntegracaoMakroMutationVariables,
  GerarIntegracaoMakroProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables, GerarIntegracaoMakroProps<TChildProps>>(GerarIntegracaoMakroDocument, {
      alias: 'gerarIntegracaoMakro',
      ...operationOptions
    });
};
export type GerarIntegracaoMakroMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoMakroMutation>;
export type GerarIntegracaoMakroMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoMakroMutation, GerarIntegracaoMakroMutationVariables>;
export const GerarIntegracaoMicroUniversoDocument = gql`
    mutation gerarIntegracaoMicroUniverso($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoMicroUniverso(filtro: $filtro)
}
    `;
export type GerarIntegracaoMicroUniversoMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables>;
export type GerarIntegracaoMicroUniversoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables>, 'mutation'>;

    export const GerarIntegracaoMicroUniversoComponent = (props: GerarIntegracaoMicroUniversoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables> mutation={GerarIntegracaoMicroUniversoDocument} {...props} />
    );
    
export type GerarIntegracaoMicroUniversoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables> & TChildProps;
export function withGerarIntegracaoMicroUniverso<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoMicroUniversoMutation,
  GerarIntegracaoMicroUniversoMutationVariables,
  GerarIntegracaoMicroUniversoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables, GerarIntegracaoMicroUniversoProps<TChildProps>>(GerarIntegracaoMicroUniversoDocument, {
      alias: 'gerarIntegracaoMicroUniverso',
      ...operationOptions
    });
};
export type GerarIntegracaoMicroUniversoMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoMicroUniversoMutation>;
export type GerarIntegracaoMicroUniversoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoMicroUniversoMutation, GerarIntegracaoMicroUniversoMutationVariables>;
export const GerarIntegracaoOlxDocument = gql`
    mutation gerarIntegracaoOlx($filtro: BeanFiltroRelatorioTelaInput, $codbuckets3amazon: Int, $transacaoComProblema: Boolean) {
  gerarIntegracaoOlx(filtro: $filtro, codbuckets3amazon: $codbuckets3amazon, transacaoComProblema: $transacaoComProblema) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoOlxMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables>;
export type GerarIntegracaoOlxComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables>, 'mutation'>;

    export const GerarIntegracaoOlxComponent = (props: GerarIntegracaoOlxComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables> mutation={GerarIntegracaoOlxDocument} {...props} />
    );
    
export type GerarIntegracaoOlxProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables> & TChildProps;
export function withGerarIntegracaoOlx<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoOlxMutation,
  GerarIntegracaoOlxMutationVariables,
  GerarIntegracaoOlxProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables, GerarIntegracaoOlxProps<TChildProps>>(GerarIntegracaoOlxDocument, {
      alias: 'gerarIntegracaoOlx',
      ...operationOptions
    });
};
export type GerarIntegracaoOlxMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoOlxMutation>;
export type GerarIntegracaoOlxMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoOlxMutation, GerarIntegracaoOlxMutationVariables>;
export const GerarIntegracaoSoftwareExpressDocument = gql`
    mutation gerarIntegracaoSoftwareExpress($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoSoftwareExpress(filtro: $filtro) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoSoftwareExpressMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables>;
export type GerarIntegracaoSoftwareExpressComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables>, 'mutation'>;

    export const GerarIntegracaoSoftwareExpressComponent = (props: GerarIntegracaoSoftwareExpressComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables> mutation={GerarIntegracaoSoftwareExpressDocument} {...props} />
    );
    
export type GerarIntegracaoSoftwareExpressProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables> & TChildProps;
export function withGerarIntegracaoSoftwareExpress<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoSoftwareExpressMutation,
  GerarIntegracaoSoftwareExpressMutationVariables,
  GerarIntegracaoSoftwareExpressProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables, GerarIntegracaoSoftwareExpressProps<TChildProps>>(GerarIntegracaoSoftwareExpressDocument, {
      alias: 'gerarIntegracaoSoftwareExpress',
      ...operationOptions
    });
};
export type GerarIntegracaoSoftwareExpressMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoSoftwareExpressMutation>;
export type GerarIntegracaoSoftwareExpressMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoSoftwareExpressMutation, GerarIntegracaoSoftwareExpressMutationVariables>;
export const GerarIntegracaoTeknisaDocument = gql`
    mutation gerarIntegracaoTeknisa($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoTeknisa(filtro: $filtro) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoTeknisaMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables>;
export type GerarIntegracaoTeknisaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables>, 'mutation'>;

    export const GerarIntegracaoTeknisaComponent = (props: GerarIntegracaoTeknisaComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables> mutation={GerarIntegracaoTeknisaDocument} {...props} />
    );
    
export type GerarIntegracaoTeknisaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables> & TChildProps;
export function withGerarIntegracaoTeknisa<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoTeknisaMutation,
  GerarIntegracaoTeknisaMutationVariables,
  GerarIntegracaoTeknisaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables, GerarIntegracaoTeknisaProps<TChildProps>>(GerarIntegracaoTeknisaDocument, {
      alias: 'gerarIntegracaoTeknisa',
      ...operationOptions
    });
};
export type GerarIntegracaoTeknisaMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoTeknisaMutation>;
export type GerarIntegracaoTeknisaMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoTeknisaMutation, GerarIntegracaoTeknisaMutationVariables>;
export const GerarIntegracaoTotvsDocument = gql`
    mutation gerarIntegracaoTotvs($filtro: BeanFiltroRelatorioTelaInput) {
  gerarIntegracaoTotvs(filtro: $filtro) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarIntegracaoTotvsMutationFn = ApolloReactCommon.MutationFunction<GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables>;
export type GerarIntegracaoTotvsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables>, 'mutation'>;

    export const GerarIntegracaoTotvsComponent = (props: GerarIntegracaoTotvsComponentProps) => (
      <ApolloReactComponents.Mutation<GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables> mutation={GerarIntegracaoTotvsDocument} {...props} />
    );
    
export type GerarIntegracaoTotvsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables> & TChildProps;
export function withGerarIntegracaoTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarIntegracaoTotvsMutation,
  GerarIntegracaoTotvsMutationVariables,
  GerarIntegracaoTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables, GerarIntegracaoTotvsProps<TChildProps>>(GerarIntegracaoTotvsDocument, {
      alias: 'gerarIntegracaoTotvs',
      ...operationOptions
    });
};
export type GerarIntegracaoTotvsMutationResult = ApolloReactCommon.MutationResult<GerarIntegracaoTotvsMutation>;
export type GerarIntegracaoTotvsMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarIntegracaoTotvsMutation, GerarIntegracaoTotvsMutationVariables>;
export const GerarRelatorioConciliadosAntecipadosDocument = gql`
    mutation gerarRelatorioConciliadosAntecipados($filtro: BeanFiltroRelatorioTelaAntecipadosInput, $excelsimples: String) {
  gerarRelatorioConciliadosAntecipados(filtro: $filtro, excelsimples: $excelsimples)
}
    `;
export type GerarRelatorioConciliadosAntecipadosMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables>;
export type GerarRelatorioConciliadosAntecipadosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables>, 'mutation'>;

    export const GerarRelatorioConciliadosAntecipadosComponent = (props: GerarRelatorioConciliadosAntecipadosComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables> mutation={GerarRelatorioConciliadosAntecipadosDocument} {...props} />
    );
    
export type GerarRelatorioConciliadosAntecipadosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables> & TChildProps;
export function withGerarRelatorioConciliadosAntecipados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioConciliadosAntecipadosMutation,
  GerarRelatorioConciliadosAntecipadosMutationVariables,
  GerarRelatorioConciliadosAntecipadosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables, GerarRelatorioConciliadosAntecipadosProps<TChildProps>>(GerarRelatorioConciliadosAntecipadosDocument, {
      alias: 'gerarRelatorioConciliadosAntecipados',
      ...operationOptions
    });
};
export type GerarRelatorioConciliadosAntecipadosMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioConciliadosAntecipadosMutation>;
export type GerarRelatorioConciliadosAntecipadosMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioConciliadosAntecipadosMutation, GerarRelatorioConciliadosAntecipadosMutationVariables>;
export const GerarRelatorioConciliadosBoletoFiltroSimplesDocument = gql`
    mutation gerarRelatorioConciliadosBoletoFiltroSimples($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioConciliadosBoletoFiltroSimples(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioConciliadosBoletoFiltroSimplesMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables>;
export type GerarRelatorioConciliadosBoletoFiltroSimplesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables>, 'mutation'>;

    export const GerarRelatorioConciliadosBoletoFiltroSimplesComponent = (props: GerarRelatorioConciliadosBoletoFiltroSimplesComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables> mutation={GerarRelatorioConciliadosBoletoFiltroSimplesDocument} {...props} />
    );
    
export type GerarRelatorioConciliadosBoletoFiltroSimplesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables> & TChildProps;
export function withGerarRelatorioConciliadosBoletoFiltroSimples<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioConciliadosBoletoFiltroSimplesMutation,
  GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables,
  GerarRelatorioConciliadosBoletoFiltroSimplesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables, GerarRelatorioConciliadosBoletoFiltroSimplesProps<TChildProps>>(GerarRelatorioConciliadosBoletoFiltroSimplesDocument, {
      alias: 'gerarRelatorioConciliadosBoletoFiltroSimples',
      ...operationOptions
    });
};
export type GerarRelatorioConciliadosBoletoFiltroSimplesMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioConciliadosBoletoFiltroSimplesMutation>;
export type GerarRelatorioConciliadosBoletoFiltroSimplesMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioConciliadosBoletoFiltroSimplesMutation, GerarRelatorioConciliadosBoletoFiltroSimplesMutationVariables>;
export const GerarRelatorioConciliadosPagamentoFiltroSimplesDocument = gql`
    mutation gerarRelatorioConciliadosPagamentoFiltroSimples($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioConciliadosPagamentoFiltroSimples(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioConciliadosPagamentoFiltroSimplesMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables>;
export type GerarRelatorioConciliadosPagamentoFiltroSimplesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables>, 'mutation'>;

    export const GerarRelatorioConciliadosPagamentoFiltroSimplesComponent = (props: GerarRelatorioConciliadosPagamentoFiltroSimplesComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables> mutation={GerarRelatorioConciliadosPagamentoFiltroSimplesDocument} {...props} />
    );
    
export type GerarRelatorioConciliadosPagamentoFiltroSimplesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables> & TChildProps;
export function withGerarRelatorioConciliadosPagamentoFiltroSimples<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioConciliadosPagamentoFiltroSimplesMutation,
  GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables,
  GerarRelatorioConciliadosPagamentoFiltroSimplesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables, GerarRelatorioConciliadosPagamentoFiltroSimplesProps<TChildProps>>(GerarRelatorioConciliadosPagamentoFiltroSimplesDocument, {
      alias: 'gerarRelatorioConciliadosPagamentoFiltroSimples',
      ...operationOptions
    });
};
export type GerarRelatorioConciliadosPagamentoFiltroSimplesMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation>;
export type GerarRelatorioConciliadosPagamentoFiltroSimplesMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioConciliadosPagamentoFiltroSimplesMutation, GerarRelatorioConciliadosPagamentoFiltroSimplesMutationVariables>;
export const GerarRelatorioConciliadosPrevisaoFiltroSimplesDocument = gql`
    mutation gerarRelatorioConciliadosPrevisaoFiltroSimples($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioConciliadosPrevisaoFiltroSimples(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables>;
export type GerarRelatorioConciliadosPrevisaoFiltroSimplesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables>, 'mutation'>;

    export const GerarRelatorioConciliadosPrevisaoFiltroSimplesComponent = (props: GerarRelatorioConciliadosPrevisaoFiltroSimplesComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables> mutation={GerarRelatorioConciliadosPrevisaoFiltroSimplesDocument} {...props} />
    );
    
export type GerarRelatorioConciliadosPrevisaoFiltroSimplesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables> & TChildProps;
export function withGerarRelatorioConciliadosPrevisaoFiltroSimples<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation,
  GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables,
  GerarRelatorioConciliadosPrevisaoFiltroSimplesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables, GerarRelatorioConciliadosPrevisaoFiltroSimplesProps<TChildProps>>(GerarRelatorioConciliadosPrevisaoFiltroSimplesDocument, {
      alias: 'gerarRelatorioConciliadosPrevisaoFiltroSimples',
      ...operationOptions
    });
};
export type GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation>;
export type GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioConciliadosPrevisaoFiltroSimplesMutation, GerarRelatorioConciliadosPrevisaoFiltroSimplesMutationVariables>;
export const GerarRelatorioConciliadosVendaFiltroSimplesDocument = gql`
    mutation gerarRelatorioConciliadosVendaFiltroSimples($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioConciliadosVendaFiltroSimples(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioConciliadosVendaFiltroSimplesMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables>;
export type GerarRelatorioConciliadosVendaFiltroSimplesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables>, 'mutation'>;

    export const GerarRelatorioConciliadosVendaFiltroSimplesComponent = (props: GerarRelatorioConciliadosVendaFiltroSimplesComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables> mutation={GerarRelatorioConciliadosVendaFiltroSimplesDocument} {...props} />
    );
    
export type GerarRelatorioConciliadosVendaFiltroSimplesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables> & TChildProps;
export function withGerarRelatorioConciliadosVendaFiltroSimples<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioConciliadosVendaFiltroSimplesMutation,
  GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables,
  GerarRelatorioConciliadosVendaFiltroSimplesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables, GerarRelatorioConciliadosVendaFiltroSimplesProps<TChildProps>>(GerarRelatorioConciliadosVendaFiltroSimplesDocument, {
      alias: 'gerarRelatorioConciliadosVendaFiltroSimples',
      ...operationOptions
    });
};
export type GerarRelatorioConciliadosVendaFiltroSimplesMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioConciliadosVendaFiltroSimplesMutation>;
export type GerarRelatorioConciliadosVendaFiltroSimplesMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioConciliadosVendaFiltroSimplesMutation, GerarRelatorioConciliadosVendaFiltroSimplesMutationVariables>;
export const GerarRelatorioEncontradosClienteLiquidacaoDocument = gql`
    mutation gerarRelatorioEncontradosClienteLiquidacao($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioEncontradosClienteLiquidacao(values: $values)
}
    `;
export type GerarRelatorioEncontradosClienteLiquidacaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables>;
export type GerarRelatorioEncontradosClienteLiquidacaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioEncontradosClienteLiquidacaoComponent = (props: GerarRelatorioEncontradosClienteLiquidacaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables> mutation={GerarRelatorioEncontradosClienteLiquidacaoDocument} {...props} />
    );
    
export type GerarRelatorioEncontradosClienteLiquidacaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables> & TChildProps;
export function withGerarRelatorioEncontradosClienteLiquidacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioEncontradosClienteLiquidacaoMutation,
  GerarRelatorioEncontradosClienteLiquidacaoMutationVariables,
  GerarRelatorioEncontradosClienteLiquidacaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables, GerarRelatorioEncontradosClienteLiquidacaoProps<TChildProps>>(GerarRelatorioEncontradosClienteLiquidacaoDocument, {
      alias: 'gerarRelatorioEncontradosClienteLiquidacao',
      ...operationOptions
    });
};
export type GerarRelatorioEncontradosClienteLiquidacaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioEncontradosClienteLiquidacaoMutation>;
export type GerarRelatorioEncontradosClienteLiquidacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioEncontradosClienteLiquidacaoMutation, GerarRelatorioEncontradosClienteLiquidacaoMutationVariables>;
export const GerarRelatorioEncontradosClientePagamentoDocument = gql`
    mutation gerarRelatorioEncontradosClientePagamento($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioEncontradosClientePagamento(values: $values)
}
    `;
export type GerarRelatorioEncontradosClientePagamentoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables>;
export type GerarRelatorioEncontradosClientePagamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables>, 'mutation'>;

    export const GerarRelatorioEncontradosClientePagamentoComponent = (props: GerarRelatorioEncontradosClientePagamentoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables> mutation={GerarRelatorioEncontradosClientePagamentoDocument} {...props} />
    );
    
export type GerarRelatorioEncontradosClientePagamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables> & TChildProps;
export function withGerarRelatorioEncontradosClientePagamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioEncontradosClientePagamentoMutation,
  GerarRelatorioEncontradosClientePagamentoMutationVariables,
  GerarRelatorioEncontradosClientePagamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables, GerarRelatorioEncontradosClientePagamentoProps<TChildProps>>(GerarRelatorioEncontradosClientePagamentoDocument, {
      alias: 'gerarRelatorioEncontradosClientePagamento',
      ...operationOptions
    });
};
export type GerarRelatorioEncontradosClientePagamentoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioEncontradosClientePagamentoMutation>;
export type GerarRelatorioEncontradosClientePagamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioEncontradosClientePagamentoMutation, GerarRelatorioEncontradosClientePagamentoMutationVariables>;
export const GerarRelatorioEncontradosClientePrevisaoDocument = gql`
    mutation gerarRelatorioEncontradosClientePrevisao($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioEncontradosClientePrevisao(values: $values)
}
    `;
export type GerarRelatorioEncontradosClientePrevisaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables>;
export type GerarRelatorioEncontradosClientePrevisaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioEncontradosClientePrevisaoComponent = (props: GerarRelatorioEncontradosClientePrevisaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables> mutation={GerarRelatorioEncontradosClientePrevisaoDocument} {...props} />
    );
    
export type GerarRelatorioEncontradosClientePrevisaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables> & TChildProps;
export function withGerarRelatorioEncontradosClientePrevisao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioEncontradosClientePrevisaoMutation,
  GerarRelatorioEncontradosClientePrevisaoMutationVariables,
  GerarRelatorioEncontradosClientePrevisaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables, GerarRelatorioEncontradosClientePrevisaoProps<TChildProps>>(GerarRelatorioEncontradosClientePrevisaoDocument, {
      alias: 'gerarRelatorioEncontradosClientePrevisao',
      ...operationOptions
    });
};
export type GerarRelatorioEncontradosClientePrevisaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioEncontradosClientePrevisaoMutation>;
export type GerarRelatorioEncontradosClientePrevisaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioEncontradosClientePrevisaoMutation, GerarRelatorioEncontradosClientePrevisaoMutationVariables>;
export const GerarRelatorioEncontradosClienteVendaDocument = gql`
    mutation gerarRelatorioEncontradosClienteVenda($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioEncontradosClienteVenda(values: $values)
}
    `;
export type GerarRelatorioEncontradosClienteVendaMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables>;
export type GerarRelatorioEncontradosClienteVendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables>, 'mutation'>;

    export const GerarRelatorioEncontradosClienteVendaComponent = (props: GerarRelatorioEncontradosClienteVendaComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables> mutation={GerarRelatorioEncontradosClienteVendaDocument} {...props} />
    );
    
export type GerarRelatorioEncontradosClienteVendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables> & TChildProps;
export function withGerarRelatorioEncontradosClienteVenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioEncontradosClienteVendaMutation,
  GerarRelatorioEncontradosClienteVendaMutationVariables,
  GerarRelatorioEncontradosClienteVendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables, GerarRelatorioEncontradosClienteVendaProps<TChildProps>>(GerarRelatorioEncontradosClienteVendaDocument, {
      alias: 'gerarRelatorioEncontradosClienteVenda',
      ...operationOptions
    });
};
export type GerarRelatorioEncontradosClienteVendaMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioEncontradosClienteVendaMutation>;
export type GerarRelatorioEncontradosClienteVendaMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioEncontradosClienteVendaMutation, GerarRelatorioEncontradosClienteVendaMutationVariables>;
export const GerarRelatorioExtratoConferenciaBoletoPrevisaoDocument = gql`
    mutation gerarRelatorioExtratoConferenciaBoletoPrevisao($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioExtratoConferenciaBoletoPrevisao(values: $values)
}
    `;
export type GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables>;
export type GerarRelatorioExtratoConferenciaBoletoPrevisaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioExtratoConferenciaBoletoPrevisaoComponent = (props: GerarRelatorioExtratoConferenciaBoletoPrevisaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables> mutation={GerarRelatorioExtratoConferenciaBoletoPrevisaoDocument} {...props} />
    );
    
export type GerarRelatorioExtratoConferenciaBoletoPrevisaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables> & TChildProps;
export function withGerarRelatorioExtratoConferenciaBoletoPrevisao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation,
  GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables,
  GerarRelatorioExtratoConferenciaBoletoPrevisaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables, GerarRelatorioExtratoConferenciaBoletoPrevisaoProps<TChildProps>>(GerarRelatorioExtratoConferenciaBoletoPrevisaoDocument, {
      alias: 'gerarRelatorioExtratoConferenciaBoletoPrevisao',
      ...operationOptions
    });
};
export type GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation>;
export type GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioExtratoConferenciaBoletoPrevisaoMutation, GerarRelatorioExtratoConferenciaBoletoPrevisaoMutationVariables>;
export const GerarRelatorioExtratoConferenciaLiquidacaoDocument = gql`
    mutation gerarRelatorioExtratoConferenciaLiquidacao($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioExtratoConferenciaLiquidacao(values: $values)
}
    `;
export type GerarRelatorioExtratoConferenciaLiquidacaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables>;
export type GerarRelatorioExtratoConferenciaLiquidacaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioExtratoConferenciaLiquidacaoComponent = (props: GerarRelatorioExtratoConferenciaLiquidacaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables> mutation={GerarRelatorioExtratoConferenciaLiquidacaoDocument} {...props} />
    );
    
export type GerarRelatorioExtratoConferenciaLiquidacaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables> & TChildProps;
export function withGerarRelatorioExtratoConferenciaLiquidacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioExtratoConferenciaLiquidacaoMutation,
  GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables,
  GerarRelatorioExtratoConferenciaLiquidacaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables, GerarRelatorioExtratoConferenciaLiquidacaoProps<TChildProps>>(GerarRelatorioExtratoConferenciaLiquidacaoDocument, {
      alias: 'gerarRelatorioExtratoConferenciaLiquidacao',
      ...operationOptions
    });
};
export type GerarRelatorioExtratoConferenciaLiquidacaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioExtratoConferenciaLiquidacaoMutation>;
export type GerarRelatorioExtratoConferenciaLiquidacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioExtratoConferenciaLiquidacaoMutation, GerarRelatorioExtratoConferenciaLiquidacaoMutationVariables>;
export const GerarRelatorioExtratoConferenciaPagamentoDocument = gql`
    mutation gerarRelatorioExtratoConferenciaPagamento($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioExtratoConferenciaPagamento(values: $values)
}
    `;
export type GerarRelatorioExtratoConferenciaPagamentoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables>;
export type GerarRelatorioExtratoConferenciaPagamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables>, 'mutation'>;

    export const GerarRelatorioExtratoConferenciaPagamentoComponent = (props: GerarRelatorioExtratoConferenciaPagamentoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables> mutation={GerarRelatorioExtratoConferenciaPagamentoDocument} {...props} />
    );
    
export type GerarRelatorioExtratoConferenciaPagamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables> & TChildProps;
export function withGerarRelatorioExtratoConferenciaPagamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioExtratoConferenciaPagamentoMutation,
  GerarRelatorioExtratoConferenciaPagamentoMutationVariables,
  GerarRelatorioExtratoConferenciaPagamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables, GerarRelatorioExtratoConferenciaPagamentoProps<TChildProps>>(GerarRelatorioExtratoConferenciaPagamentoDocument, {
      alias: 'gerarRelatorioExtratoConferenciaPagamento',
      ...operationOptions
    });
};
export type GerarRelatorioExtratoConferenciaPagamentoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioExtratoConferenciaPagamentoMutation>;
export type GerarRelatorioExtratoConferenciaPagamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioExtratoConferenciaPagamentoMutation, GerarRelatorioExtratoConferenciaPagamentoMutationVariables>;
export const GerarRelatorioExtratoConferenciaVendaDocument = gql`
    mutation gerarRelatorioExtratoConferenciaVenda($values: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioExtratoConferenciaVenda(values: $values)
}
    `;
export type GerarRelatorioExtratoConferenciaVendaMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables>;
export type GerarRelatorioExtratoConferenciaVendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables>, 'mutation'>;

    export const GerarRelatorioExtratoConferenciaVendaComponent = (props: GerarRelatorioExtratoConferenciaVendaComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables> mutation={GerarRelatorioExtratoConferenciaVendaDocument} {...props} />
    );
    
export type GerarRelatorioExtratoConferenciaVendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables> & TChildProps;
export function withGerarRelatorioExtratoConferenciaVenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioExtratoConferenciaVendaMutation,
  GerarRelatorioExtratoConferenciaVendaMutationVariables,
  GerarRelatorioExtratoConferenciaVendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables, GerarRelatorioExtratoConferenciaVendaProps<TChildProps>>(GerarRelatorioExtratoConferenciaVendaDocument, {
      alias: 'gerarRelatorioExtratoConferenciaVenda',
      ...operationOptions
    });
};
export type GerarRelatorioExtratoConferenciaVendaMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioExtratoConferenciaVendaMutation>;
export type GerarRelatorioExtratoConferenciaVendaMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioExtratoConferenciaVendaMutation, GerarRelatorioExtratoConferenciaVendaMutationVariables>;
export const GerarRelatorioFechamentoMensalTerminalDocument = gql`
    mutation gerarRelatorioFechamentoMensalTerminal($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioFechamentoMensalTerminal(filtro: $filtro)
}
    `;
export type GerarRelatorioFechamentoMensalTerminalMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables>;
export type GerarRelatorioFechamentoMensalTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables>, 'mutation'>;

    export const GerarRelatorioFechamentoMensalTerminalComponent = (props: GerarRelatorioFechamentoMensalTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables> mutation={GerarRelatorioFechamentoMensalTerminalDocument} {...props} />
    );
    
export type GerarRelatorioFechamentoMensalTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables> & TChildProps;
export function withGerarRelatorioFechamentoMensalTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioFechamentoMensalTerminalMutation,
  GerarRelatorioFechamentoMensalTerminalMutationVariables,
  GerarRelatorioFechamentoMensalTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables, GerarRelatorioFechamentoMensalTerminalProps<TChildProps>>(GerarRelatorioFechamentoMensalTerminalDocument, {
      alias: 'gerarRelatorioFechamentoMensalTerminal',
      ...operationOptions
    });
};
export type GerarRelatorioFechamentoMensalTerminalMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioFechamentoMensalTerminalMutation>;
export type GerarRelatorioFechamentoMensalTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioFechamentoMensalTerminalMutation, GerarRelatorioFechamentoMensalTerminalMutationVariables>;
export const GerarRelatorioFechamentoValoresLiquidosSemestralDocument = gql`
    mutation gerarRelatorioFechamentoValoresLiquidosSemestral($filtro: BeanFiltroRelatorioTelaInput, $botaoExportar: Boolean) {
  gerarRelatorioFechamentoValoresLiquidosSemestral(filtro: $filtro, botaoExportar: $botaoExportar) {
    nomeArquivo
    data
    mensagem
  }
}
    `;
export type GerarRelatorioFechamentoValoresLiquidosSemestralMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables>;
export type GerarRelatorioFechamentoValoresLiquidosSemestralComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables>, 'mutation'>;

    export const GerarRelatorioFechamentoValoresLiquidosSemestralComponent = (props: GerarRelatorioFechamentoValoresLiquidosSemestralComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables> mutation={GerarRelatorioFechamentoValoresLiquidosSemestralDocument} {...props} />
    );
    
export type GerarRelatorioFechamentoValoresLiquidosSemestralProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables> & TChildProps;
export function withGerarRelatorioFechamentoValoresLiquidosSemestral<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioFechamentoValoresLiquidosSemestralMutation,
  GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables,
  GerarRelatorioFechamentoValoresLiquidosSemestralProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables, GerarRelatorioFechamentoValoresLiquidosSemestralProps<TChildProps>>(GerarRelatorioFechamentoValoresLiquidosSemestralDocument, {
      alias: 'gerarRelatorioFechamentoValoresLiquidosSemestral',
      ...operationOptions
    });
};
export type GerarRelatorioFechamentoValoresLiquidosSemestralMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioFechamentoValoresLiquidosSemestralMutation>;
export type GerarRelatorioFechamentoValoresLiquidosSemestralMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioFechamentoValoresLiquidosSemestralMutation, GerarRelatorioFechamentoValoresLiquidosSemestralMutationVariables>;
export const GerarRelatorioFluxoRecebimentoCartaoDocument = gql`
    mutation gerarRelatorioFluxoRecebimentoCartao($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioFluxoRecebimentoCartao(filtro: $filtro)
}
    `;
export type GerarRelatorioFluxoRecebimentoCartaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables>;
export type GerarRelatorioFluxoRecebimentoCartaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioFluxoRecebimentoCartaoComponent = (props: GerarRelatorioFluxoRecebimentoCartaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables> mutation={GerarRelatorioFluxoRecebimentoCartaoDocument} {...props} />
    );
    
export type GerarRelatorioFluxoRecebimentoCartaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables> & TChildProps;
export function withGerarRelatorioFluxoRecebimentoCartao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioFluxoRecebimentoCartaoMutation,
  GerarRelatorioFluxoRecebimentoCartaoMutationVariables,
  GerarRelatorioFluxoRecebimentoCartaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables, GerarRelatorioFluxoRecebimentoCartaoProps<TChildProps>>(GerarRelatorioFluxoRecebimentoCartaoDocument, {
      alias: 'gerarRelatorioFluxoRecebimentoCartao',
      ...operationOptions
    });
};
export type GerarRelatorioFluxoRecebimentoCartaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioFluxoRecebimentoCartaoMutation>;
export type GerarRelatorioFluxoRecebimentoCartaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioFluxoRecebimentoCartaoMutation, GerarRelatorioFluxoRecebimentoCartaoMutationVariables>;
export const GerarRelatorioMensalidadesRecebidasDocument = gql`
    mutation gerarRelatorioMensalidadesRecebidas($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioMensalidadesRecebidas(filtro: $filtro)
}
    `;
export type GerarRelatorioMensalidadesRecebidasMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables>;
export type GerarRelatorioMensalidadesRecebidasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables>, 'mutation'>;

    export const GerarRelatorioMensalidadesRecebidasComponent = (props: GerarRelatorioMensalidadesRecebidasComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables> mutation={GerarRelatorioMensalidadesRecebidasDocument} {...props} />
    );
    
export type GerarRelatorioMensalidadesRecebidasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables> & TChildProps;
export function withGerarRelatorioMensalidadesRecebidas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioMensalidadesRecebidasMutation,
  GerarRelatorioMensalidadesRecebidasMutationVariables,
  GerarRelatorioMensalidadesRecebidasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables, GerarRelatorioMensalidadesRecebidasProps<TChildProps>>(GerarRelatorioMensalidadesRecebidasDocument, {
      alias: 'gerarRelatorioMensalidadesRecebidas',
      ...operationOptions
    });
};
export type GerarRelatorioMensalidadesRecebidasMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioMensalidadesRecebidasMutation>;
export type GerarRelatorioMensalidadesRecebidasMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioMensalidadesRecebidasMutation, GerarRelatorioMensalidadesRecebidasMutationVariables>;
export const GerarRelatorioMotivoAjusteDocument = gql`
    mutation gerarRelatorioMotivoAjuste($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioMotivoAjuste(filtro: $filtro)
}
    `;
export type GerarRelatorioMotivoAjusteMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables>;
export type GerarRelatorioMotivoAjusteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables>, 'mutation'>;

    export const GerarRelatorioMotivoAjusteComponent = (props: GerarRelatorioMotivoAjusteComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables> mutation={GerarRelatorioMotivoAjusteDocument} {...props} />
    );
    
export type GerarRelatorioMotivoAjusteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables> & TChildProps;
export function withGerarRelatorioMotivoAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioMotivoAjusteMutation,
  GerarRelatorioMotivoAjusteMutationVariables,
  GerarRelatorioMotivoAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables, GerarRelatorioMotivoAjusteProps<TChildProps>>(GerarRelatorioMotivoAjusteDocument, {
      alias: 'gerarRelatorioMotivoAjuste',
      ...operationOptions
    });
};
export type GerarRelatorioMotivoAjusteMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioMotivoAjusteMutation>;
export type GerarRelatorioMotivoAjusteMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioMotivoAjusteMutation, GerarRelatorioMotivoAjusteMutationVariables>;
export const GerarRelatorioMovimentosFechadosDocument = gql`
    mutation gerarRelatorioMovimentosFechados($chave: String, $tipo: String, $filtrode: Date, $filtroate: Date, $datainclusao: Date) {
  gerarRelatorioMovimentosFechados(chave: $chave, tipo: $tipo, filtrode: $filtrode, filtroate: $filtroate, datainclusao: $datainclusao)
}
    `;
export type GerarRelatorioMovimentosFechadosMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables>;
export type GerarRelatorioMovimentosFechadosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables>, 'mutation'>;

    export const GerarRelatorioMovimentosFechadosComponent = (props: GerarRelatorioMovimentosFechadosComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables> mutation={GerarRelatorioMovimentosFechadosDocument} {...props} />
    );
    
export type GerarRelatorioMovimentosFechadosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables> & TChildProps;
export function withGerarRelatorioMovimentosFechados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioMovimentosFechadosMutation,
  GerarRelatorioMovimentosFechadosMutationVariables,
  GerarRelatorioMovimentosFechadosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables, GerarRelatorioMovimentosFechadosProps<TChildProps>>(GerarRelatorioMovimentosFechadosDocument, {
      alias: 'gerarRelatorioMovimentosFechados',
      ...operationOptions
    });
};
export type GerarRelatorioMovimentosFechadosMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioMovimentosFechadosMutation>;
export type GerarRelatorioMovimentosFechadosMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioMovimentosFechadosMutation, GerarRelatorioMovimentosFechadosMutationVariables>;
export const GerarRelatorioOlxIntegracaoAjusteDocument = gql`
    mutation gerarRelatorioOLXIntegracaoAjuste($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioOLXIntegracaoAjuste(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioOlxIntegracaoAjusteMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables>;
export type GerarRelatorioOlxIntegracaoAjusteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables>, 'mutation'>;

    export const GerarRelatorioOlxIntegracaoAjusteComponent = (props: GerarRelatorioOlxIntegracaoAjusteComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables> mutation={GerarRelatorioOlxIntegracaoAjusteDocument} {...props} />
    );
    
export type GerarRelatorioOlxIntegracaoAjusteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables> & TChildProps;
export function withGerarRelatorioOlxIntegracaoAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioOlxIntegracaoAjusteMutation,
  GerarRelatorioOlxIntegracaoAjusteMutationVariables,
  GerarRelatorioOlxIntegracaoAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables, GerarRelatorioOlxIntegracaoAjusteProps<TChildProps>>(GerarRelatorioOlxIntegracaoAjusteDocument, {
      alias: 'gerarRelatorioOlxIntegracaoAjuste',
      ...operationOptions
    });
};
export type GerarRelatorioOlxIntegracaoAjusteMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioOlxIntegracaoAjusteMutation>;
export type GerarRelatorioOlxIntegracaoAjusteMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioOlxIntegracaoAjusteMutation, GerarRelatorioOlxIntegracaoAjusteMutationVariables>;
export const GerarRelatorioOlxIntegracaoPagamentoDocument = gql`
    mutation gerarRelatorioOLXIntegracaoPagamento($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioOLXIntegracaoPagamento(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioOlxIntegracaoPagamentoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables>;
export type GerarRelatorioOlxIntegracaoPagamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables>, 'mutation'>;

    export const GerarRelatorioOlxIntegracaoPagamentoComponent = (props: GerarRelatorioOlxIntegracaoPagamentoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables> mutation={GerarRelatorioOlxIntegracaoPagamentoDocument} {...props} />
    );
    
export type GerarRelatorioOlxIntegracaoPagamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables> & TChildProps;
export function withGerarRelatorioOlxIntegracaoPagamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioOlxIntegracaoPagamentoMutation,
  GerarRelatorioOlxIntegracaoPagamentoMutationVariables,
  GerarRelatorioOlxIntegracaoPagamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables, GerarRelatorioOlxIntegracaoPagamentoProps<TChildProps>>(GerarRelatorioOlxIntegracaoPagamentoDocument, {
      alias: 'gerarRelatorioOlxIntegracaoPagamento',
      ...operationOptions
    });
};
export type GerarRelatorioOlxIntegracaoPagamentoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioOlxIntegracaoPagamentoMutation>;
export type GerarRelatorioOlxIntegracaoPagamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioOlxIntegracaoPagamentoMutation, GerarRelatorioOlxIntegracaoPagamentoMutationVariables>;
export const GerarRelatorioOlxIntegracaoVendaDocument = gql`
    mutation gerarRelatorioOLXIntegracaoVenda($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioOLXIntegracaoVenda(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioOlxIntegracaoVendaMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables>;
export type GerarRelatorioOlxIntegracaoVendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables>, 'mutation'>;

    export const GerarRelatorioOlxIntegracaoVendaComponent = (props: GerarRelatorioOlxIntegracaoVendaComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables> mutation={GerarRelatorioOlxIntegracaoVendaDocument} {...props} />
    );
    
export type GerarRelatorioOlxIntegracaoVendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables> & TChildProps;
export function withGerarRelatorioOlxIntegracaoVenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioOlxIntegracaoVendaMutation,
  GerarRelatorioOlxIntegracaoVendaMutationVariables,
  GerarRelatorioOlxIntegracaoVendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables, GerarRelatorioOlxIntegracaoVendaProps<TChildProps>>(GerarRelatorioOlxIntegracaoVendaDocument, {
      alias: 'gerarRelatorioOlxIntegracaoVenda',
      ...operationOptions
    });
};
export type GerarRelatorioOlxIntegracaoVendaMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioOlxIntegracaoVendaMutation>;
export type GerarRelatorioOlxIntegracaoVendaMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioOlxIntegracaoVendaMutation, GerarRelatorioOlxIntegracaoVendaMutationVariables>;
export const GerarRelatorioPagamentoComDebitoAgrupadoVendaDocument = gql`
    mutation gerarRelatorioPagamentoComDebitoAgrupadoVenda($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioPagamentoComDebitoAgrupadoVenda(filtro: $filtro)
}
    `;
export type GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables>;
export type GerarRelatorioPagamentoComDebitoAgrupadoVendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables>, 'mutation'>;

    export const GerarRelatorioPagamentoComDebitoAgrupadoVendaComponent = (props: GerarRelatorioPagamentoComDebitoAgrupadoVendaComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables> mutation={GerarRelatorioPagamentoComDebitoAgrupadoVendaDocument} {...props} />
    );
    
export type GerarRelatorioPagamentoComDebitoAgrupadoVendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables> & TChildProps;
export function withGerarRelatorioPagamentoComDebitoAgrupadoVenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation,
  GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables,
  GerarRelatorioPagamentoComDebitoAgrupadoVendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables, GerarRelatorioPagamentoComDebitoAgrupadoVendaProps<TChildProps>>(GerarRelatorioPagamentoComDebitoAgrupadoVendaDocument, {
      alias: 'gerarRelatorioPagamentoComDebitoAgrupadoVenda',
      ...operationOptions
    });
};
export type GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation>;
export type GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioPagamentoComDebitoAgrupadoVendaMutation, GerarRelatorioPagamentoComDebitoAgrupadoVendaMutationVariables>;
export const GerarRelatorioPagamentosEfetivosDocument = gql`
    mutation gerarRelatorioPagamentosEfetivos($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioPagamentosEfetivos(filtro: $filtro)
}
    `;
export type GerarRelatorioPagamentosEfetivosMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables>;
export type GerarRelatorioPagamentosEfetivosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables>, 'mutation'>;

    export const GerarRelatorioPagamentosEfetivosComponent = (props: GerarRelatorioPagamentosEfetivosComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables> mutation={GerarRelatorioPagamentosEfetivosDocument} {...props} />
    );
    
export type GerarRelatorioPagamentosEfetivosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables> & TChildProps;
export function withGerarRelatorioPagamentosEfetivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioPagamentosEfetivosMutation,
  GerarRelatorioPagamentosEfetivosMutationVariables,
  GerarRelatorioPagamentosEfetivosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables, GerarRelatorioPagamentosEfetivosProps<TChildProps>>(GerarRelatorioPagamentosEfetivosDocument, {
      alias: 'gerarRelatorioPagamentosEfetivos',
      ...operationOptions
    });
};
export type GerarRelatorioPagamentosEfetivosMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioPagamentosEfetivosMutation>;
export type GerarRelatorioPagamentosEfetivosMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioPagamentosEfetivosMutation, GerarRelatorioPagamentosEfetivosMutationVariables>;
export const GerarRelatorioPrevisaoPagamentoDocument = gql`
    mutation gerarRelatorioPrevisaoPagamento($filtro: GerarRelatorioPrevisaoPagamentoInput, $consideraAjuste: Boolean) {
  gerarRelatorioPrevisaoPagamento(filtro: $filtro, consideraAjuste: $consideraAjuste)
}
    `;
export type GerarRelatorioPrevisaoPagamentoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables>;
export type GerarRelatorioPrevisaoPagamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables>, 'mutation'>;

    export const GerarRelatorioPrevisaoPagamentoComponent = (props: GerarRelatorioPrevisaoPagamentoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables> mutation={GerarRelatorioPrevisaoPagamentoDocument} {...props} />
    );
    
export type GerarRelatorioPrevisaoPagamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables> & TChildProps;
export function withGerarRelatorioPrevisaoPagamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioPrevisaoPagamentoMutation,
  GerarRelatorioPrevisaoPagamentoMutationVariables,
  GerarRelatorioPrevisaoPagamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables, GerarRelatorioPrevisaoPagamentoProps<TChildProps>>(GerarRelatorioPrevisaoPagamentoDocument, {
      alias: 'gerarRelatorioPrevisaoPagamento',
      ...operationOptions
    });
};
export type GerarRelatorioPrevisaoPagamentoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioPrevisaoPagamentoMutation>;
export type GerarRelatorioPrevisaoPagamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioPrevisaoPagamentoMutation, GerarRelatorioPrevisaoPagamentoMutationVariables>;
export const GerarRelatorioPrevisaoValoresLiquidosSemamalDocument = gql`
    mutation gerarRelatorioPrevisaoValoresLiquidosSemamal($filtro: GerarRelatorioPrevisaoValoresLiquidosSemanalInput, $consideraAjuste: Boolean, $datavendalimite: Date, $apurarPendentePagamento: Boolean, $analise: String) {
  gerarRelatorioPrevisaoValoresLiquidosSemamal(filtro: $filtro, consideraAjuste: $consideraAjuste, datavendalimite: $datavendalimite, apurarPendentePagamento: $apurarPendentePagamento, analise: $analise)
}
    `;
export type GerarRelatorioPrevisaoValoresLiquidosSemamalMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables>;
export type GerarRelatorioPrevisaoValoresLiquidosSemamalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables>, 'mutation'>;

    export const GerarRelatorioPrevisaoValoresLiquidosSemamalComponent = (props: GerarRelatorioPrevisaoValoresLiquidosSemamalComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables> mutation={GerarRelatorioPrevisaoValoresLiquidosSemamalDocument} {...props} />
    );
    
export type GerarRelatorioPrevisaoValoresLiquidosSemamalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables> & TChildProps;
export function withGerarRelatorioPrevisaoValoresLiquidosSemamal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioPrevisaoValoresLiquidosSemamalMutation,
  GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables,
  GerarRelatorioPrevisaoValoresLiquidosSemamalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables, GerarRelatorioPrevisaoValoresLiquidosSemamalProps<TChildProps>>(GerarRelatorioPrevisaoValoresLiquidosSemamalDocument, {
      alias: 'gerarRelatorioPrevisaoValoresLiquidosSemamal',
      ...operationOptions
    });
};
export type GerarRelatorioPrevisaoValoresLiquidosSemamalMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation>;
export type GerarRelatorioPrevisaoValoresLiquidosSemamalMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioPrevisaoValoresLiquidosSemamalMutation, GerarRelatorioPrevisaoValoresLiquidosSemamalMutationVariables>;
export const GerarRelatorioPrevisaoValoresLiquidosSemestralDocument = gql`
    mutation gerarRelatorioPrevisaoValoresLiquidosSemestral($filtro: GerarRelatorioPrevisaoValoresLiquidosSemestralInput) {
  gerarRelatorioPrevisaoValoresLiquidosSemestral(filtro: $filtro)
}
    `;
export type GerarRelatorioPrevisaoValoresLiquidosSemestralMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables>;
export type GerarRelatorioPrevisaoValoresLiquidosSemestralComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables>, 'mutation'>;

    export const GerarRelatorioPrevisaoValoresLiquidosSemestralComponent = (props: GerarRelatorioPrevisaoValoresLiquidosSemestralComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables> mutation={GerarRelatorioPrevisaoValoresLiquidosSemestralDocument} {...props} />
    );
    
export type GerarRelatorioPrevisaoValoresLiquidosSemestralProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables> & TChildProps;
export function withGerarRelatorioPrevisaoValoresLiquidosSemestral<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioPrevisaoValoresLiquidosSemestralMutation,
  GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables,
  GerarRelatorioPrevisaoValoresLiquidosSemestralProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables, GerarRelatorioPrevisaoValoresLiquidosSemestralProps<TChildProps>>(GerarRelatorioPrevisaoValoresLiquidosSemestralDocument, {
      alias: 'gerarRelatorioPrevisaoValoresLiquidosSemestral',
      ...operationOptions
    });
};
export type GerarRelatorioPrevisaoValoresLiquidosSemestralMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation>;
export type GerarRelatorioPrevisaoValoresLiquidosSemestralMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioPrevisaoValoresLiquidosSemestralMutation, GerarRelatorioPrevisaoValoresLiquidosSemestralMutationVariables>;
export const GerarRelatorioRejeitadosDocument = gql`
    mutation gerarRelatorioRejeitados($filtro: BeanFiltroRelatorioTelaInput, $tipo: String) {
  gerarRelatorioRejeitados(filtro: $filtro, tipo: $tipo)
}
    `;
export type GerarRelatorioRejeitadosMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables>;
export type GerarRelatorioRejeitadosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables>, 'mutation'>;

    export const GerarRelatorioRejeitadosComponent = (props: GerarRelatorioRejeitadosComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables> mutation={GerarRelatorioRejeitadosDocument} {...props} />
    );
    
export type GerarRelatorioRejeitadosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables> & TChildProps;
export function withGerarRelatorioRejeitados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioRejeitadosMutation,
  GerarRelatorioRejeitadosMutationVariables,
  GerarRelatorioRejeitadosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables, GerarRelatorioRejeitadosProps<TChildProps>>(GerarRelatorioRejeitadosDocument, {
      alias: 'gerarRelatorioRejeitados',
      ...operationOptions
    });
};
export type GerarRelatorioRejeitadosMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioRejeitadosMutation>;
export type GerarRelatorioRejeitadosMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioRejeitadosMutation, GerarRelatorioRejeitadosMutationVariables>;
export const GerarRelatorioSinteticoTipoConciliacaoDocument = gql`
    mutation gerarRelatorioSinteticoTipoConciliacao($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioSinteticoTipoConciliacao(filtro: $filtro)
}
    `;
export type GerarRelatorioSinteticoTipoConciliacaoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables>;
export type GerarRelatorioSinteticoTipoConciliacaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables>, 'mutation'>;

    export const GerarRelatorioSinteticoTipoConciliacaoComponent = (props: GerarRelatorioSinteticoTipoConciliacaoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables> mutation={GerarRelatorioSinteticoTipoConciliacaoDocument} {...props} />
    );
    
export type GerarRelatorioSinteticoTipoConciliacaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables> & TChildProps;
export function withGerarRelatorioSinteticoTipoConciliacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioSinteticoTipoConciliacaoMutation,
  GerarRelatorioSinteticoTipoConciliacaoMutationVariables,
  GerarRelatorioSinteticoTipoConciliacaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables, GerarRelatorioSinteticoTipoConciliacaoProps<TChildProps>>(GerarRelatorioSinteticoTipoConciliacaoDocument, {
      alias: 'gerarRelatorioSinteticoTipoConciliacao',
      ...operationOptions
    });
};
export type GerarRelatorioSinteticoTipoConciliacaoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioSinteticoTipoConciliacaoMutation>;
export type GerarRelatorioSinteticoTipoConciliacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioSinteticoTipoConciliacaoMutation, GerarRelatorioSinteticoTipoConciliacaoMutationVariables>;
export const GerarRelatorioTaxasDocument = gql`
    mutation gerarRelatorioTaxas($values: TaxasDAOInput, $exportar: String) {
  gerarRelatorioTaxas(values: $values, exportar: $exportar)
}
    `;
export type GerarRelatorioTaxasMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables>;
export type GerarRelatorioTaxasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables>, 'mutation'>;

    export const GerarRelatorioTaxasComponent = (props: GerarRelatorioTaxasComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables> mutation={GerarRelatorioTaxasDocument} {...props} />
    );
    
export type GerarRelatorioTaxasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables> & TChildProps;
export function withGerarRelatorioTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioTaxasMutation,
  GerarRelatorioTaxasMutationVariables,
  GerarRelatorioTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables, GerarRelatorioTaxasProps<TChildProps>>(GerarRelatorioTaxasDocument, {
      alias: 'gerarRelatorioTaxas',
      ...operationOptions
    });
};
export type GerarRelatorioTaxasMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioTaxasMutation>;
export type GerarRelatorioTaxasMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioTaxasMutation, GerarRelatorioTaxasMutationVariables>;
export const GerarRelatorioTaxasTerminalDocument = gql`
    mutation gerarRelatorioTaxasTerminal($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioTaxasTerminal(filtro: $filtro)
}
    `;
export type GerarRelatorioTaxasTerminalMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables>;
export type GerarRelatorioTaxasTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables>, 'mutation'>;

    export const GerarRelatorioTaxasTerminalComponent = (props: GerarRelatorioTaxasTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables> mutation={GerarRelatorioTaxasTerminalDocument} {...props} />
    );
    
export type GerarRelatorioTaxasTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables> & TChildProps;
export function withGerarRelatorioTaxasTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioTaxasTerminalMutation,
  GerarRelatorioTaxasTerminalMutationVariables,
  GerarRelatorioTaxasTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables, GerarRelatorioTaxasTerminalProps<TChildProps>>(GerarRelatorioTaxasTerminalDocument, {
      alias: 'gerarRelatorioTaxasTerminal',
      ...operationOptions
    });
};
export type GerarRelatorioTaxasTerminalMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioTaxasTerminalMutation>;
export type GerarRelatorioTaxasTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioTaxasTerminalMutation, GerarRelatorioTaxasTerminalMutationVariables>;
export const GerarRelatorioTerminaisAtivosDocument = gql`
    mutation gerarRelatorioTerminaisAtivos($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioTerminaisAtivos(filtro: $filtro)
}
    `;
export type GerarRelatorioTerminaisAtivosMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables>;
export type GerarRelatorioTerminaisAtivosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables>, 'mutation'>;

    export const GerarRelatorioTerminaisAtivosComponent = (props: GerarRelatorioTerminaisAtivosComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables> mutation={GerarRelatorioTerminaisAtivosDocument} {...props} />
    );
    
export type GerarRelatorioTerminaisAtivosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables> & TChildProps;
export function withGerarRelatorioTerminaisAtivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioTerminaisAtivosMutation,
  GerarRelatorioTerminaisAtivosMutationVariables,
  GerarRelatorioTerminaisAtivosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables, GerarRelatorioTerminaisAtivosProps<TChildProps>>(GerarRelatorioTerminaisAtivosDocument, {
      alias: 'gerarRelatorioTerminaisAtivos',
      ...operationOptions
    });
};
export type GerarRelatorioTerminaisAtivosMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioTerminaisAtivosMutation>;
export type GerarRelatorioTerminaisAtivosMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioTerminaisAtivosMutation, GerarRelatorioTerminaisAtivosMutationVariables>;
export const GerarRelatorioTerminaisSemMovimentoDocument = gql`
    mutation gerarRelatorioTerminaisSemMovimento($filtro: BeanFiltroRelatorioTelaInput, $valor: Float) {
  gerarRelatorioTerminaisSemMovimento(filtro: $filtro, valor: $valor)
}
    `;
export type GerarRelatorioTerminaisSemMovimentoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables>;
export type GerarRelatorioTerminaisSemMovimentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables>, 'mutation'>;

    export const GerarRelatorioTerminaisSemMovimentoComponent = (props: GerarRelatorioTerminaisSemMovimentoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables> mutation={GerarRelatorioTerminaisSemMovimentoDocument} {...props} />
    );
    
export type GerarRelatorioTerminaisSemMovimentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables> & TChildProps;
export function withGerarRelatorioTerminaisSemMovimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioTerminaisSemMovimentoMutation,
  GerarRelatorioTerminaisSemMovimentoMutationVariables,
  GerarRelatorioTerminaisSemMovimentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables, GerarRelatorioTerminaisSemMovimentoProps<TChildProps>>(GerarRelatorioTerminaisSemMovimentoDocument, {
      alias: 'gerarRelatorioTerminaisSemMovimento',
      ...operationOptions
    });
};
export type GerarRelatorioTerminaisSemMovimentoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioTerminaisSemMovimentoMutation>;
export type GerarRelatorioTerminaisSemMovimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioTerminaisSemMovimentoMutation, GerarRelatorioTerminaisSemMovimentoMutationVariables>;
export const GerarRelatorioTransacoesProblemasTefDocument = gql`
    mutation gerarRelatorioTransacoesProblemasTEF($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioTransacoesProblemasTEF(filtro: $filtro)
}
    `;
export type GerarRelatorioTransacoesProblemasTefMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables>;
export type GerarRelatorioTransacoesProblemasTefComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables>, 'mutation'>;

    export const GerarRelatorioTransacoesProblemasTefComponent = (props: GerarRelatorioTransacoesProblemasTefComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables> mutation={GerarRelatorioTransacoesProblemasTefDocument} {...props} />
    );
    
export type GerarRelatorioTransacoesProblemasTefProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables> & TChildProps;
export function withGerarRelatorioTransacoesProblemasTef<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioTransacoesProblemasTefMutation,
  GerarRelatorioTransacoesProblemasTefMutationVariables,
  GerarRelatorioTransacoesProblemasTefProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables, GerarRelatorioTransacoesProblemasTefProps<TChildProps>>(GerarRelatorioTransacoesProblemasTefDocument, {
      alias: 'gerarRelatorioTransacoesProblemasTef',
      ...operationOptions
    });
};
export type GerarRelatorioTransacoesProblemasTefMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioTransacoesProblemasTefMutation>;
export type GerarRelatorioTransacoesProblemasTefMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioTransacoesProblemasTefMutation, GerarRelatorioTransacoesProblemasTefMutationVariables>;
export const GerarRelatorioValoresDivergentesDocument = gql`
    mutation gerarRelatorioValoresDivergentes($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioValoresDivergentes(filtro: $filtro)
}
    `;
export type GerarRelatorioValoresDivergentesMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables>;
export type GerarRelatorioValoresDivergentesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables>, 'mutation'>;

    export const GerarRelatorioValoresDivergentesComponent = (props: GerarRelatorioValoresDivergentesComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables> mutation={GerarRelatorioValoresDivergentesDocument} {...props} />
    );
    
export type GerarRelatorioValoresDivergentesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables> & TChildProps;
export function withGerarRelatorioValoresDivergentes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioValoresDivergentesMutation,
  GerarRelatorioValoresDivergentesMutationVariables,
  GerarRelatorioValoresDivergentesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables, GerarRelatorioValoresDivergentesProps<TChildProps>>(GerarRelatorioValoresDivergentesDocument, {
      alias: 'gerarRelatorioValoresDivergentes',
      ...operationOptions
    });
};
export type GerarRelatorioValoresDivergentesMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioValoresDivergentesMutation>;
export type GerarRelatorioValoresDivergentesMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioValoresDivergentesMutation, GerarRelatorioValoresDivergentesMutationVariables>;
export const GerarRelatorioVendasDocument = gql`
    mutation gerarRelatorioVendas($filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioVendas(filtro: $filtro)
}
    `;
export type GerarRelatorioVendasMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables>;
export type GerarRelatorioVendasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables>, 'mutation'>;

    export const GerarRelatorioVendasComponent = (props: GerarRelatorioVendasComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables> mutation={GerarRelatorioVendasDocument} {...props} />
    );
    
export type GerarRelatorioVendasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables> & TChildProps;
export function withGerarRelatorioVendas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioVendasMutation,
  GerarRelatorioVendasMutationVariables,
  GerarRelatorioVendasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables, GerarRelatorioVendasProps<TChildProps>>(GerarRelatorioVendasDocument, {
      alias: 'gerarRelatorioVendas',
      ...operationOptions
    });
};
export type GerarRelatorioVendasMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioVendasMutation>;
export type GerarRelatorioVendasMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioVendasMutation, GerarRelatorioVendasMutationVariables>;
export const GerarRelatorioVendasPorLojaValorBrutoDocument = gql`
    mutation gerarRelatorioVendasPorLojaValorBruto($ajustes: String, $filtro: BeanFiltroRelatorioTelaInput) {
  gerarRelatorioVendasPorLojaValorBruto(ajustes: $ajustes, filtro: $filtro)
}
    `;
export type GerarRelatorioVendasPorLojaValorBrutoMutationFn = ApolloReactCommon.MutationFunction<GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables>;
export type GerarRelatorioVendasPorLojaValorBrutoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables>, 'mutation'>;

    export const GerarRelatorioVendasPorLojaValorBrutoComponent = (props: GerarRelatorioVendasPorLojaValorBrutoComponentProps) => (
      <ApolloReactComponents.Mutation<GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables> mutation={GerarRelatorioVendasPorLojaValorBrutoDocument} {...props} />
    );
    
export type GerarRelatorioVendasPorLojaValorBrutoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables> & TChildProps;
export function withGerarRelatorioVendasPorLojaValorBruto<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GerarRelatorioVendasPorLojaValorBrutoMutation,
  GerarRelatorioVendasPorLojaValorBrutoMutationVariables,
  GerarRelatorioVendasPorLojaValorBrutoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables, GerarRelatorioVendasPorLojaValorBrutoProps<TChildProps>>(GerarRelatorioVendasPorLojaValorBrutoDocument, {
      alias: 'gerarRelatorioVendasPorLojaValorBruto',
      ...operationOptions
    });
};
export type GerarRelatorioVendasPorLojaValorBrutoMutationResult = ApolloReactCommon.MutationResult<GerarRelatorioVendasPorLojaValorBrutoMutation>;
export type GerarRelatorioVendasPorLojaValorBrutoMutationOptions = ApolloReactCommon.BaseMutationOptions<GerarRelatorioVendasPorLojaValorBrutoMutation, GerarRelatorioVendasPorLojaValorBrutoMutationVariables>;
export const GravarCorrecaoDocument = gql`
    mutation gravarCorrecao($values: [UploadSelecionadosInput]) {
  gravarCorrecao(values: $values)
}
    `;
export type GravarCorrecaoMutationFn = ApolloReactCommon.MutationFunction<GravarCorrecaoMutation, GravarCorrecaoMutationVariables>;
export type GravarCorrecaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GravarCorrecaoMutation, GravarCorrecaoMutationVariables>, 'mutation'>;

    export const GravarCorrecaoComponent = (props: GravarCorrecaoComponentProps) => (
      <ApolloReactComponents.Mutation<GravarCorrecaoMutation, GravarCorrecaoMutationVariables> mutation={GravarCorrecaoDocument} {...props} />
    );
    
export type GravarCorrecaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<GravarCorrecaoMutation, GravarCorrecaoMutationVariables> & TChildProps;
export function withGravarCorrecao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GravarCorrecaoMutation,
  GravarCorrecaoMutationVariables,
  GravarCorrecaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, GravarCorrecaoMutation, GravarCorrecaoMutationVariables, GravarCorrecaoProps<TChildProps>>(GravarCorrecaoDocument, {
      alias: 'gravarCorrecao',
      ...operationOptions
    });
};
export type GravarCorrecaoMutationResult = ApolloReactCommon.MutationResult<GravarCorrecaoMutation>;
export type GravarCorrecaoMutationOptions = ApolloReactCommon.BaseMutationOptions<GravarCorrecaoMutation, GravarCorrecaoMutationVariables>;
export const ImportarTicketDocument = gql`
    mutation importarTicket($values: [TicketsImportacaoBeanInput]) {
  importarTicket(values: $values)
}
    `;
export type ImportarTicketMutationFn = ApolloReactCommon.MutationFunction<ImportarTicketMutation, ImportarTicketMutationVariables>;
export type ImportarTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportarTicketMutation, ImportarTicketMutationVariables>, 'mutation'>;

    export const ImportarTicketComponent = (props: ImportarTicketComponentProps) => (
      <ApolloReactComponents.Mutation<ImportarTicketMutation, ImportarTicketMutationVariables> mutation={ImportarTicketDocument} {...props} />
    );
    
export type ImportarTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportarTicketMutation, ImportarTicketMutationVariables> & TChildProps;
export function withImportarTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportarTicketMutation,
  ImportarTicketMutationVariables,
  ImportarTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportarTicketMutation, ImportarTicketMutationVariables, ImportarTicketProps<TChildProps>>(ImportarTicketDocument, {
      alias: 'importarTicket',
      ...operationOptions
    });
};
export type ImportarTicketMutationResult = ApolloReactCommon.MutationResult<ImportarTicketMutation>;
export type ImportarTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportarTicketMutation, ImportarTicketMutationVariables>;
export const ImportarTodosTicketsDocument = gql`
    mutation importarTodosTickets($values: ImportacaoTicketInput) {
  importarTodosTickets(values: $values)
}
    `;
export type ImportarTodosTicketsMutationFn = ApolloReactCommon.MutationFunction<ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables>;
export type ImportarTodosTicketsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables>, 'mutation'>;

    export const ImportarTodosTicketsComponent = (props: ImportarTodosTicketsComponentProps) => (
      <ApolloReactComponents.Mutation<ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables> mutation={ImportarTodosTicketsDocument} {...props} />
    );
    
export type ImportarTodosTicketsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables> & TChildProps;
export function withImportarTodosTickets<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportarTodosTicketsMutation,
  ImportarTodosTicketsMutationVariables,
  ImportarTodosTicketsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables, ImportarTodosTicketsProps<TChildProps>>(ImportarTodosTicketsDocument, {
      alias: 'importarTodosTickets',
      ...operationOptions
    });
};
export type ImportarTodosTicketsMutationResult = ApolloReactCommon.MutationResult<ImportarTodosTicketsMutation>;
export type ImportarTodosTicketsMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportarTodosTicketsMutation, ImportarTodosTicketsMutationVariables>;
export const IncluirTicketDocument = gql`
    mutation incluirTicket($values: InclusaoTicketGeralInput, $tickets: [TicketsInclusaoBeanInput]) {
  incluirTicket(values: $values, tickets: $tickets)
}
    `;
export type IncluirTicketMutationFn = ApolloReactCommon.MutationFunction<IncluirTicketMutation, IncluirTicketMutationVariables>;
export type IncluirTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<IncluirTicketMutation, IncluirTicketMutationVariables>, 'mutation'>;

    export const IncluirTicketComponent = (props: IncluirTicketComponentProps) => (
      <ApolloReactComponents.Mutation<IncluirTicketMutation, IncluirTicketMutationVariables> mutation={IncluirTicketDocument} {...props} />
    );
    
export type IncluirTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<IncluirTicketMutation, IncluirTicketMutationVariables> & TChildProps;
export function withIncluirTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IncluirTicketMutation,
  IncluirTicketMutationVariables,
  IncluirTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, IncluirTicketMutation, IncluirTicketMutationVariables, IncluirTicketProps<TChildProps>>(IncluirTicketDocument, {
      alias: 'incluirTicket',
      ...operationOptions
    });
};
export type IncluirTicketMutationResult = ApolloReactCommon.MutationResult<IncluirTicketMutation>;
export type IncluirTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<IncluirTicketMutation, IncluirTicketMutationVariables>;
export const InserirRapidoDocument = gql`
    mutation inserirRapido($values: InserirRapidoInput) {
  inserirRapido(values: $values)
}
    `;
export type InserirRapidoMutationFn = ApolloReactCommon.MutationFunction<InserirRapidoMutation, InserirRapidoMutationVariables>;
export type InserirRapidoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InserirRapidoMutation, InserirRapidoMutationVariables>, 'mutation'>;

    export const InserirRapidoComponent = (props: InserirRapidoComponentProps) => (
      <ApolloReactComponents.Mutation<InserirRapidoMutation, InserirRapidoMutationVariables> mutation={InserirRapidoDocument} {...props} />
    );
    
export type InserirRapidoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InserirRapidoMutation, InserirRapidoMutationVariables> & TChildProps;
export function withInserirRapido<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InserirRapidoMutation,
  InserirRapidoMutationVariables,
  InserirRapidoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InserirRapidoMutation, InserirRapidoMutationVariables, InserirRapidoProps<TChildProps>>(InserirRapidoDocument, {
      alias: 'inserirRapido',
      ...operationOptions
    });
};
export type InserirRapidoMutationResult = ApolloReactCommon.MutationResult<InserirRapidoMutation>;
export type InserirRapidoMutationOptions = ApolloReactCommon.BaseMutationOptions<InserirRapidoMutation, InserirRapidoMutationVariables>;
export const LogarUsuarioMestreDocument = gql`
    mutation logarUsuarioMestre($searchValues: LogarUsuarioMestreInput) {
  logarUsuarioMestre(searchValues: $searchValues)
}
    `;
export type LogarUsuarioMestreMutationFn = ApolloReactCommon.MutationFunction<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables>;
export type LogarUsuarioMestreComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables>, 'mutation'>;

    export const LogarUsuarioMestreComponent = (props: LogarUsuarioMestreComponentProps) => (
      <ApolloReactComponents.Mutation<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables> mutation={LogarUsuarioMestreDocument} {...props} />
    );
    
export type LogarUsuarioMestreProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables> & TChildProps;
export function withLogarUsuarioMestre<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogarUsuarioMestreMutation,
  LogarUsuarioMestreMutationVariables,
  LogarUsuarioMestreProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables, LogarUsuarioMestreProps<TChildProps>>(LogarUsuarioMestreDocument, {
      alias: 'logarUsuarioMestre',
      ...operationOptions
    });
};
export type LogarUsuarioMestreMutationResult = ApolloReactCommon.MutationResult<LogarUsuarioMestreMutation>;
export type LogarUsuarioMestreMutationOptions = ApolloReactCommon.BaseMutationOptions<LogarUsuarioMestreMutation, LogarUsuarioMestreMutationVariables>;
export const LogarUsuarioMestreRevendaDocument = gql`
    mutation logarUsuarioMestreRevenda($searchValues: LogarUsuarioMestreInput) {
  logarUsuarioMestreRevenda(searchValues: $searchValues)
}
    `;
export type LogarUsuarioMestreRevendaMutationFn = ApolloReactCommon.MutationFunction<LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables>;
export type LogarUsuarioMestreRevendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables>, 'mutation'>;

    export const LogarUsuarioMestreRevendaComponent = (props: LogarUsuarioMestreRevendaComponentProps) => (
      <ApolloReactComponents.Mutation<LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables> mutation={LogarUsuarioMestreRevendaDocument} {...props} />
    );
    
export type LogarUsuarioMestreRevendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables> & TChildProps;
export function withLogarUsuarioMestreRevenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogarUsuarioMestreRevendaMutation,
  LogarUsuarioMestreRevendaMutationVariables,
  LogarUsuarioMestreRevendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables, LogarUsuarioMestreRevendaProps<TChildProps>>(LogarUsuarioMestreRevendaDocument, {
      alias: 'logarUsuarioMestreRevenda',
      ...operationOptions
    });
};
export type LogarUsuarioMestreRevendaMutationResult = ApolloReactCommon.MutationResult<LogarUsuarioMestreRevendaMutation>;
export type LogarUsuarioMestreRevendaMutationOptions = ApolloReactCommon.BaseMutationOptions<LogarUsuarioMestreRevendaMutation, LogarUsuarioMestreRevendaMutationVariables>;
export const LoginDocument = gql`
    mutation login($username: String, $password: String, $values: RecaptchaInput) {
  login(username: $username, password: $password, values: $values)
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutation, LoginMutationVariables> & TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;
export type LogoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogoutMutation, LogoutMutationVariables>, 'mutation'>;

    export const LogoutComponent = (props: LogoutComponentProps) => (
      <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables> mutation={LogoutDocument} {...props} />
    );
    
export type LogoutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LogoutMutation, LogoutMutationVariables> & TChildProps;
export function withLogout<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LogoutMutation,
  LogoutMutationVariables,
  LogoutProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LogoutMutation, LogoutMutationVariables, LogoutProps<TChildProps>>(LogoutDocument, {
      alias: 'logout',
      ...operationOptions
    });
};
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ReconsiderarDesconsiderarTicketDocument = gql`
    mutation reconsiderarDesconsiderarTicket($codlinhasarqcli: String, $tipo: String, $acao: String) {
  reconsiderarDesconsiderarTicket(codlinhasarqcli: $codlinhasarqcli, tipo: $tipo, acao: $acao)
}
    `;
export type ReconsiderarDesconsiderarTicketMutationFn = ApolloReactCommon.MutationFunction<ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables>;
export type ReconsiderarDesconsiderarTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables>, 'mutation'>;

    export const ReconsiderarDesconsiderarTicketComponent = (props: ReconsiderarDesconsiderarTicketComponentProps) => (
      <ApolloReactComponents.Mutation<ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables> mutation={ReconsiderarDesconsiderarTicketDocument} {...props} />
    );
    
export type ReconsiderarDesconsiderarTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables> & TChildProps;
export function withReconsiderarDesconsiderarTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReconsiderarDesconsiderarTicketMutation,
  ReconsiderarDesconsiderarTicketMutationVariables,
  ReconsiderarDesconsiderarTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables, ReconsiderarDesconsiderarTicketProps<TChildProps>>(ReconsiderarDesconsiderarTicketDocument, {
      alias: 'reconsiderarDesconsiderarTicket',
      ...operationOptions
    });
};
export type ReconsiderarDesconsiderarTicketMutationResult = ApolloReactCommon.MutationResult<ReconsiderarDesconsiderarTicketMutation>;
export type ReconsiderarDesconsiderarTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<ReconsiderarDesconsiderarTicketMutation, ReconsiderarDesconsiderarTicketMutationVariables>;
export const RedefinirSenhaDocument = gql`
    mutation redefinirSenha($senhaAtual: String, $senha: String, $confirmaSenha: String) {
  redefinirSenha(senhaAtual: $senhaAtual, senha: $senha, confirmaSenha: $confirmaSenha)
}
    `;
export type RedefinirSenhaMutationFn = ApolloReactCommon.MutationFunction<RedefinirSenhaMutation, RedefinirSenhaMutationVariables>;
export type RedefinirSenhaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RedefinirSenhaMutation, RedefinirSenhaMutationVariables>, 'mutation'>;

    export const RedefinirSenhaComponent = (props: RedefinirSenhaComponentProps) => (
      <ApolloReactComponents.Mutation<RedefinirSenhaMutation, RedefinirSenhaMutationVariables> mutation={RedefinirSenhaDocument} {...props} />
    );
    
export type RedefinirSenhaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RedefinirSenhaMutation, RedefinirSenhaMutationVariables> & TChildProps;
export function withRedefinirSenha<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RedefinirSenhaMutation,
  RedefinirSenhaMutationVariables,
  RedefinirSenhaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RedefinirSenhaMutation, RedefinirSenhaMutationVariables, RedefinirSenhaProps<TChildProps>>(RedefinirSenhaDocument, {
      alias: 'redefinirSenha',
      ...operationOptions
    });
};
export type RedefinirSenhaMutationResult = ApolloReactCommon.MutationResult<RedefinirSenhaMutation>;
export type RedefinirSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<RedefinirSenhaMutation, RedefinirSenhaMutationVariables>;
export const ReprocessarManutencaoArquivosDocument = gql`
    mutation reprocessarManutencaoArquivos($values: ReprocessarManutencaoArquivosInput) {
  reprocessarManutencaoArquivos(values: $values) {
    editValues {
      codarquivo
    }
  }
}
    `;
export type ReprocessarManutencaoArquivosMutationFn = ApolloReactCommon.MutationFunction<ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables>;
export type ReprocessarManutencaoArquivosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables>, 'mutation'>;

    export const ReprocessarManutencaoArquivosComponent = (props: ReprocessarManutencaoArquivosComponentProps) => (
      <ApolloReactComponents.Mutation<ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables> mutation={ReprocessarManutencaoArquivosDocument} {...props} />
    );
    
export type ReprocessarManutencaoArquivosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables> & TChildProps;
export function withReprocessarManutencaoArquivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReprocessarManutencaoArquivosMutation,
  ReprocessarManutencaoArquivosMutationVariables,
  ReprocessarManutencaoArquivosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables, ReprocessarManutencaoArquivosProps<TChildProps>>(ReprocessarManutencaoArquivosDocument, {
      alias: 'reprocessarManutencaoArquivos',
      ...operationOptions
    });
};
export type ReprocessarManutencaoArquivosMutationResult = ApolloReactCommon.MutationResult<ReprocessarManutencaoArquivosMutation>;
export type ReprocessarManutencaoArquivosMutationOptions = ApolloReactCommon.BaseMutationOptions<ReprocessarManutencaoArquivosMutation, ReprocessarManutencaoArquivosMutationVariables>;
export const ReprocessarTicketDocument = gql`
    mutation reprocessarTicket($values: ReprocessarTicketInput) {
  reprocessarTicket(values: $values) {
    editValues {
      codarquivo
    }
  }
}
    `;
export type ReprocessarTicketMutationFn = ApolloReactCommon.MutationFunction<ReprocessarTicketMutation, ReprocessarTicketMutationVariables>;
export type ReprocessarTicketComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReprocessarTicketMutation, ReprocessarTicketMutationVariables>, 'mutation'>;

    export const ReprocessarTicketComponent = (props: ReprocessarTicketComponentProps) => (
      <ApolloReactComponents.Mutation<ReprocessarTicketMutation, ReprocessarTicketMutationVariables> mutation={ReprocessarTicketDocument} {...props} />
    );
    
export type ReprocessarTicketProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReprocessarTicketMutation, ReprocessarTicketMutationVariables> & TChildProps;
export function withReprocessarTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReprocessarTicketMutation,
  ReprocessarTicketMutationVariables,
  ReprocessarTicketProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReprocessarTicketMutation, ReprocessarTicketMutationVariables, ReprocessarTicketProps<TChildProps>>(ReprocessarTicketDocument, {
      alias: 'reprocessarTicket',
      ...operationOptions
    });
};
export type ReprocessarTicketMutationResult = ApolloReactCommon.MutationResult<ReprocessarTicketMutation>;
export type ReprocessarTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<ReprocessarTicketMutation, ReprocessarTicketMutationVariables>;
export const SalvarBucketS3AmazonDocument = gql`
    mutation salvarBucketS3Amazon($values: BucketS3AmazonCRUDInput, $insert: Boolean) {
  salvarBucketS3Amazon(values: $values, insert: $insert) {
    editValues {
      codbuckets3amazon
      codpessoapai
      keyid
      senha
      nome
      pasta
      bucketname
    }
    searchValues {
      codbuckets3amazon
    }
  }
}
    `;
export type SalvarBucketS3AmazonMutationFn = ApolloReactCommon.MutationFunction<SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables>;
export type SalvarBucketS3AmazonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables>, 'mutation'>;

    export const SalvarBucketS3AmazonComponent = (props: SalvarBucketS3AmazonComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables> mutation={SalvarBucketS3AmazonDocument} {...props} />
    );
    
export type SalvarBucketS3AmazonProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables> & TChildProps;
export function withSalvarBucketS3Amazon<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarBucketS3AmazonMutation,
  SalvarBucketS3AmazonMutationVariables,
  SalvarBucketS3AmazonProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables, SalvarBucketS3AmazonProps<TChildProps>>(SalvarBucketS3AmazonDocument, {
      alias: 'salvarBucketS3Amazon',
      ...operationOptions
    });
};
export type SalvarBucketS3AmazonMutationResult = ApolloReactCommon.MutationResult<SalvarBucketS3AmazonMutation>;
export type SalvarBucketS3AmazonMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarBucketS3AmazonMutation, SalvarBucketS3AmazonMutationVariables>;
export const SalvarDadosNFeDocument = gql`
    mutation salvarDadosNFe($values: DadosNfeCRUDInput, $insert: Boolean) {
  salvarDadosNFe(values: $values, insert: $insert) {
    editValues {
      codpessoa
    }
  }
}
    `;
export type SalvarDadosNFeMutationFn = ApolloReactCommon.MutationFunction<SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables>;
export type SalvarDadosNFeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables>, 'mutation'>;

    export const SalvarDadosNFeComponent = (props: SalvarDadosNFeComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables> mutation={SalvarDadosNFeDocument} {...props} />
    );
    
export type SalvarDadosNFeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables> & TChildProps;
export function withSalvarDadosNFe<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarDadosNFeMutation,
  SalvarDadosNFeMutationVariables,
  SalvarDadosNFeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables, SalvarDadosNFeProps<TChildProps>>(SalvarDadosNFeDocument, {
      alias: 'salvarDadosNFe',
      ...operationOptions
    });
};
export type SalvarDadosNFeMutationResult = ApolloReactCommon.MutationResult<SalvarDadosNFeMutation>;
export type SalvarDadosNFeMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarDadosNFeMutation, SalvarDadosNFeMutationVariables>;
export const SalvarIntegracaoAgendamentoDocument = gql`
    mutation salvarIntegracaoAgendamento($values: IntegracaoConciliaAgendamentoCRUDInput, $insert: Boolean) {
  salvarIntegracaoAgendamento(values: $values, insert: $insert) {
    editValues {
      codagendamentointegracao
      tipo
      codpessoapai
      codoperadora
      codpessoa
      conciliadores
      unidadeconciliadora
      contacorrente
      ajusteconsolidado
      status
      datainiciogeracao
      protocolo
      host
      porta
      login
      diretorioftp
      datainclusao
      dataalteracao
      exibirenccliente
    }
  }
}
    `;
export type SalvarIntegracaoAgendamentoMutationFn = ApolloReactCommon.MutationFunction<SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables>;
export type SalvarIntegracaoAgendamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables>, 'mutation'>;

    export const SalvarIntegracaoAgendamentoComponent = (props: SalvarIntegracaoAgendamentoComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables> mutation={SalvarIntegracaoAgendamentoDocument} {...props} />
    );
    
export type SalvarIntegracaoAgendamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables> & TChildProps;
export function withSalvarIntegracaoAgendamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarIntegracaoAgendamentoMutation,
  SalvarIntegracaoAgendamentoMutationVariables,
  SalvarIntegracaoAgendamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables, SalvarIntegracaoAgendamentoProps<TChildProps>>(SalvarIntegracaoAgendamentoDocument, {
      alias: 'salvarIntegracaoAgendamento',
      ...operationOptions
    });
};
export type SalvarIntegracaoAgendamentoMutationResult = ApolloReactCommon.MutationResult<SalvarIntegracaoAgendamentoMutation>;
export type SalvarIntegracaoAgendamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarIntegracaoAgendamentoMutation, SalvarIntegracaoAgendamentoMutationVariables>;
export const SalvarIntegracaoConciliaAjusteDocument = gql`
    mutation salvarIntegracaoConciliaAjuste($values: IntegracaoConciliaAjusteCRUDInput, $insert: Boolean) {
  salvarIntegracaoConciliaAjuste(values: $values, insert: $insert) {
    editValues {
      codorigemajuste
      codpessoapai
      codexterno
    }
    searchValues {
      codorigemajuste
      codpessoapai
      codexterno
    }
  }
}
    `;
export type SalvarIntegracaoConciliaAjusteMutationFn = ApolloReactCommon.MutationFunction<SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables>;
export type SalvarIntegracaoConciliaAjusteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables>, 'mutation'>;

    export const SalvarIntegracaoConciliaAjusteComponent = (props: SalvarIntegracaoConciliaAjusteComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables> mutation={SalvarIntegracaoConciliaAjusteDocument} {...props} />
    );
    
export type SalvarIntegracaoConciliaAjusteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables> & TChildProps;
export function withSalvarIntegracaoConciliaAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarIntegracaoConciliaAjusteMutation,
  SalvarIntegracaoConciliaAjusteMutationVariables,
  SalvarIntegracaoConciliaAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables, SalvarIntegracaoConciliaAjusteProps<TChildProps>>(SalvarIntegracaoConciliaAjusteDocument, {
      alias: 'salvarIntegracaoConciliaAjuste',
      ...operationOptions
    });
};
export type SalvarIntegracaoConciliaAjusteMutationResult = ApolloReactCommon.MutationResult<SalvarIntegracaoConciliaAjusteMutation>;
export type SalvarIntegracaoConciliaAjusteMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarIntegracaoConciliaAjusteMutation, SalvarIntegracaoConciliaAjusteMutationVariables>;
export const SalvarIntegracaoSoftExpressCadastroDocument = gql`
    mutation salvarIntegracaoSoftExpressCadastro($values: IntegracaoSoftExpressCadastroInput, $insert: Boolean) {
  salvarIntegracaoSoftExpressCadastro(values: $values, insert: $insert) {
    editValues {
      codpessoa
      codexterno
    }
    searchValues {
      codpessoa
      codexterno
    }
  }
}
    `;
export type SalvarIntegracaoSoftExpressCadastroMutationFn = ApolloReactCommon.MutationFunction<SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables>;
export type SalvarIntegracaoSoftExpressCadastroComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables>, 'mutation'>;

    export const SalvarIntegracaoSoftExpressCadastroComponent = (props: SalvarIntegracaoSoftExpressCadastroComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables> mutation={SalvarIntegracaoSoftExpressCadastroDocument} {...props} />
    );
    
export type SalvarIntegracaoSoftExpressCadastroProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables> & TChildProps;
export function withSalvarIntegracaoSoftExpressCadastro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarIntegracaoSoftExpressCadastroMutation,
  SalvarIntegracaoSoftExpressCadastroMutationVariables,
  SalvarIntegracaoSoftExpressCadastroProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables, SalvarIntegracaoSoftExpressCadastroProps<TChildProps>>(SalvarIntegracaoSoftExpressCadastroDocument, {
      alias: 'salvarIntegracaoSoftExpressCadastro',
      ...operationOptions
    });
};
export type SalvarIntegracaoSoftExpressCadastroMutationResult = ApolloReactCommon.MutationResult<SalvarIntegracaoSoftExpressCadastroMutation>;
export type SalvarIntegracaoSoftExpressCadastroMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarIntegracaoSoftExpressCadastroMutation, SalvarIntegracaoSoftExpressCadastroMutationVariables>;
export const SalvarIntegracaoTotvsCadastroOperadorasDocument = gql`
    mutation salvarIntegracaoTotvsCadastroOperadoras($values: IntegracaoTotvsCadastroOperadorasInput, $insert: Boolean) {
  salvarIntegracaoTotvsCadastroOperadoras(values: $values, insert: $insert) {
    editValues {
      codoperadora
      codpessoapai
      codexterno
    }
    searchValues {
      codoperadora
      codpessoapai
      codexterno
    }
  }
}
    `;
export type SalvarIntegracaoTotvsCadastroOperadorasMutationFn = ApolloReactCommon.MutationFunction<SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables>;
export type SalvarIntegracaoTotvsCadastroOperadorasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables>, 'mutation'>;

    export const SalvarIntegracaoTotvsCadastroOperadorasComponent = (props: SalvarIntegracaoTotvsCadastroOperadorasComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables> mutation={SalvarIntegracaoTotvsCadastroOperadorasDocument} {...props} />
    );
    
export type SalvarIntegracaoTotvsCadastroOperadorasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables> & TChildProps;
export function withSalvarIntegracaoTotvsCadastroOperadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarIntegracaoTotvsCadastroOperadorasMutation,
  SalvarIntegracaoTotvsCadastroOperadorasMutationVariables,
  SalvarIntegracaoTotvsCadastroOperadorasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables, SalvarIntegracaoTotvsCadastroOperadorasProps<TChildProps>>(SalvarIntegracaoTotvsCadastroOperadorasDocument, {
      alias: 'salvarIntegracaoTotvsCadastroOperadoras',
      ...operationOptions
    });
};
export type SalvarIntegracaoTotvsCadastroOperadorasMutationResult = ApolloReactCommon.MutationResult<SalvarIntegracaoTotvsCadastroOperadorasMutation>;
export type SalvarIntegracaoTotvsCadastroOperadorasMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarIntegracaoTotvsCadastroOperadorasMutation, SalvarIntegracaoTotvsCadastroOperadorasMutationVariables>;
export const SalvarMestreRevendaDocument = gql`
    mutation salvarMestreRevenda($values: MestresRevendaInput, $insert: Boolean) {
  salvarMestreRevenda(values: $values, insert: $insert) {
    codPessoaPai
    pessoa {
      pessoa {
        telefone
        email
      }
    }
    editValues {
      responsavel
      codpessoa
    }
  }
}
    `;
export type SalvarMestreRevendaMutationFn = ApolloReactCommon.MutationFunction<SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables>;
export type SalvarMestreRevendaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables>, 'mutation'>;

    export const SalvarMestreRevendaComponent = (props: SalvarMestreRevendaComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables> mutation={SalvarMestreRevendaDocument} {...props} />
    );
    
export type SalvarMestreRevendaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables> & TChildProps;
export function withSalvarMestreRevenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarMestreRevendaMutation,
  SalvarMestreRevendaMutationVariables,
  SalvarMestreRevendaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables, SalvarMestreRevendaProps<TChildProps>>(SalvarMestreRevendaDocument, {
      alias: 'salvarMestreRevenda',
      ...operationOptions
    });
};
export type SalvarMestreRevendaMutationResult = ApolloReactCommon.MutationResult<SalvarMestreRevendaMutation>;
export type SalvarMestreRevendaMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarMestreRevendaMutation, SalvarMestreRevendaMutationVariables>;
export const SalvarParametrosDocument = gql`
    mutation salvarParametros($values: ParametrosDAOInput, $insert: Boolean) {
  salvarParametros(values: $values, insert: $insert)
}
    `;
export type SalvarParametrosMutationFn = ApolloReactCommon.MutationFunction<SalvarParametrosMutation, SalvarParametrosMutationVariables>;
export type SalvarParametrosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarParametrosMutation, SalvarParametrosMutationVariables>, 'mutation'>;

    export const SalvarParametrosComponent = (props: SalvarParametrosComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarParametrosMutation, SalvarParametrosMutationVariables> mutation={SalvarParametrosDocument} {...props} />
    );
    
export type SalvarParametrosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarParametrosMutation, SalvarParametrosMutationVariables> & TChildProps;
export function withSalvarParametros<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarParametrosMutation,
  SalvarParametrosMutationVariables,
  SalvarParametrosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarParametrosMutation, SalvarParametrosMutationVariables, SalvarParametrosProps<TChildProps>>(SalvarParametrosDocument, {
      alias: 'salvarParametros',
      ...operationOptions
    });
};
export type SalvarParametrosMutationResult = ApolloReactCommon.MutationResult<SalvarParametrosMutation>;
export type SalvarParametrosMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarParametrosMutation, SalvarParametrosMutationVariables>;
export const SalvarParametrosTotvsDocument = gql`
    mutation salvarParametrosTOTVS($values: IntegracaoTotvsCadastroParametrosConciliadorInput, $insert: Boolean) {
  salvarParametrosTOTVS(values: $values, insert: $insert) {
    editValues {
      codpessoa
      codfilial
      opcional1
      opcional2
      coddepartamento
      codcentrocusto
      tipocontabil
      codmoeda
      codcontacaixa
      statuslancamento
      codcoligadaclifor
      codcoligadacontacaixa
      tiposacado
      codevento
    }
    searchValues {
      codpessoa
    }
  }
}
    `;
export type SalvarParametrosTotvsMutationFn = ApolloReactCommon.MutationFunction<SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables>;
export type SalvarParametrosTotvsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables>, 'mutation'>;

    export const SalvarParametrosTotvsComponent = (props: SalvarParametrosTotvsComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables> mutation={SalvarParametrosTotvsDocument} {...props} />
    );
    
export type SalvarParametrosTotvsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables> & TChildProps;
export function withSalvarParametrosTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarParametrosTotvsMutation,
  SalvarParametrosTotvsMutationVariables,
  SalvarParametrosTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables, SalvarParametrosTotvsProps<TChildProps>>(SalvarParametrosTotvsDocument, {
      alias: 'salvarParametrosTotvs',
      ...operationOptions
    });
};
export type SalvarParametrosTotvsMutationResult = ApolloReactCommon.MutationResult<SalvarParametrosTotvsMutation>;
export type SalvarParametrosTotvsMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarParametrosTotvsMutation, SalvarParametrosTotvsMutationVariables>;
export const SalvarPlanosConciliadoresContratoDocument = gql`
    mutation salvarPlanosConciliadoresContrato($values: PlanoConciliadoresContratoInput, $insert: Boolean) {
  salvarPlanosConciliadoresContrato(values: $values, insert: $insert)
}
    `;
export type SalvarPlanosConciliadoresContratoMutationFn = ApolloReactCommon.MutationFunction<SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables>;
export type SalvarPlanosConciliadoresContratoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables>, 'mutation'>;

    export const SalvarPlanosConciliadoresContratoComponent = (props: SalvarPlanosConciliadoresContratoComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables> mutation={SalvarPlanosConciliadoresContratoDocument} {...props} />
    );
    
export type SalvarPlanosConciliadoresContratoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables> & TChildProps;
export function withSalvarPlanosConciliadoresContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarPlanosConciliadoresContratoMutation,
  SalvarPlanosConciliadoresContratoMutationVariables,
  SalvarPlanosConciliadoresContratoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables, SalvarPlanosConciliadoresContratoProps<TChildProps>>(SalvarPlanosConciliadoresContratoDocument, {
      alias: 'salvarPlanosConciliadoresContrato',
      ...operationOptions
    });
};
export type SalvarPlanosConciliadoresContratoMutationResult = ApolloReactCommon.MutationResult<SalvarPlanosConciliadoresContratoMutation>;
export type SalvarPlanosConciliadoresContratoMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarPlanosConciliadoresContratoMutation, SalvarPlanosConciliadoresContratoMutationVariables>;
export const SalvarPlanosPosDocument = gql`
    mutation salvarPlanosPOS($values: PlanosCRUDInput, $insert: Boolean) {
  salvarPlanosPOS(values: $values, insert: $insert)
}
    `;
export type SalvarPlanosPosMutationFn = ApolloReactCommon.MutationFunction<SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables>;
export type SalvarPlanosPosComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables>, 'mutation'>;

    export const SalvarPlanosPosComponent = (props: SalvarPlanosPosComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables> mutation={SalvarPlanosPosDocument} {...props} />
    );
    
export type SalvarPlanosPosProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables> & TChildProps;
export function withSalvarPlanosPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarPlanosPosMutation,
  SalvarPlanosPosMutationVariables,
  SalvarPlanosPosProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables, SalvarPlanosPosProps<TChildProps>>(SalvarPlanosPosDocument, {
      alias: 'salvarPlanosPos',
      ...operationOptions
    });
};
export type SalvarPlanosPosMutationResult = ApolloReactCommon.MutationResult<SalvarPlanosPosMutation>;
export type SalvarPlanosPosMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarPlanosPosMutation, SalvarPlanosPosMutationVariables>;
export const SalvarPlanosPosTaxasDocument = gql`
    mutation salvarPlanosPosTaxas($values: PlanosTaxasCRUDInput, $insert: Boolean) {
  salvarPlanosPosTaxas(values: $values, insert: $insert)
}
    `;
export type SalvarPlanosPosTaxasMutationFn = ApolloReactCommon.MutationFunction<SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables>;
export type SalvarPlanosPosTaxasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables>, 'mutation'>;

    export const SalvarPlanosPosTaxasComponent = (props: SalvarPlanosPosTaxasComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables> mutation={SalvarPlanosPosTaxasDocument} {...props} />
    );
    
export type SalvarPlanosPosTaxasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables> & TChildProps;
export function withSalvarPlanosPosTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarPlanosPosTaxasMutation,
  SalvarPlanosPosTaxasMutationVariables,
  SalvarPlanosPosTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables, SalvarPlanosPosTaxasProps<TChildProps>>(SalvarPlanosPosTaxasDocument, {
      alias: 'salvarPlanosPosTaxas',
      ...operationOptions
    });
};
export type SalvarPlanosPosTaxasMutationResult = ApolloReactCommon.MutationResult<SalvarPlanosPosTaxasMutation>;
export type SalvarPlanosPosTaxasMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarPlanosPosTaxasMutation, SalvarPlanosPosTaxasMutationVariables>;
export const SalvarPlanosTerminalDocument = gql`
    mutation salvarPlanosTerminal($values: PlanosTerminalCRUDInput, $insert: Boolean) {
  salvarPlanosTerminal(values: $values, insert: $insert)
}
    `;
export type SalvarPlanosTerminalMutationFn = ApolloReactCommon.MutationFunction<SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables>;
export type SalvarPlanosTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables>, 'mutation'>;

    export const SalvarPlanosTerminalComponent = (props: SalvarPlanosTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables> mutation={SalvarPlanosTerminalDocument} {...props} />
    );
    
export type SalvarPlanosTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables> & TChildProps;
export function withSalvarPlanosTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarPlanosTerminalMutation,
  SalvarPlanosTerminalMutationVariables,
  SalvarPlanosTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables, SalvarPlanosTerminalProps<TChildProps>>(SalvarPlanosTerminalDocument, {
      alias: 'salvarPlanosTerminal',
      ...operationOptions
    });
};
export type SalvarPlanosTerminalMutationResult = ApolloReactCommon.MutationResult<SalvarPlanosTerminalMutation>;
export type SalvarPlanosTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarPlanosTerminalMutation, SalvarPlanosTerminalMutationVariables>;
export const SalvarRelatorioAgendamentoDocument = gql`
    mutation salvarRelatorioAgendamento($values: AgendamentoRelatorioInput, $insert: Boolean) {
  salvarRelatorioAgendamento(values: $values, insert: $insert)
}
    `;
export type SalvarRelatorioAgendamentoMutationFn = ApolloReactCommon.MutationFunction<SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables>;
export type SalvarRelatorioAgendamentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables>, 'mutation'>;

    export const SalvarRelatorioAgendamentoComponent = (props: SalvarRelatorioAgendamentoComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables> mutation={SalvarRelatorioAgendamentoDocument} {...props} />
    );
    
export type SalvarRelatorioAgendamentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables> & TChildProps;
export function withSalvarRelatorioAgendamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarRelatorioAgendamentoMutation,
  SalvarRelatorioAgendamentoMutationVariables,
  SalvarRelatorioAgendamentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables, SalvarRelatorioAgendamentoProps<TChildProps>>(SalvarRelatorioAgendamentoDocument, {
      alias: 'salvarRelatorioAgendamento',
      ...operationOptions
    });
};
export type SalvarRelatorioAgendamentoMutationResult = ApolloReactCommon.MutationResult<SalvarRelatorioAgendamentoMutation>;
export type SalvarRelatorioAgendamentoMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarRelatorioAgendamentoMutation, SalvarRelatorioAgendamentoMutationVariables>;
export const SalvarTefsDocument = gql`
    mutation salvarTefs($tefs: [UsuarioMestreTefsBeanInput]) {
  salvarTefs(tefs: $tefs)
}
    `;
export type SalvarTefsMutationFn = ApolloReactCommon.MutationFunction<SalvarTefsMutation, SalvarTefsMutationVariables>;
export type SalvarTefsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SalvarTefsMutation, SalvarTefsMutationVariables>, 'mutation'>;

    export const SalvarTefsComponent = (props: SalvarTefsComponentProps) => (
      <ApolloReactComponents.Mutation<SalvarTefsMutation, SalvarTefsMutationVariables> mutation={SalvarTefsDocument} {...props} />
    );
    
export type SalvarTefsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SalvarTefsMutation, SalvarTefsMutationVariables> & TChildProps;
export function withSalvarTefs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SalvarTefsMutation,
  SalvarTefsMutationVariables,
  SalvarTefsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SalvarTefsMutation, SalvarTefsMutationVariables, SalvarTefsProps<TChildProps>>(SalvarTefsDocument, {
      alias: 'salvarTefs',
      ...operationOptions
    });
};
export type SalvarTefsMutationResult = ApolloReactCommon.MutationResult<SalvarTefsMutation>;
export type SalvarTefsMutationOptions = ApolloReactCommon.BaseMutationOptions<SalvarTefsMutation, SalvarTefsMutationVariables>;
export const SaveClientesDocument = gql`
    mutation saveClientes($values: ClienteCRUDInput, $insert: Boolean) {
  saveClientes(values: $values, insert: $insert) {
    editValues {
      codpessoa
    }
    searchValues {
      codpessoa
    }
  }
}
    `;
export type SaveClientesMutationFn = ApolloReactCommon.MutationFunction<SaveClientesMutation, SaveClientesMutationVariables>;
export type SaveClientesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveClientesMutation, SaveClientesMutationVariables>, 'mutation'>;

    export const SaveClientesComponent = (props: SaveClientesComponentProps) => (
      <ApolloReactComponents.Mutation<SaveClientesMutation, SaveClientesMutationVariables> mutation={SaveClientesDocument} {...props} />
    );
    
export type SaveClientesProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveClientesMutation, SaveClientesMutationVariables> & TChildProps;
export function withSaveClientes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveClientesMutation,
  SaveClientesMutationVariables,
  SaveClientesProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveClientesMutation, SaveClientesMutationVariables, SaveClientesProps<TChildProps>>(SaveClientesDocument, {
      alias: 'saveClientes',
      ...operationOptions
    });
};
export type SaveClientesMutationResult = ApolloReactCommon.MutationResult<SaveClientesMutation>;
export type SaveClientesMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveClientesMutation, SaveClientesMutationVariables>;
export const SavePainelCadastroTaxaDocument = gql`
    mutation savePainelCadastroTaxa($values: SavePainelCadastroTaxaInput) {
  savePainelCadastroTaxa(values: $values)
}
    `;
export type SavePainelCadastroTaxaMutationFn = ApolloReactCommon.MutationFunction<SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables>;
export type SavePainelCadastroTaxaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables>, 'mutation'>;

    export const SavePainelCadastroTaxaComponent = (props: SavePainelCadastroTaxaComponentProps) => (
      <ApolloReactComponents.Mutation<SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables> mutation={SavePainelCadastroTaxaDocument} {...props} />
    );
    
export type SavePainelCadastroTaxaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables> & TChildProps;
export function withSavePainelCadastroTaxa<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePainelCadastroTaxaMutation,
  SavePainelCadastroTaxaMutationVariables,
  SavePainelCadastroTaxaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables, SavePainelCadastroTaxaProps<TChildProps>>(SavePainelCadastroTaxaDocument, {
      alias: 'savePainelCadastroTaxa',
      ...operationOptions
    });
};
export type SavePainelCadastroTaxaMutationResult = ApolloReactCommon.MutationResult<SavePainelCadastroTaxaMutation>;
export type SavePainelCadastroTaxaMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePainelCadastroTaxaMutation, SavePainelCadastroTaxaMutationVariables>;
export const SavePerfilDocument = gql`
    mutation savePerfil($values: PerfilCRUDInput, $insert: Boolean) {
  savePerfil(values: $values, insert: $insert) {
    editValues {
      codperfil
      codpessoacriador
    }
    searchValues {
      codperfil
      codpessoacriador
    }
  }
}
    `;
export type SavePerfilMutationFn = ApolloReactCommon.MutationFunction<SavePerfilMutation, SavePerfilMutationVariables>;
export type SavePerfilComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePerfilMutation, SavePerfilMutationVariables>, 'mutation'>;

    export const SavePerfilComponent = (props: SavePerfilComponentProps) => (
      <ApolloReactComponents.Mutation<SavePerfilMutation, SavePerfilMutationVariables> mutation={SavePerfilDocument} {...props} />
    );
    
export type SavePerfilProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SavePerfilMutation, SavePerfilMutationVariables> & TChildProps;
export function withSavePerfil<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePerfilMutation,
  SavePerfilMutationVariables,
  SavePerfilProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SavePerfilMutation, SavePerfilMutationVariables, SavePerfilProps<TChildProps>>(SavePerfilDocument, {
      alias: 'savePerfil',
      ...operationOptions
    });
};
export type SavePerfilMutationResult = ApolloReactCommon.MutationResult<SavePerfilMutation>;
export type SavePerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePerfilMutation, SavePerfilMutationVariables>;
export const SavePlanosConciliadoresDocument = gql`
    mutation savePlanosConciliadores($values: PlanosConciliadoresCRUDInput, $insert: Boolean) {
  savePlanosConciliadores(values: $values, insert: $insert) {
    editValues {
      codplanosconciliadores
      nome
      codpessoapai
      tarifacao
    }
    searchValues {
      codplanosconciliadores
    }
  }
}
    `;
export type SavePlanosConciliadoresMutationFn = ApolloReactCommon.MutationFunction<SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables>;
export type SavePlanosConciliadoresComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables>, 'mutation'>;

    export const SavePlanosConciliadoresComponent = (props: SavePlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Mutation<SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables> mutation={SavePlanosConciliadoresDocument} {...props} />
    );
    
export type SavePlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables> & TChildProps;
export function withSavePlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePlanosConciliadoresMutation,
  SavePlanosConciliadoresMutationVariables,
  SavePlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables, SavePlanosConciliadoresProps<TChildProps>>(SavePlanosConciliadoresDocument, {
      alias: 'savePlanosConciliadores',
      ...operationOptions
    });
};
export type SavePlanosConciliadoresMutationResult = ApolloReactCommon.MutationResult<SavePlanosConciliadoresMutation>;
export type SavePlanosConciliadoresMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePlanosConciliadoresMutation, SavePlanosConciliadoresMutationVariables>;
export const SavePlanosConciliadoresContratoDocument = gql`
    mutation savePlanosConciliadoresContrato($values: PlanosConciliadoresContratoCRUDInput, $insert: Boolean) {
  savePlanosConciliadoresContrato(values: $values, insert: $insert) {
    editValues {
      codplanosconciliadorescontrato
      seq
      codplanosconciliadores
      codpessoa
      codoperadora
      codexterno
      vigenciaDe
      vigenciaAte
      codpessoapai
    }
    searchValues {
      codplanosconciliadorescontrato
      seq
      codplanosconciliadores
    }
  }
}
    `;
export type SavePlanosConciliadoresContratoMutationFn = ApolloReactCommon.MutationFunction<SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables>;
export type SavePlanosConciliadoresContratoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables>, 'mutation'>;

    export const SavePlanosConciliadoresContratoComponent = (props: SavePlanosConciliadoresContratoComponentProps) => (
      <ApolloReactComponents.Mutation<SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables> mutation={SavePlanosConciliadoresContratoDocument} {...props} />
    );
    
export type SavePlanosConciliadoresContratoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables> & TChildProps;
export function withSavePlanosConciliadoresContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePlanosConciliadoresContratoMutation,
  SavePlanosConciliadoresContratoMutationVariables,
  SavePlanosConciliadoresContratoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables, SavePlanosConciliadoresContratoProps<TChildProps>>(SavePlanosConciliadoresContratoDocument, {
      alias: 'savePlanosConciliadoresContrato',
      ...operationOptions
    });
};
export type SavePlanosConciliadoresContratoMutationResult = ApolloReactCommon.MutationResult<SavePlanosConciliadoresContratoMutation>;
export type SavePlanosConciliadoresContratoMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePlanosConciliadoresContratoMutation, SavePlanosConciliadoresContratoMutationVariables>;
export const SavePlanosConciliadoresTaxasDocument = gql`
    mutation savePlanosConciliadoresTaxas($values: PlanosConciliadoresTaxasCRUDInput, $insert: Boolean) {
  savePlanosConciliadoresTaxas(values: $values, insert: $insert) {
    editValues {
      codplanosconciliadorestaxas
      codplanosconciliadores
      codprodutos
      de
      ate
      valorde
      valorate
      taxa
      duplicado
      bincartao
    }
    searchValues {
      codplanosconciliadorestaxas
      codplanosconciliadores
      codprodutos
      de
      ate
      valorde
      valorate
      taxa
      duplicado
      bincartao
    }
    converterProdutoTaxas
  }
}
    `;
export type SavePlanosConciliadoresTaxasMutationFn = ApolloReactCommon.MutationFunction<SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables>;
export type SavePlanosConciliadoresTaxasComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables>, 'mutation'>;

    export const SavePlanosConciliadoresTaxasComponent = (props: SavePlanosConciliadoresTaxasComponentProps) => (
      <ApolloReactComponents.Mutation<SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables> mutation={SavePlanosConciliadoresTaxasDocument} {...props} />
    );
    
export type SavePlanosConciliadoresTaxasProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables> & TChildProps;
export function withSavePlanosConciliadoresTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePlanosConciliadoresTaxasMutation,
  SavePlanosConciliadoresTaxasMutationVariables,
  SavePlanosConciliadoresTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables, SavePlanosConciliadoresTaxasProps<TChildProps>>(SavePlanosConciliadoresTaxasDocument, {
      alias: 'savePlanosConciliadoresTaxas',
      ...operationOptions
    });
};
export type SavePlanosConciliadoresTaxasMutationResult = ApolloReactCommon.MutationResult<SavePlanosConciliadoresTaxasMutation>;
export type SavePlanosConciliadoresTaxasMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePlanosConciliadoresTaxasMutation, SavePlanosConciliadoresTaxasMutationVariables>;
export const SaveTaxasAntecipacaoDocument = gql`
    mutation saveTaxasAntecipacao($values: TaxasAntecipacaoCRUDInput, $insert: Boolean) {
  saveTaxasAntecipacao(values: $values, insert: $insert) {
    editValues {
      codtaxaantecipacao
    }
    searchValues {
      codtaxaantecipacao
    }
  }
}
    `;
export type SaveTaxasAntecipacaoMutationFn = ApolloReactCommon.MutationFunction<SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables>;
export type SaveTaxasAntecipacaoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables>, 'mutation'>;

    export const SaveTaxasAntecipacaoComponent = (props: SaveTaxasAntecipacaoComponentProps) => (
      <ApolloReactComponents.Mutation<SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables> mutation={SaveTaxasAntecipacaoDocument} {...props} />
    );
    
export type SaveTaxasAntecipacaoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables> & TChildProps;
export function withSaveTaxasAntecipacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveTaxasAntecipacaoMutation,
  SaveTaxasAntecipacaoMutationVariables,
  SaveTaxasAntecipacaoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables, SaveTaxasAntecipacaoProps<TChildProps>>(SaveTaxasAntecipacaoDocument, {
      alias: 'saveTaxasAntecipacao',
      ...operationOptions
    });
};
export type SaveTaxasAntecipacaoMutationResult = ApolloReactCommon.MutationResult<SaveTaxasAntecipacaoMutation>;
export type SaveTaxasAntecipacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveTaxasAntecipacaoMutation, SaveTaxasAntecipacaoMutationVariables>;
export const SaveTerminalDocument = gql`
    mutation saveTerminal($values: TerminalCRUDInput, $insert: Boolean) {
  saveTerminal(values: $values, insert: $insert) {
    ativo
    datainiciocobranca
    concioperadora
    editValues {
      codexterno
      codpessoapai
      nome
      vinculo
      dataInclusao
      status
      codpessoa
      codoperadora
      codexternoconciliador
      codpessoacliente
    }
    searchValues {
      codexterno
      codpessoapai
      nome
      vinculo
      dataInclusao
      status
      codpessoa
      codoperadora
      codexternoconciliador
      codpessoacliente
    }
  }
}
    `;
export type SaveTerminalMutationFn = ApolloReactCommon.MutationFunction<SaveTerminalMutation, SaveTerminalMutationVariables>;
export type SaveTerminalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveTerminalMutation, SaveTerminalMutationVariables>, 'mutation'>;

    export const SaveTerminalComponent = (props: SaveTerminalComponentProps) => (
      <ApolloReactComponents.Mutation<SaveTerminalMutation, SaveTerminalMutationVariables> mutation={SaveTerminalDocument} {...props} />
    );
    
export type SaveTerminalProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveTerminalMutation, SaveTerminalMutationVariables> & TChildProps;
export function withSaveTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveTerminalMutation,
  SaveTerminalMutationVariables,
  SaveTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveTerminalMutation, SaveTerminalMutationVariables, SaveTerminalProps<TChildProps>>(SaveTerminalDocument, {
      alias: 'saveTerminal',
      ...operationOptions
    });
};
export type SaveTerminalMutationResult = ApolloReactCommon.MutationResult<SaveTerminalMutation>;
export type SaveTerminalMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveTerminalMutation, SaveTerminalMutationVariables>;
export const SaveUsuarioDocument = gql`
    mutation saveUsuario($values: UsuarioCRUDInput, $insert: Boolean) {
  saveUsuario(values: $values, insert: $insert) {
    pessoa {
      pessoa {
        codpessoa
      }
    }
    editValues {
      codperfil
    }
    searchValues {
      codpessoa
    }
  }
}
    `;
export type SaveUsuarioMutationFn = ApolloReactCommon.MutationFunction<SaveUsuarioMutation, SaveUsuarioMutationVariables>;
export type SaveUsuarioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveUsuarioMutation, SaveUsuarioMutationVariables>, 'mutation'>;

    export const SaveUsuarioComponent = (props: SaveUsuarioComponentProps) => (
      <ApolloReactComponents.Mutation<SaveUsuarioMutation, SaveUsuarioMutationVariables> mutation={SaveUsuarioDocument} {...props} />
    );
    
export type SaveUsuarioProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveUsuarioMutation, SaveUsuarioMutationVariables> & TChildProps;
export function withSaveUsuario<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveUsuarioMutation,
  SaveUsuarioMutationVariables,
  SaveUsuarioProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveUsuarioMutation, SaveUsuarioMutationVariables, SaveUsuarioProps<TChildProps>>(SaveUsuarioDocument, {
      alias: 'saveUsuario',
      ...operationOptions
    });
};
export type SaveUsuarioMutationResult = ApolloReactCommon.MutationResult<SaveUsuarioMutation>;
export type SaveUsuarioMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveUsuarioMutation, SaveUsuarioMutationVariables>;
export const TrocarSenhaDocument = gql`
    mutation trocarSenha($codpessoa: Int) {
  trocarSenha(codpessoa: $codpessoa)
}
    `;
export type TrocarSenhaMutationFn = ApolloReactCommon.MutationFunction<TrocarSenhaMutation, TrocarSenhaMutationVariables>;
export type TrocarSenhaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TrocarSenhaMutation, TrocarSenhaMutationVariables>, 'mutation'>;

    export const TrocarSenhaComponent = (props: TrocarSenhaComponentProps) => (
      <ApolloReactComponents.Mutation<TrocarSenhaMutation, TrocarSenhaMutationVariables> mutation={TrocarSenhaDocument} {...props} />
    );
    
export type TrocarSenhaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<TrocarSenhaMutation, TrocarSenhaMutationVariables> & TChildProps;
export function withTrocarSenha<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrocarSenhaMutation,
  TrocarSenhaMutationVariables,
  TrocarSenhaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, TrocarSenhaMutation, TrocarSenhaMutationVariables, TrocarSenhaProps<TChildProps>>(TrocarSenhaDocument, {
      alias: 'trocarSenha',
      ...operationOptions
    });
};
export type TrocarSenhaMutationResult = ApolloReactCommon.MutationResult<TrocarSenhaMutation>;
export type TrocarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<TrocarSenhaMutation, TrocarSenhaMutationVariables>;
export const UploadDocsEstabelecimentoDocument = gql`
    mutation uploadDocsEstabelecimento($nameFile: String, $tipoDoc: String, $file: [File], $values: ListarEstabelecimentosInput) {
  uploadDocsEstabelecimento(nameFile: $nameFile, tipoDoc: $tipoDoc, file: $file, values: $values)
}
    `;
export type UploadDocsEstabelecimentoMutationFn = ApolloReactCommon.MutationFunction<UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables>;
export type UploadDocsEstabelecimentoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables>, 'mutation'>;

    export const UploadDocsEstabelecimentoComponent = (props: UploadDocsEstabelecimentoComponentProps) => (
      <ApolloReactComponents.Mutation<UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables> mutation={UploadDocsEstabelecimentoDocument} {...props} />
    );
    
export type UploadDocsEstabelecimentoProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables> & TChildProps;
export function withUploadDocsEstabelecimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UploadDocsEstabelecimentoMutation,
  UploadDocsEstabelecimentoMutationVariables,
  UploadDocsEstabelecimentoProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables, UploadDocsEstabelecimentoProps<TChildProps>>(UploadDocsEstabelecimentoDocument, {
      alias: 'uploadDocsEstabelecimento',
      ...operationOptions
    });
};
export type UploadDocsEstabelecimentoMutationResult = ApolloReactCommon.MutationResult<UploadDocsEstabelecimentoMutation>;
export type UploadDocsEstabelecimentoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadDocsEstabelecimentoMutation, UploadDocsEstabelecimentoMutationVariables>;
export const UsuarioTrocarSenhaDocument = gql`
    mutation usuarioTrocarSenha($idTrocaSenha: String, $gravarResponsavel: Boolean, $senha: String, $confirmaSenha: String, $responsavel: String) {
  usuarioTrocarSenha(idTrocaSenha: $idTrocaSenha, gravarResponsavel: $gravarResponsavel, senha: $senha, confirmaSenha: $confirmaSenha, responsavel: $responsavel)
}
    `;
export type UsuarioTrocarSenhaMutationFn = ApolloReactCommon.MutationFunction<UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables>;
export type UsuarioTrocarSenhaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables>, 'mutation'>;

    export const UsuarioTrocarSenhaComponent = (props: UsuarioTrocarSenhaComponentProps) => (
      <ApolloReactComponents.Mutation<UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables> mutation={UsuarioTrocarSenhaDocument} {...props} />
    );
    
export type UsuarioTrocarSenhaProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables> & TChildProps;
export function withUsuarioTrocarSenha<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UsuarioTrocarSenhaMutation,
  UsuarioTrocarSenhaMutationVariables,
  UsuarioTrocarSenhaProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables, UsuarioTrocarSenhaProps<TChildProps>>(UsuarioTrocarSenhaDocument, {
      alias: 'usuarioTrocarSenha',
      ...operationOptions
    });
};
export type UsuarioTrocarSenhaMutationResult = ApolloReactCommon.MutationResult<UsuarioTrocarSenhaMutation>;
export type UsuarioTrocarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<UsuarioTrocarSenhaMutation, UsuarioTrocarSenhaMutationVariables>;
export const ValidarPendenciaCaadastroOperadoraDocument = gql`
    mutation validarPendenciaCaadastroOperadora {
  validarPendenciaCadastroOperadora
}
    `;
export type ValidarPendenciaCaadastroOperadoraMutationFn = ApolloReactCommon.MutationFunction<ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables>;
export type ValidarPendenciaCaadastroOperadoraComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables>, 'mutation'>;

    export const ValidarPendenciaCaadastroOperadoraComponent = (props: ValidarPendenciaCaadastroOperadoraComponentProps) => (
      <ApolloReactComponents.Mutation<ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables> mutation={ValidarPendenciaCaadastroOperadoraDocument} {...props} />
    );
    
export type ValidarPendenciaCaadastroOperadoraProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables> & TChildProps;
export function withValidarPendenciaCaadastroOperadora<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ValidarPendenciaCaadastroOperadoraMutation,
  ValidarPendenciaCaadastroOperadoraMutationVariables,
  ValidarPendenciaCaadastroOperadoraProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables, ValidarPendenciaCaadastroOperadoraProps<TChildProps>>(ValidarPendenciaCaadastroOperadoraDocument, {
      alias: 'validarPendenciaCaadastroOperadora',
      ...operationOptions
    });
};
export type ValidarPendenciaCaadastroOperadoraMutationResult = ApolloReactCommon.MutationResult<ValidarPendenciaCaadastroOperadoraMutation>;
export type ValidarPendenciaCaadastroOperadoraMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidarPendenciaCaadastroOperadoraMutation, ValidarPendenciaCaadastroOperadoraMutationVariables>;
export const BaixarArquivoEstabelecimentoDocument = gql`
    query baixarArquivoEstabelecimento($values: ArquivoDownloadInput) {
  baixarArquivoEstabelecimento(values: $values)
}
    `;
export type BaixarArquivoEstabelecimentoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BaixarArquivoEstabelecimentoQuery, BaixarArquivoEstabelecimentoQueryVariables>, 'query'>;

    export const BaixarArquivoEstabelecimentoComponent = (props: BaixarArquivoEstabelecimentoComponentProps) => (
      <ApolloReactComponents.Query<BaixarArquivoEstabelecimentoQuery, BaixarArquivoEstabelecimentoQueryVariables> query={BaixarArquivoEstabelecimentoDocument} {...props} />
    );
    
export type BaixarArquivoEstabelecimentoProps<TChildProps = {}> = ApolloReactHoc.DataProps<BaixarArquivoEstabelecimentoQuery, BaixarArquivoEstabelecimentoQueryVariables> & TChildProps;
export function withBaixarArquivoEstabelecimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BaixarArquivoEstabelecimentoQuery,
  BaixarArquivoEstabelecimentoQueryVariables,
  BaixarArquivoEstabelecimentoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, BaixarArquivoEstabelecimentoQuery, BaixarArquivoEstabelecimentoQueryVariables, BaixarArquivoEstabelecimentoProps<TChildProps>>(BaixarArquivoEstabelecimentoDocument, {
      alias: 'baixarArquivoEstabelecimento',
      ...operationOptions
    });
};
export type BaixarArquivoEstabelecimentoQueryResult = ApolloReactCommon.QueryResult<BaixarArquivoEstabelecimentoQuery, BaixarArquivoEstabelecimentoQueryVariables>;
export const GetBannerDocument = gql`
    query getBanner {
  getBanner
}
    `;
export type GetBannerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBannerQuery, GetBannerQueryVariables>, 'query'>;

    export const GetBannerComponent = (props: GetBannerComponentProps) => (
      <ApolloReactComponents.Query<GetBannerQuery, GetBannerQueryVariables> query={GetBannerDocument} {...props} />
    );
    
export type GetBannerProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBannerQuery, GetBannerQueryVariables> & TChildProps;
export function withGetBanner<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBannerQuery,
  GetBannerQueryVariables,
  GetBannerProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBannerQuery, GetBannerQueryVariables, GetBannerProps<TChildProps>>(GetBannerDocument, {
      alias: 'getBanner',
      ...operationOptions
    });
};
export type GetBannerQueryResult = ApolloReactCommon.QueryResult<GetBannerQuery, GetBannerQueryVariables>;
export const CanUploadDocument = gql`
    query canUpload($tipoDoc: String, $values: ListarEstabelecimentosInput) {
  canUpload(tipoDoc: $tipoDoc, values: $values) {
    canUpload
    exibirUrl
    documentos {
      nome
      status
      obs
      coddocumentos
    }
  }
}
    `;
export type CanUploadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CanUploadQuery, CanUploadQueryVariables>, 'query'>;

    export const CanUploadComponent = (props: CanUploadComponentProps) => (
      <ApolloReactComponents.Query<CanUploadQuery, CanUploadQueryVariables> query={CanUploadDocument} {...props} />
    );
    
export type CanUploadProps<TChildProps = {}> = ApolloReactHoc.DataProps<CanUploadQuery, CanUploadQueryVariables> & TChildProps;
export function withCanUpload<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CanUploadQuery,
  CanUploadQueryVariables,
  CanUploadProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CanUploadQuery, CanUploadQueryVariables, CanUploadProps<TChildProps>>(CanUploadDocument, {
      alias: 'canUpload',
      ...operationOptions
    });
};
export type CanUploadQueryResult = ApolloReactCommon.QueryResult<CanUploadQuery, CanUploadQueryVariables>;
export const DetalharTicketDocument = gql`
    query detalharTicket($values: TicketsDetalheInput) {
  detalharTicket(values: $values) {
    clientesLinha {
      ...clientes
    }
    clientesUpdateLinha {
      ...clientes
    }
    clientesOlxLinha {
      ...clientes
    }
    clientesLinhaDesconsiderados {
      ...clientes
    }
    clientesLinhaCancelados {
      ...clientes
    }
    operadoraLinha {
      ...operadoras
    }
    operadoraLiquidacaoLinha {
      ...operadoras
    }
    operadoraVendaLinha {
      ...operadoras
    }
    operadoraPrevisaoLinha {
      ...operadoras
    }
    antecipadoLinha {
      ...operadoras
    }
  }
}
    ${ClientesFragmentDoc}
${OperadorasFragmentDoc}`;
export type DetalharTicketComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DetalharTicketQuery, DetalharTicketQueryVariables>, 'query'>;

    export const DetalharTicketComponent = (props: DetalharTicketComponentProps) => (
      <ApolloReactComponents.Query<DetalharTicketQuery, DetalharTicketQueryVariables> query={DetalharTicketDocument} {...props} />
    );
    
export type DetalharTicketProps<TChildProps = {}> = ApolloReactHoc.DataProps<DetalharTicketQuery, DetalharTicketQueryVariables> & TChildProps;
export function withDetalharTicket<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DetalharTicketQuery,
  DetalharTicketQueryVariables,
  DetalharTicketProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, DetalharTicketQuery, DetalharTicketQueryVariables, DetalharTicketProps<TChildProps>>(DetalharTicketDocument, {
      alias: 'detalharTicket',
      ...operationOptions
    });
};
export type DetalharTicketQueryResult = ApolloReactCommon.QueryResult<DetalharTicketQuery, DetalharTicketQueryVariables>;
export const GetDownloadUnidadesNaoEncontradasExibicaoDocument = gql`
    query getDownloadUnidadesNaoEncontradasExibicao {
  downloadUnidadesNaoEncontradasExibicao
}
    `;
export type GetDownloadUnidadesNaoEncontradasExibicaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDownloadUnidadesNaoEncontradasExibicaoQuery, GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables>, 'query'>;

    export const GetDownloadUnidadesNaoEncontradasExibicaoComponent = (props: GetDownloadUnidadesNaoEncontradasExibicaoComponentProps) => (
      <ApolloReactComponents.Query<GetDownloadUnidadesNaoEncontradasExibicaoQuery, GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables> query={GetDownloadUnidadesNaoEncontradasExibicaoDocument} {...props} />
    );
    
export type GetDownloadUnidadesNaoEncontradasExibicaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDownloadUnidadesNaoEncontradasExibicaoQuery, GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables> & TChildProps;
export function withGetDownloadUnidadesNaoEncontradasExibicao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDownloadUnidadesNaoEncontradasExibicaoQuery,
  GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables,
  GetDownloadUnidadesNaoEncontradasExibicaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDownloadUnidadesNaoEncontradasExibicaoQuery, GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables, GetDownloadUnidadesNaoEncontradasExibicaoProps<TChildProps>>(GetDownloadUnidadesNaoEncontradasExibicaoDocument, {
      alias: 'getDownloadUnidadesNaoEncontradasExibicao',
      ...operationOptions
    });
};
export type GetDownloadUnidadesNaoEncontradasExibicaoQueryResult = ApolloReactCommon.QueryResult<GetDownloadUnidadesNaoEncontradasExibicaoQuery, GetDownloadUnidadesNaoEncontradasExibicaoQueryVariables>;
export const EstabelecimentoDownloadDocument = gql`
    query estabelecimentoDownload($values: ListarEstabelecimentosInput!, $tipoDocumento: String!) {
  estabelecimentoDownload(values: $values, tipoDocumento: $tipoDocumento)
}
    `;
export type EstabelecimentoDownloadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EstabelecimentoDownloadQuery, EstabelecimentoDownloadQueryVariables>, 'query'> & ({ variables: EstabelecimentoDownloadQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EstabelecimentoDownloadComponent = (props: EstabelecimentoDownloadComponentProps) => (
      <ApolloReactComponents.Query<EstabelecimentoDownloadQuery, EstabelecimentoDownloadQueryVariables> query={EstabelecimentoDownloadDocument} {...props} />
    );
    
export type EstabelecimentoDownloadProps<TChildProps = {}> = ApolloReactHoc.DataProps<EstabelecimentoDownloadQuery, EstabelecimentoDownloadQueryVariables> & TChildProps;
export function withEstabelecimentoDownload<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EstabelecimentoDownloadQuery,
  EstabelecimentoDownloadQueryVariables,
  EstabelecimentoDownloadProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EstabelecimentoDownloadQuery, EstabelecimentoDownloadQueryVariables, EstabelecimentoDownloadProps<TChildProps>>(EstabelecimentoDownloadDocument, {
      alias: 'estabelecimentoDownload',
      ...operationOptions
    });
};
export type EstabelecimentoDownloadQueryResult = ApolloReactCommon.QueryResult<EstabelecimentoDownloadQuery, EstabelecimentoDownloadQueryVariables>;
export const FiltrarItensUnidadesConciliadorasDocument = gql`
    query filtrarItensUnidadesConciliadoras($codPessoa: Int, $codOperadora: Int) {
  filtrarItensUnidadesConciliadoras(codPessoa: $codPessoa, codOperadora: $codOperadora) {
    label
    valor
  }
}
    `;
export type FiltrarItensUnidadesConciliadorasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FiltrarItensUnidadesConciliadorasQuery, FiltrarItensUnidadesConciliadorasQueryVariables>, 'query'>;

    export const FiltrarItensUnidadesConciliadorasComponent = (props: FiltrarItensUnidadesConciliadorasComponentProps) => (
      <ApolloReactComponents.Query<FiltrarItensUnidadesConciliadorasQuery, FiltrarItensUnidadesConciliadorasQueryVariables> query={FiltrarItensUnidadesConciliadorasDocument} {...props} />
    );
    
export type FiltrarItensUnidadesConciliadorasProps<TChildProps = {}> = ApolloReactHoc.DataProps<FiltrarItensUnidadesConciliadorasQuery, FiltrarItensUnidadesConciliadorasQueryVariables> & TChildProps;
export function withFiltrarItensUnidadesConciliadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FiltrarItensUnidadesConciliadorasQuery,
  FiltrarItensUnidadesConciliadorasQueryVariables,
  FiltrarItensUnidadesConciliadorasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FiltrarItensUnidadesConciliadorasQuery, FiltrarItensUnidadesConciliadorasQueryVariables, FiltrarItensUnidadesConciliadorasProps<TChildProps>>(FiltrarItensUnidadesConciliadorasDocument, {
      alias: 'filtrarItensUnidadesConciliadoras',
      ...operationOptions
    });
};
export type FiltrarItensUnidadesConciliadorasQueryResult = ApolloReactCommon.QueryResult<FiltrarItensUnidadesConciliadorasQuery, FiltrarItensUnidadesConciliadorasQueryVariables>;
export const GetAgendamentoIntegracaoDocument = gql`
    query getAgendamentoIntegracao($values: AgendamentoIntegracaoEditInput) {
  getAgendamentoIntegracao(values: $values) {
    editValues {
      codagendamentointegracao
      tipo
      codpessoapai
      codoperadora
      codpessoa
      conciliadores
      unidadeconciliadora
      contacorrente
      ajusteconsolidado
      status
      datainiciogeracao
      protocolo
      host
      porta
      login
      senha
      diretorioftp
      datainclusao
      dataalteracao
      exibirenccliente
      somenteajuste
    }
  }
}
    `;
export type GetAgendamentoIntegracaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAgendamentoIntegracaoQuery, GetAgendamentoIntegracaoQueryVariables>, 'query'>;

    export const GetAgendamentoIntegracaoComponent = (props: GetAgendamentoIntegracaoComponentProps) => (
      <ApolloReactComponents.Query<GetAgendamentoIntegracaoQuery, GetAgendamentoIntegracaoQueryVariables> query={GetAgendamentoIntegracaoDocument} {...props} />
    );
    
export type GetAgendamentoIntegracaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAgendamentoIntegracaoQuery, GetAgendamentoIntegracaoQueryVariables> & TChildProps;
export function withGetAgendamentoIntegracao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAgendamentoIntegracaoQuery,
  GetAgendamentoIntegracaoQueryVariables,
  GetAgendamentoIntegracaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAgendamentoIntegracaoQuery, GetAgendamentoIntegracaoQueryVariables, GetAgendamentoIntegracaoProps<TChildProps>>(GetAgendamentoIntegracaoDocument, {
      alias: 'getAgendamentoIntegracao',
      ...operationOptions
    });
};
export type GetAgendamentoIntegracaoQueryResult = ApolloReactCommon.QueryResult<GetAgendamentoIntegracaoQuery, GetAgendamentoIntegracaoQueryVariables>;
export const GetAgendamentoRelatorioDocument = gql`
    query getAgendamentoRelatorio($values: AgendamentoRelatorioEditInput) {
  getAgendamentoRelatorio(values: $values) {
    agendamentoRelatorio {
      codagendamentorelatorio
      relatorio
      parametros
      tipo
      frequencia
      validade
      datainclusao
    }
    agendamentoRelatorioEmail {
      email
    }
  }
}
    `;
export type GetAgendamentoRelatorioComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAgendamentoRelatorioQuery, GetAgendamentoRelatorioQueryVariables>, 'query'>;

    export const GetAgendamentoRelatorioComponent = (props: GetAgendamentoRelatorioComponentProps) => (
      <ApolloReactComponents.Query<GetAgendamentoRelatorioQuery, GetAgendamentoRelatorioQueryVariables> query={GetAgendamentoRelatorioDocument} {...props} />
    );
    
export type GetAgendamentoRelatorioProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAgendamentoRelatorioQuery, GetAgendamentoRelatorioQueryVariables> & TChildProps;
export function withGetAgendamentoRelatorio<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAgendamentoRelatorioQuery,
  GetAgendamentoRelatorioQueryVariables,
  GetAgendamentoRelatorioProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAgendamentoRelatorioQuery, GetAgendamentoRelatorioQueryVariables, GetAgendamentoRelatorioProps<TChildProps>>(GetAgendamentoRelatorioDocument, {
      alias: 'getAgendamentoRelatorio',
      ...operationOptions
    });
};
export type GetAgendamentoRelatorioQueryResult = ApolloReactCommon.QueryResult<GetAgendamentoRelatorioQuery, GetAgendamentoRelatorioQueryVariables>;
export const GetAgendamentosIntegracaoDocument = gql`
    query getAgendamentosIntegracao {
  getAgendamentosIntegracao {
    codagendamentointegracao
    tipo
  }
}
    `;
export type GetAgendamentosIntegracaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAgendamentosIntegracaoQuery, GetAgendamentosIntegracaoQueryVariables>, 'query'>;

    export const GetAgendamentosIntegracaoComponent = (props: GetAgendamentosIntegracaoComponentProps) => (
      <ApolloReactComponents.Query<GetAgendamentosIntegracaoQuery, GetAgendamentosIntegracaoQueryVariables> query={GetAgendamentosIntegracaoDocument} {...props} />
    );
    
export type GetAgendamentosIntegracaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAgendamentosIntegracaoQuery, GetAgendamentosIntegracaoQueryVariables> & TChildProps;
export function withGetAgendamentosIntegracao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAgendamentosIntegracaoQuery,
  GetAgendamentosIntegracaoQueryVariables,
  GetAgendamentosIntegracaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAgendamentosIntegracaoQuery, GetAgendamentosIntegracaoQueryVariables, GetAgendamentosIntegracaoProps<TChildProps>>(GetAgendamentosIntegracaoDocument, {
      alias: 'getAgendamentosIntegracao',
      ...operationOptions
    });
};
export type GetAgendamentosIntegracaoQueryResult = ApolloReactCommon.QueryResult<GetAgendamentosIntegracaoQuery, GetAgendamentosIntegracaoQueryVariables>;
export const GetAgendamentosRelatoriosDocument = gql`
    query getAgendamentosRelatorios {
  getAgendamentosRelatorios {
    tipoTxt
    frequencia
    emails
    codagendamentorelatorio
  }
}
    `;
export type GetAgendamentosRelatoriosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAgendamentosRelatoriosQuery, GetAgendamentosRelatoriosQueryVariables>, 'query'>;

    export const GetAgendamentosRelatoriosComponent = (props: GetAgendamentosRelatoriosComponentProps) => (
      <ApolloReactComponents.Query<GetAgendamentosRelatoriosQuery, GetAgendamentosRelatoriosQueryVariables> query={GetAgendamentosRelatoriosDocument} {...props} />
    );
    
export type GetAgendamentosRelatoriosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAgendamentosRelatoriosQuery, GetAgendamentosRelatoriosQueryVariables> & TChildProps;
export function withGetAgendamentosRelatorios<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAgendamentosRelatoriosQuery,
  GetAgendamentosRelatoriosQueryVariables,
  GetAgendamentosRelatoriosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAgendamentosRelatoriosQuery, GetAgendamentosRelatoriosQueryVariables, GetAgendamentosRelatoriosProps<TChildProps>>(GetAgendamentosRelatoriosDocument, {
      alias: 'getAgendamentosRelatorios',
      ...operationOptions
    });
};
export type GetAgendamentosRelatoriosQueryResult = ApolloReactCommon.QueryResult<GetAgendamentosRelatoriosQuery, GetAgendamentosRelatoriosQueryVariables>;
export const GetAllDadosNFeDocument = gql`
    query getAllDadosNFe($values: DadosNfeDAOInput) {
  getAllDadosNFe(values: $values) {
    codpessoa
    senhacertificado
    email
    senhaemail
    smtp
    porta
    naturezaoperacao
    regimeespecialtributacao
    opatantesimplesnacional
    incentivadorcultural
    pis
    cofins
    ir
    csll
    iss
    codcidade
    cnae
    valorminimocalculoir
    valorminimocalculopiscofinscsll
    subitem
    codigotributacaomunicipio
    descservico
    desccnae
    descsubitem
    codpessoaemissao
    nome
  }
}
    `;
export type GetAllDadosNFeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllDadosNFeQuery, GetAllDadosNFeQueryVariables>, 'query'>;

    export const GetAllDadosNFeComponent = (props: GetAllDadosNFeComponentProps) => (
      <ApolloReactComponents.Query<GetAllDadosNFeQuery, GetAllDadosNFeQueryVariables> query={GetAllDadosNFeDocument} {...props} />
    );
    
export type GetAllDadosNFeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAllDadosNFeQuery, GetAllDadosNFeQueryVariables> & TChildProps;
export function withGetAllDadosNFe<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAllDadosNFeQuery,
  GetAllDadosNFeQueryVariables,
  GetAllDadosNFeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAllDadosNFeQuery, GetAllDadosNFeQueryVariables, GetAllDadosNFeProps<TChildProps>>(GetAllDadosNFeDocument, {
      alias: 'getAllDadosNFe',
      ...operationOptions
    });
};
export type GetAllDadosNFeQueryResult = ApolloReactCommon.QueryResult<GetAllDadosNFeQuery, GetAllDadosNFeQueryVariables>;
export const GetAutenticacaoPainelDocument = gql`
    query getAutenticacaoPainel {
  getAutenticacaoPainel {
    login
    senha
  }
}
    `;
export type GetAutenticacaoPainelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAutenticacaoPainelQuery, GetAutenticacaoPainelQueryVariables>, 'query'>;

    export const GetAutenticacaoPainelComponent = (props: GetAutenticacaoPainelComponentProps) => (
      <ApolloReactComponents.Query<GetAutenticacaoPainelQuery, GetAutenticacaoPainelQueryVariables> query={GetAutenticacaoPainelDocument} {...props} />
    );
    
export type GetAutenticacaoPainelProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAutenticacaoPainelQuery, GetAutenticacaoPainelQueryVariables> & TChildProps;
export function withGetAutenticacaoPainel<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAutenticacaoPainelQuery,
  GetAutenticacaoPainelQueryVariables,
  GetAutenticacaoPainelProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAutenticacaoPainelQuery, GetAutenticacaoPainelQueryVariables, GetAutenticacaoPainelProps<TChildProps>>(GetAutenticacaoPainelDocument, {
      alias: 'getAutenticacaoPainel',
      ...operationOptions
    });
};
export type GetAutenticacaoPainelQueryResult = ApolloReactCommon.QueryResult<GetAutenticacaoPainelQuery, GetAutenticacaoPainelQueryVariables>;
export const GetBucketS3AmazonDocument = gql`
    query getBucketS3Amazon($values: BucketS3AmazonDAOInput) {
  getBucketS3Amazon(values: $values) {
    editValues {
      codbuckets3amazon
      codpessoapai
      keyid
      senha
      nome
      pasta
      bucketname
    }
  }
}
    `;
export type GetBucketS3AmazonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBucketS3AmazonQuery, GetBucketS3AmazonQueryVariables>, 'query'>;

    export const GetBucketS3AmazonComponent = (props: GetBucketS3AmazonComponentProps) => (
      <ApolloReactComponents.Query<GetBucketS3AmazonQuery, GetBucketS3AmazonQueryVariables> query={GetBucketS3AmazonDocument} {...props} />
    );
    
export type GetBucketS3AmazonProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBucketS3AmazonQuery, GetBucketS3AmazonQueryVariables> & TChildProps;
export function withGetBucketS3Amazon<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBucketS3AmazonQuery,
  GetBucketS3AmazonQueryVariables,
  GetBucketS3AmazonProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBucketS3AmazonQuery, GetBucketS3AmazonQueryVariables, GetBucketS3AmazonProps<TChildProps>>(GetBucketS3AmazonDocument, {
      alias: 'getBucketS3Amazon',
      ...operationOptions
    });
};
export type GetBucketS3AmazonQueryResult = ApolloReactCommon.QueryResult<GetBucketS3AmazonQuery, GetBucketS3AmazonQueryVariables>;
export const GetCarregamentoDocument = gql`
    query getCarregamento {
  getCarregamento {
    codarquivo
    tipo
    datarecebimento
    nomeOperadora
  }
}
    `;
export type GetCarregamentoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCarregamentoQuery, GetCarregamentoQueryVariables>, 'query'>;

    export const GetCarregamentoComponent = (props: GetCarregamentoComponentProps) => (
      <ApolloReactComponents.Query<GetCarregamentoQuery, GetCarregamentoQueryVariables> query={GetCarregamentoDocument} {...props} />
    );
    
export type GetCarregamentoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCarregamentoQuery, GetCarregamentoQueryVariables> & TChildProps;
export function withGetCarregamento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCarregamentoQuery,
  GetCarregamentoQueryVariables,
  GetCarregamentoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCarregamentoQuery, GetCarregamentoQueryVariables, GetCarregamentoProps<TChildProps>>(GetCarregamentoDocument, {
      alias: 'getCarregamento',
      ...operationOptions
    });
};
export type GetCarregamentoQueryResult = ApolloReactCommon.QueryResult<GetCarregamentoQuery, GetCarregamentoQueryVariables>;
export const GetClienteDocument = gql`
    query getCliente($values: ClienteDAOInput) {
  getCliente(values: $values) {
    token
    codEstabelecimentoBancario
    agencia
    conta
    diasCliente
    exibirUsuario
    enderecosAdicionais {
      codenderecoadicional
      codpessoa
      estado
      cidade
      bairro
      endereco
      complemento
      numero
      cep
      datainclusao
      tipoendereco
    }
    pessoa {
      pessoa {
        codpessoa
        principal
        nome
        bairro
        endereco
        numero
        complemento
        cep
        telefone
        email
        status
        tipo
      }
      pessoaF {
        cpf
      }
      pessoaJ {
        cnpj
        inscestadual
        inscmunicipal
      }
    }
    editValues {
      login
      codcidade
      senha
      inss
      iss
      codpessoapai
    }
  }
}
    `;
export type GetClienteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetClienteQuery, GetClienteQueryVariables>, 'query'>;

    export const GetClienteComponent = (props: GetClienteComponentProps) => (
      <ApolloReactComponents.Query<GetClienteQuery, GetClienteQueryVariables> query={GetClienteDocument} {...props} />
    );
    
export type GetClienteProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetClienteQuery, GetClienteQueryVariables> & TChildProps;
export function withGetCliente<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetClienteQuery,
  GetClienteQueryVariables,
  GetClienteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetClienteQuery, GetClienteQueryVariables, GetClienteProps<TChildProps>>(GetClienteDocument, {
      alias: 'getCliente',
      ...operationOptions
    });
};
export type GetClienteQueryResult = ApolloReactCommon.QueryResult<GetClienteQuery, GetClienteQueryVariables>;
export const GetClientesDocument = gql`
    query getClientes {
  getClientes {
    codpessoa
    nome
    status
  }
}
    `;
export type GetClientesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetClientesQuery, GetClientesQueryVariables>, 'query'>;

    export const GetClientesComponent = (props: GetClientesComponentProps) => (
      <ApolloReactComponents.Query<GetClientesQuery, GetClientesQueryVariables> query={GetClientesDocument} {...props} />
    );
    
export type GetClientesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetClientesQuery, GetClientesQueryVariables> & TChildProps;
export function withGetClientes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetClientesQuery,
  GetClientesQueryVariables,
  GetClientesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetClientesQuery, GetClientesQueryVariables, GetClientesProps<TChildProps>>(GetClientesDocument, {
      alias: 'getClientes',
      ...operationOptions
    });
};
export type GetClientesQueryResult = ApolloReactCommon.QueryResult<GetClientesQuery, GetClientesQueryVariables>;
export const GetCodigoOperadoraTotvsDocument = gql`
    query getCodigoOperadoraTOTVS($values: OperadorasTotvsDAOInput) {
  getCodigoOperadoraTOTVS(values: $values) {
    editValues {
      codoperadora
      codpessoapai
      codexterno
    }
  }
}
    `;
export type GetCodigoOperadoraTotvsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCodigoOperadoraTotvsQuery, GetCodigoOperadoraTotvsQueryVariables>, 'query'>;

    export const GetCodigoOperadoraTotvsComponent = (props: GetCodigoOperadoraTotvsComponentProps) => (
      <ApolloReactComponents.Query<GetCodigoOperadoraTotvsQuery, GetCodigoOperadoraTotvsQueryVariables> query={GetCodigoOperadoraTotvsDocument} {...props} />
    );
    
export type GetCodigoOperadoraTotvsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCodigoOperadoraTotvsQuery, GetCodigoOperadoraTotvsQueryVariables> & TChildProps;
export function withGetCodigoOperadoraTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCodigoOperadoraTotvsQuery,
  GetCodigoOperadoraTotvsQueryVariables,
  GetCodigoOperadoraTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCodigoOperadoraTotvsQuery, GetCodigoOperadoraTotvsQueryVariables, GetCodigoOperadoraTotvsProps<TChildProps>>(GetCodigoOperadoraTotvsDocument, {
      alias: 'getCodigoOperadoraTotvs',
      ...operationOptions
    });
};
export type GetCodigoOperadoraTotvsQueryResult = ApolloReactCommon.QueryResult<GetCodigoOperadoraTotvsQuery, GetCodigoOperadoraTotvsQueryVariables>;
export const GetDadoNFeDocument = gql`
    query getDadoNFe($values: DadosNfeDAOInput) {
  getDadoNFe(values: $values) {
    editValues {
      codpessoa
      senhacertificado
      email
      senhaemail
      smtp
      porta
      naturezaoperacao
      regimeespecialtributacao
      opatantesimplesnacional
      incentivadorcultural
      pis
      cofins
      ir
      csll
      iss
      codcidade
      cnae
      valorminimocalculoir
      valorminimocalculopiscofinscsll
      subitem
      codigotributacaomunicipio
      descservico
      desccnae
      descsubitem
      codpessoaemissao
    }
  }
}
    `;
export type GetDadoNFeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDadoNFeQuery, GetDadoNFeQueryVariables>, 'query'>;

    export const GetDadoNFeComponent = (props: GetDadoNFeComponentProps) => (
      <ApolloReactComponents.Query<GetDadoNFeQuery, GetDadoNFeQueryVariables> query={GetDadoNFeDocument} {...props} />
    );
    
export type GetDadoNFeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDadoNFeQuery, GetDadoNFeQueryVariables> & TChildProps;
export function withGetDadoNFe<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDadoNFeQuery,
  GetDadoNFeQueryVariables,
  GetDadoNFeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDadoNFeQuery, GetDadoNFeQueryVariables, GetDadoNFeProps<TChildProps>>(GetDadoNFeDocument, {
      alias: 'getDadoNFe',
      ...operationOptions
    });
};
export type GetDadoNFeQueryResult = ApolloReactCommon.QueryResult<GetDadoNFeQuery, GetDadoNFeQueryVariables>;
export const GetDashBoardDocument = gql`
    query getDashBoard($values: DashBoardInput) {
  getDashBoard(values: $values) {
    codpessoapai
    seriesVendaOperadora {
      name
      y
      sliced
      selected
    }
    seriesVendaTipo {
      name
      y
      sliced
      selected
    }
    categoriaVendaHora
    seriesVendaHora
    categoriaTaxaMedia
    seriesTaxaMedia {
      name
      y
      sliced
      selected
      data
    }
    seriesTicketMedio {
      name
      y
      sliced
      selected
    }
    categoriaVendaDia
    seriesVendaDia
    mensagem
  }
}
    `;
export type GetDashBoardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDashBoardQuery, GetDashBoardQueryVariables>, 'query'>;

    export const GetDashBoardComponent = (props: GetDashBoardComponentProps) => (
      <ApolloReactComponents.Query<GetDashBoardQuery, GetDashBoardQueryVariables> query={GetDashBoardDocument} {...props} />
    );
    
export type GetDashBoardProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDashBoardQuery, GetDashBoardQueryVariables> & TChildProps;
export function withGetDashBoard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDashBoardQuery,
  GetDashBoardQueryVariables,
  GetDashBoardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDashBoardQuery, GetDashBoardQueryVariables, GetDashBoardProps<TChildProps>>(GetDashBoardDocument, {
      alias: 'getDashBoard',
      ...operationOptions
    });
};
export type GetDashBoardQueryResult = ApolloReactCommon.QueryResult<GetDashBoardQuery, GetDashBoardQueryVariables>;
export const GetInfoNotaDocument = gql`
    query getInfoNota($values: FaturamentoMovimentoBeanInput) {
  getInfoNota(values: $values) {
    status
    chave
    numeronota
    protocolo
    mensagem
    reenviaremail
    reenviarnota
  }
}
    `;
export type GetInfoNotaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInfoNotaQuery, GetInfoNotaQueryVariables>, 'query'>;

    export const GetInfoNotaComponent = (props: GetInfoNotaComponentProps) => (
      <ApolloReactComponents.Query<GetInfoNotaQuery, GetInfoNotaQueryVariables> query={GetInfoNotaDocument} {...props} />
    );
    
export type GetInfoNotaProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInfoNotaQuery, GetInfoNotaQueryVariables> & TChildProps;
export function withGetInfoNota<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInfoNotaQuery,
  GetInfoNotaQueryVariables,
  GetInfoNotaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInfoNotaQuery, GetInfoNotaQueryVariables, GetInfoNotaProps<TChildProps>>(GetInfoNotaDocument, {
      alias: 'getInfoNota',
      ...operationOptions
    });
};
export type GetInfoNotaQueryResult = ApolloReactCommon.QueryResult<GetInfoNotaQuery, GetInfoNotaQueryVariables>;
export const GetInfoUsuarrioTrocarSenhaDocument = gql`
    query getInfoUsuarrioTrocarSenha($param: String) {
  getInfoUsuarrioTrocarSenha(param: $param) {
    senhaDefinida
    expirado
    mensagemSolicitaSenha
    goLogin
  }
}
    `;
export type GetInfoUsuarrioTrocarSenhaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables>, 'query'>;

    export const GetInfoUsuarrioTrocarSenhaComponent = (props: GetInfoUsuarrioTrocarSenhaComponentProps) => (
      <ApolloReactComponents.Query<GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables> query={GetInfoUsuarrioTrocarSenhaDocument} {...props} />
    );
    
export type GetInfoUsuarrioTrocarSenhaProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables> & TChildProps;
export function withGetInfoUsuarrioTrocarSenha<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInfoUsuarrioTrocarSenhaQuery,
  GetInfoUsuarrioTrocarSenhaQueryVariables,
  GetInfoUsuarrioTrocarSenhaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables, GetInfoUsuarrioTrocarSenhaProps<TChildProps>>(GetInfoUsuarrioTrocarSenhaDocument, {
      alias: 'getInfoUsuarrioTrocarSenha',
      ...operationOptions
    });
};
export type GetInfoUsuarrioTrocarSenhaQueryResult = ApolloReactCommon.QueryResult<GetInfoUsuarrioTrocarSenhaQuery, GetInfoUsuarrioTrocarSenhaQueryVariables>;
export const GetInformacoesPosicaoContratoDocument = gql`
    query getInformacoesPosicaoContrato($codPessoa: String, $codExterno: String, $operadora: String, $status: String) {
  getInformacoesPosicaoContrato(codPessoa: $codPessoa, codExterno: $codExterno, operadora: $operadora, status: $status) {
    codExterno
    operadora
    status
    data
    passou
    observacao
    revenda
    subrevenda
    cnpj
    nome
  }
}
    `;
export type GetInformacoesPosicaoContratoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInformacoesPosicaoContratoQuery, GetInformacoesPosicaoContratoQueryVariables>, 'query'>;

    export const GetInformacoesPosicaoContratoComponent = (props: GetInformacoesPosicaoContratoComponentProps) => (
      <ApolloReactComponents.Query<GetInformacoesPosicaoContratoQuery, GetInformacoesPosicaoContratoQueryVariables> query={GetInformacoesPosicaoContratoDocument} {...props} />
    );
    
export type GetInformacoesPosicaoContratoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInformacoesPosicaoContratoQuery, GetInformacoesPosicaoContratoQueryVariables> & TChildProps;
export function withGetInformacoesPosicaoContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInformacoesPosicaoContratoQuery,
  GetInformacoesPosicaoContratoQueryVariables,
  GetInformacoesPosicaoContratoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInformacoesPosicaoContratoQuery, GetInformacoesPosicaoContratoQueryVariables, GetInformacoesPosicaoContratoProps<TChildProps>>(GetInformacoesPosicaoContratoDocument, {
      alias: 'getInformacoesPosicaoContrato',
      ...operationOptions
    });
};
export type GetInformacoesPosicaoContratoQueryResult = ApolloReactCommon.QueryResult<GetInformacoesPosicaoContratoQuery, GetInformacoesPosicaoContratoQueryVariables>;
export const GetIntegracaoAjusteDocument = gql`
    query getIntegracaoAjuste($values: OrigemAjustePersonalizadoDAOInput) {
  getIntegracaoAjuste(values: $values) {
    editValues {
      codorigemajuste
      codpessoapai
      codexterno
    }
  }
}
    `;
export type GetIntegracaoAjusteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIntegracaoAjusteQuery, GetIntegracaoAjusteQueryVariables>, 'query'>;

    export const GetIntegracaoAjusteComponent = (props: GetIntegracaoAjusteComponentProps) => (
      <ApolloReactComponents.Query<GetIntegracaoAjusteQuery, GetIntegracaoAjusteQueryVariables> query={GetIntegracaoAjusteDocument} {...props} />
    );
    
export type GetIntegracaoAjusteProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetIntegracaoAjusteQuery, GetIntegracaoAjusteQueryVariables> & TChildProps;
export function withGetIntegracaoAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetIntegracaoAjusteQuery,
  GetIntegracaoAjusteQueryVariables,
  GetIntegracaoAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetIntegracaoAjusteQuery, GetIntegracaoAjusteQueryVariables, GetIntegracaoAjusteProps<TChildProps>>(GetIntegracaoAjusteDocument, {
      alias: 'getIntegracaoAjuste',
      ...operationOptions
    });
};
export type GetIntegracaoAjusteQueryResult = ApolloReactCommon.QueryResult<GetIntegracaoAjusteQuery, GetIntegracaoAjusteQueryVariables>;
export const GetIntegracaoSoftExpressCadastroDocument = gql`
    query getIntegracaoSoftExpressCadastro($values: ConciliadoresIntegSoftExpressDAOInput) {
  getIntegracaoSoftExpressCadastro(values: $values) {
    editValues {
      codpessoa
      codexterno
    }
  }
}
    `;
export type GetIntegracaoSoftExpressCadastroComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIntegracaoSoftExpressCadastroQuery, GetIntegracaoSoftExpressCadastroQueryVariables>, 'query'>;

    export const GetIntegracaoSoftExpressCadastroComponent = (props: GetIntegracaoSoftExpressCadastroComponentProps) => (
      <ApolloReactComponents.Query<GetIntegracaoSoftExpressCadastroQuery, GetIntegracaoSoftExpressCadastroQueryVariables> query={GetIntegracaoSoftExpressCadastroDocument} {...props} />
    );
    
export type GetIntegracaoSoftExpressCadastroProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetIntegracaoSoftExpressCadastroQuery, GetIntegracaoSoftExpressCadastroQueryVariables> & TChildProps;
export function withGetIntegracaoSoftExpressCadastro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetIntegracaoSoftExpressCadastroQuery,
  GetIntegracaoSoftExpressCadastroQueryVariables,
  GetIntegracaoSoftExpressCadastroProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetIntegracaoSoftExpressCadastroQuery, GetIntegracaoSoftExpressCadastroQueryVariables, GetIntegracaoSoftExpressCadastroProps<TChildProps>>(GetIntegracaoSoftExpressCadastroDocument, {
      alias: 'getIntegracaoSoftExpressCadastro',
      ...operationOptions
    });
};
export type GetIntegracaoSoftExpressCadastroQueryResult = ApolloReactCommon.QueryResult<GetIntegracaoSoftExpressCadastroQuery, GetIntegracaoSoftExpressCadastroQueryVariables>;
export const GetItensProdutosByOperadoraDocument = gql`
    query getItensProdutosByOperadora($codoperadora: Int, $codplanosconciliadores: Int) {
  getItensProdutosByOperadora(codoperadora: $codoperadora, codplanosconciliadores: $codplanosconciliadores) {
    label
    valor
  }
}
    `;
export type GetItensProdutosByOperadoraComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItensProdutosByOperadoraQuery, GetItensProdutosByOperadoraQueryVariables>, 'query'>;

    export const GetItensProdutosByOperadoraComponent = (props: GetItensProdutosByOperadoraComponentProps) => (
      <ApolloReactComponents.Query<GetItensProdutosByOperadoraQuery, GetItensProdutosByOperadoraQueryVariables> query={GetItensProdutosByOperadoraDocument} {...props} />
    );
    
export type GetItensProdutosByOperadoraProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItensProdutosByOperadoraQuery, GetItensProdutosByOperadoraQueryVariables> & TChildProps;
export function withGetItensProdutosByOperadora<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItensProdutosByOperadoraQuery,
  GetItensProdutosByOperadoraQueryVariables,
  GetItensProdutosByOperadoraProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItensProdutosByOperadoraQuery, GetItensProdutosByOperadoraQueryVariables, GetItensProdutosByOperadoraProps<TChildProps>>(GetItensProdutosByOperadoraDocument, {
      alias: 'getItensProdutosByOperadora',
      ...operationOptions
    });
};
export type GetItensProdutosByOperadoraQueryResult = ApolloReactCommon.QueryResult<GetItensProdutosByOperadoraQuery, GetItensProdutosByOperadoraQueryVariables>;
export const GetItensProdutosByOperadoraPosDocument = gql`
    query getItensProdutosByOperadoraPos($codoperadora: Int, $codplano: Int) {
  getItensProdutosByOperadoraPos(codoperadora: $codoperadora, codplano: $codplano) {
    label
    valor
  }
}
    `;
export type GetItensProdutosByOperadoraPosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItensProdutosByOperadoraPosQuery, GetItensProdutosByOperadoraPosQueryVariables>, 'query'>;

    export const GetItensProdutosByOperadoraPosComponent = (props: GetItensProdutosByOperadoraPosComponentProps) => (
      <ApolloReactComponents.Query<GetItensProdutosByOperadoraPosQuery, GetItensProdutosByOperadoraPosQueryVariables> query={GetItensProdutosByOperadoraPosDocument} {...props} />
    );
    
export type GetItensProdutosByOperadoraPosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItensProdutosByOperadoraPosQuery, GetItensProdutosByOperadoraPosQueryVariables> & TChildProps;
export function withGetItensProdutosByOperadoraPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItensProdutosByOperadoraPosQuery,
  GetItensProdutosByOperadoraPosQueryVariables,
  GetItensProdutosByOperadoraPosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItensProdutosByOperadoraPosQuery, GetItensProdutosByOperadoraPosQueryVariables, GetItensProdutosByOperadoraPosProps<TChildProps>>(GetItensProdutosByOperadoraPosDocument, {
      alias: 'getItensProdutosByOperadoraPos',
      ...operationOptions
    });
};
export type GetItensProdutosByOperadoraPosQueryResult = ApolloReactCommon.QueryResult<GetItensProdutosByOperadoraPosQuery, GetItensProdutosByOperadoraPosQueryVariables>;
export const GetItensUnidadesPlanosDocument = gql`
    query getItensUnidadesPlanos($codplanos: Int) {
  getItensUnidadesPlanos(codplanos: $codplanos) {
    label
    value
  }
}
    `;
export type GetItensUnidadesPlanosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItensUnidadesPlanosQuery, GetItensUnidadesPlanosQueryVariables>, 'query'>;

    export const GetItensUnidadesPlanosComponent = (props: GetItensUnidadesPlanosComponentProps) => (
      <ApolloReactComponents.Query<GetItensUnidadesPlanosQuery, GetItensUnidadesPlanosQueryVariables> query={GetItensUnidadesPlanosDocument} {...props} />
    );
    
export type GetItensUnidadesPlanosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItensUnidadesPlanosQuery, GetItensUnidadesPlanosQueryVariables> & TChildProps;
export function withGetItensUnidadesPlanos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItensUnidadesPlanosQuery,
  GetItensUnidadesPlanosQueryVariables,
  GetItensUnidadesPlanosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItensUnidadesPlanosQuery, GetItensUnidadesPlanosQueryVariables, GetItensUnidadesPlanosProps<TChildProps>>(GetItensUnidadesPlanosDocument, {
      alias: 'getItensUnidadesPlanos',
      ...operationOptions
    });
};
export type GetItensUnidadesPlanosQueryResult = ApolloReactCommon.QueryResult<GetItensUnidadesPlanosQuery, GetItensUnidadesPlanosQueryVariables>;
export const GetItensUnidadesPlanosConciliadoresDocument = gql`
    query getItensUnidadesPlanosConciliadores($codplanosconciliadores: Int) {
  getItensUnidadesPlanosConciliadores(codplanosconciliadores: $codplanosconciliadores) {
    label
    value
  }
}
    `;
export type GetItensUnidadesPlanosConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItensUnidadesPlanosConciliadoresQuery, GetItensUnidadesPlanosConciliadoresQueryVariables>, 'query'>;

    export const GetItensUnidadesPlanosConciliadoresComponent = (props: GetItensUnidadesPlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<GetItensUnidadesPlanosConciliadoresQuery, GetItensUnidadesPlanosConciliadoresQueryVariables> query={GetItensUnidadesPlanosConciliadoresDocument} {...props} />
    );
    
export type GetItensUnidadesPlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItensUnidadesPlanosConciliadoresQuery, GetItensUnidadesPlanosConciliadoresQueryVariables> & TChildProps;
export function withGetItensUnidadesPlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItensUnidadesPlanosConciliadoresQuery,
  GetItensUnidadesPlanosConciliadoresQueryVariables,
  GetItensUnidadesPlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItensUnidadesPlanosConciliadoresQuery, GetItensUnidadesPlanosConciliadoresQueryVariables, GetItensUnidadesPlanosConciliadoresProps<TChildProps>>(GetItensUnidadesPlanosConciliadoresDocument, {
      alias: 'getItensUnidadesPlanosConciliadores',
      ...operationOptions
    });
};
export type GetItensUnidadesPlanosConciliadoresQueryResult = ApolloReactCommon.QueryResult<GetItensUnidadesPlanosConciliadoresQuery, GetItensUnidadesPlanosConciliadoresQueryVariables>;
export const GetListOfResourcesGraphqlDocument = gql`
    query getListOfResourcesGraphql {
  getListOfResourcesGraphql {
    label
    valor
  }
}
    `;
export type GetListOfResourcesGraphqlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetListOfResourcesGraphqlQuery, GetListOfResourcesGraphqlQueryVariables>, 'query'>;

    export const GetListOfResourcesGraphqlComponent = (props: GetListOfResourcesGraphqlComponentProps) => (
      <ApolloReactComponents.Query<GetListOfResourcesGraphqlQuery, GetListOfResourcesGraphqlQueryVariables> query={GetListOfResourcesGraphqlDocument} {...props} />
    );
    
export type GetListOfResourcesGraphqlProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetListOfResourcesGraphqlQuery, GetListOfResourcesGraphqlQueryVariables> & TChildProps;
export function withGetListOfResourcesGraphql<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetListOfResourcesGraphqlQuery,
  GetListOfResourcesGraphqlQueryVariables,
  GetListOfResourcesGraphqlProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetListOfResourcesGraphqlQuery, GetListOfResourcesGraphqlQueryVariables, GetListOfResourcesGraphqlProps<TChildProps>>(GetListOfResourcesGraphqlDocument, {
      alias: 'getListOfResourcesGraphql',
      ...operationOptions
    });
};
export type GetListOfResourcesGraphqlQueryResult = ApolloReactCommon.QueryResult<GetListOfResourcesGraphqlQuery, GetListOfResourcesGraphqlQueryVariables>;
export const GetListarTaxasNaoCadastradasDocument = gql`
    query getListarTaxasNaoCadastradas {
  listarTaxasNaoCadastradas {
    produto
    nomepessoa
    codexterno
    operadora
    vendade
    vendaate
    parcelasde
    parcelasate
  }
}
    `;
export type GetListarTaxasNaoCadastradasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetListarTaxasNaoCadastradasQuery, GetListarTaxasNaoCadastradasQueryVariables>, 'query'>;

    export const GetListarTaxasNaoCadastradasComponent = (props: GetListarTaxasNaoCadastradasComponentProps) => (
      <ApolloReactComponents.Query<GetListarTaxasNaoCadastradasQuery, GetListarTaxasNaoCadastradasQueryVariables> query={GetListarTaxasNaoCadastradasDocument} {...props} />
    );
    
export type GetListarTaxasNaoCadastradasProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetListarTaxasNaoCadastradasQuery, GetListarTaxasNaoCadastradasQueryVariables> & TChildProps;
export function withGetListarTaxasNaoCadastradas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetListarTaxasNaoCadastradasQuery,
  GetListarTaxasNaoCadastradasQueryVariables,
  GetListarTaxasNaoCadastradasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetListarTaxasNaoCadastradasQuery, GetListarTaxasNaoCadastradasQueryVariables, GetListarTaxasNaoCadastradasProps<TChildProps>>(GetListarTaxasNaoCadastradasDocument, {
      alias: 'getListarTaxasNaoCadastradas',
      ...operationOptions
    });
};
export type GetListarTaxasNaoCadastradasQueryResult = ApolloReactCommon.QueryResult<GetListarTaxasNaoCadastradasQuery, GetListarTaxasNaoCadastradasQueryVariables>;
export const GetListarUsuariosExpiradosDocument = gql`
    query getListarUsuariosExpirados {
  listarUsuariosExpirados {
    codpessoa
    login
    validade
  }
}
    `;
export type GetListarUsuariosExpiradosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetListarUsuariosExpiradosQuery, GetListarUsuariosExpiradosQueryVariables>, 'query'>;

    export const GetListarUsuariosExpiradosComponent = (props: GetListarUsuariosExpiradosComponentProps) => (
      <ApolloReactComponents.Query<GetListarUsuariosExpiradosQuery, GetListarUsuariosExpiradosQueryVariables> query={GetListarUsuariosExpiradosDocument} {...props} />
    );
    
export type GetListarUsuariosExpiradosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetListarUsuariosExpiradosQuery, GetListarUsuariosExpiradosQueryVariables> & TChildProps;
export function withGetListarUsuariosExpirados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetListarUsuariosExpiradosQuery,
  GetListarUsuariosExpiradosQueryVariables,
  GetListarUsuariosExpiradosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetListarUsuariosExpiradosQuery, GetListarUsuariosExpiradosQueryVariables, GetListarUsuariosExpiradosProps<TChildProps>>(GetListarUsuariosExpiradosDocument, {
      alias: 'getListarUsuariosExpirados',
      ...operationOptions
    });
};
export type GetListarUsuariosExpiradosQueryResult = ApolloReactCommon.QueryResult<GetListarUsuariosExpiradosQuery, GetListarUsuariosExpiradosQueryVariables>;
export const GetListaUnidadesNaoEncontradasDocument = gql`
    query getListaUnidadesNaoEncontradas {
  listarUnidadesNaoEncontradasExibicao {
    codpessoapai
    operadora
    estabelecimento
    cnpj
    operadoraRoteada
    nome
  }
}
    `;
export type GetListaUnidadesNaoEncontradasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetListaUnidadesNaoEncontradasQuery, GetListaUnidadesNaoEncontradasQueryVariables>, 'query'>;

    export const GetListaUnidadesNaoEncontradasComponent = (props: GetListaUnidadesNaoEncontradasComponentProps) => (
      <ApolloReactComponents.Query<GetListaUnidadesNaoEncontradasQuery, GetListaUnidadesNaoEncontradasQueryVariables> query={GetListaUnidadesNaoEncontradasDocument} {...props} />
    );
    
export type GetListaUnidadesNaoEncontradasProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetListaUnidadesNaoEncontradasQuery, GetListaUnidadesNaoEncontradasQueryVariables> & TChildProps;
export function withGetListaUnidadesNaoEncontradas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetListaUnidadesNaoEncontradasQuery,
  GetListaUnidadesNaoEncontradasQueryVariables,
  GetListaUnidadesNaoEncontradasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetListaUnidadesNaoEncontradasQuery, GetListaUnidadesNaoEncontradasQueryVariables, GetListaUnidadesNaoEncontradasProps<TChildProps>>(GetListaUnidadesNaoEncontradasDocument, {
      alias: 'getListaUnidadesNaoEncontradas',
      ...operationOptions
    });
};
export type GetListaUnidadesNaoEncontradasQueryResult = ApolloReactCommon.QueryResult<GetListaUnidadesNaoEncontradasQuery, GetListaUnidadesNaoEncontradasQueryVariables>;
export const GetLoginSenhaAtendimentoDocument = gql`
    query getLoginSenhaAtendimento {
  getLoginSenhaAtendimento {
    login
    senha
  }
}
    `;
export type GetLoginSenhaAtendimentoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLoginSenhaAtendimentoQuery, GetLoginSenhaAtendimentoQueryVariables>, 'query'>;

    export const GetLoginSenhaAtendimentoComponent = (props: GetLoginSenhaAtendimentoComponentProps) => (
      <ApolloReactComponents.Query<GetLoginSenhaAtendimentoQuery, GetLoginSenhaAtendimentoQueryVariables> query={GetLoginSenhaAtendimentoDocument} {...props} />
    );
    
export type GetLoginSenhaAtendimentoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLoginSenhaAtendimentoQuery, GetLoginSenhaAtendimentoQueryVariables> & TChildProps;
export function withGetLoginSenhaAtendimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLoginSenhaAtendimentoQuery,
  GetLoginSenhaAtendimentoQueryVariables,
  GetLoginSenhaAtendimentoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLoginSenhaAtendimentoQuery, GetLoginSenhaAtendimentoQueryVariables, GetLoginSenhaAtendimentoProps<TChildProps>>(GetLoginSenhaAtendimentoDocument, {
      alias: 'getLoginSenhaAtendimento',
      ...operationOptions
    });
};
export type GetLoginSenhaAtendimentoQueryResult = ApolloReactCommon.QueryResult<GetLoginSenhaAtendimentoQuery, GetLoginSenhaAtendimentoQueryVariables>;
export const GetMenusDocument = gql`
    query getMenus {
  getMenus {
    id
    label
    page
    pai
    icon
  }
}
    `;
export type GetMenusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMenusQuery, GetMenusQueryVariables>, 'query'>;

    export const GetMenusComponent = (props: GetMenusComponentProps) => (
      <ApolloReactComponents.Query<GetMenusQuery, GetMenusQueryVariables> query={GetMenusDocument} {...props} />
    );
    
export type GetMenusProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMenusQuery, GetMenusQueryVariables> & TChildProps;
export function withGetMenus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMenusQuery,
  GetMenusQueryVariables,
  GetMenusProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMenusQuery, GetMenusQueryVariables, GetMenusProps<TChildProps>>(GetMenusDocument, {
      alias: 'getMenus',
      ...operationOptions
    });
};
export type GetMenusQueryResult = ApolloReactCommon.QueryResult<GetMenusQuery, GetMenusQueryVariables>;
export const GetNoticiasDocument = gql`
    query getNoticias {
  getNoticias {
    codnoticia
    titulo
    texto
  }
}
    `;
export type GetNoticiasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNoticiasQuery, GetNoticiasQueryVariables>, 'query'>;

    export const GetNoticiasComponent = (props: GetNoticiasComponentProps) => (
      <ApolloReactComponents.Query<GetNoticiasQuery, GetNoticiasQueryVariables> query={GetNoticiasDocument} {...props} />
    );
    
export type GetNoticiasProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetNoticiasQuery, GetNoticiasQueryVariables> & TChildProps;
export function withGetNoticias<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNoticiasQuery,
  GetNoticiasQueryVariables,
  GetNoticiasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetNoticiasQuery, GetNoticiasQueryVariables, GetNoticiasProps<TChildProps>>(GetNoticiasDocument, {
      alias: 'getNoticias',
      ...operationOptions
    });
};
export type GetNoticiasQueryResult = ApolloReactCommon.QueryResult<GetNoticiasQuery, GetNoticiasQueryVariables>;
export const GetPainelCadastroDocument = gql`
    query getPainelCadastro {
  getPainelCadastro {
    arquivoImagemPOS
    arquivoImagemTEF
    operadora {
      codoperadora
      nome
      formacalculo
      consinco
      estabelecimentoCadastroTaxa
    }
    statusEC {
      descricao
    }
    statusTaxa {
      descricao
    }
    statusTermo {
      descricao
    }
    statusCarteira {
      descricao
    }
    downlaodTermoAdesaoCss
    downlaodAutorizacaoCss
  }
}
    `;
export type GetPainelCadastroComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPainelCadastroQuery, GetPainelCadastroQueryVariables>, 'query'>;

    export const GetPainelCadastroComponent = (props: GetPainelCadastroComponentProps) => (
      <ApolloReactComponents.Query<GetPainelCadastroQuery, GetPainelCadastroQueryVariables> query={GetPainelCadastroDocument} {...props} />
    );
    
export type GetPainelCadastroProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPainelCadastroQuery, GetPainelCadastroQueryVariables> & TChildProps;
export function withGetPainelCadastro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPainelCadastroQuery,
  GetPainelCadastroQueryVariables,
  GetPainelCadastroProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPainelCadastroQuery, GetPainelCadastroQueryVariables, GetPainelCadastroProps<TChildProps>>(GetPainelCadastroDocument, {
      alias: 'getPainelCadastro',
      ...operationOptions
    });
};
export type GetPainelCadastroQueryResult = ApolloReactCommon.QueryResult<GetPainelCadastroQuery, GetPainelCadastroQueryVariables>;
export const GetParametroTotvsDocument = gql`
    query getParametroTOTVS($values: ParametrosConciliadorTotvsDAOInput) {
  getParametroTOTVS(values: $values) {
    editValues {
      codpessoa
      codfilial
      opcional1
      opcional2
      coddepartamento
      codcentrocusto
      tipocontabil
      codmoeda
      codcontacaixa
      statuslancamento
      codcoligadaclifor
      codcoligadacontacaixa
      tiposacado
      codevento
    }
  }
}
    `;
export type GetParametroTotvsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetParametroTotvsQuery, GetParametroTotvsQueryVariables>, 'query'>;

    export const GetParametroTotvsComponent = (props: GetParametroTotvsComponentProps) => (
      <ApolloReactComponents.Query<GetParametroTotvsQuery, GetParametroTotvsQueryVariables> query={GetParametroTotvsDocument} {...props} />
    );
    
export type GetParametroTotvsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetParametroTotvsQuery, GetParametroTotvsQueryVariables> & TChildProps;
export function withGetParametroTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetParametroTotvsQuery,
  GetParametroTotvsQueryVariables,
  GetParametroTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetParametroTotvsQuery, GetParametroTotvsQueryVariables, GetParametroTotvsProps<TChildProps>>(GetParametroTotvsDocument, {
      alias: 'getParametroTotvs',
      ...operationOptions
    });
};
export type GetParametroTotvsQueryResult = ApolloReactCommon.QueryResult<GetParametroTotvsQuery, GetParametroTotvsQueryVariables>;
export const GetPerfilDocument = gql`
    query getPerfil($values: PerfilDAOInput) {
  getPerfil(values: $values) {
    recursos {
      nometela
      recurso
      rccodigo
    }
    editValues {
      nome
    }
  }
}
    `;
export type GetPerfilComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPerfilQuery, GetPerfilQueryVariables>, 'query'>;

    export const GetPerfilComponent = (props: GetPerfilComponentProps) => (
      <ApolloReactComponents.Query<GetPerfilQuery, GetPerfilQueryVariables> query={GetPerfilDocument} {...props} />
    );
    
export type GetPerfilProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPerfilQuery, GetPerfilQueryVariables> & TChildProps;
export function withGetPerfil<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPerfilQuery,
  GetPerfilQueryVariables,
  GetPerfilProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPerfilQuery, GetPerfilQueryVariables, GetPerfilProps<TChildProps>>(GetPerfilDocument, {
      alias: 'getPerfil',
      ...operationOptions
    });
};
export type GetPerfilQueryResult = ApolloReactCommon.QueryResult<GetPerfilQuery, GetPerfilQueryVariables>;
export const GetPlanosConciliadoresDocument = gql`
    query getPlanosConciliadores($values: PlanosConciliadoresDAOInput) {
  getPlanosConciliadores(values: $values) {
    editValues {
      codplanosconciliadores
      nome
      codpessoapai
      tarifacao
    }
  }
}
    `;
export type GetPlanosConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosConciliadoresQuery, GetPlanosConciliadoresQueryVariables>, 'query'>;

    export const GetPlanosConciliadoresComponent = (props: GetPlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosConciliadoresQuery, GetPlanosConciliadoresQueryVariables> query={GetPlanosConciliadoresDocument} {...props} />
    );
    
export type GetPlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosConciliadoresQuery, GetPlanosConciliadoresQueryVariables> & TChildProps;
export function withGetPlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosConciliadoresQuery,
  GetPlanosConciliadoresQueryVariables,
  GetPlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosConciliadoresQuery, GetPlanosConciliadoresQueryVariables, GetPlanosConciliadoresProps<TChildProps>>(GetPlanosConciliadoresDocument, {
      alias: 'getPlanosConciliadores',
      ...operationOptions
    });
};
export type GetPlanosConciliadoresQueryResult = ApolloReactCommon.QueryResult<GetPlanosConciliadoresQuery, GetPlanosConciliadoresQueryVariables>;
export const GetPlanosConciliadoresContratoDocument = gql`
    query getPlanosConciliadoresContrato($values: PlanosConciliadoresContratoDAOInput) {
  getPlanosConciliadoresContrato(values: $values) {
    listConciOperadora
    listConciOperadoraGeral {
      label
      value
    }
    editValues {
      codplanosconciliadorescontrato
      seq
      codplanosconciliadores
      codpessoa
      codoperadora
      codexterno
      vigenciaDe
      vigenciaAte
      codpessoapai
    }
  }
}
    `;
export type GetPlanosConciliadoresContratoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosConciliadoresContratoQuery, GetPlanosConciliadoresContratoQueryVariables>, 'query'>;

    export const GetPlanosConciliadoresContratoComponent = (props: GetPlanosConciliadoresContratoComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosConciliadoresContratoQuery, GetPlanosConciliadoresContratoQueryVariables> query={GetPlanosConciliadoresContratoDocument} {...props} />
    );
    
export type GetPlanosConciliadoresContratoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosConciliadoresContratoQuery, GetPlanosConciliadoresContratoQueryVariables> & TChildProps;
export function withGetPlanosConciliadoresContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosConciliadoresContratoQuery,
  GetPlanosConciliadoresContratoQueryVariables,
  GetPlanosConciliadoresContratoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosConciliadoresContratoQuery, GetPlanosConciliadoresContratoQueryVariables, GetPlanosConciliadoresContratoProps<TChildProps>>(GetPlanosConciliadoresContratoDocument, {
      alias: 'getPlanosConciliadoresContrato',
      ...operationOptions
    });
};
export type GetPlanosConciliadoresContratoQueryResult = ApolloReactCommon.QueryResult<GetPlanosConciliadoresContratoQuery, GetPlanosConciliadoresContratoQueryVariables>;
export const GetPlanosConciliadoresTaxasDocument = gql`
    query getPlanosConciliadoresTaxas($values: PlanosConciliadoresTaxasDAOInput) {
  getPlanosConciliadoresTaxas(values: $values) {
    editValues {
      codplanosconciliadorestaxas
      codplanosconciliadores
      codprodutos
      bincartao
    }
    taxas {
      de
      ate
      taxa
      valorde
      valorate
      tipotaxa
    }
    mensagem
    mensagemBndes
    codoperadora
    planoantigo
    planonovo
    taxabndes
    tarifacao
    listDeTaxas {
      de
      ate
      taxa
      valorde
      valorate
      codplanosconciliadorestaxas
      tipotaxa
    }
    nomePlano
    taxaCredito
    taxaCredito0a6
    taxaCredito7a12
    taxaDebito
    taxaTicket
    mensagemErro
    converterProdutoTaxas
  }
}
    `;
export type GetPlanosConciliadoresTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosConciliadoresTaxasQuery, GetPlanosConciliadoresTaxasQueryVariables>, 'query'>;

    export const GetPlanosConciliadoresTaxasComponent = (props: GetPlanosConciliadoresTaxasComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosConciliadoresTaxasQuery, GetPlanosConciliadoresTaxasQueryVariables> query={GetPlanosConciliadoresTaxasDocument} {...props} />
    );
    
export type GetPlanosConciliadoresTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosConciliadoresTaxasQuery, GetPlanosConciliadoresTaxasQueryVariables> & TChildProps;
export function withGetPlanosConciliadoresTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosConciliadoresTaxasQuery,
  GetPlanosConciliadoresTaxasQueryVariables,
  GetPlanosConciliadoresTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosConciliadoresTaxasQuery, GetPlanosConciliadoresTaxasQueryVariables, GetPlanosConciliadoresTaxasProps<TChildProps>>(GetPlanosConciliadoresTaxasDocument, {
      alias: 'getPlanosConciliadoresTaxas',
      ...operationOptions
    });
};
export type GetPlanosConciliadoresTaxasQueryResult = ApolloReactCommon.QueryResult<GetPlanosConciliadoresTaxasQuery, GetPlanosConciliadoresTaxasQueryVariables>;
export const GetPlanosPosDocument = gql`
    query getPlanosPOS($values: PlanosDAOInput) {
  getPlanosPOS(values: $values) {
    mensalidade
    editValues {
      codplano
      nome
      codpessoapai
      cobramensalidade
      valormensalidade
      diacorte
    }
  }
}
    `;
export type GetPlanosPosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosPosQuery, GetPlanosPosQueryVariables>, 'query'>;

    export const GetPlanosPosComponent = (props: GetPlanosPosComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosPosQuery, GetPlanosPosQueryVariables> query={GetPlanosPosDocument} {...props} />
    );
    
export type GetPlanosPosProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosPosQuery, GetPlanosPosQueryVariables> & TChildProps;
export function withGetPlanosPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosPosQuery,
  GetPlanosPosQueryVariables,
  GetPlanosPosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosPosQuery, GetPlanosPosQueryVariables, GetPlanosPosProps<TChildProps>>(GetPlanosPosDocument, {
      alias: 'getPlanosPos',
      ...operationOptions
    });
};
export type GetPlanosPosQueryResult = ApolloReactCommon.QueryResult<GetPlanosPosQuery, GetPlanosPosQueryVariables>;
export const GetPlanosPosTaxasDocument = gql`
    query getPlanosPOSTaxas($values: PlanosTaxasDAOInput) {
  getPlanosPOSTaxas(values: $values) {
    editValues {
      codplanostaxas
      codplano
      codprodutos
      de
      ate
      taxa
    }
    mensagem
    codoperadora
    taxas {
      de
      ate
      taxa
    }
    listDeTaxas {
      de
      ate
      taxa
      codplanostaxas
    }
  }
}
    `;
export type GetPlanosPosTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables>, 'query'>;

    export const GetPlanosPosTaxasComponent = (props: GetPlanosPosTaxasComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables> query={GetPlanosPosTaxasDocument} {...props} />
    );
    
export type GetPlanosPosTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables> & TChildProps;
export function withGetPlanosPosTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosPosTaxasQuery,
  GetPlanosPosTaxasQueryVariables,
  GetPlanosPosTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables, GetPlanosPosTaxasProps<TChildProps>>(GetPlanosPosTaxasDocument, {
      alias: 'getPlanosPosTaxas',
      ...operationOptions
    });
};
export type GetPlanosPosTaxasQueryResult = ApolloReactCommon.QueryResult<GetPlanosPosTaxasQuery, GetPlanosPosTaxasQueryVariables>;
export const GetPlanosTerminalDocument = gql`
    query getPlanosTerminal($values: PlanosTerminalDAOInput) {
  getPlanosTerminal(values: $values) {
    listTerminal
    editValues {
      codplanosterminal
      seq
      codplanos
      codterminal
      vigenciaDe
      vigenciaAte
      codpessoapai
    }
  }
}
    `;
export type GetPlanosTerminalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables>, 'query'>;

    export const GetPlanosTerminalComponent = (props: GetPlanosTerminalComponentProps) => (
      <ApolloReactComponents.Query<GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables> query={GetPlanosTerminalDocument} {...props} />
    );
    
export type GetPlanosTerminalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables> & TChildProps;
export function withGetPlanosTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPlanosTerminalQuery,
  GetPlanosTerminalQueryVariables,
  GetPlanosTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables, GetPlanosTerminalProps<TChildProps>>(GetPlanosTerminalDocument, {
      alias: 'getPlanosTerminal',
      ...operationOptions
    });
};
export type GetPlanosTerminalQueryResult = ApolloReactCommon.QueryResult<GetPlanosTerminalQuery, GetPlanosTerminalQueryVariables>;
export const GetPosicaoContratosPorClientesDocument = gql`
    query getPosicaoContratosPorClientes($values: PosicaoContratosPorClientesInput!) {
  getPosicaoContratosPorClientes(values: $values) {
    id
    parentId
    description
    identifier
    leaf
    type
    codpessoa
    nome
    status
    origem
    ultimoStatus
    tipo
    operadora
    codexterno
    edita
    codPessoaPai
    selecionado
    dataRecebimentoPrimeiroArquivo
    childCount
  }
}
    `;
export type GetPosicaoContratosPorClientesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPosicaoContratosPorClientesQuery, GetPosicaoContratosPorClientesQueryVariables>, 'query'> & ({ variables: GetPosicaoContratosPorClientesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetPosicaoContratosPorClientesComponent = (props: GetPosicaoContratosPorClientesComponentProps) => (
      <ApolloReactComponents.Query<GetPosicaoContratosPorClientesQuery, GetPosicaoContratosPorClientesQueryVariables> query={GetPosicaoContratosPorClientesDocument} {...props} />
    );
    
export type GetPosicaoContratosPorClientesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPosicaoContratosPorClientesQuery, GetPosicaoContratosPorClientesQueryVariables> & TChildProps;
export function withGetPosicaoContratosPorClientes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPosicaoContratosPorClientesQuery,
  GetPosicaoContratosPorClientesQueryVariables,
  GetPosicaoContratosPorClientesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPosicaoContratosPorClientesQuery, GetPosicaoContratosPorClientesQueryVariables, GetPosicaoContratosPorClientesProps<TChildProps>>(GetPosicaoContratosPorClientesDocument, {
      alias: 'getPosicaoContratosPorClientes',
      ...operationOptions
    });
};
export type GetPosicaoContratosPorClientesQueryResult = ApolloReactCommon.QueryResult<GetPosicaoContratosPorClientesQuery, GetPosicaoContratosPorClientesQueryVariables>;
export const GetPrepareCadastroTaxaDocument = gql`
    query getPrepareCadastroTaxa($values: PrepareCadastroTaxaInput) {
  getPrepareCadastroTaxa(values: $values)
}
    `;
export type GetPrepareCadastroTaxaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPrepareCadastroTaxaQuery, GetPrepareCadastroTaxaQueryVariables>, 'query'>;

    export const GetPrepareCadastroTaxaComponent = (props: GetPrepareCadastroTaxaComponentProps) => (
      <ApolloReactComponents.Query<GetPrepareCadastroTaxaQuery, GetPrepareCadastroTaxaQueryVariables> query={GetPrepareCadastroTaxaDocument} {...props} />
    );
    
export type GetPrepareCadastroTaxaProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetPrepareCadastroTaxaQuery, GetPrepareCadastroTaxaQueryVariables> & TChildProps;
export function withGetPrepareCadastroTaxa<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPrepareCadastroTaxaQuery,
  GetPrepareCadastroTaxaQueryVariables,
  GetPrepareCadastroTaxaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetPrepareCadastroTaxaQuery, GetPrepareCadastroTaxaQueryVariables, GetPrepareCadastroTaxaProps<TChildProps>>(GetPrepareCadastroTaxaDocument, {
      alias: 'getPrepareCadastroTaxa',
      ...operationOptions
    });
};
export type GetPrepareCadastroTaxaQueryResult = ApolloReactCommon.QueryResult<GetPrepareCadastroTaxaQuery, GetPrepareCadastroTaxaQueryVariables>;
export const GetRelatorioFechaMovimentoPrevisaoDocument = gql`
    query getRelatorioFechaMovimentoPrevisao($filtro: BeanFiltroRelatorioTelaInput, $dias: [String]) {
  getRelatorioFechaMovimentoPrevisao(filtro: $filtro, dias: $dias)
}
    `;
export type GetRelatorioFechaMovimentoPrevisaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRelatorioFechaMovimentoPrevisaoQuery, GetRelatorioFechaMovimentoPrevisaoQueryVariables>, 'query'>;

    export const GetRelatorioFechaMovimentoPrevisaoComponent = (props: GetRelatorioFechaMovimentoPrevisaoComponentProps) => (
      <ApolloReactComponents.Query<GetRelatorioFechaMovimentoPrevisaoQuery, GetRelatorioFechaMovimentoPrevisaoQueryVariables> query={GetRelatorioFechaMovimentoPrevisaoDocument} {...props} />
    );
    
export type GetRelatorioFechaMovimentoPrevisaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRelatorioFechaMovimentoPrevisaoQuery, GetRelatorioFechaMovimentoPrevisaoQueryVariables> & TChildProps;
export function withGetRelatorioFechaMovimentoPrevisao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRelatorioFechaMovimentoPrevisaoQuery,
  GetRelatorioFechaMovimentoPrevisaoQueryVariables,
  GetRelatorioFechaMovimentoPrevisaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRelatorioFechaMovimentoPrevisaoQuery, GetRelatorioFechaMovimentoPrevisaoQueryVariables, GetRelatorioFechaMovimentoPrevisaoProps<TChildProps>>(GetRelatorioFechaMovimentoPrevisaoDocument, {
      alias: 'getRelatorioFechaMovimentoPrevisao',
      ...operationOptions
    });
};
export type GetRelatorioFechaMovimentoPrevisaoQueryResult = ApolloReactCommon.QueryResult<GetRelatorioFechaMovimentoPrevisaoQuery, GetRelatorioFechaMovimentoPrevisaoQueryVariables>;
export const GetTaxasAntecipacaoDocument = gql`
    query getTaxasAntecipacao($values: TaxaAntecipacaoDAOInput) {
  getTaxasAntecipacao(values: $values) {
    listConciOperadora
    codoperadora
    editValues {
      codtaxaantecipacao
      nome
      taxa
      vigenciade
      vigenciaate
    }
  }
}
    `;
export type GetTaxasAntecipacaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTaxasAntecipacaoQuery, GetTaxasAntecipacaoQueryVariables>, 'query'>;

    export const GetTaxasAntecipacaoComponent = (props: GetTaxasAntecipacaoComponentProps) => (
      <ApolloReactComponents.Query<GetTaxasAntecipacaoQuery, GetTaxasAntecipacaoQueryVariables> query={GetTaxasAntecipacaoDocument} {...props} />
    );
    
export type GetTaxasAntecipacaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTaxasAntecipacaoQuery, GetTaxasAntecipacaoQueryVariables> & TChildProps;
export function withGetTaxasAntecipacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTaxasAntecipacaoQuery,
  GetTaxasAntecipacaoQueryVariables,
  GetTaxasAntecipacaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTaxasAntecipacaoQuery, GetTaxasAntecipacaoQueryVariables, GetTaxasAntecipacaoProps<TChildProps>>(GetTaxasAntecipacaoDocument, {
      alias: 'getTaxasAntecipacao',
      ...operationOptions
    });
};
export type GetTaxasAntecipacaoQueryResult = ApolloReactCommon.QueryResult<GetTaxasAntecipacaoQuery, GetTaxasAntecipacaoQueryVariables>;
export const GetTefsDocument = gql`
    query getTefs($codpessoa: Int) {
  getTefs(codpessoa: $codpessoa) {
    codTef
    nomeTef
  }
}
    `;
export type GetTefsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTefsQuery, GetTefsQueryVariables>, 'query'>;

    export const GetTefsComponent = (props: GetTefsComponentProps) => (
      <ApolloReactComponents.Query<GetTefsQuery, GetTefsQueryVariables> query={GetTefsDocument} {...props} />
    );
    
export type GetTefsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTefsQuery, GetTefsQueryVariables> & TChildProps;
export function withGetTefs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTefsQuery,
  GetTefsQueryVariables,
  GetTefsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTefsQuery, GetTefsQueryVariables, GetTefsProps<TChildProps>>(GetTefsDocument, {
      alias: 'getTefs',
      ...operationOptions
    });
};
export type GetTefsQueryResult = ApolloReactCommon.QueryResult<GetTefsQuery, GetTefsQueryVariables>;
export const GetTerminalDocument = gql`
    query getTerminal($values: TerminalDAOInput) {
  getTerminal(values: $values) {
    ativo
    datainiciocobranca
    concioperadora
    editValues {
      codexterno
      nome
      codpessoacliente
      vinculo
      status
    }
  }
}
    `;
export type GetTerminalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTerminalQuery, GetTerminalQueryVariables>, 'query'>;

    export const GetTerminalComponent = (props: GetTerminalComponentProps) => (
      <ApolloReactComponents.Query<GetTerminalQuery, GetTerminalQueryVariables> query={GetTerminalDocument} {...props} />
    );
    
export type GetTerminalProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTerminalQuery, GetTerminalQueryVariables> & TChildProps;
export function withGetTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTerminalQuery,
  GetTerminalQueryVariables,
  GetTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTerminalQuery, GetTerminalQueryVariables, GetTerminalProps<TChildProps>>(GetTerminalDocument, {
      alias: 'getTerminal',
      ...operationOptions
    });
};
export type GetTerminalQueryResult = ApolloReactCommon.QueryResult<GetTerminalQuery, GetTerminalQueryVariables>;
export const GetUrlPainelDocument = gql`
    query getUrlPainel {
  getUrlPainel
}
    `;
export type GetUrlPainelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUrlPainelQuery, GetUrlPainelQueryVariables>, 'query'>;

    export const GetUrlPainelComponent = (props: GetUrlPainelComponentProps) => (
      <ApolloReactComponents.Query<GetUrlPainelQuery, GetUrlPainelQueryVariables> query={GetUrlPainelDocument} {...props} />
    );
    
export type GetUrlPainelProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUrlPainelQuery, GetUrlPainelQueryVariables> & TChildProps;
export function withGetUrlPainel<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUrlPainelQuery,
  GetUrlPainelQueryVariables,
  GetUrlPainelProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUrlPainelQuery, GetUrlPainelQueryVariables, GetUrlPainelProps<TChildProps>>(GetUrlPainelDocument, {
      alias: 'getUrlPainel',
      ...operationOptions
    });
};
export type GetUrlPainelQueryResult = ApolloReactCommon.QueryResult<GetUrlPainelQuery, GetUrlPainelQueryVariables>;
export const GetUserInfoDocument = gql`
    query getUserInfo {
  getUserInfo {
    nomeAbreviado
    uscodigo
    logoMarcarRevenda
    simulado
    fullName
    codPessoa
    codPessoaPai
    codPerfil
  }
}
    `;
export type GetUserInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserInfoQuery, GetUserInfoQueryVariables>, 'query'>;

    export const GetUserInfoComponent = (props: GetUserInfoComponentProps) => (
      <ApolloReactComponents.Query<GetUserInfoQuery, GetUserInfoQueryVariables> query={GetUserInfoDocument} {...props} />
    );
    
export type GetUserInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserInfoQuery, GetUserInfoQueryVariables> & TChildProps;
export function withGetUserInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  GetUserInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserInfoQuery, GetUserInfoQueryVariables, GetUserInfoProps<TChildProps>>(GetUserInfoDocument, {
      alias: 'getUserInfo',
      ...operationOptions
    });
};
export type GetUserInfoQueryResult = ApolloReactCommon.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUsuarioDocument = gql`
    query getUsuario($values: UsuarioDAOInput) {
  getUsuario(values: $values) {
    pessoa {
      pessoa {
        codpessoa
        nome
        estado
        cidade
        bairro
        endereco
        complemento
        numero
        codpessoa
        telefone
        email
        status
        cep
        tipo
      }
      pessoaF {
        cpf
      }
    }
    editValues {
      login
      senha
      validade
      codperfil
      responsavel
    }
  }
}
    `;
export type GetUsuarioComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsuarioQuery, GetUsuarioQueryVariables>, 'query'>;

    export const GetUsuarioComponent = (props: GetUsuarioComponentProps) => (
      <ApolloReactComponents.Query<GetUsuarioQuery, GetUsuarioQueryVariables> query={GetUsuarioDocument} {...props} />
    );
    
export type GetUsuarioProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUsuarioQuery, GetUsuarioQueryVariables> & TChildProps;
export function withGetUsuario<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsuarioQuery,
  GetUsuarioQueryVariables,
  GetUsuarioProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsuarioQuery, GetUsuarioQueryVariables, GetUsuarioProps<TChildProps>>(GetUsuarioDocument, {
      alias: 'getUsuario',
      ...operationOptions
    });
};
export type GetUsuarioQueryResult = ApolloReactCommon.QueryResult<GetUsuarioQuery, GetUsuarioQueryVariables>;
export const GetUsuariosMestresDocument = gql`
    query getUsuariosMestres {
  getUsuariosMestres {
    codpessoa
    login
    nome
    validade
    piloto
    codperfil
    codpessoapai
  }
}
    `;
export type GetUsuariosMestresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsuariosMestresQuery, GetUsuariosMestresQueryVariables>, 'query'>;

    export const GetUsuariosMestresComponent = (props: GetUsuariosMestresComponentProps) => (
      <ApolloReactComponents.Query<GetUsuariosMestresQuery, GetUsuariosMestresQueryVariables> query={GetUsuariosMestresDocument} {...props} />
    );
    
export type GetUsuariosMestresProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUsuariosMestresQuery, GetUsuariosMestresQueryVariables> & TChildProps;
export function withGetUsuariosMestres<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsuariosMestresQuery,
  GetUsuariosMestresQueryVariables,
  GetUsuariosMestresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsuariosMestresQuery, GetUsuariosMestresQueryVariables, GetUsuariosMestresProps<TChildProps>>(GetUsuariosMestresDocument, {
      alias: 'getUsuariosMestres',
      ...operationOptions
    });
};
export type GetUsuariosMestresQueryResult = ApolloReactCommon.QueryResult<GetUsuariosMestresQuery, GetUsuariosMestresQueryVariables>;
export const GetUsuariosMestresRevendaDocument = gql`
    query getUsuariosMestresRevenda {
  getUsuariosMestresRevenda {
    codpessoa
    login
    nome
    validade
    piloto
    codperfil
    codpessoapai
  }
}
    `;
export type GetUsuariosMestresRevendaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsuariosMestresRevendaQuery, GetUsuariosMestresRevendaQueryVariables>, 'query'>;

    export const GetUsuariosMestresRevendaComponent = (props: GetUsuariosMestresRevendaComponentProps) => (
      <ApolloReactComponents.Query<GetUsuariosMestresRevendaQuery, GetUsuariosMestresRevendaQueryVariables> query={GetUsuariosMestresRevendaDocument} {...props} />
    );
    
export type GetUsuariosMestresRevendaProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUsuariosMestresRevendaQuery, GetUsuariosMestresRevendaQueryVariables> & TChildProps;
export function withGetUsuariosMestresRevenda<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUsuariosMestresRevendaQuery,
  GetUsuariosMestresRevendaQueryVariables,
  GetUsuariosMestresRevendaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUsuariosMestresRevendaQuery, GetUsuariosMestresRevendaQueryVariables, GetUsuariosMestresRevendaProps<TChildProps>>(GetUsuariosMestresRevendaDocument, {
      alias: 'getUsuariosMestresRevenda',
      ...operationOptions
    });
};
export type GetUsuariosMestresRevendaQueryResult = ApolloReactCommon.QueryResult<GetUsuariosMestresRevendaQuery, GetUsuariosMestresRevendaQueryVariables>;
export const ListaClientesDocument = gql`
    query listaClientes {
  listaClientes {
    label
    valor
  }
}
    `;
export type ListaClientesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListaClientesQuery, ListaClientesQueryVariables>, 'query'>;

    export const ListaClientesComponent = (props: ListaClientesComponentProps) => (
      <ApolloReactComponents.Query<ListaClientesQuery, ListaClientesQueryVariables> query={ListaClientesDocument} {...props} />
    );
    
export type ListaClientesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListaClientesQuery, ListaClientesQueryVariables> & TChildProps;
export function withListaClientes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListaClientesQuery,
  ListaClientesQueryVariables,
  ListaClientesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListaClientesQuery, ListaClientesQueryVariables, ListaClientesProps<TChildProps>>(ListaClientesDocument, {
      alias: 'listaClientes',
      ...operationOptions
    });
};
export type ListaClientesQueryResult = ApolloReactCommon.QueryResult<ListaClientesQuery, ListaClientesQueryVariables>;
export const ListaOperadorasTaxasAntecipaxaoDocument = gql`
    query listaOperadorasTaxasAntecipaxao($codOperadora: Int) {
  listaOperadorasTaxasAntecipaxao(codOperadora: $codOperadora) {
    label
    valor
  }
}
    `;
export type ListaOperadorasTaxasAntecipaxaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListaOperadorasTaxasAntecipaxaoQuery, ListaOperadorasTaxasAntecipaxaoQueryVariables>, 'query'>;

    export const ListaOperadorasTaxasAntecipaxaoComponent = (props: ListaOperadorasTaxasAntecipaxaoComponentProps) => (
      <ApolloReactComponents.Query<ListaOperadorasTaxasAntecipaxaoQuery, ListaOperadorasTaxasAntecipaxaoQueryVariables> query={ListaOperadorasTaxasAntecipaxaoDocument} {...props} />
    );
    
export type ListaOperadorasTaxasAntecipaxaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListaOperadorasTaxasAntecipaxaoQuery, ListaOperadorasTaxasAntecipaxaoQueryVariables> & TChildProps;
export function withListaOperadorasTaxasAntecipaxao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListaOperadorasTaxasAntecipaxaoQuery,
  ListaOperadorasTaxasAntecipaxaoQueryVariables,
  ListaOperadorasTaxasAntecipaxaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListaOperadorasTaxasAntecipaxaoQuery, ListaOperadorasTaxasAntecipaxaoQueryVariables, ListaOperadorasTaxasAntecipaxaoProps<TChildProps>>(ListaOperadorasTaxasAntecipaxaoDocument, {
      alias: 'listaOperadorasTaxasAntecipaxao',
      ...operationOptions
    });
};
export type ListaOperadorasTaxasAntecipaxaoQueryResult = ApolloReactCommon.QueryResult<ListaOperadorasTaxasAntecipaxaoQuery, ListaOperadorasTaxasAntecipaxaoQueryVariables>;
export const ListaOrigensAjustesDocument = gql`
    query listaOrigensAjustes {
  listaOrigensAjustes {
    label
    valor
  }
}
    `;
export type ListaOrigensAjustesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListaOrigensAjustesQuery, ListaOrigensAjustesQueryVariables>, 'query'>;

    export const ListaOrigensAjustesComponent = (props: ListaOrigensAjustesComponentProps) => (
      <ApolloReactComponents.Query<ListaOrigensAjustesQuery, ListaOrigensAjustesQueryVariables> query={ListaOrigensAjustesDocument} {...props} />
    );
    
export type ListaOrigensAjustesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListaOrigensAjustesQuery, ListaOrigensAjustesQueryVariables> & TChildProps;
export function withListaOrigensAjustes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListaOrigensAjustesQuery,
  ListaOrigensAjustesQueryVariables,
  ListaOrigensAjustesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListaOrigensAjustesQuery, ListaOrigensAjustesQueryVariables, ListaOrigensAjustesProps<TChildProps>>(ListaOrigensAjustesDocument, {
      alias: 'listaOrigensAjustes',
      ...operationOptions
    });
};
export type ListaOrigensAjustesQueryResult = ApolloReactCommon.QueryResult<ListaOrigensAjustesQuery, ListaOrigensAjustesQueryVariables>;
export const ListaUnidadesConciliadorasDocument = gql`
    query listaUnidadesConciliadoras {
  listaUnidadesConciliadoras {
    label
    valor
  }
}
    `;
export type ListaUnidadesConciliadorasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListaUnidadesConciliadorasQuery, ListaUnidadesConciliadorasQueryVariables>, 'query'>;

    export const ListaUnidadesConciliadorasComponent = (props: ListaUnidadesConciliadorasComponentProps) => (
      <ApolloReactComponents.Query<ListaUnidadesConciliadorasQuery, ListaUnidadesConciliadorasQueryVariables> query={ListaUnidadesConciliadorasDocument} {...props} />
    );
    
export type ListaUnidadesConciliadorasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListaUnidadesConciliadorasQuery, ListaUnidadesConciliadorasQueryVariables> & TChildProps;
export function withListaUnidadesConciliadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListaUnidadesConciliadorasQuery,
  ListaUnidadesConciliadorasQueryVariables,
  ListaUnidadesConciliadorasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListaUnidadesConciliadorasQuery, ListaUnidadesConciliadorasQueryVariables, ListaUnidadesConciliadorasProps<TChildProps>>(ListaUnidadesConciliadorasDocument, {
      alias: 'listaUnidadesConciliadoras',
      ...operationOptions
    });
};
export type ListaUnidadesConciliadorasQueryResult = ApolloReactCommon.QueryResult<ListaUnidadesConciliadorasQuery, ListaUnidadesConciliadorasQueryVariables>;
export const ListarAllTefsDaoDocument = gql`
    query listarAllTefsDAO {
  listarAllTefsDAO {
    codtefs
    nome
    obs
  }
}
    `;
export type ListarAllTefsDaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarAllTefsDaoQuery, ListarAllTefsDaoQueryVariables>, 'query'>;

    export const ListarAllTefsDaoComponent = (props: ListarAllTefsDaoComponentProps) => (
      <ApolloReactComponents.Query<ListarAllTefsDaoQuery, ListarAllTefsDaoQueryVariables> query={ListarAllTefsDaoDocument} {...props} />
    );
    
export type ListarAllTefsDaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarAllTefsDaoQuery, ListarAllTefsDaoQueryVariables> & TChildProps;
export function withListarAllTefsDao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarAllTefsDaoQuery,
  ListarAllTefsDaoQueryVariables,
  ListarAllTefsDaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarAllTefsDaoQuery, ListarAllTefsDaoQueryVariables, ListarAllTefsDaoProps<TChildProps>>(ListarAllTefsDaoDocument, {
      alias: 'listarAllTefsDao',
      ...operationOptions
    });
};
export type ListarAllTefsDaoQueryResult = ApolloReactCommon.QueryResult<ListarAllTefsDaoQuery, ListarAllTefsDaoQueryVariables>;
export const ListarBandeirasDocument = gql`
    query listarBandeiras($codOperadora: Int) {
  listarBandeiras(codOperadora: $codOperadora) {
    label
    valor
  }
}
    `;
export type ListarBandeirasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarBandeirasQuery, ListarBandeirasQueryVariables>, 'query'>;

    export const ListarBandeirasComponent = (props: ListarBandeirasComponentProps) => (
      <ApolloReactComponents.Query<ListarBandeirasQuery, ListarBandeirasQueryVariables> query={ListarBandeirasDocument} {...props} />
    );
    
export type ListarBandeirasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarBandeirasQuery, ListarBandeirasQueryVariables> & TChildProps;
export function withListarBandeiras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarBandeirasQuery,
  ListarBandeirasQueryVariables,
  ListarBandeirasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarBandeirasQuery, ListarBandeirasQueryVariables, ListarBandeirasProps<TChildProps>>(ListarBandeirasDocument, {
      alias: 'listarBandeiras',
      ...operationOptions
    });
};
export type ListarBandeirasQueryResult = ApolloReactCommon.QueryResult<ListarBandeirasQuery, ListarBandeirasQueryVariables>;
export const ListarBucketS3AmazonDocument = gql`
    query listarBucketS3Amazon($values: BucketS3AmazonDAOInput) {
  listarBucketS3Amazon(values: $values) {
    codbuckets3amazon
    codpessoapai
    keyid
    senha
    nome
    pasta
    bucketname
  }
}
    `;
export type ListarBucketS3AmazonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarBucketS3AmazonQuery, ListarBucketS3AmazonQueryVariables>, 'query'>;

    export const ListarBucketS3AmazonComponent = (props: ListarBucketS3AmazonComponentProps) => (
      <ApolloReactComponents.Query<ListarBucketS3AmazonQuery, ListarBucketS3AmazonQueryVariables> query={ListarBucketS3AmazonDocument} {...props} />
    );
    
export type ListarBucketS3AmazonProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarBucketS3AmazonQuery, ListarBucketS3AmazonQueryVariables> & TChildProps;
export function withListarBucketS3Amazon<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarBucketS3AmazonQuery,
  ListarBucketS3AmazonQueryVariables,
  ListarBucketS3AmazonProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarBucketS3AmazonQuery, ListarBucketS3AmazonQueryVariables, ListarBucketS3AmazonProps<TChildProps>>(ListarBucketS3AmazonDocument, {
      alias: 'listarBucketS3Amazon',
      ...operationOptions
    });
};
export type ListarBucketS3AmazonQueryResult = ApolloReactCommon.QueryResult<ListarBucketS3AmazonQuery, ListarBucketS3AmazonQueryVariables>;
export const ListarCidadesDocument = gql`
    query listarCidades {
  listarCidades {
    label
    valor
  }
}
    `;
export type ListarCidadesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarCidadesQuery, ListarCidadesQueryVariables>, 'query'>;

    export const ListarCidadesComponent = (props: ListarCidadesComponentProps) => (
      <ApolloReactComponents.Query<ListarCidadesQuery, ListarCidadesQueryVariables> query={ListarCidadesDocument} {...props} />
    );
    
export type ListarCidadesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarCidadesQuery, ListarCidadesQueryVariables> & TChildProps;
export function withListarCidades<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarCidadesQuery,
  ListarCidadesQueryVariables,
  ListarCidadesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarCidadesQuery, ListarCidadesQueryVariables, ListarCidadesProps<TChildProps>>(ListarCidadesDocument, {
      alias: 'listarCidades',
      ...operationOptions
    });
};
export type ListarCidadesQueryResult = ApolloReactCommon.QueryResult<ListarCidadesQuery, ListarCidadesQueryVariables>;
export const ListarCodigoOperadoraTotvsDocument = gql`
    query listarCodigoOperadoraTOTVS {
  listarCodigoOperadoraTOTVS {
    codoperadora
    codpessoapai
    codexterno
    nome
  }
}
    `;
export type ListarCodigoOperadoraTotvsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarCodigoOperadoraTotvsQuery, ListarCodigoOperadoraTotvsQueryVariables>, 'query'>;

    export const ListarCodigoOperadoraTotvsComponent = (props: ListarCodigoOperadoraTotvsComponentProps) => (
      <ApolloReactComponents.Query<ListarCodigoOperadoraTotvsQuery, ListarCodigoOperadoraTotvsQueryVariables> query={ListarCodigoOperadoraTotvsDocument} {...props} />
    );
    
export type ListarCodigoOperadoraTotvsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarCodigoOperadoraTotvsQuery, ListarCodigoOperadoraTotvsQueryVariables> & TChildProps;
export function withListarCodigoOperadoraTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarCodigoOperadoraTotvsQuery,
  ListarCodigoOperadoraTotvsQueryVariables,
  ListarCodigoOperadoraTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarCodigoOperadoraTotvsQuery, ListarCodigoOperadoraTotvsQueryVariables, ListarCodigoOperadoraTotvsProps<TChildProps>>(ListarCodigoOperadoraTotvsDocument, {
      alias: 'listarCodigoOperadoraTotvs',
      ...operationOptions
    });
};
export type ListarCodigoOperadoraTotvsQueryResult = ApolloReactCommon.QueryResult<ListarCodigoOperadoraTotvsQuery, ListarCodigoOperadoraTotvsQueryVariables>;
export const ListarConciliacaoEmAbertoDocument = gql`
    query listarConciliacaoEmAberto($values: ManutencaoArquivosConiliacaoAbertoInput) {
  listarConciliacaoEmAberto(values: $values) {
    codarquivo
    titulo
    dataenvio
    datarecebimento
    dataprocessamento
    tipo
    status
    nomearquivo
    mensagem
  }
}
    `;
export type ListarConciliacaoEmAbertoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarConciliacaoEmAbertoQuery, ListarConciliacaoEmAbertoQueryVariables>, 'query'>;

    export const ListarConciliacaoEmAbertoComponent = (props: ListarConciliacaoEmAbertoComponentProps) => (
      <ApolloReactComponents.Query<ListarConciliacaoEmAbertoQuery, ListarConciliacaoEmAbertoQueryVariables> query={ListarConciliacaoEmAbertoDocument} {...props} />
    );
    
export type ListarConciliacaoEmAbertoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarConciliacaoEmAbertoQuery, ListarConciliacaoEmAbertoQueryVariables> & TChildProps;
export function withListarConciliacaoEmAberto<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarConciliacaoEmAbertoQuery,
  ListarConciliacaoEmAbertoQueryVariables,
  ListarConciliacaoEmAbertoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarConciliacaoEmAbertoQuery, ListarConciliacaoEmAbertoQueryVariables, ListarConciliacaoEmAbertoProps<TChildProps>>(ListarConciliacaoEmAbertoDocument, {
      alias: 'listarConciliacaoEmAberto',
      ...operationOptions
    });
};
export type ListarConciliacaoEmAbertoQueryResult = ApolloReactCommon.QueryResult<ListarConciliacaoEmAbertoQuery, ListarConciliacaoEmAbertoQueryVariables>;
export const ListarContaCorrenteDocument = gql`
    query listarContaCorrente {
  listarContaCorrente {
    label
    valor
  }
}
    `;
export type ListarContaCorrenteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarContaCorrenteQuery, ListarContaCorrenteQueryVariables>, 'query'>;

    export const ListarContaCorrenteComponent = (props: ListarContaCorrenteComponentProps) => (
      <ApolloReactComponents.Query<ListarContaCorrenteQuery, ListarContaCorrenteQueryVariables> query={ListarContaCorrenteDocument} {...props} />
    );
    
export type ListarContaCorrenteProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarContaCorrenteQuery, ListarContaCorrenteQueryVariables> & TChildProps;
export function withListarContaCorrente<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarContaCorrenteQuery,
  ListarContaCorrenteQueryVariables,
  ListarContaCorrenteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarContaCorrenteQuery, ListarContaCorrenteQueryVariables, ListarContaCorrenteProps<TChildProps>>(ListarContaCorrenteDocument, {
      alias: 'listarContaCorrente',
      ...operationOptions
    });
};
export type ListarContaCorrenteQueryResult = ApolloReactCommon.QueryResult<ListarContaCorrenteQuery, ListarContaCorrenteQueryVariables>;
export const ListarEnderecosAdicionaisDocument = gql`
    query listarEnderecosAdicionais {
  listarEnderecosAdicionais {
    label
    valor
  }
}
    `;
export type ListarEnderecosAdicionaisComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarEnderecosAdicionaisQuery, ListarEnderecosAdicionaisQueryVariables>, 'query'>;

    export const ListarEnderecosAdicionaisComponent = (props: ListarEnderecosAdicionaisComponentProps) => (
      <ApolloReactComponents.Query<ListarEnderecosAdicionaisQuery, ListarEnderecosAdicionaisQueryVariables> query={ListarEnderecosAdicionaisDocument} {...props} />
    );
    
export type ListarEnderecosAdicionaisProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarEnderecosAdicionaisQuery, ListarEnderecosAdicionaisQueryVariables> & TChildProps;
export function withListarEnderecosAdicionais<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarEnderecosAdicionaisQuery,
  ListarEnderecosAdicionaisQueryVariables,
  ListarEnderecosAdicionaisProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarEnderecosAdicionaisQuery, ListarEnderecosAdicionaisQueryVariables, ListarEnderecosAdicionaisProps<TChildProps>>(ListarEnderecosAdicionaisDocument, {
      alias: 'listarEnderecosAdicionais',
      ...operationOptions
    });
};
export type ListarEnderecosAdicionaisQueryResult = ApolloReactCommon.QueryResult<ListarEnderecosAdicionaisQuery, ListarEnderecosAdicionaisQueryVariables>;
export const ListarEstabelecimentosDocument = gql`
    query listarEstabelecimentos($values: ListarEstabelecimentosInput) {
  listarEstabelecimentos(values: $values) {
    display
    conciOperadora {
      codexterno
    }
    conciliadores {
      apelido
      codpessoa
    }
    pessoa {
      pessoaJ {
        cnpj
      }
      pessoa {
        nome
        codpessoa
      }
    }
  }
}
    `;
export type ListarEstabelecimentosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarEstabelecimentosQuery, ListarEstabelecimentosQueryVariables>, 'query'>;

    export const ListarEstabelecimentosComponent = (props: ListarEstabelecimentosComponentProps) => (
      <ApolloReactComponents.Query<ListarEstabelecimentosQuery, ListarEstabelecimentosQueryVariables> query={ListarEstabelecimentosDocument} {...props} />
    );
    
export type ListarEstabelecimentosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarEstabelecimentosQuery, ListarEstabelecimentosQueryVariables> & TChildProps;
export function withListarEstabelecimentos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarEstabelecimentosQuery,
  ListarEstabelecimentosQueryVariables,
  ListarEstabelecimentosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarEstabelecimentosQuery, ListarEstabelecimentosQueryVariables, ListarEstabelecimentosProps<TChildProps>>(ListarEstabelecimentosDocument, {
      alias: 'listarEstabelecimentos',
      ...operationOptions
    });
};
export type ListarEstabelecimentosQueryResult = ApolloReactCommon.QueryResult<ListarEstabelecimentosQuery, ListarEstabelecimentosQueryVariables>;
export const ListarEstadosDocument = gql`
    query listarEstados {
  listarEstados {
    label
    valor
  }
}
    `;
export type ListarEstadosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarEstadosQuery, ListarEstadosQueryVariables>, 'query'>;

    export const ListarEstadosComponent = (props: ListarEstadosComponentProps) => (
      <ApolloReactComponents.Query<ListarEstadosQuery, ListarEstadosQueryVariables> query={ListarEstadosDocument} {...props} />
    );
    
export type ListarEstadosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarEstadosQuery, ListarEstadosQueryVariables> & TChildProps;
export function withListarEstados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarEstadosQuery,
  ListarEstadosQueryVariables,
  ListarEstadosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarEstadosQuery, ListarEstadosQueryVariables, ListarEstadosProps<TChildProps>>(ListarEstadosDocument, {
      alias: 'listarEstados',
      ...operationOptions
    });
};
export type ListarEstadosQueryResult = ApolloReactCommon.QueryResult<ListarEstadosQuery, ListarEstadosQueryVariables>;
export const ListarExtratosOriginaisDocument = gql`
    query listarExtratosOriginais($values: ManutencaoArquivosInput) {
  listarExtratosOriginais(values: $values) {
    codarquivo
    dataenvio
    datarecebimento
    tipo
    nomearquivo
  }
}
    `;
export type ListarExtratosOriginaisComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarExtratosOriginaisQuery, ListarExtratosOriginaisQueryVariables>, 'query'>;

    export const ListarExtratosOriginaisComponent = (props: ListarExtratosOriginaisComponentProps) => (
      <ApolloReactComponents.Query<ListarExtratosOriginaisQuery, ListarExtratosOriginaisQueryVariables> query={ListarExtratosOriginaisDocument} {...props} />
    );
    
export type ListarExtratosOriginaisProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarExtratosOriginaisQuery, ListarExtratosOriginaisQueryVariables> & TChildProps;
export function withListarExtratosOriginais<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarExtratosOriginaisQuery,
  ListarExtratosOriginaisQueryVariables,
  ListarExtratosOriginaisProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarExtratosOriginaisQuery, ListarExtratosOriginaisQueryVariables, ListarExtratosOriginaisProps<TChildProps>>(ListarExtratosOriginaisDocument, {
      alias: 'listarExtratosOriginais',
      ...operationOptions
    });
};
export type ListarExtratosOriginaisQueryResult = ApolloReactCommon.QueryResult<ListarExtratosOriginaisQuery, ListarExtratosOriginaisQueryVariables>;
export const ListarFaturamentoMovimentoDocument = gql`
    query listarFaturamentoMovimento($values: FaturamentoMovimentoInput) {
  listarFaturamentoMovimento(values: $values) {
    datainclusao
    nome
    filtrode
    filtroate
    valorbruto
    valorliquidoadm
    valorliquidoterminal
    creditos
    debitos
    apurado
    dataprocessado
    chave
    apurado
  }
}
    `;
export type ListarFaturamentoMovimentoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarFaturamentoMovimentoQuery, ListarFaturamentoMovimentoQueryVariables>, 'query'>;

    export const ListarFaturamentoMovimentoComponent = (props: ListarFaturamentoMovimentoComponentProps) => (
      <ApolloReactComponents.Query<ListarFaturamentoMovimentoQuery, ListarFaturamentoMovimentoQueryVariables> query={ListarFaturamentoMovimentoDocument} {...props} />
    );
    
export type ListarFaturamentoMovimentoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarFaturamentoMovimentoQuery, ListarFaturamentoMovimentoQueryVariables> & TChildProps;
export function withListarFaturamentoMovimento<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarFaturamentoMovimentoQuery,
  ListarFaturamentoMovimentoQueryVariables,
  ListarFaturamentoMovimentoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarFaturamentoMovimentoQuery, ListarFaturamentoMovimentoQueryVariables, ListarFaturamentoMovimentoProps<TChildProps>>(ListarFaturamentoMovimentoDocument, {
      alias: 'listarFaturamentoMovimento',
      ...operationOptions
    });
};
export type ListarFaturamentoMovimentoQueryResult = ApolloReactCommon.QueryResult<ListarFaturamentoMovimentoQuery, ListarFaturamentoMovimentoQueryVariables>;
export const ListarIntegracaoAjusteDocument = gql`
    query listarIntegracaoAjuste {
  listarIntegracaoAjuste {
    codorigemajuste
    codpessoapai
    codexterno
    ajuste
  }
}
    `;
export type ListarIntegracaoAjusteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarIntegracaoAjusteQuery, ListarIntegracaoAjusteQueryVariables>, 'query'>;

    export const ListarIntegracaoAjusteComponent = (props: ListarIntegracaoAjusteComponentProps) => (
      <ApolloReactComponents.Query<ListarIntegracaoAjusteQuery, ListarIntegracaoAjusteQueryVariables> query={ListarIntegracaoAjusteDocument} {...props} />
    );
    
export type ListarIntegracaoAjusteProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarIntegracaoAjusteQuery, ListarIntegracaoAjusteQueryVariables> & TChildProps;
export function withListarIntegracaoAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarIntegracaoAjusteQuery,
  ListarIntegracaoAjusteQueryVariables,
  ListarIntegracaoAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarIntegracaoAjusteQuery, ListarIntegracaoAjusteQueryVariables, ListarIntegracaoAjusteProps<TChildProps>>(ListarIntegracaoAjusteDocument, {
      alias: 'listarIntegracaoAjuste',
      ...operationOptions
    });
};
export type ListarIntegracaoAjusteQueryResult = ApolloReactCommon.QueryResult<ListarIntegracaoAjusteQuery, ListarIntegracaoAjusteQueryVariables>;
export const ListarIntegracaoSoftExpressCadastroDocument = gql`
    query listarIntegracaoSoftExpressCadastro {
  listarIntegracaoSoftExpressCadastro {
    codpessoa
    codexterno
    nome
  }
}
    `;
export type ListarIntegracaoSoftExpressCadastroComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarIntegracaoSoftExpressCadastroQuery, ListarIntegracaoSoftExpressCadastroQueryVariables>, 'query'>;

    export const ListarIntegracaoSoftExpressCadastroComponent = (props: ListarIntegracaoSoftExpressCadastroComponentProps) => (
      <ApolloReactComponents.Query<ListarIntegracaoSoftExpressCadastroQuery, ListarIntegracaoSoftExpressCadastroQueryVariables> query={ListarIntegracaoSoftExpressCadastroDocument} {...props} />
    );
    
export type ListarIntegracaoSoftExpressCadastroProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarIntegracaoSoftExpressCadastroQuery, ListarIntegracaoSoftExpressCadastroQueryVariables> & TChildProps;
export function withListarIntegracaoSoftExpressCadastro<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarIntegracaoSoftExpressCadastroQuery,
  ListarIntegracaoSoftExpressCadastroQueryVariables,
  ListarIntegracaoSoftExpressCadastroProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarIntegracaoSoftExpressCadastroQuery, ListarIntegracaoSoftExpressCadastroQueryVariables, ListarIntegracaoSoftExpressCadastroProps<TChildProps>>(ListarIntegracaoSoftExpressCadastroDocument, {
      alias: 'listarIntegracaoSoftExpressCadastro',
      ...operationOptions
    });
};
export type ListarIntegracaoSoftExpressCadastroQueryResult = ApolloReactCommon.QueryResult<ListarIntegracaoSoftExpressCadastroQuery, ListarIntegracaoSoftExpressCadastroQueryVariables>;
export const ListarItensBucketS3AmazonDocument = gql`
    query listarItensBucketS3Amazon {
  listarItensBucketS3Amazon {
    label
    valor
  }
}
    `;
export type ListarItensBucketS3AmazonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensBucketS3AmazonQuery, ListarItensBucketS3AmazonQueryVariables>, 'query'>;

    export const ListarItensBucketS3AmazonComponent = (props: ListarItensBucketS3AmazonComponentProps) => (
      <ApolloReactComponents.Query<ListarItensBucketS3AmazonQuery, ListarItensBucketS3AmazonQueryVariables> query={ListarItensBucketS3AmazonDocument} {...props} />
    );
    
export type ListarItensBucketS3AmazonProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensBucketS3AmazonQuery, ListarItensBucketS3AmazonQueryVariables> & TChildProps;
export function withListarItensBucketS3Amazon<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensBucketS3AmazonQuery,
  ListarItensBucketS3AmazonQueryVariables,
  ListarItensBucketS3AmazonProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensBucketS3AmazonQuery, ListarItensBucketS3AmazonQueryVariables, ListarItensBucketS3AmazonProps<TChildProps>>(ListarItensBucketS3AmazonDocument, {
      alias: 'listarItensBucketS3Amazon',
      ...operationOptions
    });
};
export type ListarItensBucketS3AmazonQueryResult = ApolloReactCommon.QueryResult<ListarItensBucketS3AmazonQuery, ListarItensBucketS3AmazonQueryVariables>;
export const ListarItensConciliadoresDocument = gql`
    query listarItensConciliadores {
  listarItensConciliadores {
    label
    valor
  }
}
    `;
export type ListarItensConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensConciliadoresQuery, ListarItensConciliadoresQueryVariables>, 'query'>;

    export const ListarItensConciliadoresComponent = (props: ListarItensConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<ListarItensConciliadoresQuery, ListarItensConciliadoresQueryVariables> query={ListarItensConciliadoresDocument} {...props} />
    );
    
export type ListarItensConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensConciliadoresQuery, ListarItensConciliadoresQueryVariables> & TChildProps;
export function withListarItensConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensConciliadoresQuery,
  ListarItensConciliadoresQueryVariables,
  ListarItensConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensConciliadoresQuery, ListarItensConciliadoresQueryVariables, ListarItensConciliadoresProps<TChildProps>>(ListarItensConciliadoresDocument, {
      alias: 'listarItensConciliadores',
      ...operationOptions
    });
};
export type ListarItensConciliadoresQueryResult = ApolloReactCommon.QueryResult<ListarItensConciliadoresQuery, ListarItensConciliadoresQueryVariables>;
export const ListarItensConciliadoresCodExternoDocument = gql`
    query listarItensConciliadoresCodExterno($codpessoa: Int, $codOperadora: Int) {
  listarItensConciliadoresCodExterno(codpessoa: $codpessoa, codOperadora: $codOperadora) {
    label
    valor
  }
}
    `;
export type ListarItensConciliadoresCodExternoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensConciliadoresCodExternoQuery, ListarItensConciliadoresCodExternoQueryVariables>, 'query'>;

    export const ListarItensConciliadoresCodExternoComponent = (props: ListarItensConciliadoresCodExternoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensConciliadoresCodExternoQuery, ListarItensConciliadoresCodExternoQueryVariables> query={ListarItensConciliadoresCodExternoDocument} {...props} />
    );
    
export type ListarItensConciliadoresCodExternoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensConciliadoresCodExternoQuery, ListarItensConciliadoresCodExternoQueryVariables> & TChildProps;
export function withListarItensConciliadoresCodExterno<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensConciliadoresCodExternoQuery,
  ListarItensConciliadoresCodExternoQueryVariables,
  ListarItensConciliadoresCodExternoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensConciliadoresCodExternoQuery, ListarItensConciliadoresCodExternoQueryVariables, ListarItensConciliadoresCodExternoProps<TChildProps>>(ListarItensConciliadoresCodExternoDocument, {
      alias: 'listarItensConciliadoresCodExterno',
      ...operationOptions
    });
};
export type ListarItensConciliadoresCodExternoQueryResult = ApolloReactCommon.QueryResult<ListarItensConciliadoresCodExternoQuery, ListarItensConciliadoresCodExternoQueryVariables>;
export const ListarItensEstabelecimentoBancarioDocument = gql`
    query listarItensEstabelecimentoBancario {
  listarItensEstabelecimentoBancario {
    label
    valor
  }
}
    `;
export type ListarItensEstabelecimentoBancarioComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensEstabelecimentoBancarioQuery, ListarItensEstabelecimentoBancarioQueryVariables>, 'query'>;

    export const ListarItensEstabelecimentoBancarioComponent = (props: ListarItensEstabelecimentoBancarioComponentProps) => (
      <ApolloReactComponents.Query<ListarItensEstabelecimentoBancarioQuery, ListarItensEstabelecimentoBancarioQueryVariables> query={ListarItensEstabelecimentoBancarioDocument} {...props} />
    );
    
export type ListarItensEstabelecimentoBancarioProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensEstabelecimentoBancarioQuery, ListarItensEstabelecimentoBancarioQueryVariables> & TChildProps;
export function withListarItensEstabelecimentoBancario<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensEstabelecimentoBancarioQuery,
  ListarItensEstabelecimentoBancarioQueryVariables,
  ListarItensEstabelecimentoBancarioProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensEstabelecimentoBancarioQuery, ListarItensEstabelecimentoBancarioQueryVariables, ListarItensEstabelecimentoBancarioProps<TChildProps>>(ListarItensEstabelecimentoBancarioDocument, {
      alias: 'listarItensEstabelecimentoBancario',
      ...operationOptions
    });
};
export type ListarItensEstabelecimentoBancarioQueryResult = ApolloReactCommon.QueryResult<ListarItensEstabelecimentoBancarioQuery, ListarItensEstabelecimentoBancarioQueryVariables>;
export const ListarItensMeioCapturaDocument = gql`
    query listarItensMeioCaptura {
  listarItensMeioCaptura {
    label
    valor
  }
}
    `;
export type ListarItensMeioCapturaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensMeioCapturaQuery, ListarItensMeioCapturaQueryVariables>, 'query'>;

    export const ListarItensMeioCapturaComponent = (props: ListarItensMeioCapturaComponentProps) => (
      <ApolloReactComponents.Query<ListarItensMeioCapturaQuery, ListarItensMeioCapturaQueryVariables> query={ListarItensMeioCapturaDocument} {...props} />
    );
    
export type ListarItensMeioCapturaProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensMeioCapturaQuery, ListarItensMeioCapturaQueryVariables> & TChildProps;
export function withListarItensMeioCaptura<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensMeioCapturaQuery,
  ListarItensMeioCapturaQueryVariables,
  ListarItensMeioCapturaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensMeioCapturaQuery, ListarItensMeioCapturaQueryVariables, ListarItensMeioCapturaProps<TChildProps>>(ListarItensMeioCapturaDocument, {
      alias: 'listarItensMeioCaptura',
      ...operationOptions
    });
};
export type ListarItensMeioCapturaQueryResult = ApolloReactCommon.QueryResult<ListarItensMeioCapturaQuery, ListarItensMeioCapturaQueryVariables>;
export const ListarItensOrigemAjusteDocument = gql`
    query listarItensOrigemAjuste {
  listarItensOrigemAjuste {
    label
    valor
  }
}
    `;
export type ListarItensOrigemAjusteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensOrigemAjusteQuery, ListarItensOrigemAjusteQueryVariables>, 'query'>;

    export const ListarItensOrigemAjusteComponent = (props: ListarItensOrigemAjusteComponentProps) => (
      <ApolloReactComponents.Query<ListarItensOrigemAjusteQuery, ListarItensOrigemAjusteQueryVariables> query={ListarItensOrigemAjusteDocument} {...props} />
    );
    
export type ListarItensOrigemAjusteProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensOrigemAjusteQuery, ListarItensOrigemAjusteQueryVariables> & TChildProps;
export function withListarItensOrigemAjuste<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensOrigemAjusteQuery,
  ListarItensOrigemAjusteQueryVariables,
  ListarItensOrigemAjusteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensOrigemAjusteQuery, ListarItensOrigemAjusteQueryVariables, ListarItensOrigemAjusteProps<TChildProps>>(ListarItensOrigemAjusteDocument, {
      alias: 'listarItensOrigemAjuste',
      ...operationOptions
    });
};
export type ListarItensOrigemAjusteQueryResult = ApolloReactCommon.QueryResult<ListarItensOrigemAjusteQuery, ListarItensOrigemAjusteQueryVariables>;
export const ListarItensOrigemAjustePersonalizadoDocument = gql`
    query listarItensOrigemAjustePersonalizado {
  listarItensOrigemAjustePersonalizado {
    label
    valor
  }
}
    `;
export type ListarItensOrigemAjustePersonalizadoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensOrigemAjustePersonalizadoQuery, ListarItensOrigemAjustePersonalizadoQueryVariables>, 'query'>;

    export const ListarItensOrigemAjustePersonalizadoComponent = (props: ListarItensOrigemAjustePersonalizadoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensOrigemAjustePersonalizadoQuery, ListarItensOrigemAjustePersonalizadoQueryVariables> query={ListarItensOrigemAjustePersonalizadoDocument} {...props} />
    );
    
export type ListarItensOrigemAjustePersonalizadoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensOrigemAjustePersonalizadoQuery, ListarItensOrigemAjustePersonalizadoQueryVariables> & TChildProps;
export function withListarItensOrigemAjustePersonalizado<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensOrigemAjustePersonalizadoQuery,
  ListarItensOrigemAjustePersonalizadoQueryVariables,
  ListarItensOrigemAjustePersonalizadoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensOrigemAjustePersonalizadoQuery, ListarItensOrigemAjustePersonalizadoQueryVariables, ListarItensOrigemAjustePersonalizadoProps<TChildProps>>(ListarItensOrigemAjustePersonalizadoDocument, {
      alias: 'listarItensOrigemAjustePersonalizado',
      ...operationOptions
    });
};
export type ListarItensOrigemAjustePersonalizadoQueryResult = ApolloReactCommon.QueryResult<ListarItensOrigemAjustePersonalizadoQuery, ListarItensOrigemAjustePersonalizadoQueryVariables>;
export const ListarItensOrigemArquivoRecebidoDocument = gql`
    query listarItensOrigemArquivoRecebido {
  listarItensOrigemArquivoRecebido {
    label
    valor
  }
}
    `;
export type ListarItensOrigemArquivoRecebidoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensOrigemArquivoRecebidoQuery, ListarItensOrigemArquivoRecebidoQueryVariables>, 'query'>;

    export const ListarItensOrigemArquivoRecebidoComponent = (props: ListarItensOrigemArquivoRecebidoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensOrigemArquivoRecebidoQuery, ListarItensOrigemArquivoRecebidoQueryVariables> query={ListarItensOrigemArquivoRecebidoDocument} {...props} />
    );
    
export type ListarItensOrigemArquivoRecebidoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensOrigemArquivoRecebidoQuery, ListarItensOrigemArquivoRecebidoQueryVariables> & TChildProps;
export function withListarItensOrigemArquivoRecebido<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensOrigemArquivoRecebidoQuery,
  ListarItensOrigemArquivoRecebidoQueryVariables,
  ListarItensOrigemArquivoRecebidoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensOrigemArquivoRecebidoQuery, ListarItensOrigemArquivoRecebidoQueryVariables, ListarItensOrigemArquivoRecebidoProps<TChildProps>>(ListarItensOrigemArquivoRecebidoDocument, {
      alias: 'listarItensOrigemArquivoRecebido',
      ...operationOptions
    });
};
export type ListarItensOrigemArquivoRecebidoQueryResult = ApolloReactCommon.QueryResult<ListarItensOrigemArquivoRecebidoQuery, ListarItensOrigemArquivoRecebidoQueryVariables>;
export const ListarItensPlanosDocument = gql`
    query listarItensPlanos {
  listarItensPlanos {
    label
    valor
  }
}
    `;
export type ListarItensPlanosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensPlanosQuery, ListarItensPlanosQueryVariables>, 'query'>;

    export const ListarItensPlanosComponent = (props: ListarItensPlanosComponentProps) => (
      <ApolloReactComponents.Query<ListarItensPlanosQuery, ListarItensPlanosQueryVariables> query={ListarItensPlanosDocument} {...props} />
    );
    
export type ListarItensPlanosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensPlanosQuery, ListarItensPlanosQueryVariables> & TChildProps;
export function withListarItensPlanos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensPlanosQuery,
  ListarItensPlanosQueryVariables,
  ListarItensPlanosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensPlanosQuery, ListarItensPlanosQueryVariables, ListarItensPlanosProps<TChildProps>>(ListarItensPlanosDocument, {
      alias: 'listarItensPlanos',
      ...operationOptions
    });
};
export type ListarItensPlanosQueryResult = ApolloReactCommon.QueryResult<ListarItensPlanosQuery, ListarItensPlanosQueryVariables>;
export const ListarItensPlanosConciliadoresDocument = gql`
    query listarItensPlanosConciliadores {
  listarItensPlanosConciliadores {
    label
    valor
  }
}
    `;
export type ListarItensPlanosConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensPlanosConciliadoresQuery, ListarItensPlanosConciliadoresQueryVariables>, 'query'>;

    export const ListarItensPlanosConciliadoresComponent = (props: ListarItensPlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<ListarItensPlanosConciliadoresQuery, ListarItensPlanosConciliadoresQueryVariables> query={ListarItensPlanosConciliadoresDocument} {...props} />
    );
    
export type ListarItensPlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensPlanosConciliadoresQuery, ListarItensPlanosConciliadoresQueryVariables> & TChildProps;
export function withListarItensPlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensPlanosConciliadoresQuery,
  ListarItensPlanosConciliadoresQueryVariables,
  ListarItensPlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensPlanosConciliadoresQuery, ListarItensPlanosConciliadoresQueryVariables, ListarItensPlanosConciliadoresProps<TChildProps>>(ListarItensPlanosConciliadoresDocument, {
      alias: 'listarItensPlanosConciliadores',
      ...operationOptions
    });
};
export type ListarItensPlanosConciliadoresQueryResult = ApolloReactCommon.QueryResult<ListarItensPlanosConciliadoresQuery, ListarItensPlanosConciliadoresQueryVariables>;
export const ListarItensProdutosDocument = gql`
    query listarItensProdutos {
  listarItensProdutos {
    label
    valor
  }
}
    `;
export type ListarItensProdutosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensProdutosQuery, ListarItensProdutosQueryVariables>, 'query'>;

    export const ListarItensProdutosComponent = (props: ListarItensProdutosComponentProps) => (
      <ApolloReactComponents.Query<ListarItensProdutosQuery, ListarItensProdutosQueryVariables> query={ListarItensProdutosDocument} {...props} />
    );
    
export type ListarItensProdutosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensProdutosQuery, ListarItensProdutosQueryVariables> & TChildProps;
export function withListarItensProdutos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensProdutosQuery,
  ListarItensProdutosQueryVariables,
  ListarItensProdutosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensProdutosQuery, ListarItensProdutosQueryVariables, ListarItensProdutosProps<TChildProps>>(ListarItensProdutosDocument, {
      alias: 'listarItensProdutos',
      ...operationOptions
    });
};
export type ListarItensProdutosQueryResult = ApolloReactCommon.QueryResult<ListarItensProdutosQuery, ListarItensProdutosQueryVariables>;
export const ListarItensRecorrenteDocument = gql`
    query listarItensRecorrente {
  listarItensRecorrente {
    label
    valor
  }
}
    `;
export type ListarItensRecorrenteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensRecorrenteQuery, ListarItensRecorrenteQueryVariables>, 'query'>;

    export const ListarItensRecorrenteComponent = (props: ListarItensRecorrenteComponentProps) => (
      <ApolloReactComponents.Query<ListarItensRecorrenteQuery, ListarItensRecorrenteQueryVariables> query={ListarItensRecorrenteDocument} {...props} />
    );
    
export type ListarItensRecorrenteProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensRecorrenteQuery, ListarItensRecorrenteQueryVariables> & TChildProps;
export function withListarItensRecorrente<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensRecorrenteQuery,
  ListarItensRecorrenteQueryVariables,
  ListarItensRecorrenteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensRecorrenteQuery, ListarItensRecorrenteQueryVariables, ListarItensRecorrenteProps<TChildProps>>(ListarItensRecorrenteDocument, {
      alias: 'listarItensRecorrente',
      ...operationOptions
    });
};
export type ListarItensRecorrenteQueryResult = ApolloReactCommon.QueryResult<ListarItensRecorrenteQuery, ListarItensRecorrenteQueryVariables>;
export const ListarItensStatusArquivoDocument = gql`
    query listarItensStatusArquivo {
  listarItensStatusArquivo {
    label
    valor
  }
}
    `;
export type ListarItensStatusArquivoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensStatusArquivoQuery, ListarItensStatusArquivoQueryVariables>, 'query'>;

    export const ListarItensStatusArquivoComponent = (props: ListarItensStatusArquivoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensStatusArquivoQuery, ListarItensStatusArquivoQueryVariables> query={ListarItensStatusArquivoDocument} {...props} />
    );
    
export type ListarItensStatusArquivoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensStatusArquivoQuery, ListarItensStatusArquivoQueryVariables> & TChildProps;
export function withListarItensStatusArquivo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensStatusArquivoQuery,
  ListarItensStatusArquivoQueryVariables,
  ListarItensStatusArquivoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensStatusArquivoQuery, ListarItensStatusArquivoQueryVariables, ListarItensStatusArquivoProps<TChildProps>>(ListarItensStatusArquivoDocument, {
      alias: 'listarItensStatusArquivo',
      ...operationOptions
    });
};
export type ListarItensStatusArquivoQueryResult = ApolloReactCommon.QueryResult<ListarItensStatusArquivoQuery, ListarItensStatusArquivoQueryVariables>;
export const ListarItensStatusConciliacaoAntecipadosDocument = gql`
    query listarItensStatusConciliacaoAntecipados {
  listarItensStatusConciliacaoAntecipados {
    label
    valor
  }
}
    `;
export type ListarItensStatusConciliacaoAntecipadosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensStatusConciliacaoAntecipadosQuery, ListarItensStatusConciliacaoAntecipadosQueryVariables>, 'query'>;

    export const ListarItensStatusConciliacaoAntecipadosComponent = (props: ListarItensStatusConciliacaoAntecipadosComponentProps) => (
      <ApolloReactComponents.Query<ListarItensStatusConciliacaoAntecipadosQuery, ListarItensStatusConciliacaoAntecipadosQueryVariables> query={ListarItensStatusConciliacaoAntecipadosDocument} {...props} />
    );
    
export type ListarItensStatusConciliacaoAntecipadosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensStatusConciliacaoAntecipadosQuery, ListarItensStatusConciliacaoAntecipadosQueryVariables> & TChildProps;
export function withListarItensStatusConciliacaoAntecipados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensStatusConciliacaoAntecipadosQuery,
  ListarItensStatusConciliacaoAntecipadosQueryVariables,
  ListarItensStatusConciliacaoAntecipadosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensStatusConciliacaoAntecipadosQuery, ListarItensStatusConciliacaoAntecipadosQueryVariables, ListarItensStatusConciliacaoAntecipadosProps<TChildProps>>(ListarItensStatusConciliacaoAntecipadosDocument, {
      alias: 'listarItensStatusConciliacaoAntecipados',
      ...operationOptions
    });
};
export type ListarItensStatusConciliacaoAntecipadosQueryResult = ApolloReactCommon.QueryResult<ListarItensStatusConciliacaoAntecipadosQuery, ListarItensStatusConciliacaoAntecipadosQueryVariables>;
export const ListarItensTerminalDocument = gql`
    query listarItensTerminal {
  listarItensTerminal {
    label
    valor
  }
}
    `;
export type ListarItensTerminalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensTerminalQuery, ListarItensTerminalQueryVariables>, 'query'>;

    export const ListarItensTerminalComponent = (props: ListarItensTerminalComponentProps) => (
      <ApolloReactComponents.Query<ListarItensTerminalQuery, ListarItensTerminalQueryVariables> query={ListarItensTerminalDocument} {...props} />
    );
    
export type ListarItensTerminalProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensTerminalQuery, ListarItensTerminalQueryVariables> & TChildProps;
export function withListarItensTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensTerminalQuery,
  ListarItensTerminalQueryVariables,
  ListarItensTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensTerminalQuery, ListarItensTerminalQueryVariables, ListarItensTerminalProps<TChildProps>>(ListarItensTerminalDocument, {
      alias: 'listarItensTerminal',
      ...operationOptions
    });
};
export type ListarItensTerminalQueryResult = ApolloReactCommon.QueryResult<ListarItensTerminalQuery, ListarItensTerminalQueryVariables>;
export const ListarItensTerminalVinculoDocument = gql`
    query listarItensTerminalVinculo {
  listarItensTerminalVinculo {
    label
    valor
  }
}
    `;
export type ListarItensTerminalVinculoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensTerminalVinculoQuery, ListarItensTerminalVinculoQueryVariables>, 'query'>;

    export const ListarItensTerminalVinculoComponent = (props: ListarItensTerminalVinculoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensTerminalVinculoQuery, ListarItensTerminalVinculoQueryVariables> query={ListarItensTerminalVinculoDocument} {...props} />
    );
    
export type ListarItensTerminalVinculoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensTerminalVinculoQuery, ListarItensTerminalVinculoQueryVariables> & TChildProps;
export function withListarItensTerminalVinculo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensTerminalVinculoQuery,
  ListarItensTerminalVinculoQueryVariables,
  ListarItensTerminalVinculoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensTerminalVinculoQuery, ListarItensTerminalVinculoQueryVariables, ListarItensTerminalVinculoProps<TChildProps>>(ListarItensTerminalVinculoDocument, {
      alias: 'listarItensTerminalVinculo',
      ...operationOptions
    });
};
export type ListarItensTerminalVinculoQueryResult = ApolloReactCommon.QueryResult<ListarItensTerminalVinculoQuery, ListarItensTerminalVinculoQueryVariables>;
export const ListarItensTipoArquivoDocument = gql`
    query listarItensTipoArquivo {
  listarItensTipoArquivo {
    label
    valor
  }
}
    `;
export type ListarItensTipoArquivoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensTipoArquivoQuery, ListarItensTipoArquivoQueryVariables>, 'query'>;

    export const ListarItensTipoArquivoComponent = (props: ListarItensTipoArquivoComponentProps) => (
      <ApolloReactComponents.Query<ListarItensTipoArquivoQuery, ListarItensTipoArquivoQueryVariables> query={ListarItensTipoArquivoDocument} {...props} />
    );
    
export type ListarItensTipoArquivoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensTipoArquivoQuery, ListarItensTipoArquivoQueryVariables> & TChildProps;
export function withListarItensTipoArquivo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensTipoArquivoQuery,
  ListarItensTipoArquivoQueryVariables,
  ListarItensTipoArquivoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensTipoArquivoQuery, ListarItensTipoArquivoQueryVariables, ListarItensTipoArquivoProps<TChildProps>>(ListarItensTipoArquivoDocument, {
      alias: 'listarItensTipoArquivo',
      ...operationOptions
    });
};
export type ListarItensTipoArquivoQueryResult = ApolloReactCommon.QueryResult<ListarItensTipoArquivoQuery, ListarItensTipoArquivoQueryVariables>;
export const ListarItensUnidadesConciliadorasDocument = gql`
    query listarItensUnidadesConciliadoras {
  listarItensUnidadesConciliadoras {
    label
    valor
  }
}
    `;
export type ListarItensUnidadesConciliadorasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensUnidadesConciliadorasQuery, ListarItensUnidadesConciliadorasQueryVariables>, 'query'>;

    export const ListarItensUnidadesConciliadorasComponent = (props: ListarItensUnidadesConciliadorasComponentProps) => (
      <ApolloReactComponents.Query<ListarItensUnidadesConciliadorasQuery, ListarItensUnidadesConciliadorasQueryVariables> query={ListarItensUnidadesConciliadorasDocument} {...props} />
    );
    
export type ListarItensUnidadesConciliadorasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensUnidadesConciliadorasQuery, ListarItensUnidadesConciliadorasQueryVariables> & TChildProps;
export function withListarItensUnidadesConciliadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensUnidadesConciliadorasQuery,
  ListarItensUnidadesConciliadorasQueryVariables,
  ListarItensUnidadesConciliadorasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensUnidadesConciliadorasQuery, ListarItensUnidadesConciliadorasQueryVariables, ListarItensUnidadesConciliadorasProps<TChildProps>>(ListarItensUnidadesConciliadorasDocument, {
      alias: 'listarItensUnidadesConciliadoras',
      ...operationOptions
    });
};
export type ListarItensUnidadesConciliadorasQueryResult = ApolloReactCommon.QueryResult<ListarItensUnidadesConciliadorasQuery, ListarItensUnidadesConciliadorasQueryVariables>;
export const ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraDocument = gql`
    query listarItensUnidadesConciliadorasFromCodPessoaCodOperadora($codPessoa: Int, $codOperadora: Int) {
  listarItensUnidadesConciliadorasFromCodPessoaCodOperadora(codPessoa: $codPessoa, codOperadora: $codOperadora) {
    label
    valor
  }
}
    `;
export type ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables>, 'query'>;

    export const ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraComponent = (props: ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraComponentProps) => (
      <ApolloReactComponents.Query<ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables> query={ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraDocument} {...props} />
    );
    
export type ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables> & TChildProps;
export function withListarItensUnidadesConciliadorasFromCodPessoaCodOperadora<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery,
  ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables,
  ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraProps<TChildProps>>(ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraDocument, {
      alias: 'listarItensUnidadesConciliadorasFromCodPessoaCodOperadora',
      ...operationOptions
    });
};
export type ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryResult = ApolloReactCommon.QueryResult<ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQuery, ListarItensUnidadesConciliadorasFromCodPessoaCodOperadoraQueryVariables>;
export const ListarManutencaoArquivosDocument = gql`
    query listarManutencaoArquivos($values: ManutencaoArquivosInput) {
  listarManutencaoArquivos(values: $values) {
    codarquivo
    dataenvio
    datarecebimento
    dataprocessamento
    status
    tipo
    nomearquivo
    mensagem
  }
}
    `;
export type ListarManutencaoArquivosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarManutencaoArquivosQuery, ListarManutencaoArquivosQueryVariables>, 'query'>;

    export const ListarManutencaoArquivosComponent = (props: ListarManutencaoArquivosComponentProps) => (
      <ApolloReactComponents.Query<ListarManutencaoArquivosQuery, ListarManutencaoArquivosQueryVariables> query={ListarManutencaoArquivosDocument} {...props} />
    );
    
export type ListarManutencaoArquivosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarManutencaoArquivosQuery, ListarManutencaoArquivosQueryVariables> & TChildProps;
export function withListarManutencaoArquivos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarManutencaoArquivosQuery,
  ListarManutencaoArquivosQueryVariables,
  ListarManutencaoArquivosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarManutencaoArquivosQuery, ListarManutencaoArquivosQueryVariables, ListarManutencaoArquivosProps<TChildProps>>(ListarManutencaoArquivosDocument, {
      alias: 'listarManutencaoArquivos',
      ...operationOptions
    });
};
export type ListarManutencaoArquivosQueryResult = ApolloReactCommon.QueryResult<ListarManutencaoArquivosQuery, ListarManutencaoArquivosQueryVariables>;
export const ListarMovimentosFechadosDocument = gql`
    query listarMovimentosFechados($values: FaturamentoMovimentoInput) {
  listarMovimentosFechados(values: $values) {
    datainclusao
    filtrode
    filtroate
    valorbruto
    valorliquido
    creditos
    debitos
    total
  }
}
    `;
export type ListarMovimentosFechadosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarMovimentosFechadosQuery, ListarMovimentosFechadosQueryVariables>, 'query'>;

    export const ListarMovimentosFechadosComponent = (props: ListarMovimentosFechadosComponentProps) => (
      <ApolloReactComponents.Query<ListarMovimentosFechadosQuery, ListarMovimentosFechadosQueryVariables> query={ListarMovimentosFechadosDocument} {...props} />
    );
    
export type ListarMovimentosFechadosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarMovimentosFechadosQuery, ListarMovimentosFechadosQueryVariables> & TChildProps;
export function withListarMovimentosFechados<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarMovimentosFechadosQuery,
  ListarMovimentosFechadosQueryVariables,
  ListarMovimentosFechadosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarMovimentosFechadosQuery, ListarMovimentosFechadosQueryVariables, ListarMovimentosFechadosProps<TChildProps>>(ListarMovimentosFechadosDocument, {
      alias: 'listarMovimentosFechados',
      ...operationOptions
    });
};
export type ListarMovimentosFechadosQueryResult = ApolloReactCommon.QueryResult<ListarMovimentosFechadosQuery, ListarMovimentosFechadosQueryVariables>;
export const ListarOfNamesTipoMeioCapturaSemVazioDocument = gql`
    query listarOfNamesTipoMeioCapturaSemVazio {
  listarOfNamesTipoMeioCapturaSemVazio {
    label
    valor
  }
}
    `;
export type ListarOfNamesTipoMeioCapturaSemVazioComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarOfNamesTipoMeioCapturaSemVazioQuery, ListarOfNamesTipoMeioCapturaSemVazioQueryVariables>, 'query'>;

    export const ListarOfNamesTipoMeioCapturaSemVazioComponent = (props: ListarOfNamesTipoMeioCapturaSemVazioComponentProps) => (
      <ApolloReactComponents.Query<ListarOfNamesTipoMeioCapturaSemVazioQuery, ListarOfNamesTipoMeioCapturaSemVazioQueryVariables> query={ListarOfNamesTipoMeioCapturaSemVazioDocument} {...props} />
    );
    
export type ListarOfNamesTipoMeioCapturaSemVazioProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarOfNamesTipoMeioCapturaSemVazioQuery, ListarOfNamesTipoMeioCapturaSemVazioQueryVariables> & TChildProps;
export function withListarOfNamesTipoMeioCapturaSemVazio<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarOfNamesTipoMeioCapturaSemVazioQuery,
  ListarOfNamesTipoMeioCapturaSemVazioQueryVariables,
  ListarOfNamesTipoMeioCapturaSemVazioProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarOfNamesTipoMeioCapturaSemVazioQuery, ListarOfNamesTipoMeioCapturaSemVazioQueryVariables, ListarOfNamesTipoMeioCapturaSemVazioProps<TChildProps>>(ListarOfNamesTipoMeioCapturaSemVazioDocument, {
      alias: 'listarOfNamesTipoMeioCapturaSemVazio',
      ...operationOptions
    });
};
export type ListarOfNamesTipoMeioCapturaSemVazioQueryResult = ApolloReactCommon.QueryResult<ListarOfNamesTipoMeioCapturaSemVazioQuery, ListarOfNamesTipoMeioCapturaSemVazioQueryVariables>;
export const ListarOperadorasDocument = gql`
    query listarOperadoras {
  listarOperadoras {
    label
    valor
  }
}
    `;
export type ListarOperadorasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarOperadorasQuery, ListarOperadorasQueryVariables>, 'query'>;

    export const ListarOperadorasComponent = (props: ListarOperadorasComponentProps) => (
      <ApolloReactComponents.Query<ListarOperadorasQuery, ListarOperadorasQueryVariables> query={ListarOperadorasDocument} {...props} />
    );
    
export type ListarOperadorasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarOperadorasQuery, ListarOperadorasQueryVariables> & TChildProps;
export function withListarOperadoras<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarOperadorasQuery,
  ListarOperadorasQueryVariables,
  ListarOperadorasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarOperadorasQuery, ListarOperadorasQueryVariables, ListarOperadorasProps<TChildProps>>(ListarOperadorasDocument, {
      alias: 'listarOperadoras',
      ...operationOptions
    });
};
export type ListarOperadorasQueryResult = ApolloReactCommon.QueryResult<ListarOperadorasQuery, ListarOperadorasQueryVariables>;
export const ListarParametrosDocument = gql`
    query listarParametros {
  listarParametros {
    grupo
    item
    texto
  }
}
    `;
export type ListarParametrosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarParametrosQuery, ListarParametrosQueryVariables>, 'query'>;

    export const ListarParametrosComponent = (props: ListarParametrosComponentProps) => (
      <ApolloReactComponents.Query<ListarParametrosQuery, ListarParametrosQueryVariables> query={ListarParametrosDocument} {...props} />
    );
    
export type ListarParametrosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarParametrosQuery, ListarParametrosQueryVariables> & TChildProps;
export function withListarParametros<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarParametrosQuery,
  ListarParametrosQueryVariables,
  ListarParametrosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarParametrosQuery, ListarParametrosQueryVariables, ListarParametrosProps<TChildProps>>(ListarParametrosDocument, {
      alias: 'listarParametros',
      ...operationOptions
    });
};
export type ListarParametrosQueryResult = ApolloReactCommon.QueryResult<ListarParametrosQuery, ListarParametrosQueryVariables>;
export const ListarParametrosTotvsDocument = gql`
    query listarParametrosTOTVS {
  listarParametrosTOTVS {
    codpessoa
    codfilial
    opcional1
    opcional2
    coddepartamento
    codcentrocusto
    tipocontabil
    codmoeda
    codcontacaixa
    statuslancamento
    codcoligadaclifor
    codcoligadacontacaixa
    tiposacado
    codevento
    nome
  }
}
    `;
export type ListarParametrosTotvsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarParametrosTotvsQuery, ListarParametrosTotvsQueryVariables>, 'query'>;

    export const ListarParametrosTotvsComponent = (props: ListarParametrosTotvsComponentProps) => (
      <ApolloReactComponents.Query<ListarParametrosTotvsQuery, ListarParametrosTotvsQueryVariables> query={ListarParametrosTotvsDocument} {...props} />
    );
    
export type ListarParametrosTotvsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarParametrosTotvsQuery, ListarParametrosTotvsQueryVariables> & TChildProps;
export function withListarParametrosTotvs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarParametrosTotvsQuery,
  ListarParametrosTotvsQueryVariables,
  ListarParametrosTotvsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarParametrosTotvsQuery, ListarParametrosTotvsQueryVariables, ListarParametrosTotvsProps<TChildProps>>(ListarParametrosTotvsDocument, {
      alias: 'listarParametrosTotvs',
      ...operationOptions
    });
};
export type ListarParametrosTotvsQueryResult = ApolloReactCommon.QueryResult<ListarParametrosTotvsQuery, ListarParametrosTotvsQueryVariables>;
export const ListarPerfilDocument = gql`
    query listarPerfil {
  listarPerfil {
    codperfil
    nome
  }
}
    `;
export type ListarPerfilComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPerfilQuery, ListarPerfilQueryVariables>, 'query'>;

    export const ListarPerfilComponent = (props: ListarPerfilComponentProps) => (
      <ApolloReactComponents.Query<ListarPerfilQuery, ListarPerfilQueryVariables> query={ListarPerfilDocument} {...props} />
    );
    
export type ListarPerfilProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPerfilQuery, ListarPerfilQueryVariables> & TChildProps;
export function withListarPerfil<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPerfilQuery,
  ListarPerfilQueryVariables,
  ListarPerfilProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPerfilQuery, ListarPerfilQueryVariables, ListarPerfilProps<TChildProps>>(ListarPerfilDocument, {
      alias: 'listarPerfil',
      ...operationOptions
    });
};
export type ListarPerfilQueryResult = ApolloReactCommon.QueryResult<ListarPerfilQuery, ListarPerfilQueryVariables>;
export const ListarPerfisDocument = gql`
    query listarPerfis {
  listarPerfis {
    label
    valor
  }
}
    `;
export type ListarPerfisComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPerfisQuery, ListarPerfisQueryVariables>, 'query'>;

    export const ListarPerfisComponent = (props: ListarPerfisComponentProps) => (
      <ApolloReactComponents.Query<ListarPerfisQuery, ListarPerfisQueryVariables> query={ListarPerfisDocument} {...props} />
    );
    
export type ListarPerfisProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPerfisQuery, ListarPerfisQueryVariables> & TChildProps;
export function withListarPerfis<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPerfisQuery,
  ListarPerfisQueryVariables,
  ListarPerfisProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPerfisQuery, ListarPerfisQueryVariables, ListarPerfisProps<TChildProps>>(ListarPerfisDocument, {
      alias: 'listarPerfis',
      ...operationOptions
    });
};
export type ListarPerfisQueryResult = ApolloReactCommon.QueryResult<ListarPerfisQuery, ListarPerfisQueryVariables>;
export const ListarPlanosConciliadoresDocument = gql`
    query listarPlanosConciliadores($values: PlanosConciliadoresDAOInput) {
  listarPlanosConciliadores(values: $values) {
    codplanosconciliadores
    nome
    codpessoapai
    tarifacao
  }
}
    `;
export type ListarPlanosConciliadoresComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosConciliadoresQuery, ListarPlanosConciliadoresQueryVariables>, 'query'>;

    export const ListarPlanosConciliadoresComponent = (props: ListarPlanosConciliadoresComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosConciliadoresQuery, ListarPlanosConciliadoresQueryVariables> query={ListarPlanosConciliadoresDocument} {...props} />
    );
    
export type ListarPlanosConciliadoresProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosConciliadoresQuery, ListarPlanosConciliadoresQueryVariables> & TChildProps;
export function withListarPlanosConciliadores<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosConciliadoresQuery,
  ListarPlanosConciliadoresQueryVariables,
  ListarPlanosConciliadoresProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosConciliadoresQuery, ListarPlanosConciliadoresQueryVariables, ListarPlanosConciliadoresProps<TChildProps>>(ListarPlanosConciliadoresDocument, {
      alias: 'listarPlanosConciliadores',
      ...operationOptions
    });
};
export type ListarPlanosConciliadoresQueryResult = ApolloReactCommon.QueryResult<ListarPlanosConciliadoresQuery, ListarPlanosConciliadoresQueryVariables>;
export const ListarPlanosConciliadoresContratoDocument = gql`
    query listarPlanosConciliadoresContrato($values: PlanosConciliadoresContratoDAOInput) {
  listarPlanosConciliadoresContrato(values: $values) {
    codplanosconciliadorescontrato
    codplanosconciliadores
    seq
    codoperadora
    codpessoa
    codexterno
    vigenciaDe
    vigenciaAte
    codpessoapai
    plano
  }
}
    `;
export type ListarPlanosConciliadoresContratoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosConciliadoresContratoQuery, ListarPlanosConciliadoresContratoQueryVariables>, 'query'>;

    export const ListarPlanosConciliadoresContratoComponent = (props: ListarPlanosConciliadoresContratoComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosConciliadoresContratoQuery, ListarPlanosConciliadoresContratoQueryVariables> query={ListarPlanosConciliadoresContratoDocument} {...props} />
    );
    
export type ListarPlanosConciliadoresContratoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosConciliadoresContratoQuery, ListarPlanosConciliadoresContratoQueryVariables> & TChildProps;
export function withListarPlanosConciliadoresContrato<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosConciliadoresContratoQuery,
  ListarPlanosConciliadoresContratoQueryVariables,
  ListarPlanosConciliadoresContratoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosConciliadoresContratoQuery, ListarPlanosConciliadoresContratoQueryVariables, ListarPlanosConciliadoresContratoProps<TChildProps>>(ListarPlanosConciliadoresContratoDocument, {
      alias: 'listarPlanosConciliadoresContrato',
      ...operationOptions
    });
};
export type ListarPlanosConciliadoresContratoQueryResult = ApolloReactCommon.QueryResult<ListarPlanosConciliadoresContratoQuery, ListarPlanosConciliadoresContratoQueryVariables>;
export const ListarPlanosConciliadoresTaxasDocument = gql`
    query listarPlanosConciliadoresTaxas($values: PlanosConciliadoresTaxasDAOInput) {
  listarPlanosConciliadoresTaxas(values: $values) {
    codplanosconciliadorestaxas
    codplanosconciliadores
    plano
    codprodutos
    converterProduto
    duplicado
    converterProdutoTipo
    bincartao
    converterProdutoTaxas
    de
    ate
    valorde
    valorate
    taxa
    nomeOperadora
  }
}
    `;
export type ListarPlanosConciliadoresTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosConciliadoresTaxasQuery, ListarPlanosConciliadoresTaxasQueryVariables>, 'query'>;

    export const ListarPlanosConciliadoresTaxasComponent = (props: ListarPlanosConciliadoresTaxasComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosConciliadoresTaxasQuery, ListarPlanosConciliadoresTaxasQueryVariables> query={ListarPlanosConciliadoresTaxasDocument} {...props} />
    );
    
export type ListarPlanosConciliadoresTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosConciliadoresTaxasQuery, ListarPlanosConciliadoresTaxasQueryVariables> & TChildProps;
export function withListarPlanosConciliadoresTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosConciliadoresTaxasQuery,
  ListarPlanosConciliadoresTaxasQueryVariables,
  ListarPlanosConciliadoresTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosConciliadoresTaxasQuery, ListarPlanosConciliadoresTaxasQueryVariables, ListarPlanosConciliadoresTaxasProps<TChildProps>>(ListarPlanosConciliadoresTaxasDocument, {
      alias: 'listarPlanosConciliadoresTaxas',
      ...operationOptions
    });
};
export type ListarPlanosConciliadoresTaxasQueryResult = ApolloReactCommon.QueryResult<ListarPlanosConciliadoresTaxasQuery, ListarPlanosConciliadoresTaxasQueryVariables>;
export const ListarPlanosPosDocument = gql`
    query listarPlanosPOS($values: PlanosDAOInput) {
  listarPlanosPOS(values: $values) {
    codplano
    nome
    codpessoapai
    cobramensalidade
    valormensalidade
    diacorte
  }
}
    `;
export type ListarPlanosPosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosPosQuery, ListarPlanosPosQueryVariables>, 'query'>;

    export const ListarPlanosPosComponent = (props: ListarPlanosPosComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosPosQuery, ListarPlanosPosQueryVariables> query={ListarPlanosPosDocument} {...props} />
    );
    
export type ListarPlanosPosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosPosQuery, ListarPlanosPosQueryVariables> & TChildProps;
export function withListarPlanosPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosPosQuery,
  ListarPlanosPosQueryVariables,
  ListarPlanosPosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosPosQuery, ListarPlanosPosQueryVariables, ListarPlanosPosProps<TChildProps>>(ListarPlanosPosDocument, {
      alias: 'listarPlanosPos',
      ...operationOptions
    });
};
export type ListarPlanosPosQueryResult = ApolloReactCommon.QueryResult<ListarPlanosPosQuery, ListarPlanosPosQueryVariables>;
export const ListarPlanosPosTaxasDocument = gql`
    query listarPlanosPOSTaxas($values: PlanosTaxasDAOInput) {
  listarPlanosPOSTaxas(values: $values) {
    codplanostaxas
    codplano
    codprodutos
    de
    ate
    taxa
    plano
    converterProduto
  }
}
    `;
export type ListarPlanosPosTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables>, 'query'>;

    export const ListarPlanosPosTaxasComponent = (props: ListarPlanosPosTaxasComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables> query={ListarPlanosPosTaxasDocument} {...props} />
    );
    
export type ListarPlanosPosTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables> & TChildProps;
export function withListarPlanosPosTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosPosTaxasQuery,
  ListarPlanosPosTaxasQueryVariables,
  ListarPlanosPosTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables, ListarPlanosPosTaxasProps<TChildProps>>(ListarPlanosPosTaxasDocument, {
      alias: 'listarPlanosPosTaxas',
      ...operationOptions
    });
};
export type ListarPlanosPosTaxasQueryResult = ApolloReactCommon.QueryResult<ListarPlanosPosTaxasQuery, ListarPlanosPosTaxasQueryVariables>;
export const ListarPlanosTerminalDocument = gql`
    query listarPlanosTerminal($values: PlanosTerminalDAOInput) {
  listarPlanosTerminal(values: $values) {
    codplanosterminal
    seq
    codplanos
    codterminal
    vigenciaDe
    vigenciaAte
    codpessoapai
    plano
  }
}
    `;
export type ListarPlanosTerminalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables>, 'query'>;

    export const ListarPlanosTerminalComponent = (props: ListarPlanosTerminalComponentProps) => (
      <ApolloReactComponents.Query<ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables> query={ListarPlanosTerminalDocument} {...props} />
    );
    
export type ListarPlanosTerminalProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables> & TChildProps;
export function withListarPlanosTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarPlanosTerminalQuery,
  ListarPlanosTerminalQueryVariables,
  ListarPlanosTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables, ListarPlanosTerminalProps<TChildProps>>(ListarPlanosTerminalDocument, {
      alias: 'listarPlanosTerminal',
      ...operationOptions
    });
};
export type ListarPlanosTerminalQueryResult = ApolloReactCommon.QueryResult<ListarPlanosTerminalQuery, ListarPlanosTerminalQueryVariables>;
export const ListarStatusConciliacaoDocument = gql`
    query listarStatusConciliacao {
  listarStatusConciliacao {
    label
    valor
  }
}
    `;
export type ListarStatusConciliacaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarStatusConciliacaoQuery, ListarStatusConciliacaoQueryVariables>, 'query'>;

    export const ListarStatusConciliacaoComponent = (props: ListarStatusConciliacaoComponentProps) => (
      <ApolloReactComponents.Query<ListarStatusConciliacaoQuery, ListarStatusConciliacaoQueryVariables> query={ListarStatusConciliacaoDocument} {...props} />
    );
    
export type ListarStatusConciliacaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarStatusConciliacaoQuery, ListarStatusConciliacaoQueryVariables> & TChildProps;
export function withListarStatusConciliacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarStatusConciliacaoQuery,
  ListarStatusConciliacaoQueryVariables,
  ListarStatusConciliacaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarStatusConciliacaoQuery, ListarStatusConciliacaoQueryVariables, ListarStatusConciliacaoProps<TChildProps>>(ListarStatusConciliacaoDocument, {
      alias: 'listarStatusConciliacao',
      ...operationOptions
    });
};
export type ListarStatusConciliacaoQueryResult = ApolloReactCommon.QueryResult<ListarStatusConciliacaoQuery, ListarStatusConciliacaoQueryVariables>;
export const ListarTaxasAntecipacaoDocument = gql`
    query listarTaxasAntecipacao($values: TaxaAntecipacaoDAOInput) {
  listarTaxasAntecipacao(values: $values) {
    nome
    vigenciade
    vigenciaate
    taxa
    codtaxaantecipacao
  }
}
    `;
export type ListarTaxasAntecipacaoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarTaxasAntecipacaoQuery, ListarTaxasAntecipacaoQueryVariables>, 'query'>;

    export const ListarTaxasAntecipacaoComponent = (props: ListarTaxasAntecipacaoComponentProps) => (
      <ApolloReactComponents.Query<ListarTaxasAntecipacaoQuery, ListarTaxasAntecipacaoQueryVariables> query={ListarTaxasAntecipacaoDocument} {...props} />
    );
    
export type ListarTaxasAntecipacaoProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarTaxasAntecipacaoQuery, ListarTaxasAntecipacaoQueryVariables> & TChildProps;
export function withListarTaxasAntecipacao<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarTaxasAntecipacaoQuery,
  ListarTaxasAntecipacaoQueryVariables,
  ListarTaxasAntecipacaoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarTaxasAntecipacaoQuery, ListarTaxasAntecipacaoQueryVariables, ListarTaxasAntecipacaoProps<TChildProps>>(ListarTaxasAntecipacaoDocument, {
      alias: 'listarTaxasAntecipacao',
      ...operationOptions
    });
};
export type ListarTaxasAntecipacaoQueryResult = ApolloReactCommon.QueryResult<ListarTaxasAntecipacaoQuery, ListarTaxasAntecipacaoQueryVariables>;
export const ListarTefsDocument = gql`
    query listarTefs {
  listarTefs {
    label
    valor
  }
}
    `;
export type ListarTefsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarTefsQuery, ListarTefsQueryVariables>, 'query'>;

    export const ListarTefsComponent = (props: ListarTefsComponentProps) => (
      <ApolloReactComponents.Query<ListarTefsQuery, ListarTefsQueryVariables> query={ListarTefsDocument} {...props} />
    );
    
export type ListarTefsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarTefsQuery, ListarTefsQueryVariables> & TChildProps;
export function withListarTefs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarTefsQuery,
  ListarTefsQueryVariables,
  ListarTefsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarTefsQuery, ListarTefsQueryVariables, ListarTefsProps<TChildProps>>(ListarTefsDocument, {
      alias: 'listarTefs',
      ...operationOptions
    });
};
export type ListarTefsQueryResult = ApolloReactCommon.QueryResult<ListarTefsQuery, ListarTefsQueryVariables>;
export const ListarTerminalDocument = gql`
    query listarTerminal($values: TerminalDAOInput) {
  listarTerminal(values: $values) {
    codexterno
    nome
    vinculo
    status
    codpessoapai
  }
}
    `;
export type ListarTerminalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarTerminalQuery, ListarTerminalQueryVariables>, 'query'>;

    export const ListarTerminalComponent = (props: ListarTerminalComponentProps) => (
      <ApolloReactComponents.Query<ListarTerminalQuery, ListarTerminalQueryVariables> query={ListarTerminalDocument} {...props} />
    );
    
export type ListarTerminalProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarTerminalQuery, ListarTerminalQueryVariables> & TChildProps;
export function withListarTerminal<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarTerminalQuery,
  ListarTerminalQueryVariables,
  ListarTerminalProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarTerminalQuery, ListarTerminalQueryVariables, ListarTerminalProps<TChildProps>>(ListarTerminalDocument, {
      alias: 'listarTerminal',
      ...operationOptions
    });
};
export type ListarTerminalQueryResult = ApolloReactCommon.QueryResult<ListarTerminalQuery, ListarTerminalQueryVariables>;
export const ListarTicketsDocument = gql`
    query listarTickets($values: ImportacaoTicketInput) {
  listarTickets(values: $values) {
    datavenda
    totparcelas
    valor
    nsu
    tid
    autorizacao
    numterminal
    codexterno
    codpessoa
    codoperadora
  }
}
    `;
export type ListarTicketsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarTicketsQuery, ListarTicketsQueryVariables>, 'query'>;

    export const ListarTicketsComponent = (props: ListarTicketsComponentProps) => (
      <ApolloReactComponents.Query<ListarTicketsQuery, ListarTicketsQueryVariables> query={ListarTicketsDocument} {...props} />
    );
    
export type ListarTicketsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarTicketsQuery, ListarTicketsQueryVariables> & TChildProps;
export function withListarTickets<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarTicketsQuery,
  ListarTicketsQueryVariables,
  ListarTicketsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarTicketsQuery, ListarTicketsQueryVariables, ListarTicketsProps<TChildProps>>(ListarTicketsDocument, {
      alias: 'listarTickets',
      ...operationOptions
    });
};
export type ListarTicketsQueryResult = ApolloReactCommon.QueryResult<ListarTicketsQuery, ListarTicketsQueryVariables>;
export const ListarUFsDocument = gql`
    query listarUFs {
  listarUFs {
    label
    valor
  }
}
    `;
export type ListarUFsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarUFsQuery, ListarUFsQueryVariables>, 'query'>;

    export const ListarUFsComponent = (props: ListarUFsComponentProps) => (
      <ApolloReactComponents.Query<ListarUFsQuery, ListarUFsQueryVariables> query={ListarUFsDocument} {...props} />
    );
    
export type ListarUFsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarUFsQuery, ListarUFsQueryVariables> & TChildProps;
export function withListarUFs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarUFsQuery,
  ListarUFsQueryVariables,
  ListarUFsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarUFsQuery, ListarUFsQueryVariables, ListarUFsProps<TChildProps>>(ListarUFsDocument, {
      alias: 'listarUFs',
      ...operationOptions
    });
};
export type ListarUFsQueryResult = ApolloReactCommon.QueryResult<ListarUFsQuery, ListarUFsQueryVariables>;
export const ListarUsuarioDocument = gql`
    query listarUsuario($values: UsuarioDAOInput) {
  listarUsuario(values: $values) {
    codpessoa
    login
    validade
    nome
  }
}
    `;
export type ListarUsuarioComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListarUsuarioQuery, ListarUsuarioQueryVariables>, 'query'>;

    export const ListarUsuarioComponent = (props: ListarUsuarioComponentProps) => (
      <ApolloReactComponents.Query<ListarUsuarioQuery, ListarUsuarioQueryVariables> query={ListarUsuarioDocument} {...props} />
    );
    
export type ListarUsuarioProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListarUsuarioQuery, ListarUsuarioQueryVariables> & TChildProps;
export function withListarUsuario<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListarUsuarioQuery,
  ListarUsuarioQueryVariables,
  ListarUsuarioProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListarUsuarioQuery, ListarUsuarioQueryVariables, ListarUsuarioProps<TChildProps>>(ListarUsuarioDocument, {
      alias: 'listarUsuario',
      ...operationOptions
    });
};
export type ListarUsuarioQueryResult = ApolloReactCommon.QueryResult<ListarUsuarioQuery, ListarUsuarioQueryVariables>;
export const PesquisarTicketsDocument = gql`
    query pesquisarTickets($values: TicketsBeanInput) {
  pesquisarTickets(values: $values) {
    nsu
    tid
    autorizacao
    codexterno
    datavenda
    tipo
    dataprocessamento
    codlinhasarqcli
    codoperadora
    codpessoa
    origem
  }
}
    `;
export type PesquisarTicketsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PesquisarTicketsQuery, PesquisarTicketsQueryVariables>, 'query'>;

    export const PesquisarTicketsComponent = (props: PesquisarTicketsComponentProps) => (
      <ApolloReactComponents.Query<PesquisarTicketsQuery, PesquisarTicketsQueryVariables> query={PesquisarTicketsDocument} {...props} />
    );
    
export type PesquisarTicketsProps<TChildProps = {}> = ApolloReactHoc.DataProps<PesquisarTicketsQuery, PesquisarTicketsQueryVariables> & TChildProps;
export function withPesquisarTickets<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PesquisarTicketsQuery,
  PesquisarTicketsQueryVariables,
  PesquisarTicketsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PesquisarTicketsQuery, PesquisarTicketsQueryVariables, PesquisarTicketsProps<TChildProps>>(PesquisarTicketsDocument, {
      alias: 'pesquisarTickets',
      ...operationOptions
    });
};
export type PesquisarTicketsQueryResult = ApolloReactCommon.QueryResult<PesquisarTicketsQuery, PesquisarTicketsQueryVariables>;
export const PrevisualizarNotaDocument = gql`
    query previsualizarNota($values: FaturamentoMovimentoBeanInput) {
  previsualizarNota(values: $values)
}
    `;
export type PrevisualizarNotaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PrevisualizarNotaQuery, PrevisualizarNotaQueryVariables>, 'query'>;

    export const PrevisualizarNotaComponent = (props: PrevisualizarNotaComponentProps) => (
      <ApolloReactComponents.Query<PrevisualizarNotaQuery, PrevisualizarNotaQueryVariables> query={PrevisualizarNotaDocument} {...props} />
    );
    
export type PrevisualizarNotaProps<TChildProps = {}> = ApolloReactHoc.DataProps<PrevisualizarNotaQuery, PrevisualizarNotaQueryVariables> & TChildProps;
export function withPrevisualizarNota<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PrevisualizarNotaQuery,
  PrevisualizarNotaQueryVariables,
  PrevisualizarNotaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PrevisualizarNotaQuery, PrevisualizarNotaQueryVariables, PrevisualizarNotaProps<TChildProps>>(PrevisualizarNotaDocument, {
      alias: 'previsualizarNota',
      ...operationOptions
    });
};
export type PrevisualizarNotaQueryResult = ApolloReactCommon.QueryResult<PrevisualizarNotaQuery, PrevisualizarNotaQueryVariables>;
export const ResultadoAddTaxasDocument = gql`
    query resultadoAddTaxas($de: Int, $ate: Int, $taxa: Float, $tipotaxa: String, $insert: Boolean) {
  resultadoAddTaxas(de: $de, ate: $ate, taxa: $taxa, tipotaxa: $tipotaxa, insert: $insert) {
    taxas {
      de
      ate
      taxa
    }
    mensagem
    listDeTaxas {
      de
      ate
      taxa
      codplanosconciliadorestaxas
      tipotaxa
    }
  }
}
    `;
export type ResultadoAddTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoAddTaxasQuery, ResultadoAddTaxasQueryVariables>, 'query'>;

    export const ResultadoAddTaxasComponent = (props: ResultadoAddTaxasComponentProps) => (
      <ApolloReactComponents.Query<ResultadoAddTaxasQuery, ResultadoAddTaxasQueryVariables> query={ResultadoAddTaxasDocument} {...props} />
    );
    
export type ResultadoAddTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoAddTaxasQuery, ResultadoAddTaxasQueryVariables> & TChildProps;
export function withResultadoAddTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoAddTaxasQuery,
  ResultadoAddTaxasQueryVariables,
  ResultadoAddTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoAddTaxasQuery, ResultadoAddTaxasQueryVariables, ResultadoAddTaxasProps<TChildProps>>(ResultadoAddTaxasDocument, {
      alias: 'resultadoAddTaxas',
      ...operationOptions
    });
};
export type ResultadoAddTaxasQueryResult = ApolloReactCommon.QueryResult<ResultadoAddTaxasQuery, ResultadoAddTaxasQueryVariables>;
export const ResultadoAddTaxasBndesDocument = gql`
    query resultadoAddTaxasBndes($valorde: Float, $valorate: Float, $taxa: Float, $insert: Boolean) {
  resultadoAddTaxasBndes(valorde: $valorde, valorate: $valorate, taxa: $taxa, insert: $insert) {
    taxas {
      valorde
      valorate
      taxa
    }
    mensagem
    listDeTaxas {
      valorde
      valorate
      taxa
      codplanosconciliadorestaxas
    }
  }
}
    `;
export type ResultadoAddTaxasBndesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoAddTaxasBndesQuery, ResultadoAddTaxasBndesQueryVariables>, 'query'>;

    export const ResultadoAddTaxasBndesComponent = (props: ResultadoAddTaxasBndesComponentProps) => (
      <ApolloReactComponents.Query<ResultadoAddTaxasBndesQuery, ResultadoAddTaxasBndesQueryVariables> query={ResultadoAddTaxasBndesDocument} {...props} />
    );
    
export type ResultadoAddTaxasBndesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoAddTaxasBndesQuery, ResultadoAddTaxasBndesQueryVariables> & TChildProps;
export function withResultadoAddTaxasBndes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoAddTaxasBndesQuery,
  ResultadoAddTaxasBndesQueryVariables,
  ResultadoAddTaxasBndesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoAddTaxasBndesQuery, ResultadoAddTaxasBndesQueryVariables, ResultadoAddTaxasBndesProps<TChildProps>>(ResultadoAddTaxasBndesDocument, {
      alias: 'resultadoAddTaxasBndes',
      ...operationOptions
    });
};
export type ResultadoAddTaxasBndesQueryResult = ApolloReactCommon.QueryResult<ResultadoAddTaxasBndesQuery, ResultadoAddTaxasBndesQueryVariables>;
export const ResultadoAddTaxasPosDocument = gql`
    query resultadoAddTaxasPos($de: Int, $ate: Int, $taxa: Float, $insert: Boolean) {
  resultadoAddTaxasPos(de: $de, ate: $ate, taxa: $taxa, insert: $insert) {
    taxas {
      de
      ate
      taxa
    }
    mensagem
    listDeTaxas {
      de
      ate
      taxa
      codplanostaxas
    }
  }
}
    `;
export type ResultadoAddTaxasPosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoAddTaxasPosQuery, ResultadoAddTaxasPosQueryVariables>, 'query'>;

    export const ResultadoAddTaxasPosComponent = (props: ResultadoAddTaxasPosComponentProps) => (
      <ApolloReactComponents.Query<ResultadoAddTaxasPosQuery, ResultadoAddTaxasPosQueryVariables> query={ResultadoAddTaxasPosDocument} {...props} />
    );
    
export type ResultadoAddTaxasPosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoAddTaxasPosQuery, ResultadoAddTaxasPosQueryVariables> & TChildProps;
export function withResultadoAddTaxasPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoAddTaxasPosQuery,
  ResultadoAddTaxasPosQueryVariables,
  ResultadoAddTaxasPosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoAddTaxasPosQuery, ResultadoAddTaxasPosQueryVariables, ResultadoAddTaxasPosProps<TChildProps>>(ResultadoAddTaxasPosDocument, {
      alias: 'resultadoAddTaxasPos',
      ...operationOptions
    });
};
export type ResultadoAddTaxasPosQueryResult = ApolloReactCommon.QueryResult<ResultadoAddTaxasPosQuery, ResultadoAddTaxasPosQueryVariables>;
export const ResultadoAtualizaTaxasDocument = gql`
    query resultadoAtualizaTaxas($codtaxa: Int) {
  resultadoAtualizaTaxas(codtaxa: $codtaxa) {
    taxas {
      de
      ate
      taxa
    }
    mensagem
    listDeTaxas {
      de
      ate
      taxa
      codplanosconciliadorestaxas
    }
  }
}
    `;
export type ResultadoAtualizaTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoAtualizaTaxasQuery, ResultadoAtualizaTaxasQueryVariables>, 'query'>;

    export const ResultadoAtualizaTaxasComponent = (props: ResultadoAtualizaTaxasComponentProps) => (
      <ApolloReactComponents.Query<ResultadoAtualizaTaxasQuery, ResultadoAtualizaTaxasQueryVariables> query={ResultadoAtualizaTaxasDocument} {...props} />
    );
    
export type ResultadoAtualizaTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoAtualizaTaxasQuery, ResultadoAtualizaTaxasQueryVariables> & TChildProps;
export function withResultadoAtualizaTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoAtualizaTaxasQuery,
  ResultadoAtualizaTaxasQueryVariables,
  ResultadoAtualizaTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoAtualizaTaxasQuery, ResultadoAtualizaTaxasQueryVariables, ResultadoAtualizaTaxasProps<TChildProps>>(ResultadoAtualizaTaxasDocument, {
      alias: 'resultadoAtualizaTaxas',
      ...operationOptions
    });
};
export type ResultadoAtualizaTaxasQueryResult = ApolloReactCommon.QueryResult<ResultadoAtualizaTaxasQuery, ResultadoAtualizaTaxasQueryVariables>;
export const ResultadoSubTaxasDocument = gql`
    query resultadoSubTaxas($codtaxa: Int, $tarifacao: String) {
  resultadoSubTaxas(codtaxa: $codtaxa, tarifacao: $tarifacao) {
    taxas {
      de
      ate
      taxa
    }
    mensagem
    listDeTaxas {
      de
      ate
      taxa
      codplanosconciliadorestaxas
    }
  }
}
    `;
export type ResultadoSubTaxasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoSubTaxasQuery, ResultadoSubTaxasQueryVariables>, 'query'>;

    export const ResultadoSubTaxasComponent = (props: ResultadoSubTaxasComponentProps) => (
      <ApolloReactComponents.Query<ResultadoSubTaxasQuery, ResultadoSubTaxasQueryVariables> query={ResultadoSubTaxasDocument} {...props} />
    );
    
export type ResultadoSubTaxasProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoSubTaxasQuery, ResultadoSubTaxasQueryVariables> & TChildProps;
export function withResultadoSubTaxas<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoSubTaxasQuery,
  ResultadoSubTaxasQueryVariables,
  ResultadoSubTaxasProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoSubTaxasQuery, ResultadoSubTaxasQueryVariables, ResultadoSubTaxasProps<TChildProps>>(ResultadoSubTaxasDocument, {
      alias: 'resultadoSubTaxas',
      ...operationOptions
    });
};
export type ResultadoSubTaxasQueryResult = ApolloReactCommon.QueryResult<ResultadoSubTaxasQuery, ResultadoSubTaxasQueryVariables>;
export const ResultadoSubTaxasPosDocument = gql`
    query resultadoSubTaxasPos($codtaxa: Int) {
  resultadoSubTaxasPos(codtaxa: $codtaxa) {
    taxas {
      de
      ate
      taxa
    }
    mensagem
    listDeTaxas {
      de
      ate
      taxa
      codplanostaxas
    }
  }
}
    `;
export type ResultadoSubTaxasPosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultadoSubTaxasPosQuery, ResultadoSubTaxasPosQueryVariables>, 'query'>;

    export const ResultadoSubTaxasPosComponent = (props: ResultadoSubTaxasPosComponentProps) => (
      <ApolloReactComponents.Query<ResultadoSubTaxasPosQuery, ResultadoSubTaxasPosQueryVariables> query={ResultadoSubTaxasPosDocument} {...props} />
    );
    
export type ResultadoSubTaxasPosProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultadoSubTaxasPosQuery, ResultadoSubTaxasPosQueryVariables> & TChildProps;
export function withResultadoSubTaxasPos<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultadoSubTaxasPosQuery,
  ResultadoSubTaxasPosQueryVariables,
  ResultadoSubTaxasPosProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultadoSubTaxasPosQuery, ResultadoSubTaxasPosQueryVariables, ResultadoSubTaxasPosProps<TChildProps>>(ResultadoSubTaxasPosDocument, {
      alias: 'resultadoSubTaxasPos',
      ...operationOptions
    });
};
export type ResultadoSubTaxasPosQueryResult = ApolloReactCommon.QueryResult<ResultadoSubTaxasPosQuery, ResultadoSubTaxasPosQueryVariables>;